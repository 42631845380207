import React, { CSSProperties } from 'react';

type ArticleImageSiteIconProps = {
  siteIcon: string;
  background?: string;
  onMouseEnter?: () => any;
  onMouseLeave?: () => any;
  children?: JSX.Element;
  additionalStyles?: CSSProperties;
};

const ArticleImageSiteIcon = ({
  background,
  siteIcon,
  children,
  onMouseEnter,
  onMouseLeave,
  additionalStyles = {},
}: ArticleImageSiteIconProps) => {
  const iconContainerStyles = {
    height: '192px',
    minHeight: '192px',
    margin: '0 auto',
    backgroundColor: background,
    ...additionalStyles,
  };
  return (
    <div
      className="d-flex justify-content-center align-items-center position-relative"
      style={iconContainerStyles}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
      <img src={siteIcon} className="card-img-top__icon" alt="..." />
    </div>
  );
};

export default ArticleImageSiteIcon;
