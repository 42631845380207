import React, { useCallback } from 'react';
import Summary from './Summary';
import BookmarkImage from '../../assets/img/lp/bookmark-app.png';
import ClientRouter from '../../routers/ClientRouter';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../data/redux/hooks';
import { RootState } from '../../data/redux/store';

const BookmarkAppLP = () => {
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
  return (
    <Summary
      navProps={{
        buttons: user
          ? [
              <Link to={ClientRouter.login()}>
                <button className={'lp-header-btn mr-0 px-3 mx-3 button-reset-defaults'}>
                  Dashboard
                </button>
              </Link>,
            ]
          : [
              <Link to={ClientRouter.register()} className="text-blue-2 fw-700">
                Sign Up
              </Link>,
              <Link to={ClientRouter.login()}>
                <button className={'lp-header-btn mr-0 px-3 mx-3 button-reset-defaults'}>
                  Login
                </button>
              </Link>,
            ],
      }}
      mainButtonClassName="blue-bg sparks-button-md align-items-center"
      mainButtonText="Sign Up"
      descClassName="body-text-max"
      isMainButtonLinkInternal
      mainButtonLinkHref={ClientRouter.register()}
      titleText="Organize and Save Bookmarks Effortlessly"
      descText="Add articles, videos, and links from across the web in getSparks. Try Free!"
      titleClassName="sparks-h2 text-black no-gradient"
      imgSrc={BookmarkImage}
    />
  );
};

export default BookmarkAppLP;
