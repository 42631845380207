import React, {
  ChangeEvent,
  FormEventHandler,
  HTMLProps,
  useCallback,
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
} from 'react';
import { ReactComponent as SendIcon } from '../assets/img/icons/send.svg';
import { Colors } from '../assets/colors';
import Awaiter from './messages/Awaiter';

type Props = {
  onSubmit?: (value: string) => any;
  isTextArea?: boolean;
  inputProps?: HTMLProps<HTMLInputElement> & HTMLProps<HTMLTextAreaElement>;
};

const MainInput = ({ onSubmit, isTextArea, inputProps = {} }: Props) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (isTextArea && textAreaRef.current) {
      textAreaRef.current.style.height = '0px';
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + 'px';
    }
  }, [textAreaRef, value, isTextArea]);

  const handleSubmit = useCallback(async () => {
    if (onSubmit) {
      setLoading(true);
      await onSubmit(value);
      setValue('');
      setLoading(false);
    }
  }, [value, onSubmit]);

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement> | KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' || e.key === 'Return') {
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
      setValue(e.target.value);
    },
    [],
  ) as FormEventHandler<HTMLTextAreaElement> & FormEventHandler<HTMLInputElement>;

  const props = { ...inputProps, onChange: onInputChange, value, onKeyDown };

  return (
    <div className="main-input position-relative flex-grow-1 d-flex mx-md-4 my-md-3">
      {isTextArea ? (
        <textarea className="purple-input" {...props} ref={textAreaRef} id="text-area" rows={1} />
      ) : (
        <input className="purple-input mx-5 my-4" {...props} />
      )}
      {!!onSubmit && !loading && (
        <SendIcon
          onClick={handleSubmit}
          className="purple-input__icon pointer"
          width={20}
          height={20}
          fill={value ? Colors.purple01 : Colors.gray01}
        />
      )}
      {loading && (
        <Awaiter
          small
          className="purple-input__icon d-flex justify-content-center align-items-center"
        />
      )}
    </div>
  );
};

export default MainInput;
