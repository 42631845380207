import React, { useCallback, useState } from 'react';
import Awaiter from '../../../../components/messages/Awaiter';
import moment from 'moment';
import { TagId } from '../../../../data/models/Tag';
import { disableCtrl } from '../../../../services/utils';
import ArticleTags from '../../article/ArticleTags';

type Props = {
  imgSrc?: string;
  articleId?: number;
  loading: boolean;
  onTitleClick?: () => void;
  title?: string;
  onImgClick?: () => void;
  imgClassName?: string;
  titleJSX?: JSX.Element;
  actions: JSX.Element;
  description?: string;
  domain: string;
  url?: string;
  createdAt?: string;
  tags?: TagId[];
  additionalClassName?: string;
  overlay?: {
    text: string;
    clickHandler: (e: React.MouseEvent<HTMLDivElement>, openArticleWithoutCTRL?: boolean) => void;
    icon?: JSX.Element | null;
    isLink?: boolean;
    linkHref?: string;
  };
};

const ArticleListCardContent = ({
  articleId,
  imgSrc,
  loading,
  onTitleClick = () => {},
  title,
  titleJSX,
  actions,
  tags,
  description,
  domain,
  url,
  createdAt,
  additionalClassName,
  imgClassName,
  onImgClick,
  overlay,
}: Props) => {
  const [isMouseOver, setMouseOver] = useState(false);
  const onContainerClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (overlay?.clickHandler) {
        overlay.clickHandler(e, false);
      }
    },
    [overlay],
  );
  if (loading)
    return (
      <div className={' article-list-card vstack text-black'}>
        <Awaiter>Extracting data...</Awaiter>
      </div>
    );
  return (
    <div
      onClick={onContainerClick}
      className={`article-list-card ${additionalClassName} d-flex gap-3 position-relative`}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <div className="position-relative article-list-card__img--container">
        <img src={imgSrc} onClick={onImgClick} className={imgClassName} alt="loading..." />
        {isMouseOver && !!overlay && (
          <>
            {overlay.isLink && overlay.linkHref ? (
              <a href={overlay.linkHref} target="_blank" rel="noreferrer noopener">
                <div className="position-absolute d-flex flex-column align-items-center top-0 card-img-top--overlay card-img-top--overlay__bg text-white body-text-sm px-3 pb-2 w-100 left-0 right-0">
                  <div className="d-flex">
                    {overlay?.icon} <span>{overlay?.text} to</span>
                  </div>
                  <span>view original</span>
                </div>
              </a>
            ) : (
              <p
                className="position-absolute d-flex flex-column align-items-center top-0 card-img-top--overlay card-img-top--overlay__bg text-white body-text-sm px-3 pb-2 w-100 left-0 right-0"
                onClick={overlay?.clickHandler}
              >
                <div className="d-flex">
                  {overlay?.icon}
                  <span>{overlay?.text} to</span>
                </div>
                <span>view original</span>
              </p>
            )}
          </>
        )}
      </div>
      <div className="d-flex flex-column px-4 justify-content-between">
        <div className="d-flex flex-column py-3 pb-2 h-100 justify-content-between">
          <div className="d-flex flex-column " style={{ gap: 12 }}>
            {titleJSX || (
              <div
                className={`input-label-text fw-500 text-black text-start pointer mx-4 ml-0`}
                onClick={e => disableCtrl(e, onTitleClick)}
              >
                {title}
              </div>
            )}
            <div className="article-list-card__actions">{actions}</div>
            {!!description && (
              <div className="body-text-sm text-black text-start">{description}</div>
            )}
          </div>
          {!!articleId && !!tags?.length && (
            <ArticleTags
              articleId={articleId}
              updateFilterStateOnClick
              tagClassName="article-card__tag"
              titleClassName="article-card__tag text-black fw-500 pt-1"
            />
          )}
        </div>
        <div className="article-list-card__footer d-flex gap-4">
          {!!domain && (
            <a
              className="body-text-micro sparks-link text-gray-0"
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {domain}
            </a>
          )}
          {!!createdAt && (
            <span className="body-text-micro text-gray-0">
              {moment(createdAt).format('MMM d, YYYY')}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArticleListCardContent;
