import React, { useMemo } from 'react';
import ServerRouter from '../../../routers/ServerRouter';
import NetworkJSONLoader from '../../../components/network/NetworkJSONLoader';
import LandingFooter from '../../../components/footer/LandingFooter';
import { setTopic } from '../../../data/redux/actions';
import BackToDashboard from '../../../components/links/BackToDashboard';

type Props = {
  topic: number;
};

export function TopicViewPlaceholder({ topic }: Props) {
  return useMemo(
    () => (
      <div
        id="layoutSidenav_content"
        className={'vstack'}
        style={{ top: 'initial', overflow: 'auto' }}
      >
        <div className={'vstack h-100 justify-content-center align-items-center'}>
          <NetworkJSONLoader
            url={ServerRouter.topics(topic)}
            onOk={setTopic}
            children={'Loading topic'}
            cancelComponent={<BackToDashboard />}
            nonRetriable
          />
        </div>
        <LandingFooter />
      </div>
    ),
    [topic],
  );
}
