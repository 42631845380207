import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import ClientRouter from '../../routers/ClientRouter';
import Topic from '../../data/models/Topic';
import ArticlePage from './article/ArticlePage';
import ProfileView from './profile/ProfileView';
import TopicPage from './topic/TopicPage';
import DashboardTopNav from './topnav/DashboardTopNav';
import SideNav from './sidenav/SideNav';
import { useAppSelector } from '../../data/redux/hooks';
import { RootState } from '../../data/redux/store';
import { notEmpty } from '../../data/redux/utils';
import { useLocationNoUpdates } from '../../hooks/RouterUtils';
import Feedback from '../../components/feedback/Feedback';
import { ArticlesSearchResults } from './topic/search/ArticlesSearchResults';
import ServerRouter from '../../routers/ServerRouter';
import { fillPageGroups } from '../../data/redux/actions';
import InterestsSelect from '../../components/discover/InterestsSelect';
import { getHeaders } from '../../routers/utils';
import { reloadProfile } from '../../data/redux/authUtils';
import PageGroup from '../../data/models/PageGroup';
import NetworkJSONArrayLoader from '../../components/network/NetworkJSONArrayLoader';
import { useResizeDetector } from 'react-resize-detector';
import DashboardContext from './DashboardContext';
import MetaTagsList from '../../components/MetaTagsList';

export const Dashboard = () => {
  const articles = useAppSelector(useCallback((state: RootState) => state.cache.articles, []));
  const topics = useAppSelector(useCallback((state: RootState) => state.cache.topics, []));
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
  const showSideNav = useAppSelector(
    useCallback((state: RootState) => state.cache.showSideNav, []),
  );

  const location = useLocationNoUpdates();
  const { topicId, articleId, query } = useParams();
  const lastActualTopicId = useRef<number>();
  const { ref, width } = useResizeDetector({ refreshMode: 'debounce' });

  const layoutRef = useRef<HTMLDivElement>(null);

  const recentFilter = useMemo(() => {
    return new Topic({
      name: 'Home',
      key: 'recents',
      pages: Object.values(articles)
        .map(article => article?.id)
        .sort((id1, id2) => (id1 && id2 && id2 - id1) || 0)
        .filter(notEmpty),
    });
  }, [articles]);

  const isProfile = ClientRouter.profile().startsWith(location.pathname);
  const content = useMemo(() => {
    if (isProfile) return <ProfileView />;
    if (articleId) return <ArticlePage article={articles[articleId] ?? parseInt(articleId)} />;
    if (topicId) {
      lastActualTopicId.current = parseInt(topicId);
      return <TopicPage topic={topics[topicId] ?? parseInt(topicId)} />;
    }
    if (query) return <ArticlesSearchResults query={query} />;
    lastActualTopicId.current = undefined;
    return <TopicPage topic={recentFilter} />;
  }, [isProfile, articleId, topicId, query, articles, topics, recentFilter]);

  const [interestsVisible, setInterestsVisible] = useState(false);

  const onInterestsSubmit = (selectedPageGroupsIds: number[]) => {
    fetch(ServerRouter.updateProfile(), {
      method: 'PATCH',
      headers: getHeaders(),
      credentials: 'include',
      body: JSON.stringify({ preferences: { add: selectedPageGroupsIds, remove: [] } }),
    })
      .then(() => {
        reloadProfile();
      })
      .catch(e => console.error(e))
      .finally(() => setInterestsVisible(false));
  };

  useEffect(() => {
    const afterRegister = localStorage.getItem('after-register');
    if (afterRegister) {
      setInterestsVisible(true);
      localStorage.removeItem('after-register');
    }
  }, []);

  const pageGroups = useAppSelector(
    useCallback(
      (state: RootState) =>
        Object.values(state.cache.pageGroups)
          .filter(pageGroup => !!pageGroup)
          .sort((a, b) => (a?.name || '').localeCompare(b?.name || '')),
      [],
    ),
  ) as PageGroup[];

  const loader = useMemo(() => {
    return (
      <NetworkJSONArrayLoader
        url={ServerRouter.pageGroupAll()}
        onOk={res => {
          fillPageGroups(res);
        }}
        hidden
      />
    );
  }, []);

  return useMemo(() => {
    if (!user) {
      return <Navigate to={ClientRouter.login()} />;
    }
    return (
      <DashboardContext.Provider value={{ width, containerRef: ref, layoutRef }}>
        <MetaTagsList type="home" />
        <div
          className={
            'nav-fixed h-100 vstack ' + (showSideNav === 'free' ? '' : 'sidenav-' + showSideNav)
          }
          style={{ textAlign: 'center' }}
        >
          <DashboardTopNav selectedTopicId={lastActualTopicId.current} />
          <InterestsSelect
            visible={interestsVisible}
            pageGroups={pageGroups}
            onClose={() => setInterestsVisible(false)}
            onSubmit={onInterestsSubmit}
            submitButtonClassName="py-3 px-10"
            submitButtonPosition="bottom"
          />
          <section
            ref={layoutRef}
            id="layoutSidenav"
            className={'hstack h-100'}
            style={{ overflow: 'auto' }}
          >
            <SideNav
              topics={Object.values(topics).filter(notEmpty)}
              selectedTopicId={lastActualTopicId.current ? lastActualTopicId.current : null}
            />
            {content}
            <Feedback />
          </section>
          {loader}
        </div>
      </DashboardContext.Provider>
    );
  }, [
    user,
    ref,
    layoutRef,
    showSideNav,
    topics,
    content,
    interestsVisible,
    setInterestsVisible,
    pageGroups,
    width,
    loader,
  ]);
};
