import React from 'react';
import {Button} from "react-bootstrap";

export default function ExtensionImportBookmarks() {
  return (
    <div>
      <p>Save bookmarks and generate summary of saved pages in your browser bookmark, click “Import bookmarks”.</p>
      <p className={'text-center'}>
        <Button
          onClick={() => {
            window.postMessage({type: 'IMPORT_BOOKMARKS'});
          }}
          variant={'outline-primary'}>
          Import bookmarks
        </Button>
      </p>
    </div>
  );
}
