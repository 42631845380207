import React, { useCallback, useEffect, useState } from 'react';
import styles from './styles.module.css';
import cn from 'classnames';
import LandingNav from '../../components/nav/LandingNav';
import LandingFooter from '../../components/footer/LandingFooter';
import MetaTagsList from '../../components/MetaTagsList';
import DiscoverImg from '../../assets/img/lp/discover-bw.png';
import { PremiumModal } from '../dashboard/sidenav/FreePlan';
import { useAppSelector } from '../../data/redux/hooks';
import { RootState } from '../../data/redux/store';
import { useNavigate } from 'react-router-dom';
import ClientRouter from '../../routers/ClientRouter';

type PricingPlanProps = {
  activePlan: 0 | 1;
  onSubscribeClick: () => void;
  small?: boolean;
  buttonLabel?: string;
  buttonDisabled?: boolean;
};

export const PricingPlan = ({
  activePlan,
  onSubscribeClick,
  small,
  buttonDisabled = false,
  buttonLabel = 'Subscribe Now',
}: PricingPlanProps) => {
  return (
    <div className={cn(styles.pricingPlan, styles.active)}>
      <h4 className={styles.pricingPlanTitle}>{activePlan === 0 ? 'Monthly' : 'Yearly'}</h4>
      <div className={cn(styles.pricingPlanPrice, { [styles.small]: small })}>
        ${activePlan === 0 ? '2.49' : '19.99'}
      </div>
      <ul className={cn(styles.pricingPlanList, { [styles.small]: small })}>
        <li>Access to all premium features</li>
        <li>Generate and review unlimited summaries</li>
        <li>Create unlimited topics</li>
        <li>Add unlimited notes</li>
        <li>Search across all saved content</li>
        <li>Save and bookmark unlimited web pages</li>
      </ul>
      <button
        onClick={onSubscribeClick}
        disabled={buttonDisabled}
        className="button-variant-1 button-variant-1-lg align-self-center"
      >
        {buttonLabel}
      </button>
      <span className={styles.pricingPlanNote}>
        Billed as ${activePlan === 0 ? '2.49 monthly' : '19.99 yearly'} (auto-renewal)
      </span>
    </div>
  );
};

const PricingLP = () => {
  const [activePlan, setActivePlan] = useState<0 | 1>(0);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
  const navigate = useNavigate();

  const userAlreadySubscribed = user?.plan_name === 'Premium' && user?.plan_status !== 'Inactive';

  const onSubscribeClick = () => {
    if (user) {
      if (userAlreadySubscribed) {
        navigate(ClientRouter.dashboard());
      } else {
        setShowPremiumModal(true);
      }
    } else {
      navigate(ClientRouter.register());
    }
  };

  useEffect(() => {
    document.title = 'Pricing-GetSparks';
  }, []);
  return (
    <div className={styles.pricing + ' pricing'}>
      <MetaTagsList type="home" />
      <LandingNav />
      <div className={styles.pricingTopSection}>
        <div>
          <h1 className={styles.pricingTitle}>Simple Pricing</h1>
          <div className="d-flex gap-4 flex-wrap mt-3 mb-4">
            <div className={styles.pricingRate} onClick={() => setActivePlan(0)}>
              <div className={cn('radio-button', { active: activePlan === 0 })} />
              <h3 className={cn(styles.pricingRateTitle, { [styles.active]: activePlan === 0 })}>
                Monthly
              </h3>
            </div>
            <div className={styles.pricingRate} onClick={() => setActivePlan(1)}>
              <div className={cn('radio-button', { active: activePlan === 1 })} />
              <h3 className={cn(styles.pricingRateTitle, { [styles.active]: activePlan === 1 })}>
                Yearly
              </h3>
              <div
                className={cn(styles.pricingRateDiscount, { [styles.active]: activePlan === 1 })}
              >
                -35%
              </div>
            </div>
          </div>
          <div className={styles.pricingPlan}>
            <h4 className={styles.pricingPlanTitle}>Free</h4>
            <div className={styles.pricingPlanPrice}>$0</div>
            <ul className={styles.pricingPlanList}>
              <li>Save up to 5 new pages everyday</li>
              <li>Search across all saved content</li>
              <li>Access limited for daily usage enough for trying the product and casual users</li>
              <li>Limit on of summaries, topics & notes</li>
              <li> Apps for Chrome</li>
              <li> Access anytime</li>
            </ul>
          </div>
        </div>
        <div>
          <PricingPlan activePlan={activePlan} onSubscribeClick={onSubscribeClick} />
        </div>
      </div>
      <div className={'save-bookmarks px-lg-15 py-lg-10'}>
        <img className="save-bookmarks-image" src={DiscoverImg} alt="loading..." />
        <div className={'save-bookmarks-body mt-4 mt-lg-0'}>
          <h2 className="m-0 sparks-h2 fw-700 text-black">Save bookmarks to read later</h2>
          <p className="m-0 body-text-max text-black mb-5 mt-4">
            Add articles, videos, and links from across the web in getSparks to personalize your
            collection Use our app to enjoy them on any device in a distraction-free environment.
          </p>
        </div>
      </div>
      <div className="px-lg-15 py-lg-10 d-flex flex-column gap-3 py-8 px-4">
        <h2 className="sparks-h2 text-black m-0 text-center">FAQ</h2>
        <div className="body-text-max text-dark-gray fw-700">
          <ul className="m-0">
            <li>Can I use GetSparks for free?</li>
          </ul>
          <span className="fw-400">Yes! GetSparks is absolutely free to use for ever.</span>
        </div>
        <div className="body-text-max text-dark-gray fw-700">
          <ul className="m-0">
            <li>How is Primary plan different from Free?</li>
          </ul>
          <span className="fw-400">
            Premium plan offers unlimited pages, notes, questions, and topics. Also there is no
            limit on number of summary user can generate.
          </span>
        </div>
        <div className="body-text-max text-dark-gray fw-700">
          <ul className="m-0">
            <li>Does GetSparks offer monthly and yearly billing options?</li>
          </ul>
          <span className="fw-400">
            Yes, we offer either monthly or yearly billing options. The yearly billing option is
            nearly 35% cheaper than monthly plan.
          </span>
        </div>
        <div className="body-text-max text-dark-gray fw-700">
          <ul className="m-0">
            <li>How do i cancel my premium subcription?</li>
          </ul>
          <span className="fw-400">
            You can easily cancel your subscription by visiting your account profile section.
            Subscription is canceled at the end of the billing cycle.
          </span>
        </div>
      </div>
      <div className="bg-white px-lg-15 py-lg-10 d-flex flex-column gap-3 py-8 px-4">
        <h3 className={styles.pricingSubFooterTitle}>
          Save bookmarks for pages you need to research later
        </h3>
        <p className="m-0 body-text-max text-dark-gray text-center">
          Add articles, videos, and links from across the web in getSparks to personalize your
          collection. Use our app to enjoy them on any device in a distraction free environment.
        </p>
      </div>
      <LandingFooter />
      <PremiumModal
        showModal={showPremiumModal}
        onCancel={() => setShowPremiumModal(false)}
        yearlySub={!!activePlan}
        signedUser={user?.email}
      ></PremiumModal>
    </div>
  );
};

export default PricingLP;
