import React, {useCallback, useEffect, useRef, useState} from 'react';

import {setPageNumber} from "../../../data/redux/actions";
import {Document, Page, pdfjs} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import {useAppDispatch, useAppSelector} from "../../../data/redux/hooks";
import {RootState} from "../../../data/redux/store";

function PdfViewer(props: any) {
    let statePageNumber = useAppSelector(useCallback((state: RootState) => state.cache.pageNumber, []));
    let summaryBar = useAppSelector((state: RootState) => state.cache.infoTab.visible);
    const [numPages, setNumPages] = useState(null);
    const [pdfWidth, setPdfWidth] = useState(0);
    const [pdfHeight, setPdfHeight] = useState(null);
    const containerRef = useRef(null);
    const dispatch = useAppDispatch();


    // @ts-ignore
    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    // @ts-ignore
    function handleRender(page) {
        if (page.height !== pdfHeight) {
            setPdfHeight(page.height)
        }
    }

    function handleScroll() {

        const container = containerRef.current;
        // @ts-ignore
        const pageHeight = container.offsetHeight;
        // @ts-ignore
        const scrollTop = container.scrollTop;
        const currentPage = Math.floor(scrollTop / pageHeight) + 1;
        if (statePageNumber !== currentPage) {
            setPageNumber(currentPage)
        }

    }

    const updatePfdWidth = () => {
        setTimeout(() => {
            const widthDiv = props.wdth.current?.offsetWidth
            setPdfWidth(widthDiv)
        }, 500)
    }

    useEffect(() => {
        updatePfdWidth()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [summaryBar, props.wdth])

    function getWindowSize() {
        const widthDiv = props.wdth.current?.offsetWidth
        return widthDiv
    }

    function cleanPageNumber(){
        dispatch(setPageNumber(1));
        window.removeEventListener('resize',setOnResize)
    }

    const setOnResize = () =>{
        setPdfWidth(getWindowSize())
    }

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.js`;
        window.addEventListener('resize', setOnResize)
        return cleanPageNumber
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="" style={{
            overflow: "scroll",
            height: pdfHeight != null ? (pdfHeight - 1) : "50rem",
            width: "auto",
            overflowX: "hidden"
        }} ref={containerRef} onScroll={handleScroll}>
            <Document
                file={props.pdfFile}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {Array.from(
                    new Array(numPages),
                    (el, index) => (
                        <Page
                            onRenderSuccess={handleRender}
                            width={pdfWidth}
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                        />
                    ),
                )}
            </Document>
        </div>
    );
}

export default PdfViewer
