import {MutableRefObject, useRef} from 'react';

const useFocus = (): [MutableRefObject<any>, () => void] => {
  const htmlElRef: MutableRefObject<any> = useRef(null);
  const setFocus = (): void => {
    htmlElRef?.current?.focus?.();
  };

  return [htmlElRef, setFocus];
};

export default useFocus;
