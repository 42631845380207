import React, { useCallback, useMemo } from 'react';
import caseImage1 from '../../../assets/img/illustrations/index-content-1.jpg';
import caseImage2 from '../../../assets/img/illustrations/summary.svg';
import caseImage3 from '../../../assets/img/illustrations/index-content-3.png';
import RoundedBorder from '../../../components/RoundedBorder';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { faClipboard, faRectangleList } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClientRouter from '../../../routers/ClientRouter';
import { Link } from 'react-router-dom';

export type OnboardPointState = {
  children?: React.ReactNode;
  order?: number[];
  buttonType?: 'default' | 'extension';
  mainCardComponent?: React.ReactNode;
};

export default function CasesSection(props: OnboardPointState) {
  const { children, order = [1, 2, 3], buttonType = 'default', mainCardComponent } = props;
  const casesData = useMemo(
    () => [
      {
        icon: faFloppyDisk,
        iconText: 'Save',
        title: 'Create your personal library of web content',
        desc: 'Organize and add web content to your personal library. Access content easily by searching within article. Utilize tools for notes and highlights to personalization content and summarize in your own words for future references.',
        href: ClientRouter.lpSave(),
        image: caseImage1,
      },
      {
        icon: faRectangleList,
        iconText: 'Summarize',
        title: 'Be Efficient and Effective',
        desc: 'Read summary of the articles and other important artifacts before reading full text version. Having a context summary not only saves you time but also helps you retain more details. Advanced Machine Learning algorithm generate summarized text and provides references to people place, keywords mentioned in the article.',
        href: ClientRouter.lpSummarize(),
        image: caseImage2,
      },
      {
        icon: faClipboard,
        iconText: 'Add Note',
        title: 'LEAVE NOTE FOR NEXT VISIT',
        desc: 'Add the browser extension to save web Page and add notes to any web page.',
        href: ClientRouter.lpNote(),
        image: caseImage3,
      },
    ],
    [],
  );

  const generateCardJSX = useCallback(
    (caseItem: (typeof casesData)[0], isLeft: boolean) => {
      const imgColumn = (
        <div className={'col-md-6'}>
          <img className={'img-fluid'} src={caseItem.image} alt="..." width="800" height="550" />
        </div>
      );
      const dataColumn = (
        <div className={'col-md-6'}>
          <div
            className={
              'card-body d-flex align-items-center justify-content-center h-100 flex-column'
            }
          >
            <h2 className={'mb-5 card-summary'}>
              <FontAwesomeIcon icon={caseItem.icon} /> {caseItem.iconText}
            </h2>
            <h3 className={'card-title fw-bold text-uppercase mb-2'}>{caseItem.title}</h3>
            <p className={'card-text fw-light mb-5'}>{caseItem.desc}</p>
            {buttonType === 'default' ? (
              <Link className="btn fw-500 ms-lg-4 btn-primary" to={caseItem.href}>
                Learn more
              </Link>
            ) : (
              <a
                className="btn fw-500 ms-lg-4 btn-primary card-btn"
                target="_blank"
                rel="noreferrer"
                href="https://chrome.google.com/webstore/detail/dimadmjcopdjkklcmfomakopebflpbal?utm_source=getsparks.io_bookmark&utm_source=getsparks.io"
              >
                Add getSparks
              </a>
            )}
          </div>
        </div>
      );
      return (
        <div className={'card mb-5'} key={`${caseItem.title}-case`}>
          <div className={'row g-0 hstack'}>
            {isLeft ? (
              <>
                {dataColumn}
                {imgColumn}
              </>
            ) : (
              <>
                {imgColumn}
                {dataColumn}
              </>
            )}
          </div>
        </div>
      );
    },
    [buttonType],
  );

  const cards = useMemo(() => {
    const updatedOrder: React.ReactNode[] = [];
    order.forEach((index, i) => {
      updatedOrder.push(
        i === 0 && !!mainCardComponent
          ? mainCardComponent
          : generateCardJSX(casesData[index - 1], i % 2 !== 0),
      );
      if (i === 0) updatedOrder.push(children);
    });
    return updatedOrder;
  }, [order, casesData, children, generateCardJSX, mainCardComponent]);

  return (
    <section className={'bg-light py-10'}>
      <div className={'container px-5 z-1'}>{cards}</div>
      <RoundedBorder className={'text-white mt-n15'} />
    </section>
  );
}
