import React, {useCallback, useMemo} from 'react';
import NotesInfo from "./NotesInfo";
import {useAppSelector} from "../../../data/redux/hooks";
import {RootState} from "../../../data/redux/store";
import Article from "../../../data/models/Article";
import {setArticleNote} from "../../../data/redux/actions";
import {getArticleNotesCRUD, getArticleNotesManageCRUD} from "../../../services/cruds";

type Props = {
  article: Article,
};

export default function ArticleNotesInfo({article}: Props) {
  const notes = useAppSelector(useCallback((state: RootState) => article.getNotes(state.cache), [article]));
  const notesCluster = useMemo(() => ({
    notes: notes,
    getModalCRUD: getArticleNotesCRUD,
    getInlineCRUD: getArticleNotesManageCRUD,
    onOk: setArticleNote,
    defaultValues: {page: article.id}
  }), [article.id, notes]);
  return useMemo(() => <NotesInfo name={'Article Notes'} notesCluster={notesCluster}/>, [notesCluster])
}
