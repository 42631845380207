import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { Button, ModalProps } from 'react-bootstrap';
import StripeCard from './StripeCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMultiply } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../data/redux/hooks';
import { RootState } from '../../../data/redux/store';
import { setIsSignedUp, setUser } from '../../../data/redux/actions';
import SideNavPanel from './SideNavPanel';
import { ReactComponent as FullIcon } from '../../../assets/img/icons/full-site-icon.svg';
import { ReactComponent as StarIcon } from '../../../assets/img/icons/star.svg';
import { ReactComponent as BackIcon } from '../../../assets/img/icons/arrow-4.svg';
import { PricingPlan } from '../../lp/PricingLP';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import ClientRouter from '../../../routers/ClientRouter';

function WelcomeModal(props: any) {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter-sm" centered>
      <div className="p-4 text-black">
        <div className="position-relative text-center">
          <p>
            <h4>Get Started with Premium</h4>
          </p>
          <button
            className="position-absolute end-0 top-0 border-0 bg-transparent"
            onClick={props.onHide}
          >
            <FontAwesomeIcon icon={faMultiply} />
          </button>
        </div>
        <div className="mx-4">
          <div>
            <p>Enjoy your free premium account trial !</p>
          </div>
          <div className="lh-2">
            <div>
              <p>You can cancel your subscription anytime by going to account section.</p>
            </div>
          </div>
        </div>
        <div className="d-flex py-4 justify-content-center">
          <button onClick={props.onHide} className="btn btn-sm btn-success px-5">
            Continue
          </button>
        </div>
      </div>
    </Modal>
  );
}

function Stripe(props: any) {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter-sm" centered>
      <div className="p-3">
        <div
          className="position-relative d-flex flex-column align-items-center"
          style={{ gap: 10 }}
        >
          <button
            className="position-absolute start-0 top-0 border-0 bg-transparent"
            onClick={props.onBack}
          >
            <BackIcon />
          </button>
          <button
            className="position-absolute end-0 top-0 border-0 bg-transparent"
            onClick={props.onHide}
          >
            <FontAwesomeIcon icon={faMultiply} />
          </button>
          <FullIcon width={113} height={26} />
          <h4 className="fw-500 text-black m-0" style={{ fontSize: 20 }}>
            Subscribe to GetSparks Premium
          </h4>
          <div className="text-black">
            <b style={{ fontSize: 20 }}>{props.yearlySub ? '$19.99' : '$2.49'} </b>
            <span className="body-text-sm fw-500" style={{ color: '#71797E' }}>
              {props.yearlySub ? 'per year' : 'per month'}
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="flex-1 px-4">
            <StripeCard closeModal={props.closeModal} yearlySub={props.yearlySub} />
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-center text-blue-8 gap-1 border-top border-1 border-black"
        style={{ padding: '12px 0' }}
      >
        <Link to={ClientRouter.termsAndConditions()} target="_blank" rel="noopener noreferrer">
          Terms of Service
        </Link>
        <span>|</span>
        <Link to={ClientRouter.privacyPolicy()} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </Link>
      </div>
    </Modal>
  );
}

export type PremiumLimitType = 'topics' | 'pages' | 'notes' | 'questions';

function Plans({
  onHide,
  closePlan,
  isYearlySub,
  setYearlySub,
  limitType,
  ...modalProps
}: {
  onHide?: () => void;
  closePlan: () => void;
  isYearlySub: boolean;
  setYearlySub: (newValue: boolean) => void;
  limitType?: PremiumLimitType;
} & ModalProps) {
  return (
    <Modal {...modalProps} size="lg" cl aria-labelledby="contained-modal-title-vcenter-xl" centered>
      <div className="free-plan-modal p-4 text-black">
        <div className="position-relative text-center mb-3">
          <h3 className="sparks-h2 mb-0">Subscribe to GetSparks Premium</h3>
          <button
            className="position-absolute end-0 top-0 border-0 bg-transparent"
            onClick={onHide}
          >
            <FontAwesomeIcon icon={faMultiply} />
          </button>
        </div>
        <div className="free-plan-modal__content">
          <div className="flex-shrink-1">
            <div className="mb-5">
              {!!limitType && (
                <h4 className="body-text-max fw-500 text-green mb-4 lh-normal">
                  You have reached the limit for number of{' '}
                  <b className="text-capitalize">{limitType}</b> you can add for free!
                </h4>
              )}
              <div className="fs-3 line-height-normal">
                Premium account enables you to add unlimited topics, pages, and notes.
              </div>
            </div>
            <div>
              <div className="body-text-max fw-700 text-blue-3 lh-normal">Simple Pricing</div>
              <div className="d-flex gap-4 mt-4">
                <div
                  className="d-flex gap-2 align-items-center pointer"
                  onClick={() => setYearlySub(false)}
                >
                  <div className={cn('radio-button', { active: !isYearlySub })} />
                  <span
                    className={cn('body-text-max', 'fw-700', 'lh-normal', {
                      'text-blue-3': !isYearlySub,
                    })}
                  >
                    Monthly
                  </span>
                </div>
                <div
                  className="d-flex gap-2 align-items-center pointer"
                  onClick={() => setYearlySub(true)}
                >
                  <div className={cn('radio-button', { active: isYearlySub })} />
                  <span
                    className={cn('body-text-max', 'fw-700', 'lh-normal', {
                      'text-blue-3': isYearlySub,
                    })}
                  >
                    Yearly
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex-shrink-0 py-2 px-3 bg-gray-5"
            style={{
              maxWidth: 430,
            }}
          >
            <PricingPlan
              activePlan={isYearlySub ? 1 : 0}
              onSubscribeClick={closePlan}
              small
              buttonLabel="Pay Now"
            />
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-center text-blue-8 body-text-micro gap-1 border-top border-1 border-black"
        style={{ padding: '12px 0' }}
      >
        <Link
          to={ClientRouter.termsAndConditions()}
          className="text-blue-8"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </Link>
        <span>|</span>
        <Link
          to={ClientRouter.privacyPolicy()}
          className="text-blue-8"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
      </div>
    </Modal>
  );
}

function MyVerticallyCenteredModal(props: any) {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter-xl" centered>
      <div className="p-3 text-black">
        <div className="position-relative text-center">
          <p>
            <h4>Subscribe to GetSparks Premium</h4>
          </p>
          <button
            className="position-absolute end-0 top-0 border-0 bg-transparent"
            onClick={props.onHide}
          >
            <FontAwesomeIcon icon={faMultiply} />
          </button>
        </div>

        <div className="container-fluid p-3 px-3">
          <div className="row  border ">
            <div className="col-4   border "></div>
            <div className="col-4 border ">
              <div className="m-3">
                <h3>Starter</h3>
                <div className="text-xs text-start py-4">Free, For trying the product</div>
                <div className="d-flex justify-content-center">
                  <button className="btn btn-sm bg-gray-200 text-black shadow-sm">
                    Current Plan
                  </button>
                </div>
              </div>
            </div>
            <div className="col-4  border ">
              <div className="m-3">
                <h3>Premium</h3>
                <div className="text-xs">
                  For you to manage unlimited summaries, topics and all premium features.
                </div>
                <div className="p-2 justify-content-center">
                  <button
                    onClick={props.closeModal}
                    className="btn btn-success btn-sm px-4 shadow-sm"
                  >
                    Upgrade To Premium
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-xs border ">
            <div className="col-4 p-2 border-end border-bottom border-start">
              <b>Save Pages</b>
            </div>
            <div className="col-4 p-2 border-end border-bottom border-start">5 pages per day</div>
            <div className="col-4 p-2 border-end border-bottom border-start">unlimited</div>
          </div>
          <div className="row text-xs border">
            <div className="col-4 p-2 border-end border-bottom border-start">
              <b>Import Bookmarks</b>
            </div>
            <div className="col-4 p-2 border-end border-bottom border-start">yes</div>
            <div className="col-4 p-2 border-end border-bottom border-start">yes</div>
          </div>
          <div className="row text-xs border">
            <div className="col-4 p-2 border-end border-bottom border-start">
              <b>Topics</b>
            </div>
            <div className="col-4 p-2 border-end border-bottom border-start">3 topics</div>
            <div className="col-4 p-2 border-end border-bottom border-start">unlimited</div>
          </div>
          <div className="row text-xs border">
            <div className="col-4 p-2 border-end border-bottom border-start">
              <b>Summary</b>
            </div>
            <div className="col-4 p-2 border-end border-bottom border-start">yes</div>
            <div className="col-4 p-2 border-end border-bottom border-start">
              unlimited Summaries
            </div>
          </div>
          <div className="row text-xs border">
            <div className="col-4 p-2 border-end border-bottom border-start">
              <b>Summary by page sections</b>
            </div>
            <div className="col-4 p-2 border-end border-bottom border-start">yes</div>
            <div className="col-4 p-2 border-end border-bottom border-start">
              unlimited summaries by page section
            </div>
          </div>
          <div className="row text-xs border">
            <div className="col-4 p-2 border-end border-bottom border-start">
              <b>Notes</b>
            </div>
            <div className="col-4 p-2 border-end border-bottom border-start">3 notes</div>
            <div className="col-4 p-2 border-end border-bottom border-start">unlimited notes</div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

type PremiumModalProps = {
  showModal: boolean;
  showStripeModal?: boolean;
  onCancel: () => void;
  yearlySub?: boolean;
  buttonName?: string;
  customButton?: React.FC<{ onClick: () => any }>;
  signedUser?: string;
  limitType?: PremiumLimitType;
};

export const PremiumModal = (props: PremiumModalProps) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));

  const [planShow, setPlanShow] = React.useState(props.showModal);
  const [modalShow, setModalShow] = React.useState(false);
  const [stripeShow, setStripeShow] = React.useState(false);
  const [isYearlySub, setIsYearlySub] = React.useState(true);
  const [showWelcomeModal, setShowWelcomeModal] = React.useState(false);

  const handleCloseWelcome = () => {
    setShowWelcomeModal(false);
    dispatch(setUser({ ...user, plan_name: 'Premium' }));
    dispatch(setIsSignedUp(false));
  };

  function handleCloseStripe() {
    setStripeShow(false);
    setShowWelcomeModal(true);
  }

  function handleCloseModal() {
    setModalShow(false);
    setPlanShow(true);
  }

  function handlePlanCloseModal() {
    setPlanShow(false);
    setStripeShow(true);
  }

  useEffect(() => {
    setPlanShow(props.showModal);
  }, [props.showModal]);

  useEffect(() => {
    setStripeShow(!!props.showStripeModal);
  }, [props.showStripeModal]);

  useEffect(() => {
    if (typeof props.yearlySub === 'boolean') {
      setIsYearlySub(props.yearlySub);
    }
  }, [props.yearlySub]);

  return (
    <>
      {props.buttonName && !props.customButton && (
        <Button variant="success" className="text-xs btn-sm m-2" onClick={() => setModalShow(true)}>
          {props.buttonName}
        </Button>
      )}

      {!!props.customButton && <props.customButton onClick={() => setModalShow(true)} />}

      <MyVerticallyCenteredModal
        show={modalShow}
        backdrop="static"
        closeModal={handleCloseModal}
        onHide={() => setModalShow(false)}
        className="premium-modal"
      />
      <Plans
        show={planShow}
        onExit={props.onCancel}
        isYearlySub={isYearlySub}
        setYearlySub={setIsYearlySub}
        limitType={props.limitType}
        closePlan={handlePlanCloseModal}
        backdrop="static"
        onHide={() => {
          setPlanShow(false);
          dispatch(setIsSignedUp(false));
        }}
        className="premium-modal"
      />

      <Stripe
        show={stripeShow}
        backdrop="static"
        onHide={() => {
          setStripeShow(false);
        }}
        onBack={() => {
          setStripeShow(false);
          setPlanShow(true);
        }}
        signedUser={props.signedUser}
        yearlySub={isYearlySub}
        closeModal={handleCloseStripe}
        className="premium-modal"
      />

      <WelcomeModal
        show={showWelcomeModal}
        backdrop="static"
        onHide={handleCloseWelcome}
        signedUser={props.signedUser}
        className="premium-modal"
      />
    </>
  );
};

const FreePlan = (props: any) => {
  const [premiumModalShow, setPremiumModalShow] = useState(false);
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
  return (
    <>
      <SideNavPanel
        desc="Get more from getSparks using premium account."
        buttonLabel="Upgrade Now"
        title="Go Premium"
        withButtonIcon
        ButtonIcon={StarIcon}
        onButtonClick={() => setPremiumModalShow(true)}
      />
      <PremiumModal
        showModal={premiumModalShow}
        onCancel={() => setPremiumModalShow(false)}
        signedUser={user?.email}
      ></PremiumModal>
    </>
  );
};
export default FreePlan;
