import React, { ChangeEvent, useCallback, useRef } from 'react';
import { IField } from './Field';

type Props = {
  field: IField;
  error?: string;
  defaultValue?: any;
};

export default function CheckboxField({ field, defaultValue, error }: Props) {
  const submitInput = useRef<HTMLInputElement>(null);
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    submitInput.current && (submitInput.current.value = event.target.checked ? 'true' : 'false');
  }, []);
  return (
    <>
      <input
        ref={submitInput}
        name={field.name}
        defaultValue={defaultValue ?? field.defaultChecked}
        hidden
      />
      <input
        {...field}
        className={'form-check-input mx-2 ml-0'}
        name={undefined}
        onChange={onChange}
        style={error ? { borderColor: 'red' } : undefined}
        defaultChecked={defaultValue ?? field.defaultChecked}
      />
    </>
  );
}
