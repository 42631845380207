import React, { useState } from 'react';
import NetworkJSONLoader from '../../../../components/network/NetworkJSONLoader';
import ServerRouter from '../../../../routers/ServerRouter';
import { reloadProfile } from '../../../../data/redux/authUtils';
import ProductTour from './ProductTour';

export type OnboardPointState = {
  name: string;
  description?: string;
  action?: () => void;
  actionName?: string;
  children?: React.ReactNode;
};


type Props = {
  scrollToTop: (behavior?: ScrollBehavior) => void;
};

export function OnboardDetailedView({ scrollToTop }: Props) {
  const [closing, setClosing] = useState<boolean>();

  return (
    <div className={'flex-grow-1 vstack'}>
      <h1 className={'card card-body w-100 hstack'} style={{ opacity: '90%' }}>
        <span className={'flex-grow-1'}>
          Welcome to getSparks! Get Started to gain the best of Web Content!
        </span>
        {closing ? (
          <div className={'card card-body bg-white'} style={{ position: 'absolute', right: 0 }}>
            <NetworkJSONLoader
              url={ServerRouter.updateProfile()}
              children={'Hiding onboard'}
              onOk={() => {
                setClosing(false);
                reloadProfile();
              }}
              onCancel={() => setClosing(false)}
              method={'PATCH'}
              body={{ onboarding_closed: true }}
            />
          </div>
        ) : (
          <button className={'btn-close'} onClick={() => setClosing(true)} />
        )}
      </h1>
      <div className={'d-flex w-100 justify-content-center align-items-center py-3'}>
        <ProductTour />
      </div>
    </div>
  );
}
