import React from 'react';
import {Link} from "react-router-dom";
import ClientRouter from "../../routers/ClientRouter";

export default function FooterCopyright() {
  return <div className="row gx-5 align-items-center">
    <div className="col-md-6 small">Copyright &copy; getSparks 2022</div>
    <div className="col-md-6 text-md-end small">
      <Link to={ClientRouter.privacyPolicy()}>Privacy Policy</Link>
      &nbsp;&middot;&nbsp;
      <Link to={ClientRouter.termsAndConditions()}>Terms &amp; Conditions</Link>
    </div>
  </div>
}
