import LandingFooter from '../../../components/footer/LandingFooter';
import React from 'react';
import { useGetUser } from '../../../data/redux/hooks';
import { Navigate, useLocation } from 'react-router-dom';
import ClientRouter from '../../../routers/ClientRouter';

type Props = {
  children: React.ReactNode;
};

export default function DefaultLayout({ children }: Props) {
  const user = useGetUser();

  const { search } = useLocation();

  if (user) {
    return <Navigate to={`${ClientRouter.dashboard()}${search}`} />;
  }

  return (
    <div id="layoutDefault">
      <div id="layoutDefault_content">
        <main>{children}</main>
      </div>
      <LandingFooter />
    </div>
  );
}
