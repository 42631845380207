import {configureStore} from "@reduxjs/toolkit"
import cacheSlice from "./cacheSlice";

const store = configureStore({
  reducer: {cache: cacheSlice.reducer},
  // TODO Make data serializable
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
})
export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
