import React from 'react';
import {Link} from 'react-router-dom';

type Props = {
  onChange: (tab: number) => void,
  selectedTab: number,
  tabs: string[],
};

export default function TabsHeader({onChange, selectedTab, tabs}: Props) {
  const getTab = (tab: string, index: number) => {
    return <Link className={"nav-link" + (selectedTab === index ? " active" : " pointer")}
                 onClick={() => onChange?.(index)} to={''} key={index}>
      {tab}
    </Link>
  }
  return <span className={'nav nav-borders justify-content-center'}>{tabs.map(getTab)}</span>
}
