import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import TopicArticles from './articles/TopicArticles';
import TopicTimeline from './timeline/TopicTimeline';
import Topic from '../../../data/models/Topic';
import LandingFooter from '../../../components/footer/LandingFooter';
import { useAppSelector } from '../../../data/redux/hooks';
import { RootState } from '../../../data/redux/store';
import TopicInfoTab from '../info/TopicInfoTab';
import ExtensionIntroduction from './extension/ExtensionIntroduction';
import useCheckExtensionInstalled from '../../../hooks/custom/useCheckExtensionInstalled';
import Tour from '../../../components/Tour';
import BlueLogo from '../../../components/nav/BlueLogo';
import { TopicHeader } from './TopicHeader';
import DashboardTiles from '../../../components/dashboard/DashboardTiles';
import TopicDiscoveryFeed from './articles/TopicDiscoveryFeed';
import { useLocation } from 'react-router-dom';
import ServerRouter from '../../../routers/ServerRouter';
import { fillArticles, setTopic } from '../../../data/redux/actions';
import NetworkJSONLoader from '../../../components/network/NetworkJSONLoader';
import FilterByTags from './tags/FilterByTags';
import useAllTopicChildren from '../../../hooks/custom/useAllTopicChildren';
import useContentRefresh from '../../../hooks/custom/useContentRefresh';

export type TopicViewProps = PropsWithChildren<{
  blockAdding?: boolean;
}>;

export const EXTENSION_WAS_CONFIGURED = 'EXTENSION_WAS_CONFIGURED';

export default function TopicView({
  topic,
  blockAdding,
  children,
}: TopicViewProps & { topic: Topic }) {
  const topics = useAppSelector(useCallback(state => state.cache.topics, []));
  const notPendingPages = useAppSelector(
    useCallback(
      (state: RootState) =>
        topic.getArticles(state.cache).filter(({ status }) => status === 'full'),
      [topic],
    ),
  );
  const [extensionModalShown, setExtensionModalShown] = useState(
    !!localStorage.getItem(EXTENSION_WAS_CONFIGURED),
  );
  const extensionInstalled = useCheckExtensionInstalled();
  const extensionWasConfigured = useMemo(
    () => !extensionInstalled || extensionModalShown || topic.id,
    [extensionInstalled, topic.id, extensionModalShown],
  );
  const [articleTab, setArticleTab] = useState<number>(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const discoverContainerRef = useRef<HTMLDivElement>(null);
  let articles = Object.keys(
    useAppSelector(useCallback((state: RootState) => state.cache.articles, [])),
  );

  const isExtensionInstalled = useCheckExtensionInstalled();
  const [firstTour, setFirstTour] = useState(window.localStorage.getItem('FIRST_TOUR'));
  const [secondTour, setSecondTour] = useState(window.localStorage.getItem('SECOND_TOUR'));
  const [testExt, setTestExt] = useState(window.localStorage.getItem('EXTENSION_WAS_CONFIGURED'));
  const [topicInterestsVisible, setTopicInterestsVisible] = useState(false);

  const scrollToTop = useCallback(
    (behavior?: ScrollBehavior) => {
      contentRef.current?.scrollTo({ top: 0, behavior: behavior });
    },
    [contentRef],
  );

  const location = useLocation();

  const onExtensionModalClose = () => {
    setExtensionModalShown(true);
    setTestExt('true');
  };

  useEffect(() => {
    scrollToTop();
  }, [contentRef, scrollToTop, topic.id]);

  useEffect(() => {
    if (discoverContainerRef.current && location.hash === '#discover') {
      discoverContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [discoverContainerRef, location]);

  const loader = useMemo(() => {
    return <NetworkJSONLoader url={ServerRouter.topics(topic.id)} onOk={setTopic} hidden />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notPendingPages.length]);

  const allTopicChildren = useAllTopicChildren(topic.id);

  const [articlesFetched, setArticlesFetched] = useState(false);
  const articlesCardViewType = useAppSelector(state => state.cache.cardViewType);

  const articlesLoader = useMemo(() => {
    return articlesFetched ? null : (
      <NetworkJSONLoader
        url={ServerRouter.articles()}
        onOk={res => {
          fillArticles(res.results);
          setArticlesFetched(true);
        }}
      />
    );
  }, [articlesFetched]);

  useContentRefresh({
    apply: fillArticles,
  });

  const content = useMemo(() => {
    if (topic.id || topic.pages) {
      return [topic.id, ...allTopicChildren].map((item, index) => {
        const topicToDisplay = item ? topics[item] : topic;
        return topicToDisplay?.pages?.length ? (
          <>
            <TopicHeader
              topic={topicToDisplay}
              articleTab={articleTab}
              showInterests={() => setTopicInterestsVisible(true)}
              showActions={index === 0}
              toggleArticleTab={() => setArticleTab(prev => (prev ? 0 : 1))}
              className="pb-4"
            />

            {!!topicToDisplay?.pages && (
              <>
                {articleTab === 0 ? (
                  <TopicArticles
                    topic={topicToDisplay}
                    scrollToTop={scrollToTop}
                    articlesFetched={articlesFetched}
                    extensionWasConfigured={!!extensionWasConfigured}
                  />
                ) : (
                  <TopicTimeline topic={topicToDisplay} list={articlesCardViewType !== 'tile'} />
                )}
              </>
            )}
          </>
        ) : null;
      });
    }
    return children;
  }, [
    children,
    scrollToTop,
    articleTab,
    topics,
    articlesCardViewType,
    topic,
    extensionWasConfigured,
    allTopicChildren,
    articlesFetched,
  ]);

  return useMemo(
    () => (
      <>
        {loader}
        {articlesLoader}
        <div
          ref={contentRef}
          id="layoutSidenav_content"
          className={'vstack bg-white'}
          style={{ top: 'initial' }}
        >
          <div className="border-white px-5 pt-4 bg-white">
            <div className="hide-mobile">
              <DashboardTiles topic={topic} />
            </div>
            <FilterByTags />
          </div>
          <div className="bg-white px-5 pb-5">
            <div style={{ lineHeight: '2', fontSize: '20px' }}>{content}</div>
            <div ref={discoverContainerRef}>
              <TopicDiscoveryFeed
                topic={topic}
                topicInterestsVisible={topicInterestsVisible}
                setTopicInterestsVisible={setTopicInterestsVisible}
              />
            </div>
          </div>
          <LandingFooter />
        </div>
        {topic.id && <TopicInfoTab topic={topic} />}
        {!extensionWasConfigured && <ExtensionIntroduction onClose={onExtensionModalClose} />}
        {((!firstTour && !isExtensionInstalled) ||
          (!firstTour && isExtensionInstalled && testExt)) && (
          <Tour
            steps={[
              {
                target: '#topic-add-page-btn',
                title: <BlueLogo />,
                content: 'Click on Add Page to begin.',
              },
            ]}
            disableOverlayClose={true}
            scrollOffset={() => 200}
            onFinish={() => {
              setFirstTour('1');
              localStorage.setItem('FIRST_TOUR', '1');
            }}
          />
        )}
        {!secondTour && firstTour && articles?.length === 2 && (
          <Tour
            steps={[
              {
                target: 'header + div.container-xl .card:nth-child(2)',
                title: <BlueLogo />,
                content:
                  'Click on Tile to view saved page, view summary, and add notes for the page.',
              },
            ]}
            disableOverlayClose={true}
            scrollOffset={() => 200}
            onFinish={() => {
              setSecondTour('1');
              localStorage.setItem('SECOND_TOUR', '1');
            }}
          />
        )}
      </>
    ),
    [
      topic,
      loader,
      content,
      articlesLoader,
      extensionWasConfigured,
      articles?.length,
      firstTour,
      secondTour,
      testExt,
      isExtensionInstalled,
      topicInterestsVisible,
      setTopicInterestsVisible,
    ],
  );
}
