import React, { ChangeEvent, useRef, useState } from 'react';
import { IField } from './Field';
import { Button } from 'react-bootstrap';
import { File, UploadCloud } from 'react-feather';

type Props = {
  field: IField;
  onSetFile?: (file: string) => void;
  setInitialFile?: (file: File) => void;
};

export default function FileField({ field, onSetFile, setInitialFile }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const valueInputRef = useRef<HTMLInputElement>(null);
  const [filename, setFilename] = useState<string>();
  const setFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setFilename(file?.name);
    if (file) {
      if (setInitialFile && field.uploadType === 'pdf') {
        setInitialFile(file);
      }
      const reader = new FileReader();
      reader.onload = function (event) {
        if (inputRef.current) {
          inputRef.current.value = (event.target?.result ?? '') as string;
          onSetFile && onSetFile(event.target?.result as string);
        }
      };
      reader.onerror = function (event) {
        alert(reader.error);
      };
      reader.readAsText(file, 'UTF-8');
    } else {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  };
  return (
    <Button
      variant={'light'}
      className={'card-img-overlay ratio justify-content-center'}
      style={{ border: '1px solid black', height: '150px' }}
    >
      <div className={'justify-content-center px-2 align-items-center d-flex flex-column'}>
        {filename ? (
          <>
            <p className="word-break">
              <File /> {filename}
            </p>
            <p> Drag and drop or click to replace</p>
          </>
        ) : (
          <>
            <UploadCloud />
            <span> Drag and drop a file here or click</span>
          </>
        )}
      </div>
      <input ref={inputRef} type={'hidden'} />
      <input
        ref={valueInputRef}
        className={'card-img-overlay ratio pointer'}
        type={'file'}
        name={field.name}
        style={{ opacity: 0, border: '2px solid black' }}
        onChange={setFile}
      />
    </Button>
  );
}
