import React, { LegacyRef, PropsWithChildren, ReactNode, useMemo, useState } from 'react';
import { JSONObject } from '../../data/models/Model';
import ModalView from './ModalView';
import { ButtonProps, Dropdown, ModalProps } from 'react-bootstrap';
import { IconButtonProps } from '../buttons/icon/IconButton';
import { iconEditProps } from '../buttons/icon/IconEditButton';
import { iconAddProps } from '../buttons/icon/IconAddButton';
import { iconDeleteProps } from '../buttons/icon/IconDeleteButton';
import { deleteProps } from '../buttons/text/DeleteButton';
import { patchProps } from '../buttons/text/PatchButton';
import { postProps } from '../buttons/text/PostButton';
import CRUD, { CRUDOnOkCallback } from './CRUD';
import { FormFields } from './ModularForm';

export type FormProps = {
  defaultValues?: JSONObject;
  buttonProps?: ButtonProps;
  iconButtonProps?: IconButtonProps;
  modalProps?: ModalProps;
  formButton?: ReactNode;
  title?: ReactNode;
  actionButton?: (onClick: () => void) => ReactNode;
  nonLinear?: boolean;
  validateFunction?: (form: JSONObject) => JSONObject | void;
  formRef?: LegacyRef<any>;
};

type PatchFormProps = {
  defaultValues: JSONObject;
} & FormProps;

type DeleteFormProps = {
  defaultValues: { id: number; name?: string };
  withDefaultContainer?: boolean;
} & FormProps;

export default class ModalCRUD {
  url: string;
  className: string;
  fields: FormFields;
  onOk: CRUDOnOkCallback;

  constructor(url: string, className: string, fields: FormFields, onOk: CRUDOnOkCallback) {
    this.url = url;
    this.className = className;
    this.fields = fields;
    this.onOk = onOk;
  }

  post = ({
    defaultValues,
    buttonProps,
    iconButtonProps,
    formButton,
    title,
    actionButton,
    nonLinear,
    modalProps,
    validateFunction,
    ...rest
  }: FormProps) => {
    const [show, setShow] = useState<boolean>(false);
    const form = useMemo(() => {
      const crud = new CRUD(this.url, this.fields, data => {
        setShow(false);
        this.onOk(data);
      });
      return (
        <crud.post
          defaultValues={defaultValues}
          formButton={formButton}
          nonLinear={nonLinear}
          check={validateFunction}
        />
      );
    }, [defaultValues, validateFunction, formButton, nonLinear]);
    return useMemo(
      () => (
        <ModalView
          title={title ?? 'Add new ' + this.className}
          body={form}
          show={show}
          setShow={setShow}
          iconButtonProps={buttonProps ? undefined : { ...iconAddProps, ...iconButtonProps }}
          buttonProps={buttonProps && { ...postProps, ...buttonProps }}
          actionButton={actionButton}
          hideBody={true}
          modalProps={modalProps}
          {...rest}
        />
      ),
      [actionButton, buttonProps, form, iconButtonProps, show, title, modalProps, rest],
    );
  };

  patch = ({
    defaultValues,
    buttonProps,
    iconButtonProps,
    formButton,
    title,
    actionButton,
    nonLinear,
    validateFunction,
    ...rest
  }: PatchFormProps) => {
    const [show, setShow] = useState<boolean>(false);
    const form = useMemo(() => {
      const crud = new CRUD(this.url, this.fields, data => {
        setShow(false);
        this.onOk(data);
      });
      return (
        <crud.patch
          defaultValues={defaultValues}
          formButton={formButton}
          nonLinear={nonLinear}
          check={validateFunction}
        />
      );
    }, [defaultValues, validateFunction, formButton, nonLinear]);

    return useMemo(
      () => (
        <ModalView
          title={title ?? 'Edit ' + this.className}
          body={form}
          show={show}
          setShow={setShow}
          iconButtonProps={buttonProps ? undefined : { ...iconEditProps, ...iconButtonProps }}
          buttonProps={buttonProps && { ...patchProps, ...buttonProps }}
          actionButton={actionButton}
          hideBody={true}
          {...rest}
        />
      ),
      [actionButton, buttonProps, form, iconButtonProps, show, title, rest],
    );
  };

  delete = ({
    defaultValues,
    buttonProps,
    iconButtonProps,
    formButton,
    title,
    actionButton,
    validateFunction,
    ...rest
  }: DeleteFormProps) => {
    const [show, setShow] = useState<boolean>(false);
    const form = useMemo(() => {
      const crud = new CRUD(this.url, this.fields, data => {
        setShow(false);
        this.onOk(data);
      });
      return (
        <crud.delete
          defaultValues={defaultValues}
          formButton={formButton}
          check={validateFunction}
        />
      );
    }, [defaultValues, validateFunction, formButton]);
    const entityName = this.className + (defaultValues?.name ? ` "${defaultValues?.name}"` : '');
    return useMemo(
      () => (
        <ModalView
          title={title ?? 'Delete ' + this.className}
          body={`Are you sure about deleting ${entityName}?`}
          footer={form}
          show={show}
          setShow={setShow}
          iconButtonProps={buttonProps ? undefined : { ...iconDeleteProps, ...iconButtonProps }}
          buttonProps={buttonProps && { ...deleteProps, ...buttonProps }}
          actionButton={actionButton}
          {...rest}
        />
      ),
      [actionButton, buttonProps, entityName, form, iconButtonProps, show, title, rest],
    );
  };

  postDropdown = ({ children, ...rest }: PropsWithChildren<FormProps>) => {
    return this.post({
      ...rest,
      actionButton: onClick => <Dropdown.Item onClick={onClick}>{children}</Dropdown.Item>,
    });
  };

  patchDropdown = ({ children, ...rest }: PropsWithChildren<PatchFormProps>) => {
    return this.patch({
      ...rest,
      actionButton: onClick => <Dropdown.Item onClick={onClick}>{children}</Dropdown.Item>,
    });
  };

  deleteDropdown = ({
    children,
    withDefaultContainer = true,
    ...rest
  }: PropsWithChildren<DeleteFormProps>) => {
    return this.delete({
      ...rest,
      actionButton: onClick =>
        !withDefaultContainer ? (
          <div onClick={onClick}>{children}</div>
        ) : (
          <Dropdown.Item onClick={onClick}>{children}</Dropdown.Item>
        ),
    });
  };
}
