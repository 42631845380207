import React from 'react';

const TestimonialsSection = () => {
  return (
    <section className="pt-10 pb-5 bg-white">
      <div className="container px-5 z-1 testimonial bg-transparent">
        <h2>Testimonials</h2>
        <div className="d-flex justify-content-between flex-wrap">
          <p className="testimonial-quote">“getSparks goes way beyond bookmarks and makes it super easy for me to discover saved content stay organized”</p>
          <p className="testimonial-quote">“As a researcher I have to read across lots of sources and often find data being not relevant to my subject. With ability to read summary and organize my sources saved me lots of time”</p>
          <p className="testimonial-quote">“getSparks is like creating your own library of content, It has been so helpful to search within list of your own library instead of keep going back to Google and search”</p>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
