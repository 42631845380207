import React, { useCallback, useMemo } from 'react';
import Topic from '../../../data/models/Topic';
import NavTopicItem from './NavTopicItem';
import { ReactComponent as PlusIcon } from '../../../assets/img/icons/plus.svg';
import { useNavigateNoUpdates } from '../../../hooks/RouterUtils';
import { getTopicLightCRUD } from '../../../services/cruds';
import ClientRouter from '../../../routers/ClientRouter';
import { setTopic } from '../../../data/redux/actions';
import { useAppSelector } from '../../../data/redux/hooks';
import { RootState } from '../../../data/redux/store';
import { PremiumModal } from './FreePlan';
import { Colors } from '../../../assets/colors';
import { ReactComponent as DotsIcon } from '../../../assets/img/icons/dots.svg';
import { ReactComponent as TopicIcon } from '../../../assets/img/icons/topic.svg';
import useNavigateToDiscover from '../../../hooks/custom/useNavigateToDiscover';
import useMenu from '../../../hooks/custom/useMenu';

type Props = {
  topics: Topic[];
  title?: string;
  selectedTopicId?: number | null;
  withAddTopicOption?: boolean;
  withDiscoverOption?: boolean;
  withHoverAnimation?: boolean;
};

export default function NavTopics({
  topics,
  title,
  selectedTopicId,
  withAddTopicOption,
  withDiscoverOption,
  withHoverAnimation,
}: Props) {
  function compareTopics(topic1: Topic, topic2: Topic) {
    if (topic1.name === 'Default') {
      return -1;
    }
    if (topic2.name === 'Default') {
      return 1;
    }
    if (!topic1.name || !topic2.name) {
      return 0;
    }
    return topic1.name.localeCompare(topic2.name);
  }

  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
  let topics_count = Object.keys(
    useAppSelector(useCallback((state: RootState) => state.cache.topics, [])),
  );
  const topicsCached = useAppSelector(useCallback(state => state.cache.topics, []));
  const navigate = useNavigateNoUpdates();

  const [premiumModalShow, setPremiumModalShow] = React.useState(false);

  const navigateToDiscover = useNavigateToDiscover();

  const { parents, childrenByParentId } = useMemo(() => {
    const parents = topics.filter(topic => !topic.parent);
    const childrenByParentId: { [key: number]: Topic[] } = {};
    parents.forEach(parent => {
      if (parent.id) {
        childrenByParentId[parent.id] = topics.filter(topic => topic.parent === parent.id);
      }
    });
    return {
      parents,
      childrenByParentId,
    };
  }, [topics]);

  const topicCRUD = useMemo(
    () =>
      getTopicLightCRUD(
        data => {
          if (typeof data !== 'number') {
            if (data.id) {
              navigate(ClientRouter.topics(data.id));
            }
          }
          setTopic(data);
        },
        topicsCached,
        true,
      ),
    [navigate, topicsCached],
  );

  const titleMenu = useMenu();

  const button = useMemo(() => {
    const actionButton = (onClick: () => any) => {
      return (
        <titleMenu.ItemContainer onClick={onClick}>
          <PlusIcon width={15} height={15} fill={Colors.blueGray00} />
          <span>Add Topic</span>
        </titleMenu.ItemContainer>
      );
    };
    return user?.plan_name === 'Premium' && user?.plan_status !== 'Inactive' ? (
      <topicCRUD.post formRef={ref => titleMenu.placeRef(ref, 1)} actionButton={actionButton} />
    ) : (
      <>
        {topics_count.length < 4 ? (
          <topicCRUD.post actionButton={actionButton} formRef={ref => titleMenu.placeRef(ref, 1)} />
        ) : (
          actionButton(() => {
            setPremiumModalShow(true);
          })
        )}
      </>
    );
  }, [user, setPremiumModalShow, topics_count, topicCRUD, titleMenu]);

  const menuRendered = useMemo(() => {
    return <titleMenu.MenuContainer topOffset={30}>{button}</titleMenu.MenuContainer>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleMenu.menuVisible]);

  return useMemo(
    () => (
      <div className="d-flex flex-column gap-1">
        <div className="tags-main-item d-flex align-items-center justify-content-between position-relative">
          <div className="d-flex align-items-center">
            <TopicIcon width={16} height={18.2} style={{ marginBottom: 1 }} fill="#6B2ACB" />
            <span className="sidenav-item_title lh-1 mx-1 mr-0">
              Topics {topics.length ? `(${topics.length})` : ''}
            </span>
          </div>
          <button
            ref={ref => titleMenu.placeRef(ref, 0)}
            className="sidenav-item_icon default"
            onClick={titleMenu.toggleMenu}
          >
            <DotsIcon fill="black" />
          </button>
          {menuRendered}
        </div>
        {parents.sort(compareTopics).map((topic, index) => {
          return (
            <>
              <NavTopicItem
                topic={topic}
                key={topic.id ?? index}
                selectedTopicId={selectedTopicId}
                withHoverAnimation={withHoverAnimation}
              />
            </>
          );
        })}
        {withAddTopicOption && button}
        <PremiumModal
          showModal={premiumModalShow}
          onCancel={() => setPremiumModalShow(false)}
          signedUser={user?.email}
          limitType="topics"
        ></PremiumModal>
      </div>
    ),
    // eslint-disable-next-line
    [
      selectedTopicId,
      title,
      topics,
      user,
      setPremiumModalShow,
      withHoverAnimation,
      withDiscoverOption,
      navigateToDiscover,
      parents,
      menuRendered,
      childrenByParentId,
      button,
      titleMenu,
    ],
  );
}
