import Topic from './models/Topic';
import { ReduxIndexer } from './redux/utils';

function getCategories() {
  return [
    [0, 'Other'],
    [1, 'Arts & Entertainment'],
    [2, 'Autos & Vehicles'],
    [3, 'Beauty & Fitness'],
    [4, 'Books & Literature'],
    [5, 'Business & Industrial'],
    [6, 'Computers & Electronics'],
    [7, 'Finance'],
    [8, 'Food & Drink'],
    [9, 'Games'],
    [10, 'Health'],
    [11, 'Hobbies & Leisure'],
    [12, 'Home & Garden'],
    [13, 'Internet & Telecom'],
    [14, 'Jobs & Education'],
    [15, 'Law & Government'],
    [16, 'News'],
    [17, 'Online Communities'],
    [18, 'People & Society'],
    [19, 'Pets & Animals'],
    [20, 'Real Estate'],
    [21, 'Reference'],
    [22, 'Science'],
    [23, 'Sensitive Subjects'],
    [24, 'Shopping'],
    [25, 'Sports'],
    [26, 'Travel'],
  ];
}

export const Categories = getCategories();

export function getTopicsOptions(topics: ReduxIndexer<Topic>) {
  const sortedTopicsOptions: Array<[string, string, number]> = [];
  const setAllChildren = (id: number | string, level: number) => {
    const children = topics[id]?.child;
    if (children) {
      children.forEach(child => {
        const name = topics[child]?.name;
        sortedTopicsOptions.push([child.toString(), name || '<unnamed>', level]);
        setAllChildren(child, level + 1);
      });
    }
  };
  Object.entries(topics)
    .filter(([_, topic]) => !topic?.parent)
    .forEach(([topicId, topic]) => {
      sortedTopicsOptions.push([topicId, topic?.name || '<unnamed>', 0]);
      setAllChildren(topicId, 1);
    });
  return sortedTopicsOptions;
}
