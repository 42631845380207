import CRUD, { CRUDOnOkCallback } from '../components/crud/CRUD';
import ServerRouter from '../routers/ServerRouter';
import User from '../data/models/User';
import { ReduxIndexer } from '../data/redux/utils';
import Topic from '../data/models/Topic';
import Article from '../data/models/Article';
import { IField } from '../components/crud/fields/Field';
import ModalCRUD from '../components/crud/ModalCRUD';
import ArticleNote from '../data/models/ArticleNote';
import TopicNote from '../data/models/TopicNote';
import { FormFields } from '../components/crud/ModularForm';
import Question from '../data/models/Question';
import { useAppSelector } from '../data/redux/hooks';
import { useCallback } from 'react';
import { RootState } from '../data/redux/store';
import { setArticle } from '../data/redux/actions';
import Tag from '../data/models/Tag';

export function getModalCRUDBuilder(url: string, className: string) {
  return (fields: IField[], onOk: CRUDOnOkCallback) => new ModalCRUD(url, className, fields, onOk);
}

export function getCRUDBuilder(url: string) {
  return (fields: FormFields, onOk: CRUDOnOkCallback) => new CRUD(url, fields, onOk);
}

export const tagCRUDBuilder = getModalCRUDBuilder(ServerRouter.tags(), '');

export function getTagCRUD(onOk: CRUDOnOkCallback, tags: (Tag | undefined)[]) {
  const titleField = Tag.fields.title(tags);
  return tagCRUDBuilder([titleField, { name: 'id', hidden: true }], onOk);
}

export const topicCRUDBuilder = getModalCRUDBuilder(ServerRouter.topics(), 'topic');

export function getTopicLightCRUD(
  onOk: CRUDOnOkCallback,
  topics: ReduxIndexer<Topic>,
  isParentOptionHidden?: boolean,
) {
  const fields = [
    { ...Topic.light_fields.name },
    { ...Topic.light_fields.parent(topics), hidden: isParentOptionHidden },
  ];
  return topicCRUDBuilder(fields, onOk);
}

export const questionCRUDBuilder = getModalCRUDBuilder(ServerRouter.questions(), 'questions');

export const getQuestionCRUD = (onOk: CRUDOnOkCallback) => {
  return questionCRUDBuilder([Question.fields.question, Question.fields.page], onOk);
};

export const articleInlineCRUDBuilder = getCRUDBuilder(ServerRouter.articles());

export const getArticleInlineCRUD = (onOk: CRUDOnOkCallback, topics: ReduxIndexer<Topic>) => {
  return articleInlineCRUDBuilder(
    [
      { ...Article.fields.topic(topics), hidden: true },
      { ...Article.fields.url, hidden: true },
    ],
    onOk,
  );
};

export const articleCRUDBuilder = getModalCRUDBuilder(ServerRouter.articles(), 'url');

export function getArticleAddCRUD(
  onOk: CRUDOnOkCallback,
  topics: ReduxIndexer<Topic>,
  onCreateNewSelect?: () => any,
) {
  const topicField = Article.fields.topic(topics);
  return articleCRUDBuilder(
    [
      Article.fields.uploadType,
      Article.fields.url,
      Article.fields.file,
      Article.fields.text,
      { ...topicField, options: topicField.options, onCreateNewSelect },
      // Article.fields.category
    ],
    onOk,
  );
}

export function useGetArticleAddCRUD(onCreateNewSelect?: () => any) {
  const topics = useAppSelector(useCallback((state: RootState) => state.cache.topics, []));
  const topicField = Article.fields.topic(topics);
  return articleCRUDBuilder(
    [
      Article.fields.uploadType,
      Article.fields.url,
      Article.fields.file,
      Article.fields.text,
      { ...topicField, options: topicField.options, onCreateNewSelect },
      // Article.fields.category
    ],
    setArticle,
  );
}

export function getArticleManageCRUD(onOk: CRUDOnOkCallback, topics: ReduxIndexer<Topic>) {
  return articleCRUDBuilder([Article.fields.topic(topics), Article.fields.category], onOk);
}

export const articleNotesCRUDBuilder = getModalCRUDBuilder(ServerRouter.articleNotes(), 'note');

export function getArticleNotesCRUD(onOk: CRUDOnOkCallback) {
  return articleNotesCRUDBuilder(Object.values(ArticleNote.fields), onOk);
}

export const articleNotesManageCRUDBuilder = getCRUDBuilder(ServerRouter.articleNotes());

export function getArticleNotesManageCRUD(onOk: CRUDOnOkCallback) {
  return articleNotesManageCRUDBuilder(Object.values(ArticleNote.fields), onOk);
}

export const topicNotesCRUDBuilder = getModalCRUDBuilder(ServerRouter.topicNotes(), 'note');

export function getTopicNotesCRUD(onOk: CRUDOnOkCallback) {
  return topicNotesCRUDBuilder(Object.values(TopicNote.fields), onOk);
}

export const topicNotesManageCRUDBuilder = getCRUDBuilder(ServerRouter.topicNotes());

export function getTopicNotesManageCRUD(onOk: CRUDOnOkCallback) {
  return topicNotesManageCRUDBuilder(Object.values(TopicNote.fields), onOk);
}

export const importBookmarksCRUDBuilder = getModalCRUDBuilder(
  ServerRouter.importBookmarks(),
  'bookmarks',
);

export function getImportBookmarksCRUD(onOk: CRUDOnOkCallback) {
  return importBookmarksCRUDBuilder(
    [{ name: 'file_content', type: 'file', placeholder: 'Bookmarks HTML file' }],
    onOk,
  );
}

export const profileCRUDBuilder = getCRUDBuilder(ServerRouter.updateProfile());

export function getProfileCRUD(onOk: CRUDOnOkCallback) {
  return profileCRUDBuilder(
    [
      { ...User.fields.real_email, disabled: true },
      [User.fields.first_name, User.fields.last_name],
      [{ ...User.fields.get_newsletter, placeholder: 'Email Notifications' }],
    ],
    onOk,
  );
}
