import React, { useCallback, useMemo } from 'react';
import { LogOut, User as UserIcon } from 'react-feather';
import ClientRouter from '../../../routers/ClientRouter';
import ProfilePicture from '../../../components/nav/ProfilePicture';
import { Dropdown } from 'react-bootstrap';
import NavDropdown from '../../../components/nav/NavDropdown';
import ImportBookmarksDropdownItem from './ImportBookmarksDropdownItem';
import { useNavigateNoUpdates } from '../../../hooks/RouterUtils';
import { useAppSelector } from '../../../data/redux/hooks';
import { RootState } from '../../../data/redux/store';
import { setUser } from '../../../data/redux/actions';

export default function ProfileDropdown() {
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
  const navigate = useNavigateNoUpdates();
  return useMemo(() => {
    return (
      <li className={'nav-item dropdown no-caret dropdown-user mx-2'}>
        <NavDropdown
          toggleWrapperClassName="bg-blue-gray-3"
          toggle={<ProfilePicture />}
          menu={
            <>
              <Dropdown.Header>
                <div className="profile-picture-bg">
                  <ProfilePicture />
                </div>
                {user && (
                  <div className={'dropdown-user-details'}>
                    <div className={'dropdown-user-details-name'}>{user.username}</div>
                    <div className={'dropdown-user-details-email'}>{user.email}</div>
                  </div>
                )}
              </Dropdown.Header>
              <Dropdown.Divider />
              <ImportBookmarksDropdownItem />
              {Object.entries({
                Profile: { icon: <UserIcon />, onClick: () => navigate(ClientRouter.profile()) },
                Logout: { icon: <LogOut />, onClick: () => setUser(0) },
              }).map((entry, index) => (
                <Dropdown.Item onClick={entry[1].onClick} className={'dropdown-item'} key={index}>
                  <div className={'dropdown-item-icon'}>{entry[1].icon}</div>
                  {entry[0]}
                </Dropdown.Item>
              ))}
            </>
          }
        />
      </li>
    );
  }, [navigate, user]);
}
