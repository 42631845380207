import React, { useMemo } from 'react';
import Topic from '../../../../data/models/Topic';
import Article from '../../../../data/models/Article';
import useCheckExtensionInstalled from '../../../../hooks/custom/useCheckExtensionInstalled';
import usePagination from '../../../../hooks/custom/usePagination';
import { useAppSelector } from '../../../../data/redux/hooks';
import TopicTilesArticles from './TopicTilesArticles';
import TopicListArticles from './TopicListArticles';
import useTopicArticlesWithFilters from '../../../../hooks/custom/useTopicArticlesWithFilters';

export type TopicArticlesProps = {
  topic: Topic;
  scrollToTop: (behavior?: ScrollBehavior) => void;
  extensionWasConfigured?: boolean;
  articlesFetched: boolean;
};

export const ARTICLE_VIEWED = 'article_viewed';

export default function TopicArticles({
  topic,
  scrollToTop,
  extensionWasConfigured,
  articlesFetched,
}: TopicArticlesProps) {
  const extensionInstalled = useCheckExtensionInstalled();
  const articles = useTopicArticlesWithFilters(topic);
  let articleAdded = false;
  for (const a of articles) {
    const article = a as Article;
    if (
      article?.status === 'full' &&
      !article.url?.match(':\\/\\/[^/]+\\/#\\/how-does-sparks-work\\/$')
    ) {
      articleAdded = true;
      break;
    }
  }

  const articlesPagination = usePagination({ items: articles });
  const articlesCardViewType = useAppSelector(state => state.cache.cardViewType);

  return useMemo(
    () => {
      return (
        <>
          {articlesCardViewType === 'tile' && (
            <TopicTilesArticles
              extensionInstalled={extensionInstalled}
              extensionWasConfigured={extensionWasConfigured}
              topic={topic}
              articleAdded={articleAdded}
              articlesFetched={articlesFetched}
              pagination={articlesPagination}
              scrollToTop={scrollToTop}
            />
          )}
          {(articlesCardViewType === 'list' || articlesCardViewType === 'headlines') && (
            <TopicListArticles pagination={articlesPagination} />
          )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      articleAdded,
      articles,
      scrollToTop,
      topic,
      extensionInstalled,
      extensionWasConfigured,
      articlesPagination.paginatedItems,
      articlesFetched,
    ],
  );
}
