import React, { ReactNode, useContext, useEffect, useMemo } from 'react';
import { ArticleViewType } from '../../../data/redux/utils';
import Article from '../../../data/models/Article';
import { ArticleViewTypeContext } from './ArticleView';
import { ReactComponent as BookIcon } from '../../../assets/img/icons/book.svg';
import { ReactComponent as ImageCrossedIcon } from '../../../assets/img/icons/image-crossed.svg';
import { ReactComponent as StarIcon } from '../../../assets/img/icons/star.svg';
import { ReactComponent as ImageIcon } from '../../../assets/img/icons/image.svg';
// import { ReactComponent as SummaryIcon } from '../../../assets/img/icons/summary.svg';
import { Colors } from '../../../assets/colors';
import Toggle from 'react-toggle';

type Props = {
  article?: Article;
  short?: boolean;
  containerClassName?: string;
};

type Options = Partial<{ [x in ArticleViewType]: { icon?: ReactNode; label: string } }>;

export default function ArticleViewTypeSwitch({
  article,
  short = true,
  containerClassName = '',
}: Props) {
  const [articleViewType, setArticleViewType] = useContext(ArticleViewTypeContext);
  const options: Options = useMemo(() => {
    const options: Options = {};
    if (article?.text) {
      options['text'] = {
        icon: (
          <ImageCrossedIcon
            fill={articleViewType === 'text' && !short ? Colors.primary01 : Colors.textBlack}
          />
        ),
        label: 'Images Off',
      };
    }
    if (article?.page_content !== 'TEXT') {
      options['preview'] = {
        icon: (
          <BookIcon
            width={17}
            height={15}
            fill={articleViewType === 'preview' && !short ? Colors.primary01 : Colors.textBlack}
          />
        ),
        label: 'Reader',
      };
      if (article?.page_content !== 'PDF') {
        options['web'] = {
          icon: (
            <StarIcon
              fill={articleViewType === 'web' && !short ? Colors.primary01 : Colors.textBlack}
            />
          ),
          label: 'Original',
        };
      }
    }
    return options;
  }, [article, articleViewType, short]);

  useEffect(() => {
    if (!(articleViewType in options) && Object.keys(options).length > 0) {
      setArticleViewType(Object.keys(options)[0] as ArticleViewType);
    }
  }, [articleViewType, options, setArticleViewType]);

  const activeOption = options[articleViewType];

  return useMemo(() => {
    return short ? (
      <div
        className={`article-switch-short__item topic-header-menu__item d-flex gap-2 flex-grow-1 align-items-center ${containerClassName}`}
        // onClick={() => setArticleViewType(type as ArticleViewType)}
      >
        <ImageIcon fill={Colors.blueGray00} width={15} height={15} />
        Images
        <Toggle
          checked={articleViewType === 'preview'}
          icons={false}
          onChange={() => setArticleViewType(prev => (prev === 'preview' ? 'text' : 'preview'))}
          className="sparks-toggle mx-2 mr-0"
        />
        {/*{option.icon} <span className="body-text-sm">{option.label}</span>*/}
      </div>
    ) : (
      <div className={`article-switch-short ${containerClassName}`}>
        <div className="article-switch-short__label topic-header-menu__label px-3 align-self-start">
          Text Mode
        </div>
        {Object.keys(options).map(type => {
          const option = options[type as ArticleViewType];
          return (
            option && (
              <div
                className={`article-switch-short__item topic-header-menu__item ${
                  activeOption?.label === option.label ? 'text-primary-1' : ''
                } d-flex gap-2 flex-grow-1`}
                key={`${type}-short`}
                onClick={() => setArticleViewType(type as ArticleViewType)}
              >
                {option.icon} <span className="body-text-sm">{option.label}</span>
              </div>
            )
          );
        })}
      </div>
    );
  }, [options, setArticleViewType, activeOption, containerClassName, short, articleViewType]);
}
