import Note from "./Note";
import {IField} from "../../components/crud/fields/Field";

export default class TopicNote extends Note {
  static fields = {
    ...Note.fields,
    topic: {name: "topic", hidden: true} as IField,
  };
  readonly topic?: number;

  constructor(jsonData: Partial<TopicNote>) {
    super(jsonData);
    Object.assign(this, jsonData);
  }
}
