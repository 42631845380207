import Topic from '../../data/models/Topic';
import { FormProps } from './ModalCRUD';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppSelector } from '../../data/redux/hooks';
import { RootState } from '../../data/redux/store';
import { getTopicLightCRUD } from '../../services/cruds';
import ServerRouter from '../../routers/ServerRouter';
import { fillTopics, setArticle } from '../../data/redux/actions';
import Article from '../../data/models/Article';
import CRUD from './CRUD';
import ModalView from './ModalView';
import { iconAddProps } from '../buttons/icon/IconAddButton';
import { postProps } from '../buttons/text/PostButton';
import { PremiumLimitType, PremiumModal } from '../../pages/dashboard/sidenav/FreePlan';
import { JSONObject } from '../../data/models/Model';
import { checkIsLimitError } from '../../services/utils';
import useConditionalLoader from '../../hooks/custom/useConditionalLoader';

type ArticleAddCrudProps = {
  topic?: Topic;
};

const ArticleAddCRUDComponent = ({
  actionButton,
  modalProps,
  buttonProps,
  iconButtonProps = {},
  defaultValues,
  formButton,
  nonLinear,
  topic,
}: FormProps & ArticleAddCrudProps) => {
  const [defaultTopicId, setDefaultTopicId] = useState(topic?.id);
  const topics = useAppSelector(useCallback((state: RootState) => state.cache.topics, []));
  const topicsCount = useMemo(() => Object.keys(topics).length, [topics]);
  const topicCRUDRef = useRef<HTMLButtonElement>(null);

  const [show, setShow] = useState(false);

  const [premiumLimitType, setPremiumLimitType] = useState<PremiumLimitType>('pages');
  const [premiumModalShow, setPremiumModalShow] = useState(false);
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));

  const { setShouldFetch: setShouldFetchTopics, loader: topicLoader } = useConditionalLoader({
    url: ServerRouter.topics(),
    onOk: fillTopics,
  });
  const topicCRUD = useMemo(
    () =>
      getTopicLightCRUD(data => {
        if (typeof data !== 'number' && data.id) {
          setDefaultTopicId(data.id);
        }
        setShouldFetchTopics(true);
      }, topics),
    [topics, setShouldFetchTopics],
  );

  const createTopicOptionHandler = useCallback(() => {
    if (topicsCount >= 4) {
      setPremiumLimitType('topics');
      setPremiumModalShow(true);
      return 'clear';
    }
    if (topicCRUDRef.current) {
      topicCRUDRef.current.click();
    }
    return 'clear';
  }, [topicsCount]);

  const onRequestFailed = useCallback((error?: JSONObject) => {
    const isLimitError = checkIsLimitError(error);
    if (isLimitError) {
      setPremiumLimitType('pages');
      setPremiumModalShow(true);
    }
  }, []);

  const form = useMemo(() => {
    const topicField = Article.fields.topic(topics);

    const crud = new CRUD(
      ServerRouter.articles(),
      [
        Article.fields.uploadType,
        Article.fields.url,
        Article.fields.file,
        Article.fields.text,
        {
          ...topicField,
          options: topicField.options,
          onCreateNewSelect: createTopicOptionHandler,
          value: defaultTopicId,
          onChange: value => setDefaultTopicId(value),
        },
        // Article.fields.category
      ],
      data => {
        setShow(false);
        setArticle(data);
        setShouldFetchTopics(true);
      },
    );
    return (
      <crud.post
        defaultValues={{ topic, category: topic?.category, ...defaultValues }}
        formButton={formButton}
        defaultShowErrors={false}
        nonLinear={nonLinear}
        onRequestFailed={onRequestFailed}
      />
    );
  }, [
    defaultValues,
    formButton,
    nonLinear,
    topics,
    createTopicOptionHandler,
    defaultTopicId,
    topic,
    onRequestFailed,
    setShouldFetchTopics,
  ]);

  useEffect(() => {
    if (topic?.id) {
      setDefaultTopicId(topic.id);
    }
  }, [topic?.id]);

  const modal = useMemo(
    () => (
      <ModalView
        title="Add new url"
        body={form}
        show={show}
        setShow={setShow}
        iconButtonProps={buttonProps ? undefined : { ...iconAddProps, ...iconButtonProps }}
        buttonProps={buttonProps && { ...postProps, ...buttonProps }}
        actionButton={actionButton}
        hideBody={true}
        modalProps={modalProps}
      />
    ),
    [form, show, buttonProps, actionButton, modalProps, iconButtonProps],
  );

  return (
    <>
      {modal}
      {topicLoader}
      <PremiumModal
        showModal={premiumModalShow}
        limitType={premiumLimitType}
        onCancel={() => setPremiumModalShow(false)}
        signedUser={user?.email}
      ></PremiumModal>
      <topicCRUD.post
        actionButton={onClick => (
          <button ref={topicCRUDRef} onClick={onClick} hidden>
            Create
          </button>
        )}
        modalProps={{ backdropClassName: 'overlap-backdrop' }}
      />
    </>
  );
};

export default ArticleAddCRUDComponent;
