import React, {useCallback, useMemo} from 'react';
import {useAppSelector} from "../../../../data/redux/hooks";
import {RootState} from "../../../../data/redux/store";
import onboarding from "../../../../assets/img/illustrations/onboarding.jpg";
import OnboardCollapsedView from "./OnboardCollapsedView";
import {OnboardDetailedView} from "./OnboardDetailedView";

type Props = {
  scrollToTop: (behavior?: ScrollBehavior) => void,
}

export default function OnboardView({scrollToTop}: Props) {
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
  return useMemo(() => <>
    <div className={'card card-body mb-5 d-md-none'}>
      The current version of the getSparks web app is designed for desktop use. We are working hard to enhance the UI
      for the mobile interface and build a mobile app.
    </div>
    <div className={'card card-body mb-5 d-none d-md-block'} style={{backgroundImage: `url(${onboarding})`}}>
      {user?.onboarding_closed ? <OnboardCollapsedView/> : <OnboardDetailedView scrollToTop={scrollToTop}/>}
    </div>
  </>, [scrollToTop, user?.onboarding_closed]);
}
