import { JSONObject } from '../data/models/Model';
import { iteratorToDict } from '../services/utils';

export const getURLFormer = (serverURL: string) => {
  if (!serverURL.endsWith('/')) {
    serverURL += '/';
  }
  return (strings: TemplateStringsArray, ...keys: any) =>
    (...values: any) => {
      const result = [strings[0]];
      values.forEach((value: string, i: number) => {
        result.push(value, strings[i + 1]);
      });
      return serverURL + result.join('');
    };
};

export function getCookie(name: string) {
  const match = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return match ? match[2] : undefined;
}

export function deleteCookie(name: string) {
  document.cookie = name + '=; Max-Age=-99999999;path=/;';
}

export function getHeaders(headersInit?: HeadersInit) {
  const headers = new Headers(headersInit);
  headers.append('Content-Type', 'application/json');
  const csrf = getCookie('csrftoken');
  csrf && headers.append('X-CSRFToken', csrf);
  const token = getCookie('token');
  if (token) {
    headers.append('Authorization', `Token ${token}`);
  }
  return headers as any;
}

export const getAxiosHeaders = () => {
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${getCookie('token')}`,
    },
  };
};

type FetchURLProps = {
  url: string;
  body?: JSONObject;
  method?: string;
  headers?: HeadersInit;
};

export async function fetchURL({ url, body, method, headers }: FetchURLProps) {
  if (method) {
    return fetch(url, {
      method: method,
      headers: getHeaders(headers),
      credentials: 'include',
      body: JSON.stringify(body),
    });
  }
  return fetch(url, {
    method: 'GET',
    headers: getHeaders(headers),
    credentials: 'include',
  });
}

function formErrorResponse(error: string, statusCode?: number) {
  return new Response(undefined, { status: statusCode, statusText: error });
}

type ProcessResponseProps = FetchURLProps & {
  onOk: (response: Response) => void;
  onError: (error: Response) => void;
};

export async function processResponse({ onOk, onError, ...rest }: ProcessResponseProps) {
  try {
    const response = await fetchURL(rest);
    try {
      if (response.ok) {
        onOk(response);
      } else {
        onError(response);
      }
    } catch (e: any) {
      onError(formErrorResponse('Error processing Response: ' + e.message));
    }
  } catch (e: any) {
    onError(formErrorResponse('Error fetching Response: ' + e.message));
  }
}

export type ResponseHeaders = { [key: string]: string | undefined };
export type JSONOnOkResponse = (
  json: JSONObject | JSONObject[] | null,
  headers: ResponseHeaders,
) => void;
export type ProcessJSONResponseProps = Omit<ProcessResponseProps, 'onOk'> & {
  onOk: JSONOnOkResponse;
};

export function processJSONResponse({ onOk, onError, ...rest }: ProcessJSONResponseProps) {
  processResponse({
    onOk: async response => {
      try {
        const headers = iteratorToDict(response.headers.entries());
        if (response.status === 304) {
          onOk(null, headers);
        } else {
          const json = await response.json();
          try {
            onOk(json, headers);
          } catch (e: any) {
            onError(formErrorResponse('Error processing JSON Response: ' + e.message));
          }
        }
      } catch (e: any) {
        onError(formErrorResponse('Error extracting JSON Response: ' + e.message));
      }
    },
    onError: onError,
    ...rest,
  }).then(() => {});
}

export type JSONObjectOnOkResponse = (json: JSONObject, headers: ResponseHeaders) => void;
export type ProcessJSONObjectResponseProps = Omit<ProcessJSONResponseProps, 'onOk'> & {
  onOk: JSONObjectOnOkResponse;
};

export function processJSONObjectResponse({
  onOk,
  onError,
  ...rest
}: ProcessJSONObjectResponseProps) {
  processJSONResponse({
    onOk: (json, headers) => {
      if (Array.isArray(json)) {
        onError(formErrorResponse('Expected single JSON object, fetched array'));
      } else {
        onOk(json ?? {}, headers);
      }
    },
    onError: onError,
    ...rest,
  });
}

export type JSONArrayOnOkResponse = (json: JSONObject[] | null, headers: ResponseHeaders) => void;
export type ProcessJSONArrayResponseProps = Omit<ProcessJSONResponseProps, 'onOk'> & {
  onOk: JSONArrayOnOkResponse;
};

export function processJSONArrayResponse({
  onOk,
  onError,
  ...rest
}: ProcessJSONArrayResponseProps) {
  processJSONResponse({
    onOk: (json, headers) => {
      if (json === null || Array.isArray(json)) {
        onOk(json, headers);
      } else {
        onError(formErrorResponse('Malformed array was fetched'));
      }
    },
    onError: onError,
    ...rest,
  });
}

export function appendUTMSource(url: string) {
  if (url.indexOf('?') < 0) {
    url += '?';
  } else if (url[-1] !== '&') {
    url += '&';
  }
  return url + 'utm_source=getsparks.io';
}
