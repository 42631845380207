import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Article from '../../../data/models/Article';
import { SummaryScrollContext } from '../article/ArticleView';
import { useAppSelector } from '../../../data/redux/hooks';
import { RootState } from '../../../data/redux/store';
import Toggle from 'react-toggle';
import InfoTabHeader from './InfoTabHeader';
import SummaryItem from '../../../components/SummaryItem';
import useMenu from '../../../hooks/custom/useMenu';
import { ReactComponent as NotesIcon } from '../../../assets/img/icons/notes.svg';
import { ReactComponent as PrintIcon } from '../../../assets/img/icons/print.svg';
import { ReactComponent as CopyIcon } from '../../../assets/img/icons/copy.svg';
import { ReactComponent as QuestionIcon } from '../../../assets/img/icons/question.svg';
import { ReactComponent as InfoIcon } from '../../../assets/img/icons/info.svg';
import { InfoTabShowing } from '../../../data/redux/utils';
import { setInfoTab } from '../../../data/redux/actions';
import { Colors } from '../../../assets/colors';
import SkeletonLoader from '../../../components/loader/SkeletonLoader';
import useArticleMetadataFetch from '../../../hooks/custom/useArticleMetadataFetch';

type SwitchButtonProps = {
  value: boolean;
  onChange: () => void;
};

export const summaryLoaderBody = [
  [[1], [1], [1], [1], [1, 0], [1, 1, 0, 0]],
  [[1], [1], [1], [1], [1, 0], [1, 1, 0, 0]],
  [[1], [1], [1, 0], [1, 1, 0, 0]],
];

const ArticleSummarySwitchButton = ({ onChange, value }: SwitchButtonProps) => {
  return (
    <div className="d-flex gap-2 align-items-center hide-mobile">
      <div className="body-text-sm">Segmented</div>
      <Toggle checked={value} icons={false} onChange={onChange} className="sparks-toggle" />
    </div>
  );
};

type Props = {
  article: Article;
};

const ArticleSummaryInfo = ({ article }: Props) => {
  const metadata = useAppSelector(
    useCallback(state => article.getMetadata(state.cache), [article]),
  );
  const text = metadata?.summary || '';
  const chunks = metadata?.chunks || [];

  const [isGrouped, setGrouped] = useState(true);
  const { setShouldSyncScrolls } = useContext(SummaryScrollContext);

  const containerRef = useRef<HTMLDivElement>(null);
  let statePageNumber = useAppSelector(
    useCallback((state: RootState) => state.cache.pageNumber, []),
  );
  const summaryDiv = useRef<HTMLDivElement>(null);
  const printRef = useRef<HTMLDivElement>(null);

  const menu = useMenu();
  const infoTab = useAppSelector(useCallback((state: RootState) => state.cache.infoTab, []));

  const switchTab = useCallback(
    (showing: InfoTabShowing) => {
      const visible =
        !infoTab.visible || infoTab.showing !== showing || infoTab.about !== 'article';
      setInfoTab({ visible, showing: showing, about: 'article' });
    },
    [infoTab],
  );

  const { metadataLoader, loading } = useArticleMetadataFetch(article);

  useEffect(() => {
    if (summaryDiv.current && !isGrouped) {
      if (summaryDiv.current.children[statePageNumber - 1])
        summaryDiv.current.children[statePageNumber - 1]?.focus();
    }
  }, [statePageNumber, isGrouped]);

  useEffect(() => {
    if (setShouldSyncScrolls) {
      setShouldSyncScrolls(!isGrouped);
    }
  }, [isGrouped, setShouldSyncScrolls]);

  const neverShowSummary = !article.text || article.page_content === 'Bookmark';
  const summaryAvailable = (!!chunks?.length || !!text) && !neverShowSummary;
  const noSummary = !summaryAvailable && !loading;

  const menuView = useMemo(() => {
    return (
      <>
        <menu.ItemContainer onClick={() => switchTab('notes')}>
          <NotesIcon width={18} height={18} fill="#9197A1" /> <span>Take a Note</span>
        </menu.ItemContainer>
        <menu.ItemContainer onClick={() => switchTab('questions')}>
          <QuestionIcon width={18} height={18} fill="#9197A1" /> <span>Ask a Question</span>
        </menu.ItemContainer>
        <div className="blue-gray-divider-sm"></div>
        <CopyToClipboard text={text}>
          <menu.ItemContainer>
            <CopyIcon width={18} height={18} fill="#9197A1" /> <span>Copy</span>
          </menu.ItemContainer>
        </CopyToClipboard>
        <ReactToPrint
          content={() => printRef.current}
          trigger={() => (
            <menu.ItemContainer>
              <PrintIcon width={18} height={18} fill="#9197A1" /> <span>Print</span>
            </menu.ItemContainer>
          )}
        />
        <div className="hide-mobile" onClick={() => switchTab('details')}>
          <menu.ItemContainer>
            <InfoIcon fill={Colors.blueGray00} width={15} height={15} />{' '}
            <span className="body-text-sm">Details</span>
          </menu.ItemContainer>
        </div>
      </>
    );
  }, [menu, switchTab, text]);

  return (
    <>
      <InfoTabHeader title="Summary">
        {!noSummary && (
          <ArticleSummarySwitchButton
            onChange={() => setGrouped(prev => !prev)}
            value={isGrouped}
          />
        )}
        <div className="only-mobile">
          <menu.Toggle />
        </div>
        <menu.MenuContainer>{menuView}</menu.MenuContainer>
      </InfoTabHeader>
      {metadataLoader}
      <div ref={containerRef}>
        <div>
          <h3 className="text-center only-print">{article.title}</h3>
          {noSummary && (
            <div className="bg-red-02 body-text text-red-01 text-center py-3 m-3">
              Summary is not available for this page
            </div>
          )}
        </div>
        {loading &&
          !summaryAvailable &&
          !neverShowSummary &&
          summaryLoaderBody.map((body, i) => (
            <SkeletonLoader loaderBody={body} key={`article-summary-${i}`} />
          ))}
        {!!text && summaryAvailable && isGrouped && (
          <div className="summary__content text-break">
            <SummaryItem menu={menuView} printRef={printRef} body={text} title={article.title} />
          </div>
        )}
        {!!chunks?.length && summaryAvailable && !isGrouped && (
          <div ref={summaryDiv} className="summary__content text-break gap-2 d-flex flex-column">
            {chunks.map(({ chunk_summary, id, page_number }) => (
              <SummaryItem
                menu={menuView}
                printRef={printRef}
                body={chunk_summary}
                key={id}
                page={article.page_content === 'PDF' ? page_number : 0}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ArticleSummaryInfo;
