import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import ClientRouter from '../../routers/ClientRouter';
import { FooterList } from './FooterList';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import ExternalLink from '../network/ExternalLink';
import FooterCopyright from './FooterCopyright';
import GreyLogo from './GreyLogo';
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Link, matchPath, useLocation } from 'react-router-dom';

export function SocialLink({ url, icon }: { url: string; icon: IconDefinition }) {
  return (
    <ExternalLink className="icon-list-social-link" to={url}>
      <FontAwesomeIcon icon={icon} />
    </ExternalLink>
  );
}

type Props = {
  light?: boolean;
};

export default function LandingFooter({ light }: Props) {
  const location = useLocation();
  const isHomePage = matchPath(location.pathname, ClientRouter.home());
  const theme = light ? 'light' : 'dark';
  return useMemo(
    () => (
      <div id="layoutDefault_footer" style={{ textAlign: 'left' }}>
        <footer className={`footer pt-10 pb-5 mt-auto bg-${theme} footer-${theme}`}>
          <div className="container px-5">
            <div className="row gx-5">
              <div className="col-lg-3">
                <GreyLogo />
                <div className="icon-list-social mb-5">
                  <SocialLink
                    url={'https://www.facebook.com/GetSparks-105205965389670'}
                    icon={faFacebook}
                  />
                  <SocialLink url={'https://twitter.com/getsparks1'} icon={faTwitter} />
                </div>
                {isHomePage && (
                  <a
                    href="https://www.producthunt.com/posts/getsparks-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-getsparks&#0045;2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=367670&theme=light"
                      alt="getSparks - &#0032;Library&#0032;of&#0032;web&#0032;content&#0044;&#0032;generate&#0032;summary&#0032;and&#0032;add&#0032;Notes&#0046; | Product Hunt"
                      style={{ width: 250, height: 54 }}
                      width="250"
                      height="54"
                    />
                  </a>
                )}
              </div>
              <div className="col-lg-9">
                <div className="row gx-5">
                  <FooterList
                    name={'Product'}
                    urls={{
                      Extension: ClientRouter.lpExtension(),
                      Discover: ClientRouter.discover(),
                    }}
                  />
                  <div className="col-lg-4 col-md-4 mb-5 mb-lg-0">
                    <div className="text-uppercase-expanded text-xs mb-4">Technical</div>
                    <ul className="list-unstyled mb-0">
                      <li className="mb-2">
                        <a href="https://blog.getsparks.io/">Blog</a>
                      </li>
                      <li className="mb-2">
                        <a
                          href="https://learn.getsparks.io/"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Knowledge Base
                        </a>
                      </li>
                      <li className="mb-2">
                        <Link to={ClientRouter.about()}>About</Link>
                      </li>
                    </ul>
                  </div>
                  <FooterList
                    name={'Legal'}
                    urls={{
                      'Privacy Policy': ClientRouter.privacyPolicy(),
                      'Terms and Conditions': ClientRouter.termsAndConditions(),
                      Refund: ClientRouter.Refund(),
                    }}
                  />
                </div>
              </div>
            </div>
            <hr className="my-5" />
            <FooterCopyright />
          </div>
        </footer>
      </div>
    ),
    [theme, isHomePage],
  );
}
