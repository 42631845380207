import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import NetworkJSONArrayLoader from '../../../components/network/NetworkJSONArrayLoader';
import { JSONArrayOnOkResponse } from '../../../routers/utils';

type SideNavItemProps = {
  onFetch: JSONArrayOnOkResponse;
  onCached?: () => ReactElement;
  url: string;
  entitiesName: string;
  shouldRefetch?: boolean;
  onRefetched?: () => any;
};

const headers = process.env.REACT_APP_SERVER_URL ? undefined : [['If-None-Match', '']];

export default function SideNavItem({
  onFetch,
  onCached,
  url,
  entitiesName,
  onRefetched,
  shouldRefetch,
}: SideNavItemProps) {
  const [cached, setCached] = useState<boolean>();
  const onOk: JSONArrayOnOkResponse = useCallback(
    (...args) => {
      onFetch(...args);
      setCached(true);
    },
    [onFetch],
  );
  useEffect(() => {
    if (shouldRefetch && onRefetched) {
      setCached(false);
      onRefetched();
    }
  }, [onRefetched, shouldRefetch]);
  return useMemo(() => {
    if (cached) return onCached ? onCached() : null;
    return (
      <NetworkJSONArrayLoader url={url} onOk={onOk} headers={headers}>
        Caching {entitiesName}
      </NetworkJSONArrayLoader>
    );
  }, [cached, entitiesName, onCached, onOk, url]);
}
