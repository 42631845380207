import React, { useEffect } from 'react';
import styles from './styles.module.css';
import LandingNav, { LandingNavProps } from '../../components/nav/LandingNav';
import chromeImg from '../../assets/img/lp/chrome.png';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowForward } from '../../assets/img/icons/arrow-forward.svg';
import MetaTagsList from '../../components/MetaTagsList';

type SummaryTitleProps = {
  titleClassName?: string;
  descClassName?: string;
  mainButtonText?: string | JSX.Element;
  mainButtonClassName?: string;
  isMainButtonLinkInternal?: boolean;
  mainButtonLinkHref?: string;
  titleText?: string | JSX.Element;
  descText?: string | JSX.Element;
  imgSrc?: any;
};

type Props = {
  titleJSX?: JSX.Element;
  withCookies?: boolean;
  linkJSX?: JSX.Element;
  navProps?: LandingNavProps;
} & SummaryTitleProps;

export const SummaryFooter = ({ short }: { short?: boolean }) => (
  <>
    {!short && (
      <div className="p-4 bg-black pt-10 pb-10 pt-lg-10 pb-lg-10">
        <div className="max-width">
          <div className="sparks-h2 text-white lp-summary-footer_text">
            Add annotations to your saved links, pdfs and notes in getSparks and easily access them
            anytime.
          </div>
          <div className="lp-summary-footer_ad-boxes mt-5">
            <div className="lp-summary-footer_ad-box p-lg-5 p-4 border border-gray-700">
              <div className="sparks-h3 text-gray-700">Get the</div>
              <div className="sparks-h2 text-gradient-primary mb-4">Web App</div>
              <Link
                to="/register"
                className="text-decoration-none text-white sparks-button sparks-button-sm button-text"
              >
                Show Me
              </Link>
            </div>
            <div className="lp-summary-footer_ad-box p-lg-5 border border-gray-700 hide-mobile">
              <div className="sparks-h3 text-gray-700">Get the chrome</div>
              <div className="sparks-h2 text-gradient-primary mb-4">Extension</div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://chrome.google.com/webstore/detail/dimadmjcopdjkklcmfomakopebflpbal?utm_source=getsparks.io_bookmark&utm_source=getsparks.io"
                className="sparks-button text-white sparks-button-sm button-text text-decoration-none"
              >
                Show Me
              </a>
            </div>
          </div>
        </div>
      </div>
    )}

    <div className="p-4 bg-black pt-lg-15 pb-lg-15 pt-5 pb-5">
      <div className="max-width lp-summary-footer-row align-items-start">
        <div className="lp-summary-footer-column">
          <div className="text-gray-1 mb-2 fw-500">Product</div>
          <Link to="/lp/extension/" className="text-white pointer">
            EXTENSION
          </Link>
          <Link to="/" className="text-white pointer">
            WEB APP
          </Link>
        </div>
        <div className="lp-summary-footer-column">
          <div className="text-gray-1 mb-2 fw-500">Legal</div>
          <Link to="/privacy-policy/" className="text-white pointer">
            PRIVACY POLICY
          </Link>
          <Link to="/terms-and-conditions/" className="text-white pointer">
            TERMS AND CONDITIONS
          </Link>
          <Link to="/refund/" className="text-white pointer">
            REFUND POLICIES
          </Link>
        </div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://chrome.google.com/webstore/detail/dimadmjcopdjkklcmfomakopebflpbal?utm_source=getsparks.io_bookmark&utm_source=getsparks.io"
          className="lp-border p-2 d-flex align-items-center pointer text-decoration-none hide-mobile"
        >
          <img className="m-2 ml-0 my-0" src={chromeImg} alt="" width={60} height={55} />
          <div className="d-flex flex-column">
            <span className="text-gray-1 button-text fw-500">Available on</span>
            <p className="text-white pointer mb-0 button-text fw-500">Chrome Webstore</p>
          </div>
        </a>
      </div>
      <div className="max-width pt-5 fw-500 text-gray-1">copyright @ getSparks 2022</div>
    </div>
  </>
);

export const SummaryTitleSection = ({
  titleClassName = 'sparks-hmax',
  descClassName = 'body-text-sm',
  mainButtonText = 'TRY IT NOW',
  titleText = (
    <>
      Paste,
      <br />
      Summarize,
      <br />
      and Done!
    </>
  ),
  descText = '“Add to getSparks” allows you to generate text summaries of PDFs, any link from the internet and any block of text.',
  mainButtonClassName,
  isMainButtonLinkInternal = false,
  mainButtonLinkHref = 'https://chrome.google.com/webstore/detail/add-to-getsparks/dimadmjcopdjkklcmfomakopebflpbal',
  imgSrc,
}: SummaryTitleProps) => {
  return (
    <div className="d-flex align-items-center flex-wrap justify-content-between">
      <div className="pt-10 pb-10 pt-lg-5 pb-lg-10 lp-summary-title mx-5 ml-0">
        <h1 className={`${titleClassName} text-gradient-primary mb-3`}>{titleText}</h1>
        <p className={`${descClassName} lp-summary-title_desc mb-5`}>{descText}</p>
        <div>
          {isMainButtonLinkInternal ? (
            <Link
              to={mainButtonLinkHref}
              className={`text-decoration-none sparks-button button-text text-white ${mainButtonClassName} pointer`}
            >
              {mainButtonText}
            </Link>
          ) : (
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={mainButtonLinkHref}
              className={`text-decoration-none sparks-button button-text text-white ${mainButtonClassName} pointer`}
            >
              {mainButtonText}
            </a>
          )}
        </div>
      </div>
      {!!imgSrc && <img src={imgSrc} className="lp-summary-img my-3" alt="loading..." />}
    </div>
  );
};

const Summary = (props: Props) => {
  const { titleJSX, withCookies = true, linkJSX, navProps = {} } = props;
  useEffect(() => {
    if (withCookies) {
      document.cookie = 'onboarding=summary; max-age=604800; SameSite=None; Secure';
    }
  }, [withCookies]);

  const ChromeNavLink = (
    <a
      target="_blank"
      rel="noreferrer noopener"
      href="https://chrome.google.com/webstore/detail/add-to-getsparks/dimadmjcopdjkklcmfomakopebflpbal"
      className="text-black pointer d-flex lh-1 align-items-center text-decoration-none"
    >
      <span className="text-blue-2 button-text fw-500" style={{ marginRight: 10 }}>
        ADD GETSPARKS
      </span>{' '}
      <ArrowForward fill="#0061f2" />
    </a>
  );

  return (
    <div className="lp-summary">
      <MetaTagsList type="home" />
      <div className="header-fixed">
        <LandingNav buttons={linkJSX || ChromeNavLink} {...navProps} />
        <div className="gray-separator" />
      </div>

      {titleJSX || (
        <div className="lp-summary-title-bg pt-10 p-4 d-flex align-items-center">
          <div className="p-4 p-lg-0 max-width flex-grow-1">
            <SummaryTitleSection {...props} />{' '}
          </div>
        </div>
      )}

      <div className="p-4 lp-summary-learning-bg">
        <div className="max-width pt-lg-15 pb-lg-15 pb-4 pt-4">
          <h2 className={styles.text80}>Make Learning Easy</h2>
          <div className="mt-5 mt-lg-10 d-flex flex-column align-items-start">
            <div className="lp-summary-box py-3 px-3 py-lg-4 px-lg-5 text-gradient-primary sparks-h3 mb-3">
              <span className="bg-light-black text-gradient">
                “Effortlessly condenses lengthy texts into{' '}
              </span>
              concise summaries”
            </div>
            <div className="lp-summary-box py-3 px-3 py-lg-4 px-lg-5 text-gradient-primary sparks-h3 mb-3">
              <span className="bg-light-black text-gradient text-gray-0">“Helps me </span>
              retain key points
              <span className="bg-light-black text-gradient"> clearly and effectively”</span>
            </div>
            <div className="lp-summary-box py-3 px-3 py-lg-4 px-lg-5 text-gradient-primary sparks-h3 mb-3">
              “Saves my time and increases efficiency
              <span className="bg-light-black text-gradient">
                {' '}
                by adding notes without need of copy and pasting”
              </span>
            </div>
            <div className="lp-summary-box py-3 px-3 py-lg-4 px-lg-5 text-gradient-primary sparks-h3 mb-3">
              <span className="bg-light-black text-gradient">
                “Easily discover my content by using{' '}
              </span>
              powerful search
              <span className="bg-light-black text-gradient"> across pages”</span>
            </div>
            <div className="lp-summary-box py-3 px-3 py-lg-4 px-lg-5 text-gradient-primary sparks-h3 mb-3">
              <span className="bg-light-black text-gradient">
                “Test my knowledge and gains meaningful insights by{' '}
              </span>
              Asking Questions”
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 lp-summary-one-tab-bg">
        <div className="p-lg-0 max-width pt-lg-15 pb-lg-15 pt-5 pb-5 text-gradient-primary">
          <p className={styles.text42 + ' pb-lg-2 py-3'}>One place to organize all your content</p>
          <p className={styles.text42 + ' pb-lg-2 py-3'}>
            Gain insights by reviewing content in alternate views
          </p>
          <p className={styles.text42 + ' pb-lg-2 py-3'}>Discover new content for your topics</p>
          <p className={styles.text42 + ' pb-lg-2 py-3'}>PDFs, links & texts all in one place</p>
        </div>
      </div>
      <SummaryFooter />
    </div>
  );
};

export default Summary;
