import React from 'react';
import LandingNav from '../../components/nav/LandingNav';
import LandingFooter from '../../components/footer/LandingFooter';
import RoundedBorder from '../../components/RoundedBorder';
import MetaTagsList from '../../components/MetaTagsList';

export default function Refund() {
  return (
    <div id="layoutDefault">
      <MetaTagsList type="refund" />
      <div id="layoutDefault_content">
        <main>
          <LandingNav />
          <section className={'bg-white'}>
            <div className={'container px-5'}>
              <div className={'row gx-5 justify-content-center'}>
                <div className={'col-lg-10 mt-5'}>
                  <h1>getSparks Refund Policy</h1>
                  <p className={'lead'}>Last update December 01, 2022</p>
                  <p>
                    Thanks for your purchase. We hope you are satisfied with your purchase. If
                    you're not completely satisfied with your purchase you can always cancel the
                    subscription to stop renewal before the next billing cycle. Please see below for
                    more information on our cancellation policy.
                  </p>
                  <hr className={'my-5'} />
                  <h2 className={'mb-3'} id="cancellation">
                    Cancellation
                  </h2>
                  <p>
                    All of our customers acknowledge that they were able to cancel the subscription
                    without incurring any charges during the trial period.
                  </p>
                  <ul>
                    <li>
                      Please research out to{' '}
                      <a href="mailto:support@getsparks.io">support@getsparks.io</a> in case you run
                      into any issues or need support with your account. Please use the email used
                      for creating the subscription to and an invoice number for quicker response.{' '}
                    </li>
                  </ul>
                  <p>
                    Please allow at least 5 business days from receipt of your email to respond.
                  </p>
                  <hr className={'my-5'} />
                  <h2 className={'mb-3'} id="tracking">
                    Questions
                  </h2>
                  <p>
                    If you have any questions about our return policy, please contact us via:{' '}
                    <a href="mailto:support@getsparks.io">support@getsparks.io</a>
                  </p>

                  <hr className={'my-5'} />
                </div>
              </div>
            </div>
            <RoundedBorder className={'text-dark'} />
          </section>
        </main>
      </div>
      <LandingFooter />
    </div>
  );
}
