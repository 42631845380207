import React from 'react';
import {Button, ButtonProps} from "react-bootstrap";
import styles from '../buttons.module.css';
import {notEmpty} from "../../../data/redux/utils";

export const cancelProps: ButtonProps = {
  variant: "light",
  children: "Cancel",
  className: styles.cancel
};

export default function CancelButton(props: ButtonProps) {
  const className = [cancelProps.className, props.className].filter(notEmpty).join(' ');
  return <Button {...cancelProps} {...props} className={className}/>
}
