import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfoTabHeader from './InfoTabHeader';
import Topic from '../../../data/models/Topic';
import { useAppSelector } from '../../../data/redux/hooks';
import { RootState } from '../../../data/redux/store';
import axios from 'axios';
import ServerRouter from '../../../routers/ServerRouter';
import { getAxiosHeaders } from '../../../routers/utils';
import SummaryItem from '../../../components/SummaryItem';
import styles from './InfoTab.module.css';
import moment from 'moment';
import SkeletonLoader from '../../../components/loader/SkeletonLoader';
import { summaryLoaderBody } from './ArticleSummaryInfo';

type Props = {
  topic: Topic;
};

type MultiSummaryItem = {
  title: string;
  id: number;
  summary: string;
  createdAt: string;
};

const TopicSummaryInfo = ({ topic }: Props) => {
  const [summaries, setSummaries] = useState<MultiSummaryItem[]>([]);
  const [isLoading, setLoading] = useState(false);

  const pages = useAppSelector(
    useCallback((state: RootState) => topic.getArticles(state.cache), [topic]),
  );
  const topicPages = useMemo(
    () => pages.filter(page => page.topic === topic.id),
    [pages, topic.id],
  );
  useEffect(() => {
    const fetchedSummaries: { [key: string]: MultiSummaryItem } = {};
    const promises: Promise<any>[] = [];
    setLoading(true);
    topicPages.forEach(page => {
      if (page.id && page.metadata) {
        fetchedSummaries[page.id] = {
          title: page.title || page.url || '',
          id: page.id,
          createdAt: page.created_at || '',
          summary: '',
        };
        promises.push(axios.get(`${ServerRouter.metadata()}${page.metadata}/`, getAxiosHeaders()));
      }
    });
    Promise.all(promises)
      .then(responses => {
        responses.forEach(response => {
          if (response?.data?.summary) {
            const data = response.data;
            fetchedSummaries[data.page].summary = data.summary;
          }
        });
        setSummaries(
          Object.values(fetchedSummaries)
            .filter(({ summary }) => !!summary)
            .sort((a, b) => (moment(b.createdAt).isAfter(moment(a.createdAt)) ? 1 : -1)),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [topicPages]);
  return topicPages.length ? (
    <div className={styles.topicSummary}>
      <InfoTabHeader title="Topic Summary"></InfoTabHeader>
      {isLoading &&
        summaryLoaderBody.map((body, i) => (
          <SkeletonLoader loaderBody={body} key={`article-summary-${i}`} />
        ))}
      {!isLoading &&
        summaries.map(({ summary, title, id }) => (
          <div
            className="border-bottom border-1 border-color-blue-gray-2"
            key={`summary-topic-${id}`}
          >
            <SummaryItem body={summary} title={title} />
          </div>
        ))}
    </div>
  ) : (
    <></>
  );
};

export default TopicSummaryInfo;
