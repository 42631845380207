import React, {useMemo} from 'react';
import {OnboardPointState} from "./OnboardDetailedView";
import {OnboardToDoAction} from "./OnboardToDoAction";

export default function OnboardToDo(props: OnboardPointState) {
  const {name, description, action, actionName, children} = props;
  return useMemo(() => <div className={'w-100 mt-3 card card-body hstack'} style={{opacity: '90%'}}>
    <div>
      <h2 className={'m-0'}>{name}</h2>
      <div className={'small ms-3'}>{description}</div>
    </div>
    {children ?? (action && <OnboardToDoAction action={action}>{actionName}</OnboardToDoAction>)}
  </div>, [action, actionName, children, description, name]);
}
