import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SideNavMainSection from './SideNavMainSection';
import { useAppSelector } from '../../../data/redux/hooks';
import ServerRouter from '../../../routers/ServerRouter';
import SideNavItem from './SideNavItem';
import { RootState } from '../../../data/redux/store';
import {
  fillArticlesNotes,
  fillTags,
  fillTopics,
  fillTopicsNotes,
  setShowSideNav,
} from '../../../data/redux/actions';
import FreePlan from './FreePlan';
import SideNavPanel from './SideNavPanel';
import useCheckExtensionInstalled from '../../../hooks/custom/useCheckExtensionInstalled';
import IconSwitchSidenavButton from '../../../components/buttons/icon/IconSwitchSidenavButton';
import NavTopics from './NavTopics';
import Topic from '../../../data/models/Topic';
import NavTags from './NavTags';

export type TopicsNavProps = {
  topics: Topic[];
  selectedTopicId?: number | null;
};

export default function SideNav({ topics, selectedTopicId }: TopicsNavProps) {
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
  const isExtensionInstalled = useCheckExtensionInstalled();

  const infoTab = useAppSelector(useCallback((state: RootState) => state.cache.infoTab, []));

  const [shouldRefetchTopics, setShouldRefetchTopics] = useState(false);
  const onTopicsCached = useCallback(() => {
    return (
      <NavTopics
        topics={topics}
        title={'Topics'}
        selectedTopicId={selectedTopicId}
        withHoverAnimation
      />
    );
  }, [topics, selectedTopicId]);

  const onTagsCached = useCallback(() => {
    return <NavTags />;
  }, []);

  useEffect(() => {
    if (infoTab.visible) {
      setShowSideNav('toggled');
    }
  }, [infoTab.visible]);

  return useMemo(() => {
    return (
      <div id="layoutSidenav_nav">
        <nav className="sidenav sidenav-light border-color-blue-gray-2 border-top border-right">
          <div className="sidenav-menu">
            <div className="nav accordion position-relative pt-2">
              <IconSwitchSidenavButton />
              <SideNavMainSection selectedTopicId={selectedTopicId} />
              <div className="blue-gray-divider-sm my-2" />
              <SideNavItem
                onFetch={fillTopics}
                onCached={onTopicsCached}
                shouldRefetch={shouldRefetchTopics}
                onRefetched={() => setShouldRefetchTopics(false)}
                url={ServerRouter.topics()}
                entitiesName={'topics'}
              />
              <div className="blue-gray-divider-sm my-2" />
              <SideNavItem
                onFetch={fillTags}
                onCached={onTagsCached}
                url={ServerRouter.tags()}
                entitiesName={'tags'}
              />
              <SideNavItem
                onFetch={fillArticlesNotes}
                url={ServerRouter.articleNotes()}
                entitiesName={'articles notes'}
              />
              <SideNavItem
                onFetch={fillTopicsNotes}
                url={ServerRouter.topicNotes()}
                entitiesName={'topics notes'}
              />
            </div>
          </div>
          {!isExtensionInstalled && (
            <SideNavPanel
              desc="Make it easier to save web content, view summary, and add notes while you browse for you."
              title="Get Extension"
              link="https://chrome.google.com/webstore/detail/dimadmjcopdjkklcmfomakopebflpbal?utm_source=getsparks.io_bookmark&utm_source=getsparks.io"
              withButtonIcon
              buttonLabel="Add to Chrome"
            />
          )}
          {user?.plan_name === 'Free' ? <FreePlan signedUser={user?.email}></FreePlan> : <></>}
        </nav>
      </div>
    );
  }, [
    selectedTopicId,
    isExtensionInstalled,
    user?.email,
    user?.plan_name,
    onTagsCached,
    onTopicsCached,
    shouldRefetchTopics,
  ]);
}
