import { Dispatch, SetStateAction, useEffect, useRef } from 'react';

type Props = {
  setOAuthError: Dispatch<SetStateAction<string | undefined>>;
  setTokenId: Dispatch<SetStateAction<string | undefined>>;
};

export default function GoogleButton({ setTokenId, setOAuthError }: Props): JSX.Element {
  const divRef = useRef<HTMLDivElement>(null);
  const formOAuthError = (error: string | { error: string; details: string }) => {
    if (typeof error === 'string') {
      return error;
    } else {
      if (
        error.error !== 'popup_closed_by_user' &&
        (error.error !== 'idpiframe_initialization_failed' ||
          error.details !== 'Cookies are not enabled in current environment.')
      ) {
        return 'Error: ' + error.error + (error.details ? ': ' + error.details : '');
      }
    }
  };

  useEffect(() => {
    if (typeof window === 'undefined' || !window.google || !divRef.current) {
      return;
    }

    try {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: async (response: any) => {
          if ('credential' in response) {
            setTokenId(response['credential']);
            setOAuthError(undefined);
          } else {
            setOAuthError("Google hasn't provided an authorization token");
          }
        },
      });
      window.google.accounts.id.renderButton(divRef.current, {
        type: 'standard',
        theme: 'filled_blue',
        size: 'large',
        text: 'continue_with',
        shape: 'rectangular',
      });
    } catch (error) {
      console.log('google error ===', error);
      setOAuthError(formOAuthError(error as string | { error: string; details: string }));
    }
    // eslint-disable-next-line
  }, [window.google, divRef.current, setOAuthError, setTokenId]);

  return <div ref={divRef} />;
}
