import React, { ReactNode } from 'react';
import { Header } from '../dashboard/Header';
import { Link, useLocation } from 'react-router-dom';
import ClientRouter from '../../routers/ClientRouter';
import SmallFooter from '../../components/footer/SmallFooter';
import BlueLogo from '../../components/nav/BlueLogo';
import { ReactComponent as CheckIcon } from '../../assets/img/icons/check.svg';

export type AuthContainerProps = {
  title: string;
  isLogin?: boolean;
  colSize?: string;
  header?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  hideGoogleLogin?: boolean;
};

export function AuthContainer({ title, header, body, footer, isLogin }: AuthContainerProps) {
  const { search } = useLocation();
  return (
    <div id={'layoutAuthentication'} className="auth auth-bg">
      <Header className={'navbar-marketing'} noBottomSpace>
        <div className="d-flex justify-content-between">
          <Link to={ClientRouter.home()}>
            <BlueLogo />
          </Link>
          {!isLogin ? (
            <div className="text-black">
              Have an existing account?{' '}
              <Link to={{ pathname: ClientRouter.login(), search }} className="link-purple">
                SIGN IN
              </Link>
            </div>
          ) : (
            <div className="text-black">
              Don’t have an account?{' '}
              <Link to={{ pathname: ClientRouter.register(), search }} className="link-purple">
                SIGN UP
              </Link>
            </div>
          )}
        </div>
      </Header>
      <div className="auth-content d-flex flex-grow-1 gap-10 max-width auth-row align-items-center px-4">
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex">
            <CheckIcon fill="#4D4D4D" width={20} height={20} />
            <div className="mx-3 mr-0 flex-1">
              <h4 className="sparks-h4 text-black">Generate text summaries</h4>
              <p className="body-text-sm text-gray-1">
                You can generate summaries of PDF’s, links from the internet or any text you input.{' '}
              </p>
            </div>
          </div>
          <div className="d-flex mt-4 mb-4">
            <CheckIcon fill="#4D4D4D" width={20} height={20} />
            <div className="mx-3 mr-0  flex-1">
              <h4 className="sparks-h4 text-black">Save any links or pages and read later</h4>
              <p className="body-text-sm text-gray-1">
                Save news, recipes or any other web articles to read later, distraction free without
                any ads. All pages and summaries are easily accessible anytime, all in one place.{' '}
              </p>
            </div>
          </div>
          <div className="d-flex">
            <CheckIcon fill="#4D4D4D" width={20} height={20} />
            <div className="mx-3 mr-0 flex-1">
              <h4 className="sparks-h4 text-black">Add notes to your saved links</h4>
              <p className="body-text-sm text-gray-1">
                Add annotations to the links you saved, easily keep track of your knowledge base.{' '}
              </p>
            </div>
          </div>
        </div>
        <div className="container-xl px-0">
          <div className="row">
            <div>
              <div className="card">
                <div className="card-body p-5 pb-0 text-center">{header}</div>
                <div className="card-body p-5 pt-0 text-center">{body}</div>
                {!!footer && (
                  <>
                    <hr className="my-0" />
                    <div className="card-body px-5 py-4 small text-center">{footer}</div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SmallFooter />
    </div>
  );
}
