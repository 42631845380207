import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import ClientRouter from '../../../routers/ClientRouter';
import BlueLogo from '../../../components/nav/BlueLogo';
import AlertsCenter from './AlertsCenter';
import { ReactComponent as NotesIcon } from '../../../assets/img/icons/notes.svg';
import { ReactComponent as QuestionIcon } from '../../../assets/img/icons/question.svg';
import { useAppSelector } from '../../../data/redux/hooks';
import { setInfoTab } from '../../../data/redux/actions';
import SearchComponent from './SearchComponent';
import { ReactComponent as Plus } from '../../../assets/img/icons/plus.svg';
import { ReactComponent as Summary } from '../../../assets/img/icons/summary.svg';
import { ReactComponent as Discover } from '../../../assets/img/icons/discover.svg';
import { RootState } from '../../../data/redux/store';
import moment from 'moment';
import { PremiumModal } from '../sidenav/FreePlan';
import { getInfoTabActive, InfoTabShowing } from '../../../data/redux/utils';
import ProfileDropdown from './ProfileDropdown';
import useMenu from '../../../hooks/custom/useMenu';
import { Colors } from '../../../assets/colors';
import ArticleAddCRUDComponent from '../../../components/crud/ArticleAddCRUDComponent';
import useNavigateToDiscover from '../../../hooks/custom/useNavigateToDiscover';
import IconSwitchSidenavButton from '../../../components/buttons/icon/IconSwitchSidenavButton';

type Props = {
  selectedTopicId?: number;
};

export default function DashboardTopNav({ selectedTopicId }: Props) {
  const topics = useAppSelector(useCallback(state => state.cache.topics, []));
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
  let articlesCount = 0;
  let articles_keys = Object.keys(
    useAppSelector(useCallback((state: RootState) => state.cache.articles, [])),
  );
  let articles = useAppSelector(useCallback((state: RootState) => state.cache.articles, []));
  for (let i = 0; i < articles_keys.length; i++) {
    if (moment(articles[articles_keys[i]]?.created_at).isSame(moment(), 'day')) {
      articlesCount = articlesCount + 1;
    }
  }

  const [premiumModalShow, setPremiumModalShow] = React.useState(false);
  const showTopNav = useAppSelector(useCallback(state => state.cache.showTopNav, []));
  const infoTab = useAppSelector(useCallback((state: RootState) => state.cache.infoTab, []));

  const navigateToDiscover = useNavigateToDiscover();

  const mobileMenu = useMenu();

  const switchTab = useCallback(
    (showing: InfoTabShowing) => () => {
      const visible = !infoTab.visible || infoTab.showing !== showing || infoTab.about !== 'topic';
      setInfoTab({ visible, showing: showing, about: 'topic' });
    },
    [infoTab.about, infoTab.showing, infoTab.visible],
  );
  const showSideNav = useAppSelector(
    useCallback((state: RootState) => state.cache.showSideNav, []),
  );
  const addPageButton = useMemo(() => {
    const isShortButton = !!selectedTopicId;
    const className = isShortButton
      ? 'sparks-button article-button notes-button d-flex align-items-center height-40'
      : 'button-variant-1 article-button--primary';
    const btn = (
      <ArticleAddCRUDComponent
        topic={selectedTopicId ? topics[selectedTopicId] : undefined}
        iconButtonProps={{ tooltip: 'Add URL', tooltipPlacement: 'bottom' }}
        actionButton={onClick => (
          <button
            className={className}
            onClick={onClick}
            style={isShortButton ? {} : { minWidth: 130 }}
          >
            <Plus fill={isShortButton ? 'black' : 'white'} width={13} height={13} />
            {!isShortButton ? <span style={{ marginTop: 1 }}>Add Page</span> : null}
          </button>
        )}
      />
    );

    return user?.plan_name === 'Premium' && user?.plan_status !== 'Inactive' ? (
      btn
    ) : articlesCount < 5 ? (
      btn
    ) : (
      <button
        className={className}
        onClick={() => {
          setPremiumModalShow(true);
        }}
      >
        <Plus fill={isShortButton ? 'black' : 'white'} width={13} height={13} />
        {!isShortButton ? <span style={{ marginTop: 1 }}>Add Page</span> : null}
      </button>
    );
  }, [user?.plan_name, user?.plan_status, articlesCount, selectedTopicId, topics]);

  return useMemo(() => {
    const className =
      'topnav navbar navbar-expand navbar-light bg-white position-sticky border-color-blue-gray-2 border-bottom' +
      (showTopNav ? '' : ' d-none');
    return (
      <nav className={className}>
        {(showSideNav === 'toggled' || showSideNav === 'closed') && (
          <div className="mx-3 mr-0">
            <IconSwitchSidenavButton />
          </div>
        )}
        <Link className="d-flex mx-3 align-items-center gap-3" to={ClientRouter.home()}>
          <BlueLogo isShort={showSideNav === 'toggled' || showSideNav === 'closed'} />
        </Link>
        <div>
          <PremiumModal
            showModal={premiumModalShow}
            onCancel={() => setPremiumModalShow(false)}
            signedUser={user?.email}
            limitType="pages"
          ></PremiumModal>
        </div>
        <ul className="navbar-nav align-items-center ms-auto gap-2 me-3 hide-mobile">
          <div className="mx-2 ml-0 d-flex">
            <SearchComponent selectedTopicId={selectedTopicId} />
          </div>
          {selectedTopicId ? (
            <button
              onClick={switchTab('summary')}
              className={`button-variant-1 article-button--primary  ${
                getInfoTabActive(infoTab, 'summary') ? 'active' : ''
              }`}
            >
              <Summary width={14} height={14} fill="#fff" />
              Summary
            </button>
          ) : (
            addPageButton
          )}
          <button onClick={navigateToDiscover} className="button-variant-1">
            <Discover width={13} height={13} fill="#fff" />
            <span style={{ marginTop: 1 }}>Discover</span>
          </button>
          {selectedTopicId ? (
            <button
              onClick={switchTab('notes')}
              className={`sparks-button article-button ${
                getInfoTabActive(infoTab, 'notes') ? 'article-button--active' : ''
              } notes-button d-flex align-items-center h-40`}
            >
              <NotesIcon fill="black" width={16} height={16} />
            </button>
          ) : (
            <ProfileDropdown />
          )}
          {!!selectedTopicId && addPageButton}
          {!selectedTopicId && <AlertsCenter />}
        </ul>
        <ul className="d-flex only-mobile align-items-center mb-0 justify-content-end flex-grow-1 gap-2 mx-3">
          <ArticleAddCRUDComponent
            iconButtonProps={{ tooltip: 'Add URL', tooltipPlacement: 'bottom' }}
            actionButton={onClick => (
              <button
                className="sparks-button article-button article-button-icon shadow-none bg-purple-1"
                onClick={onClick}
              >
                <Plus fill="white" width={13} height={13} />
              </button>
            )}
          />
          {!!selectedTopicId && (
            <>
              <mobileMenu.Toggle />
              <mobileMenu.MenuContainer>
                <mobileMenu.ItemContainer onClick={switchTab('summary')}>
                  <Summary fill={Colors.blueGray00} width={14} height={14} />
                  <span>View Summary</span>
                </mobileMenu.ItemContainer>
                <mobileMenu.ItemContainer onClick={switchTab('notes')}>
                  <NotesIcon width={14} height={14} fill="#9197A1" /> <span>Take a Note</span>
                </mobileMenu.ItemContainer>
                <mobileMenu.ItemContainer onClick={switchTab('questions')}>
                  <QuestionIcon width={14} height={14} fill="#9197A1" /> <span>Ask a Question</span>
                </mobileMenu.ItemContainer>
              </mobileMenu.MenuContainer>
            </>
          )}
        </ul>
      </nav>
    );
  }, [
    premiumModalShow,
    selectedTopicId,
    showTopNav,
    showSideNav,
    addPageButton,
    user?.email,
    switchTab,
    mobileMenu,
    navigateToDiscover,
    infoTab,
  ]);
}
