import React, { PropsWithChildren, useMemo } from 'react';
import Article from '../../../../data/models/Article';
import { Link } from 'react-router-dom';
import ClientRouter from '../../../../routers/ClientRouter';
import { setInfoTab } from '../../../../data/redux/actions';

type Props = PropsWithChildren<{
  article: Article;
  className?: string;
  isMobile?: boolean;
}>;

export function ArticleCardAnchor({ article, children, className, isMobile }: Props) {
  className = className || 'w-100 h-100 text-decoration-none ';
  return useMemo(() => {
    return (
      <Link
        onClick={() => {
          if (!isMobile) {
            setInfoTab({
              about: 'article',
              visible: true,
              showing: 'questions',
            });
          }
        }}
        className={className}
        to={ClientRouter.articles(article.id)}
        children={children}
      />
    );
  }, [article.id, children, className, isMobile]);
}
