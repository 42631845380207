import React, { useCallback, useMemo, useRef, useState } from 'react';
import DiscoverArticle from '../../../../data/models/DiscoverArticle';
import moment from 'moment';
import TextShortener from '../../../../components/TextShortener';
import { ReactComponent as CheckIcon } from '../../../../assets/img/icons/check.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/img/icons/plus.svg';
import { useAppSelector } from '../../../../data/redux/hooks';
import { RootState } from '../../../../data/redux/store';
import { getArticleInlineCRUD } from '../../../../services/cruds';
import { setArticle } from '../../../../data/redux/actions';
import Topic from '../../../../data/models/Topic';
import UpgradeToPremium from '../../../../components/messages/UpgradeToPremium';
import { PremiumModal } from '../../sidenav/FreePlan';
import { JSONObject } from '../../../../data/models/Model';
import { checkIsLimitError } from '../../../../services/utils';
import { Colors } from '../../../../assets/colors';
import { Link } from 'react-router-dom';
import ClientRouter from '../../../../routers/ClientRouter';
import ArticleListCardContent from './ArticleListCardContent';
import { Command } from 'react-feather';

type Props = {
  discoverArticle: DiscoverArticle;
  topic?: Topic;
  onDiscoverArticleAdded?: (article: DiscoverArticle) => any;
  large?: boolean;
  onlyTilesView?: boolean;
};

const DiscoverArticleCardView = ({
  discoverArticle,
  topic,
  onDiscoverArticleAdded = () => {},
  large,
  onlyTilesView,
}: Props) => {
  const topics = useAppSelector(useCallback((state: RootState) => state.cache.topics, []));
  const articlesUrls = useAppSelector(
    useCallback(
      state => (topic ? topic.getArticles(state.cache).map(({ url }) => url || '') : []),
      [topic],
    ),
  );
  const articlesCardViewType = useAppSelector(state => state.cache.cardViewType);

  const [premiumArticlesShow, setPremiumArticlesShow] = useState(false);
  const [premiumModalShow, setPremiumModalShow] = useState(false);
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));

  const [isMouseOver, setMouseOver] = useState(false);
  const linkRef = useRef<HTMLAnchorElement>(null);

  const onRequestFailed = useCallback(
    (error?: JSONObject) => {
      setPremiumArticlesShow(checkIsLimitError(error));
    },
    [setPremiumArticlesShow],
  );

  const domain = useMemo(() => {
    if (!discoverArticle.url) return '';
    try {
      return new URL(discoverArticle.url).hostname;
    } catch (e) {
      return '';
    }
  }, [discoverArticle.url]);

  const originalURL = useMemo(() => {
    try {
      const url = new URL(discoverArticle?.url || '');
      url.searchParams.set('utm_source', 'getsparks.io');
      return url;
    } catch (e) {
      return '';
    }
  }, [discoverArticle.url]);

  const articleCRUD = getArticleInlineCRUD(res => {
    onDiscoverArticleAdded(discoverArticle);
    setArticle(res);
  }, topics);

  const articleAlreadyAdded = useMemo(() => {
    if (!discoverArticle.url) return false;
    return articlesUrls.includes(discoverArticle.url);
  }, [articlesUrls, discoverArticle.url]);

  const hoverProps = {
    onMouseEnter: () => setMouseOver(true),
    onMouseLeave: () => setMouseOver(false),
  };

  if (!onlyTilesView && (articlesCardViewType === 'list' || articlesCardViewType === 'headlines')) {
    const isMacOS = window.navigator.platform.match(/^Mac/);
    let text = !isMacOS ? 'Ctrl + Click' : ' + Click';
    return (
      <ArticleListCardContent
        title={discoverArticle.title}
        imgSrc={discoverArticle.image}
        loading={false}
        url={discoverArticle.url}
        domain={domain}
        overlay={{
          text,
          clickHandler: e => {
            if ((e.ctrlKey || e.metaKey) && linkRef.current) {
              linkRef.current.click();
            }
          },
          isLink: true,
          linkHref: originalURL.toString(),
          icon: isMacOS ? (
            <Command color="#000" className="overlay-icon" width={20} height={20} />
          ) : null,
        }}
        titleJSX={
          <a
            className={`input-label-text text-black text-start pointer text-decoration-none`}
            href={discoverArticle.url}
            ref={linkRef}
            target="_blank"
            rel="noopener noreferrer"
          >
            {discoverArticle.title}
          </a>
        }
        createdAt={discoverArticle.created_at}
        actions={
          <>
            {!!topic && articleAlreadyAdded && (
              <CheckIcon width={16} height={15} fill={Colors.purple01} />
            )}
            {!!topic && !articleAlreadyAdded && (
              <articleCRUD.post
                defaultValues={{
                  topic: topic.id,
                  url: discoverArticle.url,
                }}
                onRequestFailed={onRequestFailed}
                formButton={
                  <button className="button-reset-defaults">
                    <PlusIcon width={16} height={15} fill="#000" className="pointer" />
                  </button>
                }
              />
            )}
          </>
        }
        description={articlesCardViewType === 'headlines' ? '' : discoverArticle?.description}
        additionalClassName={articlesCardViewType === 'headlines' ? 'headlines' : ''}
      />
    );
  }

  return (
    <div className={`article-tile ${large ? 'article-tile--xl' : ''}`}>
      <div className="vstack h-100 text-start ready">
        <div className="article-card-content position-relative h-100">
          <div className="position-relative img-container" {...hoverProps}>
            <img className="card-img-top w-100" src={discoverArticle.image} alt="loading..." />
            {isMouseOver && (
              <a href={originalURL.toString()} target="_blank" rel="noreferrer noopener">
                <div className="card-img-top--overlay">
                  <p className="card-img-top--overlay__text">Click to view original</p>
                </div>
              </a>
            )}
          </div>
          <div className="article-card-content__body d-flex flex-column justify-content-between">
            <div>
              <div className="body-text-micro text-dark-gray mb-1">
                {moment(discoverArticle.date).format('Do MMMM, YYYY')}
              </div>
              <a
                className="article-card-content__title input-label-text text-black text-decoration-none"
                href={discoverArticle.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {discoverArticle.title}
              </a>
            </div>
            <div className="mt-2 d-flex justify-content-between align-items-center gap-2">
              <a
                rel="noopener noreferrer"
                href={discoverArticle.url}
                className="card-text text-sm text-gray-0 sparks-link fw-400 overflow-hidden"
              >
                <TextShortener>{domain}</TextShortener>
              </a>
              {!!topic && articleAlreadyAdded && (
                <CheckIcon width={16} height={15} fill={Colors.purple01} />
              )}
              {!!topic && !articleAlreadyAdded && (
                <articleCRUD.post
                  defaultValues={{
                    topic: topic.id,
                    url: discoverArticle.url,
                  }}
                  onRequestFailed={onRequestFailed}
                  formButton={
                    <button className="button-reset-defaults">
                      <PlusIcon width={16} height={15} fill="#000" className="pointer" />
                    </button>
                  }
                />
              )}
              {!topic && !articleAlreadyAdded && (
                <Link to={ClientRouter.register()}>
                  <PlusIcon width={16} height={15} fill="#000" className="pointer" />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <UpgradeToPremium
        show={premiumArticlesShow}
        zIndex={2000}
        message={'new pages'}
        onHide={() => setPremiumArticlesShow(false)}
        closePlan={() => {
          setPremiumArticlesShow(false);
          setPremiumModalShow(true);
        }}
      ></UpgradeToPremium>
      <PremiumModal
        showModal={premiumModalShow}
        onCancel={() => setPremiumModalShow(false)}
        signedUser={user?.email}
      ></PremiumModal>
    </div>
  );
};

export default DiscoverArticleCardView;
