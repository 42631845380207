import React, { useCallback, useContext, useMemo } from 'react';
import ArticleDetails from '../article/ArticleDetails';
import Article from '../../../data/models/Article';
import ArticleNotesInfo from './ArticleNotesInfo';
import { useAppSelector } from '../../../data/redux/hooks';
import { RootState } from '../../../data/redux/store';
import ArticleSummaryInfo from './ArticleSummaryInfo';
import InfoTab from './InfoTab';
import { SummaryScrollContext } from '../article/ArticleView';
import QuestionsInfo from './QuestionsInfo';

type Props = {
  article: Article;
};

export default function ArticleInfoTab({ article }: Props) {
  const infoTab = useAppSelector(useCallback((state: RootState) => state.cache.infoTab, []));
  const { summaryScrollRef } = useContext(SummaryScrollContext);

  const content = useMemo(() => {
    switch (infoTab.showing) {
      case 'notes':
        return <ArticleNotesInfo article={article} />;
      case 'details':
        return (
          <>
            <h2 className={'text-center'}>Details</h2>
            <ArticleDetails article={article} />
          </>
        );
      case 'summary':
        return <ArticleSummaryInfo article={article} />;
      case 'questions':
        return <QuestionsInfo />;
    }
  }, [article, infoTab.showing]);
  return content ? (
    <InfoTab className=" header-margin info-tab-height" scrollRef={summaryScrollRef}>
      {content}
    </InfoTab>
  ) : null;
}
