import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.css';
import { ReactComponent as CloseIcon } from '../../assets/img/icons/close.svg';
import { ReactComponent as CaretIcon } from '../../assets/img/icons/caret.svg';
import useArticleMetadataFetch from '../../hooks/custom/useArticleMetadataFetch';
import Article from '../../data/models/Article';
import { useAppSelector } from '../../data/redux/hooks';
import { Colors } from '../../assets/colors';
import ServerRouter from '../../routers/ServerRouter';
import { getHeaders } from '../../routers/utils';
import DefaultQuestionItem from './DefaultQuestionItem';
import { RootState } from '../../data/redux/store';
import Awaiter from '../messages/Awaiter';

type Props = {
  article: Article;
  createQuestion: (question: string) => any;
};

const QuestionsAI = ({ article, createQuestion }: Props) => {
  const [sectionVisible, setSectionVisible] = useState<boolean>(true);
  const hideSection = () => setSectionVisible(false);

  const questionsContainerRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState<'start' | 'middle' | 'end' | 'none'>(
    'start',
  );
  const [defaultQuestionsLoading, setDefaultQuestionsLoading] = useState(false);

  const { metadataLoader, refetchMetadata } = useArticleMetadataFetch(article);
  const metadata = useAppSelector(
    useCallback(state => article.getMetadata(state.cache), [article]),
  );

  const allQuestions = Object.values(
    useAppSelector((state: RootState) => state.cache.questions),
  ).filter(question => !!question);

  const generateDefaultQuestions = useCallback(() => {
    setDefaultQuestionsLoading(true);
    fetch(ServerRouter.defaultQuestions(article.metadata), {
      method: 'PUT',
      headers: getHeaders(),
      credentials: 'include',
    }).then(() => {
      refetchMetadata();
    });
  }, [article.metadata, refetchMetadata]);

  const defaultQuestions = metadata?.default_questions;

  const filteredDefaultQuestions = useMemo(() => {
    if (Array.isArray(defaultQuestions)) {
      const allQuestionsBody = allQuestions.map(question => question?.question);
      return defaultQuestions.filter(question => !allQuestionsBody.includes(question.question));
    }
    return null;
  }, [allQuestions, defaultQuestions]);

  const scrollQuestions = useCallback(
    (direction: 'left' | 'right' = 'right') => {
      if (questionsContainerRef.current) {
        const scrollDiff = direction === 'right' ? 350 : -350;
        questionsContainerRef.current.scrollTo({
          left: questionsContainerRef.current.scrollLeft + scrollDiff,
          top: 0,
          behavior: 'smooth',
        });
      }
    },
    [questionsContainerRef],
  );

  const onQuestionsScroll = useCallback(() => {
    if (questionsContainerRef.current) {
      const container = questionsContainerRef.current;
      if (container.clientWidth >= container.scrollWidth) return setScrollPosition('none');
      if (!container.scrollLeft) return setScrollPosition('start');
      if (container.scrollLeft + container.clientWidth + 10 >= container.scrollWidth)
        return setScrollPosition('end');
      setScrollPosition('middle');
    }
  }, [questionsContainerRef]);

  useEffect(() => {
    if (defaultQuestions) {
      setDefaultQuestionsLoading(false);
    }
  }, [defaultQuestions]);

  const section = useMemo(() => {
    const metadataNotFetched = !metadata?.default_questions;
    if (sectionVisible || metadataNotFetched) {
      return (
        <div className={styles.section}>
          <div className={styles.sectionBody}>
            {!metadataNotFetched && (
              <button className={styles.closeIcon + ' button-reset-defaults'} onClick={hideSection}>
                <CloseIcon fill={Colors.blueGray00} width={9} height={9} />
              </button>
            )}
            <div>
              <h3 className="m-0 sparks-h4 text-dark-gray">Enhance your learning.</h3>
              <div className="body-text text-gray-1">
                {metadataNotFetched
                  ? 'Extract key details by asking correct questions relevant to this page.'
                  : 'Click on the AI generated questions below to see response'}
              </div>
            </div>
          </div>
          {metadataNotFetched && !defaultQuestionsLoading && (
            <div>
              <button
                onClick={generateDefaultQuestions}
                className="sparks-button article-button button-text text-nowrap"
              >
                Generate Questions
              </button>
            </div>
          )}
          {defaultQuestionsLoading && <Awaiter small />}
        </div>
      );
    }
  }, [sectionVisible, generateDefaultQuestions, metadata, defaultQuestionsLoading]);

  return (
    <div>
      {metadataLoader}
      {section}
      <div className="position-relative">
        <div
          ref={questionsContainerRef}
          onScroll={onQuestionsScroll}
          className="hide-scrollbar d-flex gap-1 overflow-auto px-4 mt-3"
        >
          {filteredDefaultQuestions?.map(question => (
            <DefaultQuestionItem
              question={question}
              createQuestion={createQuestion}
              key={`${question.question}-default-question`}
            />
          ))}
        </div>
        {!!filteredDefaultQuestions?.length &&
          (scrollPosition === 'middle' || scrollPosition === 'end') && (
            <button
              onClick={() => scrollQuestions('left')}
              className={styles.scrollArrowLeft + ' button-reset-defaults'}
            >
              <CaretIcon fill="#000" width={7} height={12} />
            </button>
          )}
        {!!filteredDefaultQuestions?.length &&
          (scrollPosition === 'middle' || scrollPosition === 'start') && (
            <button
              onClick={() => scrollQuestions('right')}
              className={styles.scrollArrow + ' button-reset-defaults'}
            >
              <CaretIcon fill="#000" width={7} height={12} />
            </button>
          )}
      </div>
    </div>
  );
};

export default QuestionsAI;
