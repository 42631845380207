import React from 'react';
import {Stack} from "react-bootstrap";
import ExternalLink from "../../../../components/network/ExternalLink";
import {WEB_EXTENSION_URL} from "../../../../services/constants";

export default function ExtensionAlert() {
  return (
    <Stack className={'p-3 d-none d-md-block'}>
      <p><b>Add Extension</b></p>
      <p className={'small'}>The browser extension makes it easier to save web content while you browse.</p>
      <div style={{textAlign: "right"}}>
        <ExternalLink to={WEB_EXTENSION_URL} className={'btn btn-primary'}>
          Add to Chrome
        </ExternalLink>
      </div>
    </Stack>
  );
}
