import React, { useMemo, useState } from 'react';
import User from '../../data/models/User';
import CRUD from '../../components/crud/CRUD';
import ServerRouter from '../../routers/ServerRouter';
import { Link } from 'react-router-dom';
import ClientRouter from '../../routers/ClientRouter';
import AuthFormRepresentation from './AuthFormRepresentation';

export default function ResetPassword() {
  const [sent, setSent] = useState<boolean>(false);
  const body = useMemo(() => {
    const crud = new CRUD(ServerRouter.passwordResetRequest(), [User.fields.real_email], () =>
      setSent(true),
    );
    if (sent)
      return (
        <div className="d-flex">
          <Link
            className="button button-purple button-text text-gray-4 flex-grow-1 text-decoration-none text-white"
            to={ClientRouter.home()}
          >
            Back to sign in
          </Link>
        </div>
      );
    return (
      <crud.post
        formButton={
          <div className="d-flex flex-grow-1">
            <button className="button button-purple button-text text-gray-4 flex-grow-1">
              Send Email
            </button>
          </div>
        }
        nonLinear
      />
    );
  }, [sent]);
  return useMemo(
    () => (
      <AuthFormRepresentation
        title={!sent ? 'Reset Your Password' : 'Email sent!'}
        header={
          <p className="mt-2 body-text-sm text-gray-1  text-align-start">
            {!sent
              ? 'Get the newsletter and emails from getSparks with our latest announcements, product updates.'
              : 'Check your email for the link to proceed with your password reset request.'}
          </p>
        }
        body={body}
        hideGoogleLogin
        isLogin
      />
    ),
    [body, sent],
  );
}
