import React, { useCallback, useMemo, useState } from 'react';
import TabsHeader from '../../../components/TabsHeader';
import { Header } from '../Header';
import ProfileContent from './ProfileContent';
import ProfileSecurity from './ProfileSecurity';
import LandingFooter from '../../../components/footer/LandingFooter';
import { ArrowLeft } from 'react-feather';
import { Link } from 'react-router-dom';
import ClientRouter from '../../../routers/ClientRouter';
import Awaiter from '../../../components/messages/Awaiter';
import { useAppSelector } from '../../../data/redux/hooks';
import { RootState } from '../../../data/redux/store';
import useClosedSideNav from '../../../hooks/custom/useClosedSideNav';
import PaymentContent from './PaymentContent';

export default function ProfileView() {
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
  useClosedSideNav();
  const [tab, setTab] = useState<number>(0);
  const renderTab = useMemo(() => {
    if (!user || !user.email) {
      return <Awaiter>Fetching profile...</Awaiter>;
    }
    if (tab === 0) return <ProfileContent />;
    if (tab === 1) return <ProfileSecurity />;
    return <PaymentContent></PaymentContent>;
  }, [tab, user]);
  return useMemo(
    () => (
      <div
        id="layoutSidenav_content"
        className={'vstack'}
        style={{ top: 'initial', overflow: 'auto' }}
      >
        <Header sticky noBottomSpace>
          <Link style={{ textAlign: 'left' }} to={ClientRouter.dashboard()}>
            <ArrowLeft />
            Back to Dashboard
          </Link>
          <h1>Account profile</h1>
          <h2 className={'flex-grow-1 text-center'}>
            <TabsHeader
              onChange={setTab}
              selectedTab={tab}
              tabs={['Profile', 'Security', 'Payment']}
            />
          </h2>
        </Header>
        <div className={'container-xl px-4 my-4'}>{renderTab}</div>
        <LandingFooter />
      </div>
    ),
    [renderTab, tab],
  );
}
