import React from 'react';
import ClientRouter from "../../routers/ClientRouter";
import {ArrowLeft} from "react-feather";
import {Link, LinkProps} from "react-router-dom";

type Props = Omit<LinkProps, 'to'>;

export default function BackToDashboard(props: Props) {
  return (
    <Link to={ClientRouter.dashboard()} {...props}>
      <ArrowLeft/>Back to Dashboard
    </Link>
  );
}
