import { faChrome, faFirefox, faSafari } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { FormEvent, useState } from 'react';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { Download, ExternalLink } from 'react-feather';
import FileField from '../../../components/crud/fields/FileField';
import { reloadProfile } from '../../../data/redux/authUtils';
import useCheckExtensionInstalled from '../../../hooks/custom/useCheckExtensionInstalled';
import ServerRouter from '../../../routers/ServerRouter';
import { getAxiosHeaders } from '../../../routers/utils';
import { WEB_EXTENSION_URL } from '../../../services/constants';
import css from './style.module.css';

const ImportBookmarksDropdownItem = () => {
  const [showBookmarkModal, setShowBookmarkModal] = useState(false);
  const [bookmarkFile, setBookmarkFile] = useState('');

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    axios
      .post(
        ServerRouter.importBookmarks(),
        {
          file_content: bookmarkFile,
        },
        getAxiosHeaders(),
      )
      .then(() => reloadProfile())
      .catch(error => console.log(error));

    setBookmarkFile('');
    setShowBookmarkModal(false);
  };

  const extensionInstalled = useCheckExtensionInstalled();
  const isChrome =
    navigator.userAgent.includes('Chrome') && navigator.vendor.includes('Google Inc');

  return (
    <>
      <Dropdown.Item onClick={() => setShowBookmarkModal(true)}>
        <div className={'dropdown-item-icon'}>
          <Download />
        </div>
        Import bookmarks
      </Dropdown.Item>
      <Modal centered show={showBookmarkModal} onHide={() => setShowBookmarkModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Import Bookmarks</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isChrome && !extensionInstalled ? (
            <>
              <p>Add getSparks Chrome Extension and import bookmarks using 1 click</p>
              <Button
                onClick={() => {
                  // @ts-ignore
                  window.open(WEB_EXTENSION_URL, '_blank').focus();
                  setShowBookmarkModal(false);
                }}
                variant="primary"
                className={css.button}
              >
                Add to Chrome
              </Button>
              <p>The browser extension makes it easier to save web-content while your browse</p>
            </>
          ) : (
            <>
              {isChrome && extensionInstalled ? (
                <>
                  <p>Click "Continue" to import bookmarks</p>
                  <Button
                    onClick={() => {
                      window.postMessage({ type: 'IMPORT_BOOKMARKS' });
                      setShowBookmarkModal(false);
                      reloadProfile();
                    }}
                    variant="primary"
                    className={css.button}
                  >
                    Continue
                  </Button>
                </>
              ) : (
                <div className={css.bookmarkForm}>
                  <form onSubmit={handleFormSubmit}>
                    <div className={css.bookmarkFormLabel}>Bookmarks HTML file</div>
                    <FileField field={{}} onSetFile={(file: string) => setBookmarkFile(file)} />
                    <Button
                      className={css.bookmarkFormButton}
                      children="Import"
                      type="submit"
                      variant="primary"
                    />
                  </form>
                  <hr />
                  <div>
                    <h5>How to export bookmarks for my browser?</h5>
                    <div className={'d-flex justify-content-evenly'}>
                      <ExternalLink to={'https://support.google.com/chrome/answer/96816?hl=en'}>
                        <FontAwesomeIcon icon={faChrome} /> Chrome
                      </ExternalLink>
                      <ExternalLink
                        to={
                          'https://support.mozilla.org/en-US/kb/export-firefox-bookmarks-to-backup-or-transfer'
                        }
                      >
                        <FontAwesomeIcon icon={faFirefox} /> Firefox
                      </ExternalLink>
                      <ExternalLink
                        to={
                          'https://support.apple.com/guide/safari/import-bookmarks-and-passwords-ibrw1015/mac'
                        }
                      >
                        <FontAwesomeIcon icon={faSafari} /> Safari
                      </ExternalLink>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ImportBookmarksDropdownItem;
