import React from 'react';
import LandingNav from '../../components/nav/LandingNav';
import LandingFooter from '../../components/footer/LandingFooter';
import RoundedBorder from '../../components/RoundedBorder';
import MetaTagsList from '../../components/MetaTagsList';

export default function PrivacyPolicy() {
  return (
    <div id="layoutDefault">
      <MetaTagsList type="privacyPolicy" />
      <div id="layoutDefault_content">
        <main>
          <LandingNav />
          <section className={'bg-white'}>
            <div className={'container px-5'}>
              <div className={'row gx-5 justify-content-center'}>
                <div className={'col-lg-10 mt-5'}>
                  <h1>Privacy Policy</h1>
                  <p className={'lead'}>Last updated: 23 November 2021</p>
                  <p>
                    Protecting your private information is our priority. This Statement of Privacy
                    applies to https://getsparks.io, and getSparks and governs data collection and
                    usage. For the purposes of this Privacy Policy, unless otherwise noted, all
                    references to getSparks include https://getsparks.io. The getSparks website is a
                    Content Collection site. By using the getSparks website, you consent to the data
                    practices described in this statement.
                  </p>
                  <hr className={'my-5'} />
                  <h2 className={'mb-3'} id="collection">
                    1. Collection of your Personal Information
                  </h2>
                  <p>
                    In order to better provide you with products and services offered, getSparks may
                    collect personally identifiable information, such as your:
                  </p>
                  <ul>
                    <li>First and Last Name</li>
                    <li>Mailing Address</li>
                    <li>E-mail Address</li>
                    <li>Phone Number</li>
                  </ul>
                  <p>
                    If you purchase getSparks's products and services, we collect billing and credit
                    card information. This information is used to complete the purchase transaction.
                  </p>
                  <p>
                    We do not collect any personal information about you unless you voluntarily
                    provide it to us. However, you may be required to provide certain personal
                    information to us when you elect to use certain products or services. These may
                    include: (a) registering for an account; (b) entering a sweepstakes or contest
                    sponsored by us or one of our partners; (c) signing up for special offers from
                    selected third parties; (d) sending us an email message; (e) submitting your
                    credit card or other payment information when ordering and purchasing products
                    and services. To wit, we will use your information for, but not limited to,
                    communicating with you in relation to services and/or products you have
                    requested from us. We also may gather additional personal or non-personal
                    information in the future.
                  </p>
                  <hr className={'my-5'} />
                  <h2 className={'mb-3'} id="using">
                    2. Use of your Personal Information
                  </h2>
                  <p>
                    getSparks collects and uses your personal information to operate and deliver the
                    services you have requested.
                  </p>
                  <p>
                    getSparks may also use your personally identifiable information to inform you of
                    other products or services available from getSparks and its affiliates.
                  </p>
                  <hr className={'my-5'} />
                  <h2 className={'mb-3'} id="sharing">
                    3. Sharing Information with Third Parties
                  </h2>
                  <p>getSparks does not sell, rent or lease its customer lists to third parties.</p>
                  <p>
                    getSparks may share data with trusted partners to help perform statistical
                    analysis, send you email or postal mail, provide customer support, or arrange
                    for deliveries. All such third parties are prohibited from using your personal
                    information except to provide these services to getSparks, and they are required
                    to maintain the confidentiality of your information.
                  </p>
                  <p>
                    getSparks may disclose your personal information, without notice, if required to
                    do so by law or in the good faith belief that such action is necessary to: (a)
                    conform to the edicts of the law or comply with legal process served on
                    getSparks or the site; (b) protect and defend the rights or property of
                    getSparks; and/or (c) act under exigent circumstances to protect the personal
                    safety of users of getSparks, or the public.
                  </p>
                  <hr className={'my-5'} />
                  <h2 className={'mb-3'} id="tracking">
                    4. Tracking User Behavior
                  </h2>
                  <p>
                    getSparks may keep track of the websites and pages our users visit within
                    getSparks, in order to determine what getSparks services are the most popular.
                    This data is used to deliver customized content and advertising within getSparks
                    to customers whose behavior indicates that they are interested in a particular
                    subject area.
                  </p>
                  <hr className={'my-5'} />
                  <h2 className={'mb-3'} id="automatedCollection">
                    5. Automatically Collected Information
                  </h2>
                  <p>
                    Information about your computer hardware and software may be automatically
                    collected by getSparks. This information can include: your IP address, browser
                    type, domain names, access times and referring website addresses. This
                    information is used for the operation of the service, to maintain quality of the
                    service, and to provide general statistics regarding use of the getSparks
                    website.
                  </p>
                  <hr className={'my-5'} />
                  <h2 className={'mb-3'}>6. Security of your Personal Information</h2>
                  <p>
                    getSparks secures your personal information from unauthorized access, use, or
                    disclosure. getSparks uses the following methods for this purpose:
                  </p>
                  <ul>
                    <li>SSL Protocol</li>
                  </ul>
                  <hr className={'my-5'} />
                  <h2 className={'mb-3'} id="security">
                    7. Security of your Personal Information
                  </h2>
                  <p>
                    When personal information (such as a credit card number) is transmitted to other
                    websites, it is protected through the use of encryption, such as the Secure
                    Sockets Layer (SSL) protocol.
                  </p>
                  <p>
                    We strive to take appropriate security measures to protect against unauthorized
                    access to or alteration of your personal information. Unfortunately, no data
                    transmission over the Internet or any wireless network can be guaranteed to be
                    100% secure. As a result, while we strive to protect your personal information,
                    you acknowledge that: (a) there are security and privacy limitations inherent to
                    the Internet which are beyond our control; and (b) security, integrity, and
                    privacy of any and all information and data exchanged between you and us through
                    this Site cannot be guaranteed.
                  </p>
                  <hr className={'my-5'} />
                  <h2 className={'mb-3'} id="deletion">
                    8. Right to Deletion
                  </h2>
                  <p>
                    Subject to certain exceptions set out below, on receipt of a verifiable request
                    from you, we will:
                  </p>
                  <ul>
                    <li>Delete your personal information from our records; and</li>
                    <li>
                      Direct any service providers to delete your personal information from their
                      records.
                    </li>
                  </ul>
                  <p>
                    Please note that we may not be able to comply with requests to delete your
                    personal information if it is necessary to:
                  </p>
                  <ul>
                    <li>
                      Complete the transaction for which the personal information was collected,
                      fulfill the terms of a written warranty or product recall conducted in
                      accordance with federal law, provide a good or service requested by you, or
                      reasonably anticipated within the context of our ongoing business relationship
                      with you, or otherwise perform a contract between you and us;
                    </li>
                    <li>
                      Detect security incidents, protect against malicious, deceptive, fraudulent,
                      or illegal activity; or prosecute those responsible for that activity;
                    </li>
                    <li>
                      Debug to identify and repair errors that impair existing intended
                      functionality;
                    </li>
                    <li>
                      Exercise free speech, ensure the right of another consumer to exercise his or
                      her right of free speech, or exercise another right provided for by law;
                    </li>
                    <li>Comply with the California Electronic Communications Privacy Act;</li>
                    <li>
                      Engage in public or peer-reviewed scientific, historical, or statistical
                      research in the public interest that adheres to all other applicable ethics
                      and privacy laws, when our deletion of the information is likely to render
                      impossible or seriously impair the achievement of such research, provided we
                      have obtained your informed consent;
                    </li>
                    <li>
                      Enable solely internal uses that are reasonably aligned with your expectations
                      based on your relationship with us;
                    </li>
                    <li>Comply with an existing legal obligation; or</li>
                    <li>
                      Otherwise use your personal information, internally, in a lawful manner that
                      is compatible with the context in which you provided the information.
                    </li>
                  </ul>
                  <hr className={'my-5'} />
                  <h2 className={'mb-3'} id="children">
                    9. Children Under Thirteen
                  </h2>
                  <p>
                    getSparks does not knowingly collect personally identifiable information from
                    children under the age of thirteen. If you are under the age of thirteen, you
                    must ask your parent or guardian for permission to use this website.
                  </p>
                  <hr className={'my-5'} />
                  <h2 className={'mb-3'} id="disconnecting">
                    10. Disconnecting your getSparks Account from Third Party Websites
                  </h2>
                  <p>
                    You will be able to connect your getSparks account to third party accounts. BY
                    CONNECTING YOUR GETSPARKS ACCOUNT TO YOUR THIRD PARTY ACCOUNT, YOU ACKNOWLEDGE
                    AND AGREE THAT YOU ARE CONSENTING TO THE CONTINUOUS RELEASE OF INFORMATION ABOUT
                    YOU TO OTHERS (IN ACCORDANCE WITH YOUR PRIVACY SETTINGS ON THOSE THIRD PARTY
                    SITES). IF YOU DO NOT WANT INFORMATION ABOUT YOU, INCLUDING PERSONALLY
                    IDENTIFYING INFORMATION, TO BE SHARED IN THIS MANNER, DO NOT USE THIS FEATURE.
                    You may disconnect your account from a third party account at any time. Users
                    may delete your account by visiting their 'My Account' page and remove this
                    site's third-party access if you signed up with a Google Account or Facebook
                    Account.
                  </p>
                  <hr className={'my-5'} />
                  <h2 className={'mb-3'} id="communications">
                    11. E-mail Communications
                  </h2>
                  <p>
                    From time to time, getSparks may contact you via email for the purpose of
                    providing announcements, promotional offers, alerts, confirmations, surveys,
                    and/or other general communication. In order to improve our Services, we may
                    receive a notification when you open an email from getSparks or click on a link
                    therein.
                  </p>
                  <p>
                    If you would like to stop receiving marketing or promotional communications via
                    email from getSparks, you may opt out of such communications by Customers may
                    unsubscribe from emails by 'replying STOP' or 'clicking on the UNSUBSCRIBE
                    button.
                  </p>
                  <hr className={'my-5'} />
                  <h2 className={'mb-3'} id="changes">
                    12. Changes to this Statement
                  </h2>
                  <p>
                    getSparks reserves the right to change this Privacy Policy from time to time. We
                    will notify you about significant changes in the way we treat personal
                    information by sending a notice to the primary email address specified in your
                    account, by placing a prominent notice on our website, and/or by updating any
                    privacy information. Your continued use of the website and/or Services available
                    after such modifications will constitute your: (a) acknowledgment of the
                    modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.
                  </p>
                  <hr className={'my-5'} />
                  <h2 className={'mb-3'} id="contact">
                    13. Contact Information
                  </h2>
                  <p>
                    getSparks welcomes your questions or comments regarding this Statement of
                    Privacy. If you believe that getSparks has not adhered to this Statement, please
                    contact getSparks at:
                  </p>
                  <p>GetSparks, San Ramon, California</p>
                  <p>Email Address:</p>
                  <p>support@getsparks.io</p>
                  <p>Effective as of June 17, 2023</p>
                  <hr className={'my-5'} />
                </div>
              </div>
            </div>
            <RoundedBorder className={'text-dark'} />
          </section>
        </main>
      </div>
      <LandingFooter />
    </div>
  );
}
