import { bindActionCreators } from '@reduxjs/toolkit';
import cacheSlice from './cacheSlice';
import store from './store';

const dispatchedActions = bindActionCreators(cacheSlice.actions, store.dispatch);
export const setCardViewType = dispatchedActions.setCardViewType;
export const setTokenId = dispatchedActions.setTokenId;
export const setIsSignedUp = dispatchedActions.setIsSignedUp;
export const setPageNumber = dispatchedActions.setPageNumber;
export const setToken = dispatchedActions.setToken;
export const setShowTopNav = dispatchedActions.setShowTopNav;
export const setTagsFilter = dispatchedActions.setTagsFilter;
export const setShowSideNav = dispatchedActions.setShowSideNav;
export const setInfoTab = dispatchedActions.setInfoTab;
export const setUser = dispatchedActions.setUser;
export const setQuestion = dispatchedActions.setQuestion;
export const setArticle = dispatchedActions.setArticle;
export const setArticleNote = dispatchedActions.setArticleNote;
export const setArticleMetadata = dispatchedActions.setArticleMetadata;
export const setTopic = dispatchedActions.setTopic;
export const setTopicNote = dispatchedActions.setTopicNote;
export const setPageGroup = dispatchedActions.setPageGroup;
export const fillQuestions = dispatchedActions.fillQuestions;
export const fillArticles = dispatchedActions.fillArticles;
export const fillArticlesNotes = dispatchedActions.fillArticlesNotes;
export const fillArticlesMetadatas = dispatchedActions.fillArticlesMetadatas;
export const fillTopics = dispatchedActions.fillTopics;
export const fillTopicsNotes = dispatchedActions.fillTopicsNotes;
export const fillPageGroups = dispatchedActions.fillPageGroups;
export const setTag = dispatchedActions.setTag;
export const fillTags = dispatchedActions.fillTags;
