import React, {useCallback, useMemo} from 'react';
import useOpenTabs from "../../../../hooks/custom/useOpenTabs";
import {useAppSelector} from "../../../../data/redux/hooks";
import {notEmpty} from "../../../../data/redux/utils";
import ExtensionAddTab from "./ExtensionAddTab";

export function ExtensionAddOpenTabs() {
  const openTabs = useOpenTabs()?.filter(tab => tab.url.startsWith('https://') || tab.url.startsWith('http://'));
  const articlesURLs = useAppSelector(useCallback(state => {
    const articles = Object.values(state.cache.articles);
    return new Set(articles.map(article => article?.url).filter(notEmpty));
  }, []));
  return useMemo(() => {
    return (
      <div className={'text-break'}
           style={{height: 350, overflow: 'auto'}}>
        {openTabs?.length
            ? <>
              <p>Save opened tabs and generate summary of pages, add each page one by one by clicking on “add”.</p>
              {openTabs.map((tab, index) => <ExtensionAddTab key={index} tab={tab} articlesURLs={articlesURLs}/>)}
            </>
          : <>
            <p>Currently, there are no open tabs or urls available to save.</p>
            <p>Click Finish to continue.</p>
          </>}
      </div>
    );
  }, [articlesURLs, openTabs]);
}
