import { CaseReducer, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import Topic from '../models/Topic';
import Article from '../models/Article';
// import Question from '../models/Question';
import TopicNote from '../models/TopicNote';
import ArticleNote from '../models/ArticleNote';
import ArticleMetadata from '../models/ArticleMetadata';
import { CRUDOnOkParam } from '../../components/crud/CRUD';
import Model, { JSONObject } from '../models/Model';
import { CardViewType, InfoTab, MenuTab, notEmpty, ReduxIndexer, StateType } from './utils';
import User from '../models/User';
import { deleteCookie, getCookie } from '../../routers/utils';
import { isEqual } from '../../services/utils';
import Question from '../models/Question';
import PageGroup from '../models/PageGroup';
import Tag from '../models/Tag';

type UpdateReducerAction = PayloadAction<CRUDOnOkParam>;
type UpdateCacheReducer = CaseReducer<StateType, UpdateReducerAction>;
type FillReducerAction = PayloadAction<JSONObject[] | null>;
type FillCacheReducer = CaseReducer<StateType, FillReducerAction>;
type ReducersType = {
  setTokenId: CaseReducer<StateType, PayloadAction<string | undefined>>;
  setCardViewType: CaseReducer<StateType, PayloadAction<CardViewType>>;
  setIsSignedUp: CaseReducer<StateType, PayloadAction<boolean>>;
  setPageNumber: CaseReducer<StateType, PayloadAction<number>>;
  setTagsFilter: CaseReducer<StateType, PayloadAction<number[]>>;
  setToken: CaseReducer<StateType, PayloadAction<string | undefined>>;
  setShowTopNav: CaseReducer<StateType, PayloadAction<boolean>>;
  setShowSideNav: CaseReducer<StateType, PayloadAction<MenuTab>>;
  setInfoTab: CaseReducer<StateType, PayloadAction<InfoTab>>;
  setUser: UpdateCacheReducer;
  setTag: UpdateCacheReducer;
  setArticle: UpdateCacheReducer;
  setQuestion: UpdateCacheReducer;
  setPageGroup: UpdateCacheReducer;
  setArticleNote: UpdateCacheReducer;
  setArticleMetadata: UpdateCacheReducer;
  setTopic: UpdateCacheReducer;
  setTopicNote: UpdateCacheReducer;
  fillArticles: FillCacheReducer;
  fillQuestions: FillCacheReducer;
  fillPageGroups: FillCacheReducer;
  fillTags: FillCacheReducer;
  fillArticlesNotes: FillCacheReducer;
  fillArticlesMetadatas: FillCacheReducer;
  fillTopics: FillCacheReducer;
  fillTopicsNotes: FillCacheReducer;
};
type Constructor<T> = new (x: JSONObject) => T;

function updateIndexer<T extends Model>(
  model: Constructor<T>,
  indexer: ReduxIndexer<T>,
  state: Draft<StateType>,
  data: CRUDOnOkParam,
) {
  if (typeof data === 'number') {
    const instance = indexer[data];
    if (instance) {
      instance.delete(state);
      delete indexer[data];
    }
  } else {
    if (data.id) {
      const oldInstance = indexer[data.id];
      if (oldInstance) {
        data = Object.assign({ ...oldInstance.__data }, data);
        if (isEqual(oldInstance.__data, data)) {
          return;
        }
      }
      const instance = new model(data);
      if (instance.id) {
        indexer[instance.id] = instance;
      }
    }
  }
}

function reduceUpdateIndexer<T extends Model>(
  model: Constructor<T>,
  indexer: (state: Draft<StateType>) => ReduxIndexer<T>,
) {
  return (state: Draft<StateType>, action: UpdateReducerAction) =>
    updateIndexer(model, indexer(state), state, action.payload);
}

function fillIndexer<T extends Model>(
  model: Constructor<T>,
  indexer: ReduxIndexer<T>,
  state: Draft<StateType>,
  data: JSONObject[],
) {
  data.forEach(json => updateIndexer(model, indexer, state, json));
  const newIndexes = new Set<number>(data.map(json => json.id).filter(notEmpty));
  Object.values(indexer)
    .filter(notEmpty)
    .forEach(instance => {
      if (!instance.id || !newIndexes.has(instance.id)) {
        instance.delete(state);
        if (instance.id) delete indexer[instance.id];
      }
    });
}

function reduceFillIndexer<T extends Model>(
  model: Constructor<T>,
  indexer: (state: Draft<StateType>) => ReduxIndexer<T>,
) {
  return (state: Draft<StateType>, action: FillReducerAction) => {
    if (action.payload) {
      fillIndexer(model, indexer(state), state, action.payload);
    }
  };
}

const getInitialState = (): StateType => ({
  showTopNav: true,
  showSideNav: 'free',
  infoTab: { visible: false, about: 'topic', showing: 'notes' },
  user: getCookie('token') ? new User({}) : undefined,
  token: getCookie('token'),
  tokenId: undefined,
  isSignedUp: false,
  articles: {},
  articlesNotes: {},
  articlesMetadata: {},
  questions: {},
  tags: {},
  pageGroups: {},
  topics: {},
  topicsNotes: {},
  tagsFilter: [],
  pageNumber: 1,
  cardViewType: 'tile',
});

export const cacheSlice = createSlice<StateType, ReducersType>({
  name: 'cache',
  initialState: getInitialState(),
  reducers: {
    setCardViewType: (state, action) => {
      state.cardViewType = action.payload;
    },
    setShowTopNav: (state, action) => {
      state.showTopNav = action.payload;
    },
    setShowSideNav: (state, action) => {
      state.showSideNav = action.payload;
    },
    setInfoTab: (state, action) => {
      state.infoTab = action.payload;
    },
    setTokenId: (state, action) => {
      state.tokenId = action.payload;
    },
    setIsSignedUp: (state, action) => {
      state.isSignedUp = action.payload;
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setTagsFilter: (state, action) => {
      state.tagsFilter = action.payload;
    },
    setUser: (state, action) => {
      let data = action.payload;
      if (typeof data === 'number') {
        if (state.user) {
          deleteCookie('token');
          Object.assign(state, getInitialState());
        }
        state.user = undefined;
      } else {
        if (state.user) {
          data = Object.assign({ ...state.user.__data }, data);
        }
        state.user = new User(data);
      }
    },
    setArticle: reduceUpdateIndexer(Article, state => state.articles),
    setTag: reduceUpdateIndexer(Tag, state => state.tags),
    setQuestion: reduceUpdateIndexer(Question, state => state.questions),
    setPageGroup: reduceUpdateIndexer(PageGroup, state => state.pageGroups),
    setArticleNote: reduceUpdateIndexer(ArticleNote, state => state.articlesNotes),
    setArticleMetadata: reduceUpdateIndexer(ArticleMetadata, state => state.articlesMetadata),
    setTopic: reduceUpdateIndexer(Topic, state => state.topics),
    setTopicNote: reduceUpdateIndexer(TopicNote, state => state.topicsNotes),
    fillTags: reduceFillIndexer(Tag, state => state.tags),
    fillArticlesMetadatas: reduceFillIndexer(ArticleMetadata, state => state.articlesMetadata),
    fillArticlesNotes: reduceFillIndexer(ArticleNote, state => state.articlesNotes),
    fillArticles: reduceFillIndexer(Article, state => state.articles),
    fillQuestions: reduceFillIndexer(Question, state => state.questions),
    fillPageGroups: reduceFillIndexer(PageGroup, state => state.pageGroups),
    fillTopicsNotes: reduceFillIndexer(TopicNote, state => state.topicsNotes),
    fillTopics: reduceFillIndexer(Topic, state => state.topics),
  },
});

export default cacheSlice;
