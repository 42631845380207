import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../../data/redux/hooks';
import { RootState } from '../../../../data/redux/store';
import ServerRouter from '../../../../routers/ServerRouter';
import { getHeaders } from '../../../../routers/utils';
import DiscoverArticle from '../../../../data/models/DiscoverArticle';
import Topic from '../../../../data/models/Topic';
import InterestsSelect, {
  shortInterestSelectCommonProps,
} from '../../../../components/discover/InterestsSelect';
import { fillPageGroups, setToken, setTopic } from '../../../../data/redux/actions';
import { authorize } from '../../../../data/redux/authUtils';
import { ReactComponent as SortIcon } from '../../../../assets/img/icons/sort.svg';
import NetworkJSONArrayLoader from '../../../../components/network/NetworkJSONArrayLoader';
import PageGroup from '../../../../data/models/PageGroup';
import Awaiter from '../../../../components/messages/Awaiter';
import TopicTimeline from '../timeline/TopicTimeline';
import DiscoverArticleCardView from './DiscoverArticleCardView';
import usePagination from '../../../../hooks/custom/usePagination';
import ArticleCardPlaceholder from './ArticleCardPlaceholder';
import NetworkJSONLoader from '../../../../components/network/NetworkJSONLoader';
import useFetchMatchUrls from '../../../../hooks/custom/useFetchMatchUrls';

type Props = {
  topic: Topic;
  setTopicInterestsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  topicInterestsVisible: boolean;
};

const TopicDiscoveryFeed = ({ topic, topicInterestsVisible, setTopicInterestsVisible }: Props) => {
  const [matchUrlsPage, setMatchUrlsPage] = useState<number | null>(1);
  const [pageGroupsFetched, setPageGroupsFetched] = useState(false);
  const [topicFetched, setTopicFetched] = useState(true);
  const [userFetched, setUserFetched] = useState(true);
  const [discoverTab, setDiscoverTab] = useState<number>(0);

  const isAccountLevel = useMemo(
    () => !topic.id || topic.name === 'Default',
    [topic.id, topic.name],
  );

  const articlesUrls = useAppSelector(
    useCallback(state => topic.getArticles(state.cache).map(({ url }) => url || ''), [topic]),
  );
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
  const pageGroups = useAppSelector(
    useCallback(
      (state: RootState) =>
        Object.values(state.cache.pageGroups)
          .filter(pageGroup => !!pageGroup)
          .sort((a, b) => (a?.name || '').localeCompare(b?.name || '')),
      [],
    ),
  ) as PageGroup[] | undefined;
  const articlesCardViewType = useAppSelector(state => state.cache.cardViewType);

  const defaultPageGroups = useMemo(() => {
    let selectedInterests = topic.preferences;
    if (isAccountLevel) {
      selectedInterests = (user?.preferences || []).map(({ id }) => id);
    }
    return selectedInterests;
  }, [topic, user, isAccountLevel]);

  const loader = useMemo(() => {
    return !pageGroupsFetched && isAccountLevel ? (
      <NetworkJSONArrayLoader
        url={ServerRouter.pageGroupAll()}
        onOk={res => {
          fillPageGroups(res);
          setPageGroupsFetched(true);
        }}
        hidden
      />
    ) : null;
  }, [pageGroupsFetched, isAccountLevel]);

  const topicLoader = useMemo(() => {
    return !topicFetched ? (
      <NetworkJSONLoader
        url={ServerRouter.topics(topic.id)}
        onOk={res => {
          setTopic(res);
          setTopicFetched(true);
        }}
        hidden
      />
    ) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicFetched]);

  const refreshTopic = useCallback(() => setTopicFetched(false), []);

  const userLoader = useMemo(() => {
    return !userFetched ? (
      <NetworkJSONLoader
        url={ServerRouter.getUserData()}
        onOk={res => {
          authorize(res);
          setUserFetched(true);
        }}
        onCancel={() => setToken(undefined)}
        hidden
      />
    ) : null;
  }, [userFetched]);

  const refreshUser = useCallback(() => setUserFetched(false), []);

  const onTopicInterestsSubmit = (selectedPageGroupsIds: number[], selectedTokensIds: number[]) => {
    const promises: Promise<any>[] = [];
    const fetchProps: RequestInit = {
      method: 'PATCH',
      headers: getHeaders(),
      credentials: 'include',
    };
    if (selectedPageGroupsIds.length !== defaultPageGroups?.length) {
      promises.push(
        fetch(isAccountLevel ? ServerRouter.updateProfile() : ServerRouter.topics(topic.id), {
          ...fetchProps,
          body: JSON.stringify(
            isAccountLevel
              ? {
                  preferences: {
                    add: selectedPageGroupsIds,
                    remove: (user?.preferences || [])
                      .filter(({ id }) => !selectedPageGroupsIds.includes(id))
                      .map(({ id }) => id),
                  },
                }
              : { preferences: selectedPageGroupsIds },
          ),
        }),
      );
    }
    if (selectedTokensIds.length !== topic.tokens?.length) {
      promises.push(
        fetch(ServerRouter.topicAddTokens(topic.id), {
          ...fetchProps,
          body: JSON.stringify({ tokens: selectedTokensIds }),
        }),
      );
    }

    Promise.all(promises).finally(() => {
      if (isAccountLevel) {
        refreshUser();
      }
      refreshTopic();
      setTopicInterestsVisible(false);
    });
  };

  const {
    fetchDiscoveryArticles,
    canFetchMore,
    discoverArticles,
    isLoading,
    clearDiscoveryArticles,
    onDiscoverArticleAdded,
  } = useFetchMatchUrls({
    urls: articlesUrls,
    tokens: topic.tokens,
    preferences: topic.preferences,
    page: matchUrlsPage,
    topic,
  });

  const {
    paginatedItems,
    nextPageExists,
    onShowMoreClicked,
    containerRef,
    page,
    pageSize,
    lastRowEmptyItemsNumber,
  } = usePagination({
    items: discoverArticles,
  });

  const paginatedArticles = paginatedItems as DiscoverArticle[];

  const content = useMemo(() => {
    switch (articlesCardViewType) {
      case 'tile':
        return discoverTab ? (
          <TopicTimeline
            list={false}
            discoverArticles={discoverArticles}
            topic={topic}
            isDiscover
          />
        ) : (
          <div className="articles-grid" ref={containerRef}>
            {paginatedArticles.map(
              article =>
                article && (
                  <DiscoverArticleCardView
                    key={article.id}
                    onDiscoverArticleAdded={onDiscoverArticleAdded}
                    discoverArticle={article}
                    topic={topic}
                  />
                ),
            )}
            {!!lastRowEmptyItemsNumber &&
              Array.from(Array(lastRowEmptyItemsNumber)).map((_, index) => (
                <ArticleCardPlaceholder key={`empty-${index}`} large={false} />
              ))}
          </div>
        );

      case 'list':
      case 'headlines':
        return discoverTab ? (
          <TopicTimeline list discoverArticles={discoverArticles} topic={topic} isDiscover />
        ) : (
          <div className="topic-list-articles" ref={containerRef}>
            {paginatedArticles.map(
              article =>
                article && (
                  <DiscoverArticleCardView
                    key={article.id}
                    onDiscoverArticleAdded={onDiscoverArticleAdded}
                    discoverArticle={article}
                    topic={topic}
                  />
                ),
            )}
          </div>
        );
      default:
        return null;
    }
  }, [
    articlesCardViewType,
    discoverArticles,
    paginatedArticles,
    containerRef,
    lastRowEmptyItemsNumber,
    discoverTab,
    onDiscoverArticleAdded,
    topic,
  ]);

  useEffect(() => {
    fetchDiscoveryArticles(true);
    setMatchUrlsPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articlesUrls?.length, topic.preferences, topic.tokens, user?.preferences]);

  useEffect(() => {
    if (canFetchMore && matchUrlsPage) {
      fetchDiscoveryArticles(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchUrlsPage]);

  useEffect(() => {
    if (page * pageSize >= discoverArticles.length) {
      if (canFetchMore) {
        setMatchUrlsPage(prev => (prev || 1) + 1);
      } else {
        setMatchUrlsPage(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);

  useEffect(() => {
    clearDiscoveryArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic.id]);

  useEffect(() => {
    if (topicInterestsVisible) {
      refreshTopic();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicInterestsVisible]);

  return (
    <>
      {loader}
      {userLoader}
      {topicLoader}
      {discoverArticles.length ? (
        <>
          <div className="d-flex justify-content-between mt-5 mb-4">
            <div className="sparks-h3 text-align-start">Discover More Content</div>
            <button
              className={`sparks-button article-button notes-button d-flex align-items-center height-40 ${
                discoverTab ? 'bg-blue-gray-3' : ''
              }`}
              onClick={() => setDiscoverTab(discoverTab ? 0 : 1)}
            >
              <SortIcon height={12} width={13} fill="#000" />
            </button>
          </div>
          <div>
            {content}
            {isLoading && <Awaiter />}
            {nextPageExists && (
              <button
                className="article-button sparks-button-secondary mt-4"
                onClick={onShowMoreClicked}
              >
                Show More Pages
              </button>
            )}
          </div>
        </>
      ) : null}
      <InterestsSelect
        {...shortInterestSelectCommonProps}
        visible={topicInterestsVisible}
        onClose={() => setTopicInterestsVisible(false)}
        onSubmit={onTopicInterestsSubmit}
        defaultPageGroups={defaultPageGroups}
        defaultTokens={topic.tokens}
        pageGroups={pageGroups}
        tokens={isAccountLevel ? [] : topic.page_tokens}
      />
    </>
  );
};

export default TopicDiscoveryFeed;
