import NetworkJSONLoader from '../../../components/network/NetworkJSONLoader';
import ServerRouter from '../../../routers/ServerRouter';
import { setArticle, setArticleMetadata } from '../../../data/redux/actions';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Post from './Post';
import { useAppSelector } from '../../../data/redux/hooks';
import { RootState } from '../../../data/redux/store';
import PdfViewer from './PdfViewer';
import PdfViewerPrint from './PdfViewerPrint';
import { ReactComponent as CheckIcon } from '../../../assets/img/icons/check.svg';
import { Colors } from '../../../assets/colors';
import Tooltip from '../../../components/Tooltip';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import { ArticleViewTypeContext } from './ArticleView';
import useJSONStringToObject from '../../../hooks/custom/useJSONStringToObject';
import ArticleDiscoveryFeed from './ArticleDiscoveryFeed';
import ArticleTags from './ArticleTags';

export type ArticlePreviewProps = {
  text?: string;
  id?: number;
  url?: string;
  type?: string;
  pageContent?: string;
  isPrint?: boolean;
};

function ArticleEmpty({ id }: ArticlePreviewProps) {
  const article = useAppSelector(
    useCallback(
      (state: RootState) => {
        if (id) return state.cache.articles[id];
      },
      [id],
    ),
  );
  return (
    <div className="article-wrapper">
      <div className="article-content-empty p-3 d-flex gap-5">
        <img src={article?.site_icon} alt="loading..." />
        <div className="d-flex flex-column gap-3 justify-content-center">
          {article?.title ? (
            <h4 className="sparks-h4 word-break">{article?.title}</h4>
          ) : (
            <a href={article?.url} className="sparks-link sparks-h4 word-break">
              {article?.url}
            </a>
          )}
          <div className="text-green body-text d-flex align-items-center gap-2">
            <CheckIcon width={20} height={20} fill={Colors.textGreen} />
            <span>Saved as Bookmark</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function ArticlePreview({
  url,
  id,
  text,
  type,
  pageContent,
  isPrint,
}: ArticlePreviewProps) {
  const postRef = React.useRef<HTMLDivElement>(null);
  const article = useAppSelector(
    useCallback(
      (state: RootState) => {
        if (id) return state.cache.articles[id];
      },
      [id],
    ),
  );
  const [articleViewType] = useContext(ArticleViewTypeContext);
  const metadata = useAppSelector(
    useCallback((state: RootState) => article?.getMetadata(state.cache), [article]),
  );
  const topic = useAppSelector(
    useCallback((state: RootState) => article?.getTopic(state.cache), [article]),
  );
  const tags = useJSONStringToObject(article?.metatags);
  const readTime = useMemo(() => {
    if (metadata) {
      if (metadata.reading_time) return <div>{metadata.reading_time} Min read ·</div>;
    } else if (typeof article?.metadata === 'number') {
      return (
        <NetworkJSONLoader url={ServerRouter.metadata(article?.metadata)} onOk={setArticleMetadata}>
          Fetching metadata
        </NetworkJSONLoader>
      );
    }
  }, [article?.metadata, metadata]);
  useEffect(() => {
    if (postRef.current) {
      postRef.current.querySelectorAll('iframe').forEach(iframe => {
        if (iframe.src.includes('youtube.com/embed/')) {
          iframe.removeAttribute('width');
          iframe.removeAttribute('height');
          iframe.style.width = '100%';
          iframe.style.height = '100%';
          iframe.style.position = 'absolute';
          if (iframe.parentElement) {
            iframe.parentElement.style.position = 'relative';
            iframe.parentElement.style.paddingBottom = '56.25%';
            iframe.parentElement.style.height = '0';
            iframe.parentElement.style.overflow = 'hidden';
          }
        }
      });
    }
  }, [text]);
  const newText = useMemo(() => {
    if (type === 'text' && !!text) {
      return text
        .split('\n')
        .filter(text => !!text)
        .map((item, key) => {
          return (
            <p key={key} className="m-0">
              {item}
            </p>
          );
        });
    }
    return '';
  }, [text, type]);

  const [shouldFetchArticle, setShouldFetchArticle] = useState(true);
  const loader = useMemo(() => {
    return (
      shouldFetchArticle && (
        <NetworkJSONLoader
          url={ServerRouter.articles(id)}
          onOk={article => {
            setArticle(article);
            setShouldFetchArticle(false);
          }}
          children={'Fetching article'}
        />
      )
    );
  }, [id, shouldFetchArticle]);

  const domain = useMemo(() => {
    try {
      if (url) {
        return new URL(url).hostname;
      }
      return '';
    } catch (e) {
      return '';
    }
  }, [url]);

  const isBookmark = pageContent === 'Bookmark';
  const isEmpty = text !== undefined && !text;

  const showHtml = type !== 'text' && !!text && !isBookmark;
  const showText = type === 'text' && !!text;
  const showPdf = article?.page_content === 'PDF';

  return (
    <div className="article-wrapper bg-white flex-grow-1">
      <Post postRef={postRef}>
        <div className="bg-white mt-5 mb-4">
          <div>
            {articleViewType !== 'web' && (
              <>
                {article?.title && (
                  <Tooltip placement="top" overlay="Open in a new tab">
                    <a
                      href={article.url}
                      className="sparks-link"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <h1 className="color-inherit article-title text-align-start">
                        {article.title}
                      </h1>
                    </a>
                  </Tooltip>
                )}
                <div className={'d-flex gap-1 mt-3 align-items-start body-text-sm'}>
                  {!!article?.created_at && (
                    <div>{moment(new Date(article?.created_at)).format('MM/DD/YY')} ·</div>
                  )}
                  {readTime}
                  {!!tags?.byline && <div>{tags.byline} ·</div>}
                  {!!topic && (
                    <div>
                      Saved in{' '}
                      <Tooltip placement="top" overlay="Go to Topic">
                        <Link
                          className="sparks-link sparks-link-sm sparks-link--active"
                          to={`/topics/${topic?.id}`}
                        >
                          {topic.name}
                        </Link>
                      </Tooltip>
                    </div>
                  )}
                </div>
                {!!article?.id && (
                  <div className="mt-3">
                    <ArticleTags articleId={article.id} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {loader}
        {showText && <div className={'single-post-text article-content text-break'}>{newText}</div>}
        {
          showPdf && !showText && (
            <>
              {isPrint === true ? (
                <PdfViewerPrint pdfFile={article?.pdf}></PdfViewerPrint>
              ) : (
                <PdfViewer wdth={postRef} pdfFile={article?.pdf}></PdfViewer>
              )}
            </>
          )
          // <PdfViewer pdfFile={article?.pdf}></PdfViewer>
        }
        {showHtml && !showPdf && (
          <div
            className="single-post-text article-content text-break font-source-serif text-dark-gray"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
        {!isBookmark && !!article && !!topic && (
          <ArticleDiscoveryFeed article={article} topic={topic} />
        )}
        {type === 'preview' && !!text && !isBookmark && (
          <div className="no-print">
            {(showHtml || showText) && <div className="border-bottom mb-4" />}
            <div style={{ fontSize: 12 }}>
              The content you have chosen to save (which may include videos, articles, images and
              other copyrighted materials) is intended for your personal, noncommercial use. Such
              content is owned or controlled by {domain} or the party credited as the content
              provider. Please refer to {domain} and the Terms of Service available on its website
              for information and restrictions related to the content.
            </div>
          </div>
        )}
        {(isEmpty || isBookmark) && <ArticleEmpty url={url} id={id} />}
      </Post>
    </div>
  );
}
