import React, { useCallback, useMemo, useRef } from 'react';
import Note from '../../../data/models/Note';
import ModalCRUD from '../../../components/crud/ModalCRUD';
import { JSONObject } from '../../../data/models/Model';
import { Edit } from 'react-feather';
import moment from 'moment';
import useMenu from '../../../hooks/custom/useMenu';
import { ReactComponent as DotsIcon } from '../../../assets/img/icons/dots.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/delete.svg';
import { ReactComponent as CopyIcon } from '../../../assets/img/icons/copy.svg';
import { ReactComponent as PrintIcon } from '../../../assets/img/icons/print.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactToPrint from 'react-to-print';

type Props = {
  note: Note;
  crud: ModalCRUD;
  defaultValues: JSONObject;
  setTargetNote: React.Dispatch<React.SetStateAction<JSONObject | undefined>>;
  light?: boolean;
  withDivider?: boolean;
};

export function NoteView({
  note,
  crud,
  defaultValues,
  setTargetNote,
  light,
  withDivider = true,
}: Props) {
  const printRef = useRef<HTMLDivElement>(null);
  const { MenuContainer, ItemContainer, toggleMenu, placeRef } = useMenu();
  const enterEditMode = useCallback(() => {
    setTargetNote({ ...defaultValues, ...note.__data });
  }, [defaultValues, note.__data, setTargetNote]);
  return useMemo(
    () => (
      <>
        <div className={` ${light ? '' : 'bg-blue-5'}`}>
          <MenuContainer topOffset={220}>
            <ItemContainer onClick={enterEditMode}>
              <Edit width={15} height={15} stroke="#9197A1" />
              <span>Edit</span>
            </ItemContainer>
            {!!note.id ? (
              <crud.deleteDropdown
                defaultValues={{ id: note.id }}
                withDefaultContainer={false}
                formRef={ref => placeRef(ref, 1)}
                children={
                  <ItemContainer>
                    <DeleteIcon width={15} height={15} fill="#9197A1" />
                    <span>Delete</span>
                  </ItemContainer>
                }
              />
            ) : (
              <></>
            )}

            <CopyToClipboard
              text={`${note.title || ''}\n\n${document.getElementById(`note-${note.id}-desc`)?.innerText || ''}`}
            >
              <ItemContainer>
                <CopyIcon width={18} height={18} fill="#9197A1" /> <span>Copy</span>
              </ItemContainer>
            </CopyToClipboard>
            <ReactToPrint
              content={() => printRef.current}
              trigger={() => (
                <ItemContainer>
                  <PrintIcon width={18} height={18} fill="#9197A1" /> <span>Print</span>
                </ItemContainer>
              )}
            />
          </MenuContainer>
          <div ref={printRef} className="p-5">
            <div className="d-flex align-items-start py-2 position-relative">
              <div
                className={'note-title flex-grow-1 text-break sparks-h4'}
                onClick={enterEditMode}
              >
                {note.title}
              </div>
              <div
                style={{ minWidth: 135 }}
                className=" d-flex mx-2 mr-0 align-items-center gap-3 no-print"
              >
                <div>{moment(note.created_at).format('hh:mm A')}</div>
                <div>
                  <button
                    className="article-button article-button-icon article-button-icon--secondary no-print"
                    onClick={toggleMenu}
                    ref={ref => placeRef(ref, 0)}
                  >
                    <DotsIcon fill="#323232" width={4} height={14} className="pointer" />
                  </button>
                </div>
              </div>
            </div>
            <div
              id={`note-${note.id}-desc`}
              className="note-desc body-text text-dark-gray"
              dangerouslySetInnerHTML={{ __html: note.description ?? '' }}
              onClick={enterEditMode}
            />
          </div>
        </div>

        {withDivider && <div className="blue-gray-divider" />}
      </>
    ),
    [
      light,
      enterEditMode,
      note.title,
      note.created_at,
      note.id,
      note.description,
      toggleMenu,
      MenuContainer,
      ItemContainer,
      crud,
      withDivider,
      placeRef,
      printRef,
    ],
  );
}
