import React, { useCallback, useMemo, useState } from 'react';
import ClientRouter from '../../routers/ClientRouter';
import { Link } from 'react-router-dom';
import { Menu } from 'react-feather';
import BlueLogo from './BlueLogo';
import { useAppSelector } from '../../data/redux/hooks';
import { RootState } from '../../data/redux/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';

export type LandingNavProps = {
  buttons?: React.ReactNode;
  hideLinks?: boolean;
  signUpLabel?: string;
};

export default function LandingNav({
  buttons,
  hideLinks,
  signUpLabel = 'Sign up for getSparks',
}: LandingNavProps) {
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
  const [productDropdownVisible, setProductDropdownVisible] = useState(false);
  const toggleProductDropdown = () => setProductDropdownVisible(prev => !prev);

  const [mobileDropdownVisible, setMobileDropdownVisible] = useState(false);
  const toggleMobileDropdown = () => setMobileDropdownVisible(prev => !prev);

  return useMemo(() => {
    let buttonsRendered = buttons;
    if (!buttonsRendered) {
      buttonsRendered = user ? (
        <Link className="button-variant-1 mr-0 mx-3" to={ClientRouter.dashboard()}>
          Dashboard
        </Link>
      ) : (
        <div className="d-flex gap-3">
          <Link
            className="btn fw-500 ms-lg-4 nav-link-hover nav-link-font text-uppercase"
            to={ClientRouter.login()}
          >
            Login
          </Link>
          <Link className="main-cta-btn" to={ClientRouter.register()}>
            {signUpLabel}
          </Link>
        </div>
      );
    }
    return (
      <>
        <nav
          className={cn('navbar navbar-marketing navbar-expand-lg bg-white navbar-light', {
            'bottom-shadow': productDropdownVisible,
          })}
        >
          <div className="container px-5">
            <Link to={ClientRouter.home()}>
              <BlueLogo />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={toggleMobileDropdown}
            >
              <Menu />
            </button>
            <div
              className={`collapse navbar-collapse ${mobileDropdownVisible ? 'show' : ''}`}
              id="navbarSupportedContent"
            >
              {!hideLinks && (
                <ul className="navbar-nav me-lg-5">
                  <li className="nav-item">
                    <div className="nav-link" onClick={toggleProductDropdown}>
                      Product <FontAwesomeIcon icon={faChevronDown} width={20} height={20} />
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link to={ClientRouter.pricing()} className="nav-link">
                      Pricing
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://blog.getsparks.io">
                      Blog
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link to={ClientRouter.discover()} className="nav-link">
                      Discover
                    </Link>
                  </li>
                </ul>
              )}
              <div className="ms-auto">{buttonsRendered}</div>
            </div>
          </div>
        </nav>
        <div
          className={
            productDropdownVisible ? `navbar-dropdown navbar-dropdown--active` : 'navbar-dropdown'
          }
        >
          <div className="navbar-dropdown-inner">
            <div className="navbar-dropdown-inner-item navbar-dropdown-inner-item--left hide-mobile align-items-center d-flex flex-column">
              <h3 className="fw-700">getSparks App</h3>
              <p>Access your saved pages, summary, and Notes all at one place.</p>
              <Link
                className="main-cta-btn btn fw-500 ms-lg-4 btn-primary btn-sm py-2 px-3"
                style={{ height: 32 }}
                to={ClientRouter.register()}
              >
                {signUpLabel}
              </Link>
            </div>
            <div className="navbar-dropdown-inner-item navbar-dropdown-inner-item--right hide-mobile align-items-center d-flex flex-column">
              <h3 className="fw-700">getSparks browser extension</h3>
              <p>
                Add notes to any web page, review summary and save more pages with a click of
                button.
              </p>
              <a
                className="main-cta-btn btn fw-500 ms-lg-4 btn-primary btn-sm py-2 px-3"
                style={{ height: 32 }}
                target="_blank"
                rel="noreferrer"
                href="https://chrome.google.com/webstore/detail/dimadmjcopdjkklcmfomakopebflpbal?utm_source=getsparks.io_bookmark&utm_source=getsparks.io"
              >
                Add to Chrome
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }, [buttons, user, productDropdownVisible, hideLinks, signUpLabel, mobileDropdownVisible]);
}
