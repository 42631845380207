import React, { useCallback, useEffect } from 'react';
import cn from 'classnames';
import { useAppSelector } from '../../../../data/redux/hooks';
import { ReactComponent as HashtagIcon } from '../../../../assets/img/icons/hashtag.svg';
import { TagId } from '../../../../data/models/Tag';
import { Colors } from '../../../../assets/colors';
import { setTagsFilter } from '../../../../data/redux/actions';
import { useParams } from 'react-router-dom';

export const useTagClick = () => {
  const selectedTags = useAppSelector(state => state.cache.tagsFilter);

  return (e: React.MouseEvent<HTMLDivElement>, id: TagId) => {
    if (e.ctrlKey || e.metaKey) {
      setTagsFilter(
        selectedTags.includes(id)
          ? selectedTags.filter(tagId => id !== tagId)
          : [...selectedTags, id],
      );
    } else {
      const multipleTagsSelected = selectedTags.length > 1;
      setTagsFilter(!selectedTags.includes(id) || multipleTagsSelected ? [id] : []);
    }
  };
};

const FilterByTags = () => {
  const { topicId } = useParams();
  const selectedTags = useAppSelector(state => state.cache.tagsFilter);
  const tags = useAppSelector(useCallback(state => state.cache.tags, []));

  const onTagClick = useTagClick();

  useEffect(() => {
    setTagsFilter([]);
  }, [topicId]);

  return !!selectedTags.length ? (
    <div className="d-flex align-items-center gap-3">
      <span className="filter-tags-title flex-shrink-0">Filter By Tags:</span>
      <div className="d-flex gap-2 overflow-auto horizontal-scroll-bar">
        {selectedTags.map(tagId => {
          const tag = tags[tagId];
          return !!tag?.id ? (
            <div
              key={`filter-tags-${tag.id}`}
              className={cn('filter-tag-item pointer', {
                active: selectedTags.includes(tag.id),
              })}
              onClick={e => {
                if (tag.id) onTagClick(e, tag.id);
              }}
            >
              <HashtagIcon
                width={16}
                height={16}
                fill={selectedTags.includes(tag.id) ? Colors.blue08 : '#000'}
                style={{ marginBottom: 2 }}
              />
              {tag.title}
            </div>
          ) : null;
        })}
      </div>
    </div>
  ) : null;
};

export default FilterByTags;
