import React from 'react';
import Summary from './Summary';
import appImg from '../../assets/img/lp/app-lp.png';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';

type Props = {};

const AppLP = (props: Props) => {
  return (
    <Summary
      linkJSX={
        <div className="text-black d-flex align-items-center gap-3">
          Don’t have an account?{' '}
          <Link className="sparks-link--blue text-decoration-none" to="/register">
            <button className="sparks-button sparks-button-sm article-button--primary">
              Sign up
            </button>
          </Link>
        </div>
      }
      titleJSX={
        <div className={styles.appLp + ' pt-10 p-4 d-flex align-items-center'}>
          <div className="px-4 py-10 px-lg-0 max-width flex-grow-1">
            <div className="d-flex gap-4">
              <div className="d-flex flex-column">
                <h1>
                  Save pages,
                  <br />
                  Read Summaries,
                  <br />
                  Add Notes
                </h1>
                <p className="py-4">
                  A complete solution to organize content and gain insight for your research .
                </p>
                <Link to="/register">
                  <button className="button-lp-app">Sign up for getSparks</button>
                </Link>
              </div>
              <img src={appImg} alt="loading..." />
            </div>
          </div>
        </div>
      }
      withCookies={false}
    />
  );
};

export default AppLP;
