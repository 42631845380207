import React, { useCallback, useMemo, useState } from 'react';
import Tag, { TagId } from '../../../data/models/Tag';
import { useAppSelector } from '../../../data/redux/hooks';
import { ReactComponent as EditIcon } from '../../../assets/img/icons/edit.svg';
import { ReactComponent as HashtagIcon } from '../../../assets/img/icons/hashtag.svg';
import { Colors } from '../../../assets/colors';
import ServerRouter from '../../../routers/ServerRouter';
import axios from 'axios';
import { getAxiosHeaders } from '../../../routers/utils';
import { setArticle, setTag } from '../../../data/redux/actions';
import CreatableSelect from 'react-select/creatable';
import { SingleValue } from 'react-select';
import { useTagClick } from '../topic/tags/FilterByTags';

type Props = {
  articleId: number;
  containerClassName?: string;
  tagClassName?: string;
  titleClassName?: string;
  updateFilterStateOnClick?: boolean;
};

const ArticleTags = ({
  articleId,
  containerClassName,
  tagClassName,
  titleClassName,
  updateFilterStateOnClick,
}: Props) => {
  const article = useAppSelector(state => state.cache.articles[articleId]);
  const tagIds = useMemo(() => article?.tags || [], [article?.tags]);
  const [searchValue, setSearchValue] = useState('');

  const [editing, setEditing] = useState(false);

  const allTags = useAppSelector(useCallback(state => Object.values(state.cache.tags), []));
  const { tags, availableTags } = useMemo(() => {
    const related: Tag[] = [];
    const notAdded: Tag[] = [];
    allTags.forEach(tag => {
      if (tag && tag?.id) {
        if (tagIds.includes(tag.id)) related.push(tag);
        else notAdded.push(tag);
      }
    });
    return {
      tags: related,
      availableTags: notAdded,
    };
  }, [allTags, tagIds]);

  const validateNewTag = useCallback(
    (newTagTitle: string) =>
      !!newTagTitle &&
      !allTags.some(tag => tag && tag.title?.toLowerCase() === newTagTitle.toLowerCase()),
    [allTags],
  );

  const patchArticleTags = useCallback(
    (updatedTagIds?: number[]) => {
      if (!updatedTagIds) return;
      return axios
        .patch(ServerRouter.pageTags(articleId), { tags: updatedTagIds }, getAxiosHeaders())
        .then(() => {
          setArticle({ ...article, tags: updatedTagIds });
        });
    },
    [article, articleId],
  );

  const addTag = useCallback(
    (newValue: SingleValue<{ label: string; value: number }>) => {
      if (newValue) {
        patchArticleTags([...tagIds, newValue.value]);
        setSearchValue('');
      }
    },
    [tagIds, patchArticleTags],
  );

  const removeTag = useCallback(
    (tagId: TagId) => {
      patchArticleTags(tagIds.filter(id => id !== tagId));
    },
    [tagIds, patchArticleTags],
  );

  const createTag = useCallback(
    (title: string) => {
      axios
        .post(
          ServerRouter.tags(),
          {
            title,
          },
          getAxiosHeaders(),
        )
        .then(res => {
          if (res.data) {
            const tag = res.data;
            setTag(tag);
            const updatedTagIds = [...tagIds, tag.id] as number[];
            return updatedTagIds;
          }
        })
        .then(patchArticleTags);
    },
    [tagIds, patchArticleTags],
  );

  const onTagClick = useTagClick();

  return (
    <>
      <div className="d-flex gap-1">
        <div className={titleClassName || 'filter-tags-title pt-2'}>Tags: </div>
        {editing ? (
          <div className={'article-tags__list form-control'}>
            {tags.map(tag => (
              <div
                className={
                  tagClassName ||
                  'filter-tag-item red-2-text-hover red-2-icon-hover active border-0 px-0 pointer'
                }
                title={`Remove ${tag.title}`}
                onClick={() => removeTag(tag.id)}
                key={`article-tag-${tag.id}`}
              >
                <HashtagIcon
                  style={{ marginBottom: 2 }}
                  fill={Colors.blue08}
                  width={14}
                  height={14}
                />
                {tag.title}
              </div>
            ))}
            <CreatableSelect
              options={availableTags.map(tag => ({ label: tag.title, value: tag.id }))}
              classNamePrefix="sparks-select"
              className="sparks-select"
              value={null}
              isValidNewOption={validateNewTag}
              noOptionsMessage={() => 'Enter title of tag you want to add'}
              placeholder="Add tags..."
              onChange={addTag}
              onCreateOption={createTag}
              onInputChange={val => setSearchValue(val)}
              inputValue={searchValue}
            />
            <EditIcon
              className="pointer mr-0"
              onClick={() => setEditing(false)}
              width={12}
              height={12}
            />
          </div>
        ) : (
          <div className="article-tags__list">
            {tags.map(tag => (
              <div
                className={`${tagClassName || 'filter-tag-item active border-0 px-1'} ${
                  updateFilterStateOnClick ? 'pointer' : ''
                }`}
                key={`article-tag-${tag.id}`}
                onClick={e => (updateFilterStateOnClick ? onTagClick(e, tag.id) : null)}
              >
                <HashtagIcon
                  style={{ marginBottom: 2 }}
                  fill={Colors.blue08}
                  width={14}
                  height={14}
                />
                {tag.title}
              </div>
            ))}
            <EditIcon
              className="pointer mx-2 mr-0"
              onClick={() => setEditing(true)}
              width={12}
              height={12}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ArticleTags;
