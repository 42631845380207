import React, {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IField } from './Field';
import WYSIWYGEditor from '../editors/WYSIWYGEditor';
import { NotesInfoContext } from '../../../pages/dashboard/info/NotesInfo';
import useAutosizeTextArea from '../../../hooks/custom/useAutosizeTextArea';

type Props = {
  field: IField;
  defaultValue?: any;
  autoFocus?: boolean;
  content?: RefObject<HTMLDivElement>;
};

const actions = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'olist',
  'ulist',
  'line',
  'link',
  'heading1',
  'heading2',
];

export function NotesWysiwygField({ field, content }: Props) {
  const titleRef = useRef<HTMLTextAreaElement>(null);
  const descRef = useRef<HTMLInputElement>(null);
  const [titleValue, setTitleValue] = useState('');
  useAutosizeTextArea(titleRef.current, titleValue);
  const { setCustomHeaderContent, targetNote } = useContext(NotesInfoContext);
  useEffect(() => {
    const innerHTML = content?.current?.innerHTML;
    if (innerHTML && descRef.current) {
      descRef.current.value = innerHTML;
    }
  }, [content, descRef]);
  return (
    <div className="p-3 w-100 mw-100">
      <input hidden name="description" ref={descRef} />
      <textarea
        placeholder="Note Title"
        name="title"
        rows={1}
        onChange={e => setTitleValue(e.target.value)}
        defaultValue={targetNote?.title || ''}
        ref={titleRef}
        autoFocus
        className="w-100 notes-add-input sparks-h4"
      />
      <WYSIWYGEditor
        html={targetNote?.description || ''}
        actions={actions}
        contentRef={content}
        setActionsView={setCustomHeaderContent}
        multiField
        hideActionView
        fieldClassName="notes-add-input body-text"
        onChange={(html, index) => {
          if (descRef.current) {
            descRef.current.value = html;
          }
        }}
      />
    </div>
  );
}

export default function WysiwygField({ field, defaultValue, content }: Props) {
  const input = useRef<HTMLInputElement>(null);
  const autocleanPaste = useCallback((e: ClipboardEvent) => {
    e.preventDefault();
    const text = e.clipboardData?.getData('text/plain');
    document.execCommand('insertHTML', false, text);
  }, []);
  useEffect(() => {
    if (field.pasteFormatAutoclean && content?.current) {
      const input = content.current;
      input.addEventListener('paste', autocleanPaste);
      return () => input.removeEventListener('paste', autocleanPaste);
    }
  }, [autocleanPaste, content, field.pasteFormatAutoclean]);
  return useMemo(() => {
    return (
      <>
        <input hidden name={field.name} defaultValue={defaultValue} ref={input} />
        <WYSIWYGEditor
          html={defaultValue}
          actions={actions}
          contentRef={content}
          onChange={html => input.current && (input.current.value = html)}
        />
      </>
    );
  }, [content, defaultValue, field.name]);
}
