import React from 'react';
import Summary from './Summary';
import LPDemoImg from '../../assets/img/lp/lpdemo.png';

type Props = {};

const SummaryDemo = (props: Props) => {
  return (
    <Summary
      mainButtonClassName="blue-bg sparks-button-md align-items-center"
      mainButtonText={
        <span style={{ fontWeight: 400 }}>
          <b>Add GetSparks</b> Its Free
        </span>
      }
      descClassName="body-text-max"
      titleClassName="sparks-h2 text-black no-gradient"
      imgSrc={LPDemoImg}
    />
  );
};

export default SummaryDemo;
