import React, { useEffect } from 'react';
import { useAppSelector } from '../../data/redux/hooks';
import useMenu from '../../hooks/custom/useMenu';
import { CardViewType } from '../../data/redux/utils';
import { setCardViewType } from '../../data/redux/actions';
import { ReactComponent as ListIcon } from '../../assets/img/icons/list.svg';
import { ReactComponent as HeadlinesIcon } from '../../assets/img/icons/headlines.svg';
import { ReactComponent as TilesIcon } from '../../assets/img/icons/tiles.svg';

export const ARTICLE_CARD_VIEW_TYPE_KEY = 'ARTICLE_CARD_VIEW_TYPE';

const CardViewTypeSelect = () => {
  const cardViewType = useAppSelector(state => state.cache.cardViewType);
  const menu = useMenu();

  useEffect(() => {
    const savedCardViewType = localStorage.getItem(ARTICLE_CARD_VIEW_TYPE_KEY);
    if (savedCardViewType) {
      setCardViewType(savedCardViewType as CardViewType);
    }
  }, []);

  const getItemProps = (type: CardViewType) => {
    return {
      onClick: () => {
        setCardViewType(type);
        menu.toggleMenu();
        localStorage.setItem(ARTICLE_CARD_VIEW_TYPE_KEY, type);
      },
      className: `${
        cardViewType === type ? 'bg-blue-gray-3' : ''
      } d-flex align-items-center text-black body-text-sm`,
    };
  };

  const IconByCardViewType = {
    tile: TilesIcon,
    headlines: HeadlinesIcon,
    list: ListIcon,
  };

  return (
    <div className="position-relative">
      <menu.Toggle
        Icon={IconByCardViewType[cardViewType]}
        className="dashboard-options-button"
        height={12}
        width={12}
      />
      <menu.MenuContainer>
        <menu.ItemContainer {...getItemProps('tile')}>
          <TilesIcon width={15} height={15} /> Tiles
        </menu.ItemContainer>
        <menu.ItemContainer {...getItemProps('headlines')}>
          <HeadlinesIcon width={15} height={15} /> Headlines
        </menu.ItemContainer>
        <menu.ItemContainer {...getItemProps('list')}>
          <ListIcon width={15} height={15} /> List
        </menu.ItemContainer>
      </menu.MenuContainer>
    </div>
  );
};

export default CardViewTypeSelect;
