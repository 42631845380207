import React, { ChangeEvent, MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import FileField from './FileField';
import WysiwygField, { NotesWysiwygField } from './WYSIWYGField';
import SelectField from './SelectField';
import CheckboxField from './CheckboxField';
import { IField } from './Field';
import useFocus from '../../../hooks/useFocus';
import TextAreaField from './TextAreaField';

type Props = {
  field: IField;
  error?: string;
  showErrors?: boolean;
  setError?: (field: IField, errorMessage: string) => void;
  defaultValue?: any;
  autoFocus?: boolean;
  setPdfFile?: React.Dispatch<React.SetStateAction<File | undefined>>;
  onEnterPress?: (() => any) | null;
};

const DefaultTextInput = (
  props: React.HTMLProps<HTMLInputElement> & {
    className?: string;
    inputRef: MutableRefObject<any>;
    onShow: () => void;
    onHide: () => void;
    field: IField;
    setError?: (field: IField, errorMessage: string) => void;
  },
) => {
  const { onShow, onHide, field, setError, inputRef, onKeyDown, ...rest } = props;
  useEffect(() => {
    if (inputRef?.current && field.validate && setError) {
      setError(field, field.validate(inputRef.current.value) ? '' : field.errorMessage || '');
    }
    return () => setError && setError(field, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return <input {...rest} {...field} ref={inputRef} onKeyDown={onKeyDown} />;
};

export default function Input({
  field,
  error,
  defaultValue,
  autoFocus,
  setPdfFile,
  setError,
  showErrors = true,
  onEnterPress,
}: Props) {
  const [inputRef, setInputFocus] = useFocus();
  useEffect(() => {
    autoFocus && setInputFocus();
  });
  const validate = useCallback(
    (value: string) => {
      if (field.validate && setError) {
        const isValid = field.validate(value);
        if (!isValid) {
          setError(field, field.errorMessage || 'Invalid');
        } else {
          setError(field, '');
        }
      }
    },
    [field, setError],
  );

  useEffect(() => {
    if (inputRef.current) {
      validate(inputRef.current.value);
    }
  }, [showErrors, inputRef, validate]);

  return useMemo(() => {
    if (field.type === 'file') return <FileField field={field} setInitialFile={setPdfFile} />;
    if (field.type === 'wysiwyg')
      return <WysiwygField field={field} defaultValue={defaultValue} content={inputRef} />;
    if (field.type === 'notes-wysiwyg')
      return <NotesWysiwygField field={field} defaultValue={defaultValue} content={inputRef} />;
    if (field.options) return <SelectField field={field} defaultValue={defaultValue} />;
    if (field.type === 'checkbox')
      return (
        <CheckboxField field={field} defaultValue={defaultValue} error={showErrors ? error : ''} />
      );
    if (field.type === 'textarea')
      return (
        <TextAreaField
          field={field}
          defaultValue={defaultValue}
          error={showErrors ? error : ''}
          setError={setError}
          focusRef={inputRef}
        />
      );

    return (
      <DefaultTextInput
        inputRef={inputRef}
        setError={setError}
        onChange={(e: ChangeEvent<HTMLInputElement>) => validate(e.target.value)}
        style={showErrors && error ? { borderColor: 'red' } : undefined}
        field={field}
        label={field.label || undefined}
        className={'form-control'}
        defaultValue={defaultValue}
        onShow={() => validate(inputRef.current?.value)}
        onHide={() => setError && setError(field, '')}
        onKeyDown={e => {
          if (onEnterPress && e.key === 'Enter') {
            onEnterPress();
          }
        }}
      />
    );
  }, [
    defaultValue,
    error,
    field,
    inputRef,
    setError,
    setPdfFile,
    validate,
    showErrors,
    onEnterPress,
  ]);
}
