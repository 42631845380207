import React, { useMemo } from 'react';
import Topic from '../../../data/models/Topic';
import Details from '../../../components/Details';
import { Categories } from '../../../data/FormData';
import { setTopic } from '../../../data/redux/actions';
import { topicCRUDBuilder } from '../../../services/cruds';

type Props = {
  topic: Topic;
};

export default function TopicDetails({ topic }: Props) {
  let instance: { name: string; value: any }[] = useMemo(
    () => [
      { name: 'Description', value: topic.description },
      { name: 'Category', value: Categories[topic.category ?? 0] },
    ],
    [topic.category, topic.description],
  );
  return useMemo(
    () => (
      <Details
        instance={instance}
        appends={{
          cruds: {
            Description: topicCRUDBuilder([Topic.fields.description], setTopic),
            Category: topicCRUDBuilder([Topic.fields.category], setTopic),
          },
          values: topic.__data,
        }}
      />
    ),
    [instance, topic.__data],
  );
}
