import { useState, useEffect, useRef } from 'react';

export default function useComponentVisible(initialIsVisible: boolean) {
  const [isComponentVisible, setComponentVisible] = useState(initialIsVisible);
  const ref = useRef<any>(null);
  const insideRefs = useRef<any>([]);

  const handleClickOutside = (event: MouseEvent) => {
    const allowedRefs = [ref.current, ...insideRefs.current];
    const clickedOutside = allowedRefs.every(ref => !ref || !ref.contains(event.target));

    if (clickedOutside) {
      setComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentVisible, setComponentVisible, insideRefs };
}
