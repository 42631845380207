import { useCallback, useState } from 'react';
import ServerRouter from '../../routers/ServerRouter';
import { getHeaders } from '../../routers/utils';
import moment from 'moment/moment';
import DiscoverArticle from '../../data/models/DiscoverArticle';
import { useAppSelector } from '../../data/redux/hooks';
import { RootState } from '../../data/redux/store';
import Topic from '../../data/models/Topic';

const useFetchMatchUrls = ({
  urls,
  page,
  tokens,
  preferences,
  topic,
}: {
  urls?: string[];
  page?: number | null;
  tokens?: number[];
  preferences?: number[];
  topic?: Topic;
}) => {
  const [isLoading, setLoading] = useState(false);
  const [discoverArticles, setDiscoveryArticles] = useState<DiscoverArticle[]>([]);
  const [canFetchMore, setCanFetchMore] = useState(false);
  const articlesUrls = useAppSelector(
    useCallback(
      (state: RootState) => (topic ? topic.getArticles(state.cache).map(({ url }) => url) : []),
      [topic],
    ),
  );
  const [urlsAddedInCurrentSession, setUrlsAddedInCurrentSession] = useState<string[]>([]);
  const fetchDiscoveryArticles = useCallback(
    (fullRefresh?: boolean) => {
      setLoading(true);
      if (urls) {
        const domain = new Set(
          urls
            .map(url => {
              try {
                return new URL(url).hostname;
              } catch (e) {
                return '';
              }
            })
            .filter(domain => !!domain),
        );
        fetch(ServerRouter.matchUrlsPagination(page || 1), {
          method: 'POST',
          headers: getHeaders(),
          credentials: 'include',
          body: JSON.stringify({
            domain: Array.from(domain),
            tokens: tokens,
            groups: preferences,
            date_from: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            date_to: moment().format('YYYY-MM-DD'),
          }),
        })
          .then(res => res.json())
          .then(res => {
            const {
              results: { results = [], next },
            } = res;
            setCanFetchMore(!!next);
            setDiscoveryArticles(prev => {
              if (fullRefresh) {
                return results
                  .map((res: Partial<DiscoverArticle>) => new DiscoverArticle(res))
                  .filter(
                    (discoverArticle: DiscoverArticle) =>
                      !articlesUrls.includes(discoverArticle.url) ||
                      urlsAddedInCurrentSession.includes(discoverArticle.url || ''),
                  );
              }
              return [
                ...prev,
                ...results.map((res: Partial<DiscoverArticle>) => new DiscoverArticle(res)),
              ];
            });
          })
          .catch(err => {
            console.log('match urls err ===', err);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setDiscoveryArticles([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [urls, preferences, tokens, preferences, page],
  );

  const clearDiscoveryArticles = useCallback(() => {
    setDiscoveryArticles([]);
  }, []);

  const onDiscoverArticleAdded = useCallback((discoverArticle: DiscoverArticle) => {
    if (!!discoverArticle.url) {
      setUrlsAddedInCurrentSession(prev => [...prev, discoverArticle.url as string]);
    }
  }, []);

  return {
    canFetchMore,
    isLoading,
    discoverArticles,
    fetchDiscoveryArticles,
    clearDiscoveryArticles,
    onDiscoverArticleAdded,
  };
};

export default useFetchMatchUrls;
