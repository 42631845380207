import React, {ReactNode, useCallback, useMemo} from 'react';
import Article from "../../../data/models/Article";
import Details from "../../../components/Details";
import {keywordsToString} from "../../../services/utils";
import {useAppSelector} from "../../../data/redux/hooks";
import {Categories} from "../../../data/FormData";
import {RootState} from "../../../data/redux/store";
import {setArticle} from "../../../data/redux/actions";
import {articleCRUDBuilder} from "../../../services/cruds";
import useJSONStringToObject from "../../../hooks/custom/useJSONStringToObject";

type Props = {
  article: Article,
};

export default function ArticleDetails({article}: Props) {
  const topics = useAppSelector(useCallback((state: RootState) => state.cache.topics, []));
  const tags = useJSONStringToObject(article?.metatags);
  const metadata = useAppSelector(useCallback((state: RootState) => {
    if (article.metadata)
      return state.cache.articlesMetadata[article.metadata]
  }, [article.metadata]));
  const instance: { name: string, value: ReactNode }[] = useMemo(() => {
    let fields: { name: string, value: ReactNode }[] = [];
    if (tags)
      fields.push(
        {name: 'Excerpt', value: tags.excerpt},
        {name: 'By', value: tags.byline},
        {name: 'Source', value: tags.source},
      )
    fields.push({name: 'Category', value: Categories[article.category ?? 0]});
    if (metadata)
      fields.push(
        {name: 'People', value: metadata.people},
        {name: 'Places', value: metadata.places},
        {name: 'Sentiment', value: metadata.sentiment ? "Positive" : "Negative"},
        {name: 'Keywords', value: keywordsToString(metadata.keywords)},
        {name: 'Speaking Time', value: metadata.speaking_time + " min"},
        {name: 'Words', value: metadata.words},
        {name: 'Paragraphs', value: metadata.paragraphs},
        {name: 'Characters', value: metadata.chars},
      )
    return fields;
  }, [article.category, metadata, tags]);
  return useMemo(() => <Details instance={instance} appends={{
    cruds: {
      Topic: articleCRUDBuilder([Article.fields.topic(topics)], setArticle),
      Category: articleCRUDBuilder([Article.fields.category], setArticle)
    },
    values: article.__data
  }}/>, [article.__data, instance, topics]);
}
