import React, { useCallback, useMemo } from 'react';
import Topic from '../../../data/models/Topic';
import ClientRouter from '../../../routers/ClientRouter';
import TextShortener from '../../../components/TextShortener';
import { useAppSelector } from '../../../data/redux/hooks';
import { RootState } from '../../../data/redux/store';
import { fillTopics, setShowSideNav } from '../../../data/redux/actions';
import { useNavigateNoUpdates } from '../../../hooks/RouterUtils';
import moment from 'moment';
import useMenu from '../../../hooks/custom/useMenu';
import { ReactComponent as DotsIcon } from '../../../assets/img/icons/dots.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../../assets/img/icons/edit.svg';
import { ReactComponent as Plus } from '../../../assets/img/icons/plus.svg';
import { ReactComponent as FolderTree } from '../../../assets/img/icons/folder-tree.svg';
import { Colors } from '../../../assets/colors';
import { getTopicLightCRUD } from '../../../services/cruds';
import useConditionalLoader from '../../../hooks/custom/useConditionalLoader';
import ServerRouter from '../../../routers/ServerRouter';
import useAllTopicParents from '../../../hooks/custom/useAllTopicParents';

type Props = {
  topic: Topic;
  selectedTopicId?: number | null;
  withHoverAnimation?: boolean;
  isChild?: boolean;
};

export default function NavTopicItem({
  topic,
  selectedTopicId,
  withHoverAnimation,
  isChild,
}: Props) {
  const pages = useAppSelector(
    useCallback((state: RootState) => topic.getArticles(state.cache), [topic]),
  );
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));

  let articlesCount = 0;
  let articles_keys = Object.keys(
    useAppSelector(useCallback((state: RootState) => state.cache.articles, [])),
  );

  let articles = useAppSelector(useCallback((state: RootState) => state.cache.articles, []));
  for (let i = 0; i < articles_keys.length; i++) {
    if (moment(articles[articles_keys[i]]?.created_at).isSame(moment(), 'day')) {
      articlesCount = articlesCount + 1;
    }
  }

  const { id, name } = topic;
  const isActive = selectedTopicId === id;
  const activeClass = isActive ? ' active' : '';
  const animationClass = withHoverAnimation ? ' animation' : '';

  const navigate = useNavigateNoUpdates();
  const menu = useMenu();
  const topics = useAppSelector(useCallback(state => state.cache.topics, []));
  const { setShouldFetch: setShouldFetchTopics, loader: topicLoader } = useConditionalLoader({
    url: ServerRouter.topics(),
    onOk: fillTopics,
  });

  const topicCRUD = getTopicLightCRUD(
    () => {
      setShouldFetchTopics(true);
    },
    topics,
    true,
  );

  const { toggleMenu, ItemContainer, MenuContainer, placeRef, menuVisible } = menu;

  const menuRendered = useMemo(() => {
    return topic.id ? (
      <MenuContainer topOffset={30}>
        <topicCRUD.delete
          formRef={ref => placeRef(ref, 1)}
          defaultValues={{ id: topic.id }}
          validateFunction={() => {
            const anyChildExists =
              !!topic.child?.length && topic.child.some(childId => !!topics[childId]);
            if (anyChildExists) {
              return {
                non_field_errors: ' Please remove “sub-topics” before deleting',
              };
            }
          }}
          actionButton={onClick => (
            <ItemContainer onClick={onClick}>
              <DeleteIcon width={15} height={15} fill={Colors.blueGray00} />
              <span>Delete</span>
            </ItemContainer>
          )}
        />
        <topicCRUD.patch
          formRef={ref => placeRef(ref, 2)}
          defaultValues={{ ...topic, owner: user?.id }}
          title="Edit topic"
          actionButton={onClick => {
            return (
              <ItemContainer onClick={onClick}>
                <EditIcon width={15} height={15} fill={Colors.blueGray00} />
                <span>Rename</span>
              </ItemContainer>
            );
          }}
        />
        <topicCRUD.post
          formRef={ref => placeRef(ref, 3)}
          defaultValues={{ parent: topic.id }}
          actionButton={onClick => {
            return (
              <ItemContainer onClick={onClick}>
                <Plus width={15} height={15} fill={Colors.blueGray00} />
                <span>Add Topic</span>
              </ItemContainer>
            );
          }}
        ></topicCRUD.post>
      </MenuContainer>
    ) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic, menuVisible]);

  const allTopicParents = useAllTopicParents(selectedTopicId);

  const expanded = topic.id
    ? selectedTopicId === topic.id || allTopicParents.includes(topic.id)
    : false;

  if (name === 'Default' && !pages.length) return null;

  return (
    <div>
      {topicLoader}
      <div
        className={
          'flex-1 parent-hover position-relative sidenav-item_container w-100' + activeClass
        }
      >
        <div
          className={
            'sidenav-item overflow-hidden text-capitalize position-relative ' +
            activeClass +
            animationClass
          }
          onClick={() => {
            setShowSideNav('free');

            navigate(ClientRouter.topics(id));
          }}
        >
          {isChild && <FolderTree width={13} height={11} className="sidenav-item_folder-icon" />}
          <TextShortener text={name} className="sidenav-item_title" />
        </div>
        {!!topic.id && (
          <>
            <div>
              <button
                ref={ref => placeRef(ref, 0)}
                className="sidenav-item_icon position-absolute"
                onClick={toggleMenu}
              >
                <DotsIcon fill="black" />
              </button>
              {menuRendered}
            </div>
          </>
        )}
      </div>
      {!!topic.child?.length && expanded && (
        <div className="mx-4 mr-0">
          {topic.child.map(childTopicId => {
            const childTopic = topics[childTopicId];
            return childTopic ? (
              <NavTopicItem selectedTopicId={selectedTopicId} topic={childTopic} isChild />
            ) : null;
          })}
        </div>
      )}
    </div>
  );
}
