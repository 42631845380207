import { IField } from '../../components/crud/fields/Field';
import TimeStampedModel from './TimeStampedModel';

export default class Note extends TimeStampedModel {
  static fields = {
    title: {
      hidden: true,
    } as IField,
    description: {
      type: 'notes-wysiwyg',
      noAutoFocus: true,
    } as IField,
  };
  readonly description?: string;
  readonly title?: string;

  constructor(jsonData: Partial<Note>) {
    super(jsonData);
    Object.assign(this, jsonData);
  }
}
