import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { IField } from './Field';

type Props = {
  field: IField;
  error?: string;
  setError?: (field: IField, errorMessage: string) => void;
  showError?: boolean;
  defaultValue?: any;
  focusRef?: React.RefObject<HTMLTextAreaElement>;
};

export default function TextAreaField({
  field,
  defaultValue,
  error,
  focusRef,
  setError,
  showError,
}: Props) {
  const submitInput = useRef<HTMLInputElement>(null);
  const onChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      submitInput.current && (submitInput.current.value = event.target.value);
      if (field.validate && setError) {
        setError(field, field.validate(event.target.value) ? '' : field.errorMessage || '');
      }
      if (focusRef?.current) {
        focusRef.current.style.height = '0px';
        focusRef.current.style.height = focusRef.current.scrollHeight + 5 + 'px';
      }
    },
    [focusRef, setError, field],
  );

  useEffect(() => {
    if (submitInput.current && field.validate && setError) {
      setError(field, field.validate(submitInput.current.value) ? '' : field.errorMessage || '');
    }
    return () => setError && setError(field, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitInput]);

  return useMemo(
    () => (
      <>
        <input
          ref={submitInput}
          name={field.name}
          defaultValue={defaultValue ?? field.defaultChecked}
          hidden
        />
        <textarea
          {...field}
          className={`form-control ${error ? 'is-invalid' : ''}`}
          name={undefined}
          onChange={onChange}
          onFocus={onChange}
          defaultValue={defaultValue}
          ref={focusRef}
        />
      </>
    ),
    [defaultValue, error, field, focusRef, onChange],
  );
}
