import {useCallback, useEffect, useState} from "react";

export default function useCheckExtensionInstalled() {
  const [extensionInstalled, setExtensionInstalled] = useState(false);
  const onMessage = useCallback((event: MessageEvent) => {
    if (event.data?.result === 'installed') {
      setExtensionInstalled(true);
    }
  }, []);
  useEffect(() => {
    window.addEventListener("message", onMessage);
    window.postMessage({type: 'IS_INSTALLED'});
    return () => window.removeEventListener("message", onMessage);
  });
  return extensionInstalled;
}
