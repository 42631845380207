import React, {Fragment, ReactNode, useCallback, useMemo} from 'react';
import {JSONObject} from "../data/models/Model";
import ModalCRUD from "./crud/ModalCRUD";
import PatchButton from "./buttons/text/PatchButton";

export type DetailsProps = {
  instance: { name: string, value: ReactNode }[],
  appends?: { cruds: { [x: string]: ModalCRUD }, values: JSONObject },
};

export default function Details({instance, appends}: DetailsProps) {
  const patch = useCallback((property: string) => {
    if (appends) {
      const crud = appends.cruds[property];
      return crud ? <crud.patch title={"Change " + property} defaultValues={appends.values}
                                formButton={<PatchButton children={"Save"}/>}/> : null;
    }
  }, [appends]);
  return useMemo(() => <div className={'text-break m-3'}>
    {instance.map((property, index) => {
      return <Fragment key={index}>
        <b>{property.name}
          {patch(property.name)}
        </b>
        <p>{property.value}</p>
      </Fragment>
    })}
  </div>, [instance, patch]);
}
