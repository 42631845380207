import React, { useMemo } from 'react';
import User from '../../data/models/User';
import AuthFormRepresentation from './AuthFormRepresentation';
import CRUD from '../../components/crud/CRUD';
import ServerRouter from '../../routers/ServerRouter';
import { register } from '../../data/redux/authUtils';
import { FormFields } from '../../components/crud/ModularForm';
import MetaTagsList from '../../components/MetaTagsList';

export default function Register() {
  const formButton = useMemo(
    () => (
      <>
        <div className="d-flex flex-column  justify-content-evenly mb-0">
          <button
            className="button button-purple button-text text-gray-4 flex-grow-1"
            type="submit"
          >
            Sign Up
          </button>
        </div>
      </>
    ),
    [],
  );
  return useMemo(() => {
    const fields: FormFields = [
      [User.fields.first_name, User.fields.last_name],
      User.fields.email,
      { ...User.fields.password, placeholder: 'Use 8 characters with mix of letters and symbols' },
      User.fields.get_newsletter,
    ];
    const crud = new CRUD(ServerRouter.register(), fields, register);
    return (
      <div className="register">
        <MetaTagsList type="register" />
        <AuthFormRepresentation
          title={'Sign-Up'}
          body={<crud.post formButton={formButton} defaultShowErrors={false} />}
          colSize={'col-xl-8 col-lg-9'}
        />
      </div>
    );
  }, [formButton]);
}
