import React from 'react';
import foldersImage from "../../assets/img/lp/folders.jpg";
import ExtensionLP1 from "./ExtensionLP1";

export default function ExtensionLP2() {
  return <ExtensionLP1
    imgSrc={foldersImage}
    title={'Save and Organize interesting stories now and access later'}
    imgTitle={'Organize as Topics'}/>
}
