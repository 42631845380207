import React, { useMemo, useState } from 'react';
import NetworkJSONArrayLoader from '../../components/network/NetworkJSONArrayLoader';
import { JSONObject } from '../../data/models/Model';

type Props = {
  defaultShouldFetch?: boolean;
  url: string;
  onOk: (items: JSONObject[] | null) => any;
};

function useConditionalLoader({ onOk, url, defaultShouldFetch = false }: Props) {
  const [shouldFetch, setShouldFetch] = useState(defaultShouldFetch);

  const loader = useMemo(
    () =>
      shouldFetch ? (
        <NetworkJSONArrayLoader
          url={url}
          onOk={topics => {
            onOk(topics);
            setShouldFetch(false);
          }}
          hidden
        />
      ) : null,
    [shouldFetch, onOk, url],
  );

  return {
    setShouldFetch,
    shouldFetch,
    loader,
  };
}

export default useConditionalLoader;
