import React from 'react';
import MetaTags from 'react-meta-tags';

type Props = {
  type: 'home' | 'register' | 'login' | 'privacyPolicy' | 'termsAndConditions' | 'refund';
};
const titleByKey = {
  home: 'getSparks - Bookmark Manager and Content Management platform',
  register: 'Register - getSparks',
  login: 'Login - getSparks',
  privacyPolicy: 'Privacy Policy - getSparks',
  termsAndConditions: 'Terms and Conditions - getSparks',
  refund: 'Refund - getSparks',
};

const MetaTagsList = ({ type }: Props) => {
  const title = titleByKey[type];
  return (
    <MetaTags>
      <title>{title}</title>
      <meta name="og:title" content="getSparks" />
      <meta
        name="description"
        content="An AI powered tool for saving web pages to read later and manage bookmarks."
      />
      <meta
        name="og:description"
        content="An AI powered tool for saving web pages to read later and manage bookmarks."
      />
      <meta
        name="keyword"
        content="getSparks, Sparks, read later, save webpage for later, save for later, read, manage bookmarks, Summarize, give me something to read, interesting to read, long reads, Ask Questions, notes, read it later, bookmark"
      />
      <meta
        name="og:keyword"
        content="getSparks, Sparks, read later, save webpage for later, save for later, read, manage bookmarks, Summarize, give me something to read, interesting to read, long reads, Ask Questions, notes, read it later, bookmark"
      />
      <meta name="og:url" content="https://getsparks.io" />
      <meta http-equiv="x-ua-compatible" content="ie=edge,chrome=1" />
      <meta name="robots" content="index, follow" />
      <link
        rel="icon"
        href="/static/media/favicon.f3dd34a9573d1e53004a.png"
        sizes="16x16"
        type="image/png"
      />
      <link
        rel="icon"
        href="/static/media/favicon.f3dd34a9573d1e53004a.png"
        sizes="32x32"
        type="image/png"
      />
      <link
        rel="icon"
        href="/static/media/favicon.f3dd34a9573d1e53004a.png"
        sizes="64x64"
        type="image/png"
      />
      <link
        rel="icon"
        href="/static/media/favicon.f3dd34a9573d1e53004a.png"
        sizes="128x128"
        type="image/png"
      />
      <link rel="icon" type="image/svg+xml" href="/static/media/Icon%20blue%20512x512.svg" />
      <meta name="twitter:card" content="app" />
      <meta name="twitter:site" content="@getsparks1" />
      <meta
        name="twitter:description"
        content="Free Bookmark Manager and Content Consumption platform"
      />
      <meta name="twitter:image" content="/static/media/full-site-icon.jpg" />
      <meta property="og:type" content="product" />
      <meta
        name="og:title"
        content="getSparks-Free Bookmark Manager and Content Consumption platform"
      />
      <meta
        name="og:description"
        content="An AI powered tool for saving web pages to read later and manage bookmarks."
      />
      <meta
        name="og:keyword"
        content="getSparks, Sparks, read later, save webpage for later, save for later, read, manage bookmarks, Summarize, give me something to read, interesting to read, long reads, Ask Questions, notes, read it later, bookmark"
      />
      <meta property="og:url" content="https://getsparks.io" />
      <meta property="og:image" content="/static/media/full-site-icon.jpg" />
      <meta
        name="og:title"
        content="GetSparks - Free Bookmark Manager and Content Consumption platform"
      />
      <meta
        name="description"
        content="An AI powered tool for saving web pages to read later and manage bookmarks."
      />
      <meta
        name="keyword"
        content="getSparks, Sparks, read later, save webpage for later, save for later, read, manage bookmarks, Summarize, give me something to read, interesting to read, long reads, Ask Questions, notes, read it later, bookmark"
      />
    </MetaTags>
  );
};

export default MetaTagsList;
