import React, { FunctionComponent, SVGProps, useCallback } from 'react';
import { IconButtonProps } from './IconButton';
import { ReactComponent as Menu } from '../../../assets/img/icons/menu.svg';
import { useAppSelector } from '../../../data/redux/hooks';
import { RootState } from '../../../data/redux/store';
import { setShowSideNav } from '../../../data/redux/actions';
import { MenuTab } from '../../../data/redux/utils';
import { ReactComponent as MenuIcon } from '../../../assets/img/icons/menu.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/img/icons/arrow-2.svg';

export const iconSwitchSidenavProps = { tooltip: 'Topics', children: <Menu /> };

export const switchingDict: { [x in MenuTab]: MenuTab } = {
  closed: 'open',
  open: 'closed',
  free: 'toggled',
  toggled: 'free',
};

export const iconDict: { [x in MenuTab]: FunctionComponent<SVGProps<SVGSVGElement>> } = {
  closed: MenuIcon,
  toggled: MenuIcon,
  open: ArrowIcon,
  free: ArrowIcon,
};

export default function IconSwitchSidenavButton(props: IconButtonProps) {
  const showSideNav = useAppSelector(
    useCallback((state: RootState) => state.cache.showSideNav, []),
  );
  const Icon = iconDict[showSideNav];
  return (
    <button
      onClick={() => setShowSideNav(switchingDict[showSideNav])}
      {...props}
      className="sparks-button article-button article-button-icon switch-sidenav-button"
    >
      <Icon width={16} height={11.5} fill="#000" />
    </button>
  );
}
