import React, { RefObject } from 'react';
import { OnRefChangeType } from 'react-resize-detector/build/types/types';

const DashboardContext = React.createContext<{
  width?: number;
  containerRef?: OnRefChangeType<any>;
  layoutRef?: RefObject<HTMLDivElement>;
}>({});

export default DashboardContext;
