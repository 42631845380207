import React from 'react';
import ArticleCardView from './ArticleCardView';
import { UsePaginationReturnType } from '../../../../hooks/custom/usePagination';

type Props = {
  pagination: UsePaginationReturnType;
};

const TopicListArticles = ({
  pagination: { paginatedItems: articles, onShowMoreClicked, nextPageExists },
}: Props) => {
  return (
    <div>
      <div className="topic-list-articles">
        {articles.map(article => (
          <ArticleCardView key={`article-list-${article.id}`} article={article} />
        ))}
      </div>
      {nextPageExists && (
        <button className="article-button sparks-button-secondary mt-4" onClick={onShowMoreClicked}>
          Show More Pages
        </button>
      )}
    </div>
  );
};

export default TopicListArticles;
