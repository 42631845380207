import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import Awaiter from '../messages/Awaiter';
import { processJSONArrayResponse, ProcessJSONArrayResponseProps } from '../../routers/utils';
import ErrorMessage from '../messages/ErrorMessage';

type Props = PropsWithChildren<
  {
    hidden?: boolean;
    onCancel?: () => void;
    nonRetriable?: boolean;
    cancelComponent?: React.ReactNode;
  } & Omit<ProcessJSONArrayResponseProps, 'onError'>
>;

export default function NetworkJSONArrayLoader(props: Props) {
  const {
    children,
    hidden,
    body,
    url,
    method,
    headers,
    onOk,
    onCancel,
    nonRetriable,
    cancelComponent,
  } = props;
  const [error, setError] = useState<string | undefined>();
  const load = useCallback(() => {
    setError(undefined);
    processJSONArrayResponse({
      onError: error => setError(error.statusText),
      body,
      url,
      method,
      headers,
      onOk,
    });
  }, [body, headers, method, onOk, url]);
  useEffect(() => {
    load();
  }, [load]);
  if (hidden) return null;
  if (error)
    return (
      <ErrorMessage
        onRetry={nonRetriable ? undefined : load}
        onCancel={onCancel}
        cancelComponent={cancelComponent}
      >
        {children}: {error}
      </ErrorMessage>
    );
  return (
    <Awaiter>
      {children}
      {children ? '...' : ''}
    </Awaiter>
  );
}
