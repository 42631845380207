import { getURLFormer } from './utils';

const url = getURLFormer('');

const ClientRouter = {
  home: url``,
  indexHTML: url`index.html`,
  lpApp: url`lp/app/`,
  lpSite: url`lp/site/`,
  lpExtension: url`lp/extension/`,
  lpExtension1: url`lp/extension1/`,
  lpExtension2: url`lp/extension2/`,
  lpSummaryGIF: url`lp/summaryGif/`,
  lpNotesGIF: url`lp/notesGif/`,
  lpSaveGIF: url`lp/saveGif/`,
  lpBookmarkApp: url`/lp/bookmark_app`,
  lpBookmark: url`/lp/clr_bookmark`,
  lpBookmarkBW: url`/lp/bnw_bookmark`,
  lpSave: url`lp/save/`,
  lpSummarize: url`lp/summary/`,
  lpUninstall: url`lp/uninstall/`,
  lpSummary: url`lp/summaryview/`,
  lpSummaryDemo: url`lp/summarydemo/`,
  lpNote: url`lp/note/`,
  pricing: url`pricing/`,
  dashboard: url`dashboard/${0}/`,
  topics: url`topics/${0}/`,
  tags: url`tags/${0}/`,
  articlesSearch: url`articles-search/${0}`,
  articles: url`articles/${0}/`,
  printArticles: url`articlesPrint/${0}/`,
  blog: url`blog/`,
  login: url`login/`,
  register: url`register/`,
  profile: url`profile/`,
  discover: url`discover/`,
  resetPassword: url`reset-password/`,
  passwordReset: url`password-reset/${0}/${1}/`,
  privacyPolicy: url`privacy-policy/`,
  Refund: url`refund/`,
  termsAndConditions: url`terms-and-conditions/`,
  howDoesSparksWork: url`how-does-sparks-work/`,
  about: url`about/`,
  save: url`save/`,
};

export default ClientRouter;
