import React, { useContext, useMemo } from 'react';
import { setShowSideNav } from '../../../data/redux/actions';
import ClientRouter from '../../../routers/ClientRouter';
import { Colors } from '../../../assets/colors';
import TextShortener from '../../../components/TextShortener';
import { ReactComponent as DiscoverIcon } from '../../../assets/img/icons/discover.svg';
import { ReactComponent as HomeIcon } from '../../../assets/img/icons/home.svg';
import { useNavigate } from 'react-router-dom';
import DashboardContext from '../DashboardContext';

export type FiltersNavProps = {
  selectedTopicId?: number | null;
};

export default function SideNavMainSection({ selectedTopicId }: FiltersNavProps) {
  const navigate = useNavigate();
  const { layoutRef } = useContext(DashboardContext);
  return useMemo(() => {
    return (
      <>
        {[
          { key: 'recents', name: 'Home' },
          { key: 'discover', name: 'Discover' },
        ].map(filter => {
          const activeClass = filter.key === 'recents' && !selectedTopicId ? ' active' : '';
          const Icon = filter.key === 'recents' ? HomeIcon : DiscoverIcon;
          return (
            <div
              className={
                'flex-1 parent-hover position-relative sidenav-item_container w-100' + activeClass
              }
            >
              <div
                className={
                  'sidenav-item overflow-hidden text-capitalize position-relative ' + activeClass
                }
                onClick={() => {
                  setShowSideNav('free');
                  if (filter.key === 'recents' && layoutRef?.current) {
                    layoutRef?.current?.scrollTo({ top: 0, behavior: 'smooth', left: 0 });
                  }
                  navigate({
                    pathname: ClientRouter.dashboard(),
                    hash: filter.key === 'discover' ? 'discover' : '',
                  });
                }}
              >
                <Icon
                  width={15}
                  height={15}
                  fill={Colors.purple00}
                  className="mb-1"
                  style={{ marginRight: 13 }}
                />

                <TextShortener text={filter.name} className="sidenav-item_title" />
              </div>
            </div>
          );
        })}
      </>
    );
  }, [selectedTopicId, layoutRef, navigate]);
}
