import React, { useRef } from 'react';
import { IField } from './Field';

type Props = {
  field: IField;
  defaultValue: any;
  autoFocus?: boolean;
};

export default function SelectField({ field, defaultValue, autoFocus }: Props) {
  const {
    nullableName,
    nullableValue,
    options,
    className,
    createNewName,
    onCreateNewSelect,
    value,
    onChange,
    ...restField
  } = field;
  const ref = useRef<HTMLSelectElement>(null);
  return (
    <select
      {...restField}
      ref={ref}
      className={'form-select ' + className}
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      {...(value ? { value } : {})}
      onChange={e => {
        if (onCreateNewSelect && e.target.value === 'create-new') {
          const response = onCreateNewSelect();
          if (response === 'clear' && ref.current) {
            ref.current.value = '';
          }
        } else if (onChange) {
          onChange(e.target.value);
        }
      }}
    >
      {!!nullableName && <option value="none">{nullableName}</option>}
      {!!options?.length &&
        options.map(([value, label, level], index) => (
          <option key={index} value={value}>
            {level ? '-'.repeat(level) + ' ' : ''}
            {label}
          </option>
        ))}
      {!!createNewName && <option value={'create-new'}>{createNewName}</option>}
    </select>
  );
}
