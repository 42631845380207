import React, { useMemo } from 'react';
import Article from '../../../../data/models/Article';
import ArticleCardContent from './ArticleCardContent';

type Props = {
  article: Article;
};

const ArticleCardView = ({ article }: Props) => {
  return useMemo(() => {
    const ready =
      article.status === 'full' &&
      !article?.url?.match(':\\/\\/[^/]+\\/#\\/how-does-sparks-work\\/$');
    return <ArticleCardContent ready={ready} article={article} />;
  }, [article]);
};

export default ArticleCardView;
