import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMultiply } from '@fortawesome/free-solid-svg-icons';
import ClientRouter from '../../routers/ClientRouter';
import { Link } from 'react-router-dom';

type Props = {
  show?: boolean;
  message?: string;
  messageAction?: string;
  onHide?: () => void;
  closePlan?: () => void;
  zIndex?: number;
};

export default function UpgradeToPremium(props: Props) {
  return (
    <Modal
      {...props}
      size="lg"
      style={props.zIndex ? { zIndex: props.zIndex } : {}}
      aria-labelledby="contained-modal-title-vcenter-sm"
      centered
    >
      <div className="p-3 text-black">
        <div className="position-relative text-center">
          <p>
            <h4>Get Started with Premium</h4>
          </p>
          <button
            className="position-absolute end-0 top-0 border-0 bg-transparent"
            onClick={props.onHide}
          >
            <FontAwesomeIcon icon={faMultiply} />
          </button>
        </div>
        <div className="mx-5">
          <div>
            <p>
              You have reached the limit for number of {props.message} you can{' '}
              {props.messageAction || 'add'} for free account. Please click continue to sign up for
              premium account.
            </p>
          </div>
          <div className="lh-2">
            <div>
              <p>Premium account enables you to add unlimited topics, pages, and notes.</p>
            </div>
          </div>
        </div>
        <div className="d-flex pt-2 justify-content-center">
          <button onClick={props.closePlan} className="btn px-4 btn-success">
            Continue
          </button>
        </div>
      </div>

      <hr className="mx-3" />
      <div className="d-flex justify-content-center text-black">
        <Link to={ClientRouter.privacyPolicy()}>Privacy Policy</Link>
        <p className="mx-2">
          <b>|</b>
        </p>
        <Link to={ClientRouter.termsAndConditions()}>Terms &amp; Conditions</Link>
      </div>
    </Modal>
  );
}
