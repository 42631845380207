import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Article from '../../../data/models/Article';
import NetworkJSONLoader from '../../../components/network/NetworkJSONLoader';
import ServerRouter from '../../../routers/ServerRouter';
import ArticleContent from './ArticleContent';
import { useNavigateNoUpdates } from '../../../hooks/RouterUtils';
import { useAppSelector } from '../../../data/redux/hooks';
import { RootState } from '../../../data/redux/store';
import { setArticle } from '../../../data/redux/actions';
import { getHeaders } from '../../../routers/utils';

export const ArticlePrint = () => {
  const { articleId } = useParams();

  const article = useAppSelector(
    useCallback(
      (state: RootState) => {
        if (articleId) return state.cache.articles[parseInt(articleId)];
      },
      [articleId],
    ),
  );

  const navigate = useNavigateNoUpdates();

  const afterprint = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (article) {
      window.addEventListener('afterprint', afterprint);
      article.title && (document.title = article.title);
      if (article.page_content === "PDF") {
        window.frames["printf"].print();
      } else {
        window.print();
      }
      fetch(ServerRouter.pageActivity(), {
        method: 'POST',
        headers: getHeaders(),
        credentials: 'include',
        body: JSON.stringify({
          page: articleId && parseFloat(articleId),
          is_printed: true,
        }),
      }).catch(e => console.error(e));
    }
    return () => window.removeEventListener('afterprint', afterprint);
  }, [afterprint, article, navigate, articleId]);

  return useMemo(() => {
    if (!article) {
      return (
        <NetworkJSONLoader
          url={ServerRouter.articles(articleId)}
          onOk={json => setArticle(new Article(json))}
        >
          Fetching article
        </NetworkJSONLoader>
      );
    }
    return <ArticleContent article={article} isPrint={true}/>;
  }, [article, articleId]);
};
