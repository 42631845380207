import React, { useCallback, useMemo } from 'react';
import { useAppSelector } from '../../../data/redux/hooks';
import NavTagsItem from './NavTagsItem';
import { setTag } from '../../../data/redux/actions';
import { ReactComponent as HashtagIcon } from '../../../assets/img/icons/hashtag.svg';
import { ReactComponent as PlusIcon } from '../../../assets/img/icons/plus.svg';
import { ReactComponent as DotsIcon } from '../../../assets/img/icons/dots.svg';
import useMenu from '../../../hooks/custom/useMenu';
import { Colors } from '../../../assets/colors';
import { getTagCRUD } from '../../../services/cruds';

const NavTags = () => {
  const tags = useAppSelector(useCallback(state => Object.values(state.cache.tags), []));
  const { toggleMenu, MenuContainer, ItemContainer, placeRef } = useMenu();
  const tagCRUD = getTagCRUD(setTag, tags);

  const post = useMemo(() => {
    return (
      <tagCRUD.post
        formRef={ref => placeRef(ref, 1)}
        defaultValues={{}}
        nonLinear
        title="Add new tag"
        actionButton={onClick => (
          <ItemContainer onClick={onClick}>
            <PlusIcon width={15} height={15} fill={Colors.blueGray00} />
            <span>Add Tag</span>
          </ItemContainer>
        )}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tagsRendered = useMemo(() => {
    return tags.map(tag => (tag ? <NavTagsItem tag={tag} key={`nav-tag-id-${tag?.id}`} /> : null));
  }, [tags]);

  return (
    <div>
      <div className="tags-main-item d-flex align-items-center justify-content-between position-relative">
        <div className="d-flex align-items-center">
          <HashtagIcon width={16} height={18.2} style={{ marginBottom: 1 }} fill="#6B2ACB" />
          <span className="sidenav-item_title lh-1 mx-1 mr-0">
            Tags {tags.length ? `(${tags.length})` : ''}
          </span>
        </div>
        <button
          ref={ref => placeRef(ref, 0)}
          className="sidenav-item_icon default"
          onClick={toggleMenu}
        >
          <DotsIcon fill="black" />
        </button>
        <MenuContainer topOffset={30}>{post}</MenuContainer>
      </div>
      {tagsRendered}
    </div>
  );
};

export default NavTags;
