import { getURLFormer } from './utils';

const url = getURLFormer(process.env.REACT_APP_SERVER_URL ?? '/');

const ServerRouter = {
  articleNotes: url`api/page-note/${0}/`,
  articles: url`api/pages/${0}/`,
  articleTokensUpdate: url`api/pages/${0}/select-tokens/`,
  articlesPagination: url`api/pages/?page=${0}&page_size=${1}`,
  questions: url`api/questions/`,
  feedback: url`api/feedback/`,
  getUserData: url`api/get-user-data/`,
  googleOAuth: url`google-oauth/`,
  importBookmarks: url`api/import-bookmarks/`,
  metadata: url`api/metadata/${0}/`,
  defaultQuestions: url`api/default-questions/${0}/`,
  matchUrls: url`api/match-urls/`,
  matchUrlsPagination: url`api/match-urls/?page=${0}`,
  obtainAuthToken: url`api/token-auth/`,
  pageActivity: url`api/page-activity/`,
  pageGroup: url`api/page-group/`,
  pageGroupAll: url`api/page-group/all/`,
  passwordReset: url`api/password-reset/${0}/${1}/`,
  passwordResetRequest: url`api/password-reset-request/`,
  register: url`api/register/`,
  tags: url`api/tags/${0}`,
  pageTags: url`api/pages/${0}/tags/`,
  topicNotes: url`api/topic-note/${0}/`,
  topicAddTokens: url`api/topic/${0}/add-tokens/`,
  topicExportCSV: url`api/topic/${0}/export-csv/`,
  topicExportHTML: url`api/topic/${0}/export-html/`,
  topicExportText: url`api/topic/${0}/export-txt/`,
  homeExportCSV: url`api/topic/export-csv-home/?pages=${0}`,
  homeExportHTML: url`api/topic/export-html-home/?pages=${0}`,
  homeExportText: url`api/topic/export-txt-home/?pages=${0}`,
  importCSV: url`api/import-csv/`,
  importText: url`api/import-txt/`,
  importHTML: url`api/import-html/`,
  topics: url`api/topic/${0}/`,
  updateProfile: url`api/update-profile/`,
  pageTokens: url`api/page-tokens/`,
  paymentIntentCreate: url`api/payment-intent/create/`,
  updateSubscription: url`api/subscription/update/`,
  cancelSubscription: url`api/subscription/cancel/`,
  getInvoices: url`api/get-invoices/`,
  deleteAccount: url`api/delete-profile/`,
  reactivateSubscription: url`api/subscription/reactivate`,
};

export default ServerRouter;
