import React from 'react';
import styles from './styles.module.css';

type Props = {
  text?: string;
  loaderBody: Array<Array<number>>;
  withoutPadding?: boolean;
};

const SkeletonLoader = ({
  text = 'Fetching content....just a moment',
  loaderBody,
  withoutPadding,
}: Props) => {
  return (
    <div className={styles.loader} style={withoutPadding ? { padding: 0 } : {}}>
      <div className="body-text">{text}</div>
      {loaderBody.map((row, index) => (
        <div key={`loader-${index}`} className={styles.loaderRow}>
          {row.map((length, i) => (
            <>
              {!!row[i - 1] && !!length && <div className={styles.dot} />}
              <div
                key={`loader-${index}-${i}`}
                className={styles.loaderRowItem}
                style={!length ? { background: 'none' } : {}}
              />
            </>
          ))}
        </div>
      ))}
    </div>
  );
};

export default SkeletonLoader;
