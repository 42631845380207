import React, { useContext, useId, useMemo } from 'react';
import Article from '../../../data/models/Article';
import ArticleMetadata from '../../../data/models/ArticleMetadata';
import ArticlePreview from './ArticlePreview';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { ArticleViewTypeContext } from './ArticleView';

type Props = {
  article: Article;
  metadata?: ArticleMetadata;
  isPrint?: boolean;
};

const proxyURL = process.env.REACT_APP_CONTENT_PROXY_URL
  ? process.env.REACT_APP_CONTENT_PROXY_URL
  : window.location.hostname === 'staging.getsparks.io'
  ? 'https://corsproxy-staging.getsparks.workers.dev/?url='
  : 'https://corsproxy.getsparks.workers.dev/?url=';

export default function ArticleContent({ article, isPrint }: Props) {
  const [articleViewType] = useContext(ArticleViewTypeContext);
  const iframeTitle = useId();
  return useMemo(() => {
    if (articleViewType === 'preview') {
      return (
        <ArticlePreview
          text={article.page_content === 'HTML' ? article.html : article.text}
          isPrint={isPrint}
          pageContent={article.page_content}
          type="preview"
          id={article.id}
          url={article.url}
        />
      );
    } else if (articleViewType === 'text') {
      return (
        <ArticlePreview
          text={article.text}
          isPrint={isPrint}
          pageContent={article.page_content}
          type="text"
          id={article.id}
          url={article.url}
        />
      );
    }
    if (article.url)
      return <iframe src={proxyURL + article.url} width="100%" height="100%" title={iframeTitle} />;
    return <ErrorMessage children={"This article don't have URL"} />;
  }, [article, articleViewType, iframeTitle, isPrint]);
}
