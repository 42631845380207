import React, { useCallback } from 'react';
import Topic from '../../../data/models/Topic';
import { useAppSelector } from '../../../data/redux/hooks';
import { RootState } from '../../../data/redux/store';
import { useParams } from 'react-router-dom';
import { switchingDict } from '../../../components/buttons/icon/IconSwitchSidenavButton';
import { ReactComponent as Filter } from '../../../assets/img/icons/filter.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/delete.svg';
import { ReactComponent as SortIcon } from '../../../assets/img/icons/sort.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/img/icons/settings.svg';
import { ReactComponent as EditIcon } from '../../../assets/img/icons/edit.svg';
import { ReactComponent as ArrowDown } from '../../../assets/img/icons/file-arrow-down-solid 1.svg';
import { ReactComponent as ArrowUp } from '../../../assets/img/icons/file-arrow-up-solid 1.svg';
import SearchComponent from '../topnav/SearchComponent';
import { fillTopics, setShowSideNav } from '../../../data/redux/actions';
import useMenu from '../../../hooks/custom/useMenu';
import { Colors } from '../../../assets/colors';
import CardViewTypeSelect from '../../../components/dashboard/CardViewTypeSelect';
import TopicExport from './TopicExport';
import TopicImport from './TopicImport';
import { getTopicLightCRUD } from '../../../services/cruds';
import useConditionalLoader from '../../../hooks/custom/useConditionalLoader';
import ServerRouter from '../../../routers/ServerRouter';

type Props = {
  topic: Topic;
  children?: JSX.Element | null;
  className?: string;
  showActions?: boolean;
  articleTab: number;
  toggleArticleTab: () => void;
  showInterests: () => void;
};

export function TopicHeader({
  topic,
  children,
  className,
  showActions,
  showInterests,
  toggleArticleTab,
  articleTab,
}: Props) {
  const showSideNav = useAppSelector(
    useCallback((state: RootState) => state.cache.showSideNav, []),
  );
  const { topicId } = useParams();
  const optionsMenu = useMenu();
  const topics = useAppSelector(useCallback(state => state.cache.topics, []));
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
  const { setShouldFetch: setShouldFetchTopics, loader } = useConditionalLoader({
    onOk: fillTopics,
    defaultShouldFetch: false,
    url: ServerRouter.topics(),
  });
  const topicCRUD = getTopicLightCRUD(
    () => {
      setShouldFetchTopics(true);
    },
    topics,
    true,
  );

  return (
    <>
      {loader}
      <div
        className={`hstack flex-wrap pt-3 hide-mobile ${className}`}
        style={{ textAlign: 'left' }}
      >
        <div className="d-flex flex-1 gap-3 justify-content-between">
          <div className="sparks-h3">{topic.name}</div>
          <div className="d-flex">
            {showActions ? (
              <div className="d-flex gap-2">
                <CardViewTypeSelect />
                <button
                  className={`dashboard-options-button ${articleTab ? 'bg-blue-gray-3' : ''}`}
                  onClick={toggleArticleTab}
                >
                  <SortIcon height={12} width={13} fill="#000" />
                </button>
                <button className="dashboard-options-button" onClick={showInterests}>
                  <SettingsIcon height={13} width={13} fill="#000" />
                </button>
                <div className="position-relative">
                  <optionsMenu.Toggle className="dashboard-options-button " />
                  <optionsMenu.MenuContainer>
                    {!!topic.id && (
                      <topicCRUD.delete
                        formRef={ref => optionsMenu.placeRef(ref, 3)}
                        defaultValues={{ id: topic.id }}
                        validateFunction={() => {
                          const anyChildExists =
                            !!topic.child?.length && topic.child.some(childId => !!topics[childId]);
                          if (anyChildExists) {
                            return {
                              non_field_errors: ' Please remove “sub-topics” before deleting',
                            };
                          }
                        }}
                        actionButton={onClick => (
                          <optionsMenu.ItemContainer
                            onClick={onClick}
                            className="d-flex align-items-center"
                          >
                            <DeleteIcon width={16} height={16} fill={Colors.blueGray00} />
                            <span className="body-text-sm mt-1 lh-1">Delete</span>
                          </optionsMenu.ItemContainer>
                        )}
                      />
                    )}
                    {!!topic.id && (
                      <topicCRUD.patch
                        formRef={ref => optionsMenu.placeRef(ref, 4)}
                        defaultValues={{ ...topic, owner: user?.id }}
                        title="Edit topic"
                        actionButton={onClick => {
                          return (
                            <optionsMenu.ItemContainer
                              onClick={onClick}
                              className="d-flex align-items-center"
                            >
                              <EditIcon width={16} height={16} />
                              <span className="body-text-sm mt-1 lh-1">Rename</span>
                            </optionsMenu.ItemContainer>
                          );
                        }}
                      />
                    )}

                    <TopicExport
                      topic={topic}
                      formRef={ref => optionsMenu.placeRef(ref, 1)}
                      actionButton={onClick => (
                        <optionsMenu.ItemContainer
                          onClick={onClick}
                          className="d-flex align-items-center"
                        >
                          <ArrowDown width={16} height={20} />
                          <span className="body-text-sm mt-1 lh-1">Export</span>
                        </optionsMenu.ItemContainer>
                      )}
                    />
                    <TopicImport
                      topic={topic}
                      formRef={ref => optionsMenu.placeRef(ref, 2)}
                      actionButton={onClick => (
                        <optionsMenu.ItemContainer
                          onClick={onClick}
                          className="d-flex align-items-center"
                        >
                          <ArrowUp width={16} height={20} fill="#9197A1" />
                          <span className="body-text-sm mt-1 lh-1">Import</span>
                        </optionsMenu.ItemContainer>
                      )}
                    />
                  </optionsMenu.MenuContainer>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="only-mobile d-flex gap-2">
        <SearchComponent selectedTopicId={topicId ? +topicId : undefined} />
        <button
          className="sparks-button article-button article-button-icon"
          onClick={() => setShowSideNav(switchingDict[showSideNav])}
        >
          <Filter width={16} height={12} />
        </button>
      </div>
    </>
  );
}
