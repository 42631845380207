import React, { useMemo } from 'react';
import Article from '../../../../data/models/Article';
import moment from 'moment';
import DiscoverArticle from '../../../../data/models/DiscoverArticle';
import DiscoverArticleCardView from '../articles/DiscoverArticleCardView';
import Topic from '../../../../data/models/Topic';
import ArticleCardView from '../articles/ArticleCardView';
import useTopicArticlesWithFilters from '../../../../hooks/custom/useTopicArticlesWithFilters';

type Props = {
  discoverArticles?: (DiscoverArticle | undefined)[];
  isDiscover?: boolean;
  topic: Topic;
  list?: boolean;
};

const TopicTimeline = ({ discoverArticles, isDiscover, topic, list }: Props) => {
  const articles = useTopicArticlesWithFilters(topic);
  const articlesGroupByDate = useMemo(() => {
    type GroupItem = {
      items: (Article | DiscoverArticle)[];
      label: string;
    };
    const grouped: {
      thisWeek: GroupItem;
      lastWeek: GroupItem;
      older: GroupItem;
    } = {
      thisWeek: { items: [], label: 'This week' },
      older: { items: [], label: 'Older' },
      lastWeek: { items: [], label: 'Last week' },
    };

    const groupArticles = (articles: (Article | DiscoverArticle | undefined)[]) => {
      articles.forEach(article => {
        if (!article) return;
        const current = new Date();
        const firstWeekDay = moment(
          new Date(current.setDate(current.getDate() - current.getDay())),
        );
        const discoverArticle = article as DiscoverArticle;
        const articleDate = isDiscover ? discoverArticle.date : article.created_at;
        const articleCreatedAt = moment(articleDate);
        const diff = firstWeekDay.diff(articleDate, 'weeks');
        if (firstWeekDay <= articleCreatedAt) {
          grouped.thisWeek.items.push(article);
        } else if (diff === 0) {
          grouped.lastWeek.items.push(article);
        } else {
          grouped.older.items.push(article);
        }
      });
    };

    if (articles) groupArticles(articles);
    if (discoverArticles) groupArticles(discoverArticles);

    return grouped;
  }, [articles, discoverArticles, isDiscover]);
  return (
    <div className={`row gx-5 justify-content-center`}>
      {[articlesGroupByDate.thisWeek, articlesGroupByDate.lastWeek, articlesGroupByDate.older].map(
        ({ label, items }) => {
          return items.length ? (
            <div key={`timeline-${label}`}>
              <div className="input-label-text text-gray-0 text-align-start mb-4">{label}</div>
              <div className={`${list ? 'topic-list-articles' : 'articles-grid'}`}>
                {items.map(article => {
                  if (isDiscover) {
                    const discoverArticle = article as DiscoverArticle;
                    return (
                      <DiscoverArticleCardView
                        key={`timeline-${article.id}`}
                        discoverArticle={discoverArticle}
                        topic={topic}
                      />
                    );
                  }
                  return (
                    <ArticleCardView key={`timeline-${article.id}`} article={article as Article} />
                  );
                })}
              </div>
            </div>
          ) : null;
        },
      )}
    </div>
  );
};

export default TopicTimeline;
