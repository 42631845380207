import React from 'react';
import {Col, Row} from "react-bootstrap";
import AddToChrome from "../../components/links/AddToChrome";
import logo from "../../assets/img/favicon.png";
import tabsImage from "../../assets/img/lp/tabs.jpg";
import ExtensionLP from "./ExtensionLP";
import LandingNav from "../../components/nav/LandingNav";

type Props = {
  imgSrc?: string,
  title?: string,
  imgTitle?: string,
}

export default function ExtensionLP1({imgSrc, title, imgTitle}: Props) {
  return <ExtensionLP>
    <LandingNav/>
    <section className={'bg-white'}>
      <Row className={'p-5 px-5 hstack'} direction={"horizontal"}>
        <Col xs={12} md={6} className={'my-3 text-center'}>
          <h1 className={'text-wrap text-xl'}>
            {title ?? 'Save interesting stories now and access later'}
          </h1>
          <h3 className={'fw-normal text-wrap p-3'}>
            No need to send links to yourself.
            <br/>
            Click
            <span> <img alt={''} src={logo} width={16}/> </span>
            in your browser to save the page.
          </h3>
          <div><AddToChrome style={{fontSize: 32}}/></div>
        </Col>
        <Col xs={12} md={6} className={'my-3 text-center'}>
          <h1>{imgTitle ?? 'Save from any source'}</h1>
          <img alt={''} src={imgSrc ?? tabsImage}
               style={{maxWidth: '75%', height: 'auto', borderRadius: '10px', boxShadow: '0px 0px 20px grey'}}/>
        </Col>
      </Row>
    </section>
  </ExtensionLP>
}
