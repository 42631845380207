import {IField} from "../../components/crud/fields/Field";
import Note from "./Note";

export default class ArticleNote extends Note {
  static fields = {
    ...Note.fields,
    page: {name: "page", hidden: true} as IField,
  };
  readonly page?: number;

  constructor(jsonData: Partial<ArticleNote>) {
    super(jsonData);
    Object.assign(this, jsonData);
  }
}
