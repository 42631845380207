import React from 'react';
import useMenu from '../hooks/custom/useMenu';
import ArticleInfoCard from './ArticleInfoCard';

type Props = {
  title?: string;
  body: string;
  page?: number;
  menu?: JSX.Element;
  printRef?: React.RefObject<HTMLDivElement>;
};

const SummaryItem = ({ title, body, page, menu, printRef }: Props) => {
  const { toggleMenu, MenuContainer, placeRef } = useMenu();
  return (
    <ArticleInfoCard
      body={body}
      containerRef={printRef}
      page={page}
      toggleRef={ref => placeRef(ref, 0)}
      toggleMenu={toggleMenu}
      title={title}
      dotsClassName="hide-mobile"
      menu={<MenuContainer>{menu}</MenuContainer>}
    />
  );
};

export default SummaryItem;
