import TimeStampedModel from './TimeStampedModel';
import { IField } from '../../components/crud/fields/Field';
import { TaskStatus } from '../../services/utils';

export type Answer = {
  answer: string;
  score: number;
};

export default class Question extends TimeStampedModel {
  static fields = {
    question: {
      name: 'question',
      type: 'text',
    } as IField,
    page: {
      name: 'page',
      hidden: true,
    } as IField,
  };

  readonly question?: string;
  readonly id?: number;
  readonly created_at?: string;
  readonly topic?: number;
  readonly updated_at?: string;
  readonly task_result?: TaskStatus;
  readonly user?: number;
  readonly answers?: {
    answer: string;
    created_at: string;
    id: number;
    page_id: number;
    question_id: number;
    updated_at: string;
  }[];

  constructor(jsonData: Partial<Question>) {
    super(jsonData);
    Object.assign(this, jsonData);
  }
}
