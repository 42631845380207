import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.css';
import LandingNav from '../../components/nav/LandingNav';
import { Link } from 'react-router-dom';
import ClientRouter from '../../routers/ClientRouter';
import DiscoverImg from '../../assets/img/lp/discover-bw.png';
import NetworkJSONArrayLoader from '../../components/network/NetworkJSONArrayLoader';
import ServerRouter from '../../routers/ServerRouter';
import { fillPageGroups, fillTopics } from '../../data/redux/actions';
import { useAppSelector } from '../../data/redux/hooks';
import { RootState } from '../../data/redux/store';
import PageGroup from '../../data/models/PageGroup';
import InterestsItem from '../../components/discover/InterestsItem';
import useFetchMatchUrls from '../../hooks/custom/useFetchMatchUrls';
import HorizontalScroll from '../../components/HorizontalScroll';
import DiscoverArticleCardView from '../dashboard/topic/articles/DiscoverArticleCardView';
import LandingFooter from '../../components/footer/LandingFooter';
import MetaTagsList from '../../components/MetaTagsList';

const Discover = () => {
  const [selectedPageGroup, setSelectedPageGroup] = useState<PageGroup>();
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
  const pageGroups = useAppSelector(
    useCallback(
      (state: RootState) =>
        Object.values(state.cache.pageGroups)
          .filter(pageGroup => !!pageGroup)
          .sort((a, b) => (a?.name || '').localeCompare(b?.name || '')),
      [],
    ),
  ) as PageGroup[];
  const topics = useAppSelector(useCallback(state => Object.values(state.cache.topics), []));
  const defaultTopic = useMemo(() => topics.find(topic => topic?.name === 'Default'), [topics]);
  const pageGroupsLoader = useMemo(
    () => <NetworkJSONArrayLoader url={ServerRouter.pageGroupAll()} onOk={fillPageGroups} />,
    [],
  );
  const topicsLoader = useMemo(
    () => <NetworkJSONArrayLoader url={ServerRouter.topics()} onOk={fillTopics} hidden />,
    [],
  );

  const { fetchDiscoveryArticles, discoverArticles } = useFetchMatchUrls({
    urls: [],
    preferences: selectedPageGroup?.id ? [selectedPageGroup.id] : [],
    tokens: [],
    page: 1,
  });

  useEffect(() => {
    fetchDiscoveryArticles(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPageGroup]);

  return (
    <div className={styles.container + ' discover'}>
      <MetaTagsList type="home" />
      <LandingNav
        buttons={
          user
            ? [
                <Link to={ClientRouter.login()}>
                  <button className={'button-variant-1 mr-0 mx-3'}>Dashboard</button>
                </Link>,
              ]
            : [
                <Link to={ClientRouter.register()} className="text-blue-2 fw-700">
                  Sign Up
                </Link>,
                <Link to={ClientRouter.login()}>
                  <button className={'lp-header-btn mr-0 px-3 mx-3 button-reset-defaults'}>
                    Login
                  </button>
                </Link>,
              ]
        }
      />
      <div className={styles.inner}>
        <div className={'save-bookmarks px-lg-15 py-lg-10'}>
          <img className="save-bookmarks-image" src={DiscoverImg} alt="loading..." />
          <div className={'save-bookmarks-body mt-4 mt-lg-0'}>
            <h2 className="m-0 sparks-h2 fw-700 text-black">
              Save bookmarks {selectedPageGroup?.name ? `for ${selectedPageGroup.name} ` : ''}to
              read later
            </h2>
            <p className="m-0 body-text-max text-black mb-5 mt-4">
              Add articles, videos, and links from across the web in getSparks to personalize your
              collection Use our app to enjoy them on any device in a distraction-free environment.
            </p>
            <Link
              to={ClientRouter.register()}
              className={styles.signUpButton + ' text-decoration-none'}
            >
              <button className="button-variant-1 button-variant-1-lg">Sign Up</button>
            </Link>
          </div>
        </div>
        <div className={styles.divider + ' mt-0'}></div>
        {topicsLoader}
        <div className={styles.topics + ' px-lg-15'}>
          <h1 className="sparks-h1-text text-center mb-5">
            Discover Topics
            {selectedPageGroup?.name ? (
              <>
                : <span className="text-purple-1">{selectedPageGroup.name}</span>
              </>
            ) : null}
          </h1>
          <HorizontalScroll withArrows scrollClassName={styles.scroll}>
            {pageGroups.length
              ? pageGroups.map(pageGroup => (
                  <InterestsItem
                    withoutMaxWidth
                    item={pageGroup}
                    selectedItemsIds={selectedPageGroup?.id ? [selectedPageGroup.id] : []}
                    onItemSelected={() => setSelectedPageGroup(pageGroup)}
                    withoutMargin
                    key={`${pageGroup.id}-page-group`}
                  />
                ))
              : pageGroupsLoader}
          </HorizontalScroll>
        </div>
        <div className={styles.feed + ' px-lg-15 mt-5'}>
          <HorizontalScroll>
            {!!discoverArticles.length && (
              <div className="d-flex align-items-center gap-4">
                <div className={styles.largeCard}>
                  <DiscoverArticleCardView
                    discoverArticle={discoverArticles[0]}
                    topic={defaultTopic}
                    large
                  />
                </div>
                <div style={{ maxHeight: 660 }} className="d-flex flex-column flex-wrap gap-3">
                  {discoverArticles.slice(1, 21).map(discoverArticle => (
                    <div style={{ maxWidth: 272 }} key={`${discoverArticle.id}-discover-article`}>
                      <DiscoverArticleCardView
                        discoverArticle={discoverArticle}
                        topic={defaultTopic}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </HorizontalScroll>
        </div>
        <div className={styles.divider + ' mb-0'}></div>
        <div className="bg-gray-5">
          <div className={styles.subFooter}>
            <h3 className={styles.h3 + ' m-0 text-center text-black mb-3'}>
              Save bookmarks for pages you want to research later
            </h3>
            <p className="body-text-max text-dark-gray mb-5 text-center">
              Add articles, videos, and links from across the web in getSparks to personalize your
              collection Use our app to enjoy them on any device in a distraction-free environment.
            </p>
            <Link to={ClientRouter.register()}>
              <button className="button-variant-1 button-variant-1-lg">Sign Up</button>
            </Link>
          </div>
        </div>
      </div>
      <LandingFooter />
    </div>
  );
};

export default Discover;
