import React, { useMemo, useRef, useState } from 'react';
import { TopicViewProps } from '../TopicView';
import Topic from '../../../../data/models/Topic';
import ServerRouter from '../../../../routers/ServerRouter';
import { notEmpty } from '../../../../data/redux/utils';
import TopicPage from '../TopicPage';
import NetworkJSONLoader from '../../../../components/network/NetworkJSONLoader';
import Article from '../../../../data/models/Article';

type Props = {
  query: string;
} & TopicViewProps;

export function ArticlesSearchResults({ query }: Props) {
  const [results, setResults] = useState<{ query: string; pages: number[] }>();
  const queryRef = useRef<string>();
  queryRef.current = query;
  const args = useMemo(() => {
    let json: { [x: string]: string } = {};
    query.split('&').forEach(value => {
      const parts = value.split('=');
      if (parts.length === 2) {
        json[parts[0]] = parts[1];
      }
    });
    return json;
  }, [query]);
  const children = useMemo(() => {
    if (results?.query !== query) {
      return (
        <NetworkJSONLoader
          children={'Searching'}
          url={ServerRouter.articles() + '?' + query}
          onOk={({ results }) => {
            if (query === queryRef.current) {
              setResults({
                query: query,
                pages: ((results as Article[]) ?? []).map(article => article.id).filter(notEmpty),
              });
            }
          }}
        />
      );
    }
    return (
      <div className={'vstack'}>
        No articles found for the keyword "{args['search']}", please try again by changing search
        filter or new keyword.
      </div>
    );
  }, [args, query, results?.query]);
  return useMemo(() => {
    const pages =
      results && results.query === queryRef.current && results.pages.length
        ? results.pages
        : undefined;
    return (
      <TopicPage
        blockAdding={true}
        topic={new Topic({ name: 'Search results', pages: pages })}
        children={children}
      />
    );
  }, [children, results]);
}
