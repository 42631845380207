import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.css';
import { useAppSelector } from '../../data/redux/hooks';
import { RootState } from '../../data/redux/store';
import { ReactComponent as CloseIcon } from '../../assets/img/icons/close.svg';
import PageGroup from '../../data/models/PageGroup';
import { TokenGroup, tokenGroupNameByKey, TopicToken } from '../../data/models/Topic';
import InterestItemsList from './InterestItemsList';

type Props = {
  short?: boolean;
  visible: boolean;
  onClose: () => void;
  closeButtonType?: 'skip' | 'icon';
  submitButtonPosition?: 'top' | 'bottom';
  submitButtonLabel?: string;
  submitButtonClassName?: string;
  title?: string;
  description?: string;
  onSubmit: (pageGroups: number[], tokens: number[]) => void;
  defaultPageGroups?: number[];
  defaultTokens?: number[];
  pageGroups?: PageGroup[];
  tokens?: TopicToken[];
};

export const shortInterestSelectCommonProps = {
  title: 'Customize Your Interests',
  description:
    'Your saved content will be displayed here, start adding content to generate summary.',
  closeButtonType: 'icon',
  submitButtonPosition: 'top',
  submitButtonLabel: 'Save',
  short: true,
} as Partial<Props>;

const InterestsSelect = ({
  visible,
  onClose,
  closeButtonType = 'skip',
  submitButtonLabel = 'Continue',
  submitButtonPosition = 'bottom',
  submitButtonClassName,
  title,
  description,
  onSubmit,
  short,
  defaultPageGroups,
  defaultTokens,
  pageGroups,
  tokens,
}: Props) => {
  const [selectedPageGroupsIds, setSelectedPageGroupsIds] = useState<number[]>([]);
  const [selectedTokensIds, setSelectedTokensIds] = useState<number[]>([]);

  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));

  const onItemSelected = useCallback((id: number, type: 'page-group' | 'token' = 'page-group') => {
    if (type === 'page-group') {
      setSelectedPageGroupsIds(prev =>
        prev.includes(id) ? prev.filter(prevId => prevId !== id) : [...prev, id],
      );
    } else {
      setSelectedTokensIds(prev =>
        prev.includes(id) ? prev.filter(prevId => prevId !== id) : [...prev, id],
      );
    }
  }, []);

  const onPageGroupSelected = useCallback(
    (id: number) => onItemSelected(id, 'page-group'),
    [onItemSelected],
  );
  const onTokenSelected = useCallback(
    (id: number) => onItemSelected(id, 'token'),
    [onItemSelected],
  );

  const tokensByGroups = useMemo(() => {
    if (tokens) {
      const groups: { [group: string]: TopicToken[] } = {};
      tokens.forEach(token => {
        if (!token.group) return;
        if (groups[token.group]) {
          groups[token.group].push(token);
        } else {
          groups[token.group] = [token];
        }
      });
      return groups;
    }
    return null;
  }, [tokens]);

  const handleSubmit = useCallback(() => {
    onSubmit(selectedPageGroupsIds, selectedTokensIds);
  }, [selectedPageGroupsIds, selectedTokensIds, onSubmit]);

  useEffect(() => {
    if (defaultPageGroups) {
      setSelectedPageGroupsIds(defaultPageGroups);
    }
  }, [defaultPageGroups]);

  useEffect(() => {
    if (defaultTokens) {
      setSelectedTokensIds(defaultTokens);
    }
  }, [defaultTokens]);

  const submitButton = (
    <button
      onClick={handleSubmit}
      className={
        'sparks-button article-button article-button--primary align-self-start ' +
        submitButtonClassName
      }
    >
      {submitButtonLabel}
    </button>
  );

  return (
    <>
      <div
        className={
          styles.onboarding + ` ${short ? styles.short : ''} ${visible ? styles.active : ''}`
        }
      >
        <div className={styles.onboarding__inner}>
          <div className="position-relative">
            <div className="d-flex justify-content-between">
              <h1 className="sparks-h1-text text-align-start">
                {title || `Welcome to getSparks${user?.first_name ? `, ${user.first_name}` : ''}!`}
              </h1>
              <div className="d-flex gap-2" style={{ maxHeight: 40 }}>
                {submitButtonPosition === 'top' && submitButton}
                {closeButtonType === 'skip' ? (
                  <button
                    onClick={onClose}
                    className="sparks-button article-button article-button--sm fw-500 bg-blue-gray-3 border-0 py-2"
                  >
                    Skip
                  </button>
                ) : (
                  <button className="sparks-button article-button fw-500" onClick={onClose}>
                    <CloseIcon fill="#000" width={9} height={9} />
                  </button>
                )}
              </div>
            </div>
            <p className="body-text text-gray-0 text-align-start">
              {description ||
                'Help us craft a relevant and personalized experience for you, by picking a few interests of yours.'}
            </p>
            {!!pageGroups && (
              <InterestItemsList
                items={pageGroups}
                selectedItemsIds={selectedPageGroupsIds}
                onItemSelected={onPageGroupSelected}
                withoutExpanding={short}
              />
            )}
            {!!tokens?.length &&
              !!tokensByGroups &&
              Object.entries(tokensByGroups).map(([group, tokens]) => (
                <div className="mt-4">
                  <div className="sparks-h3 text-align-start">
                    {tokenGroupNameByKey[group as TokenGroup]}
                  </div>
                  <InterestItemsList
                    items={tokens.map(token => ({ id: token.id, name: token.value }))}
                    selectedItemsIds={selectedTokensIds}
                    onItemSelected={onTokenSelected}
                    withoutExpanding
                  />
                </div>
              ))}
            {submitButtonPosition === 'bottom' && <div className="d-flex mt-4">{submitButton}</div>}
          </div>
        </div>
      </div>
      {visible && <div className={styles.overlay} onClick={onClose} />}
    </>
  );
};

export default InterestsSelect;
