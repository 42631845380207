import { CRUDOnOkParam } from '../../components/crud/CRUD';
import TagManager from 'react-gtm-module';
import { getCookie, getHeaders } from '../../routers/utils';
import { setIsSignedUp, setToken, setTokenId, setUser } from './actions';
import useCheckExtensionInstalled from '../../hooks/custom/useCheckExtensionInstalled';
import ServerRouter from '../../routers/ServerRouter';

export const login = (json: CRUDOnOkParam) => {
  if (json && typeof json !== 'number' && json.token) {
    if (json.new_registration) {
      TagManager.dataLayer({ dataLayer: { event: 'newRegistration' } });
      setIsSignedUp(true);
    }
    document.cookie = `token=${json.token};path=/;`;
    setTokenId(undefined);
    setToken(json.token);
    return json.token;
  } else {
    console.warn('Wrong login token response:', json);
  }
};

export const register = (json: CRUDOnOkParam) => {
  if (json && typeof json !== 'number' && json.token) {
    if (!json.new_registration) {
      TagManager.dataLayer({ dataLayer: { event: 'newRegistration' } });
      setIsSignedUp(true);
    }
    localStorage.setItem('after-register', 'true');
    return login(json);
  } else {
    console.warn('Wrong register token response:', json);
  }
};

export const authorize = (json: CRUDOnOkParam) => {
  if (json && typeof json !== 'number' && json.email) {
    setUser(json);
    setToken(undefined);
  } else {
    console.warn('Wrong login profile response:', json);
  }
};

export function reloadProfile() {
  setToken(getCookie('token'));
}

export function useUpdateHasExtensionFlag() {
  const isExtensionInstalled = useCheckExtensionInstalled();
  return (callback: (...args: any[]) => unknown) =>
    (...args: any[]) => {
      callback(...args);
      const headers = getHeaders();
      fetch(ServerRouter.updateProfile(), {
        method: 'PATCH',
        headers,
        credentials: 'include',
        body: JSON.stringify({
          has_extension: isExtensionInstalled,
        }),
      }).catch(err => {
        console.warn('has_extension update error ', err);
      });
    };
}
