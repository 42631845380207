import React, { useCallback, useState } from 'react';
import styles from './styles.module.css';
import InterestsItem from './InterestsItem';

type Props = {
  items: {
    id?: number;
    name?: string;
  }[];
  selectedItemsIds: number[];
  onItemSelected: (id: number) => void;
  withoutExpanding?: boolean;
};

const InterestItemsList = ({
  items,
  selectedItemsIds,
  onItemSelected,
  withoutExpanding,
}: Props) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = useCallback(() => setExpanded(prev => !prev), []);

  return (
    <div className={styles.onboarding__items}>
      {(expanded || withoutExpanding ? items : items.slice(0, 10)).map(token =>
        token ? (
          <InterestsItem
            key={`token-${token.id}`}
            item={token}
            selectedItemsIds={selectedItemsIds}
            onItemSelected={onItemSelected}
          />
        ) : null,
      )}
      {!withoutExpanding && items.length >= 10 && (
        <div className={styles.onboarding__item} onClick={toggleExpanded}>
          <div className="sparks-h4 text-black lh-1 text-purple-1">
            {expanded ? 'Less' : 'More'}
          </div>
        </div>
      )}
    </div>
  );
};

export default InterestItemsList;
