import React from 'react';
import {DropdownButton} from "react-bootstrap";
import {DropdownButtonProps} from "react-bootstrap/DropdownButton";
import Tooltip, {TooltipProps} from "../../Tooltip";

export type IconDropdownButtonProps = {
  tooltip?: TooltipProps;
} & DropdownButtonProps;

export function IconDropdownButton({tooltip, ...rest}: IconDropdownButtonProps) {
  const dropdown = <DropdownButton className={'no-caret'} variant={'icon'} size="sm" {...rest}
                                   children={rest.children}/>;
  return tooltip ? <Tooltip {...tooltip}>{dropdown}</Tooltip> : dropdown;
}
