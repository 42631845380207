import React from 'react';
import {appendUTMSource} from "../../routers/utils";

type AProps = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

export type ExternalLinkProps = {
  to: string,
} & AProps;

export default function ExternalLink({to, children, ...aProps}: ExternalLinkProps) {
  return <a rel="noreferrer" target={"_blank"} href={appendUTMSource(to)} {...aProps}>
    {children ?? (new URL(to).hostname)}
  </a>
}
