import { Link } from 'react-router-dom';
import ClientRouter from '../../../routers/ClientRouter';
import RoundedBorder from '../../../components/RoundedBorder';
import React from 'react';

type Props = {
  children?: React.ReactNode;
  signUpLabel?: string;
};

export default function TryOutSection({ children, signUpLabel = 'Sign up for getSparks' }: Props) {
  return (
    <section className={'bg-white py-10'}>
      <div className={'container px-5'}>
        <div className={'row gx-5 justify-content-center'}>
          <div className={'col-xl-6 col-lg-8 col-md-10 text-center'}>
            <h2>Ready to get Started?</h2>
            <p className={'lead text-gray-500 mb-4'}>Try out getSparks for free.</p>
            {children ?? (
              <div className="d-flex justify-content-center">
                <Link className="main-cta-btn" to={ClientRouter.register()}>
                  {signUpLabel}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <RoundedBorder className={'text-dark'} />
    </section>
  );
}
