import React, { ReactNode } from 'react';
import DefaultLayout from './components/DefaultLayout';
import LandingNav from '../../components/nav/LandingNav';
import LandingHeader from './components/LandingHeader';
import FeaturesSection from './components/FeaturesSection';
import CasesSection from './components/CasesSection';
import TryOutSection from './components/TryOutSection';
import { Card } from 'react-bootstrap';
import toolbarImage from '../../assets/img/extension/toolbar.jpg';
import AddToChrome from '../../components/links/AddToChrome';
import MetaTagsList from '../../components/MetaTagsList';

export default function ExtensionLP({ children }: { children?: ReactNode }) {
  return (
    <DefaultLayout>
      <MetaTagsList type="home" />
      {children ?? (
        <>
          <LandingNav buttons={<></>} />
          <LandingHeader>
            <div>
              <h1 className={'page-header-ui-title'}>
                Organize & Analyze Content that Sparks Your Interest
              </h1>
            </div>
            <Card className={'p-4'}>
              <div className={'d-flex justify-content-evenly align-items-start flex-wrap'}>
                <img src={toolbarImage} alt="toolbar" className={'img-fluid'} />
                <div>
                  <h1>Save to getSparks from your Computer</h1>
                  <div className={'w-100 px-5'}>
                    <AddToChrome className={'btn btn-primary w-100'} style={{ fontSize: 32 }} />
                  </div>
                </div>
              </div>
            </Card>
          </LandingHeader>
        </>
      )}
      <FeaturesSection />
      <CasesSection />
      <TryOutSection>
        <AddToChrome />
      </TryOutSection>
    </DefaultLayout>
  );
}
