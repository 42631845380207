import React, { useEffect, useMemo, useState } from 'react';
import NetworkJSONLoader from '../../components/network/NetworkJSONLoader';
import ServerRouter from '../../routers/ServerRouter';
import { TaskStatus } from '../../services/utils';
import { setArticleMetadata } from '../../data/redux/actions';
import Article from '../../data/models/Article';

const useArticleMetadataFetch = (article: Article) => {
  const [shouldFetchMetadata, setShouldFetchMetadata] = useState(false);
  const [shouldRetry, setShouldRetry] = useState(false);
  const [loading, setLoading] = useState(true);

  const refetchMetadata = () => setShouldFetchMetadata(true);

  const metadataLoader = useMemo(() => {
    setShouldRetry(false);
    return shouldFetchMetadata ? (
      <NetworkJSONLoader
        url={ServerRouter.metadata(article.metadata)}
        hidden
        timeout={5000}
        onOk={res => {
          setShouldRetry(
            !!res.task_result &&
              res.task_result !== TaskStatus.success &&
              res.task_result !== TaskStatus.error,
          );
          setLoading(!res.task_result || res.task_result === TaskStatus.pending);
          setArticleMetadata(res);
          setShouldFetchMetadata(false);
        }}
        onError={errorText => {
          console.log(errorText);
          setShouldFetchMetadata(false);
        }}
      />
    ) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchMetadata]);

  useEffect(() => {
    if (shouldRetry) {
      setShouldFetchMetadata(true);
    }
  }, [shouldRetry]);

  useEffect(() => {
    if (article.metadata) {
      setShouldFetchMetadata(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article.metadata]);

  return { metadataLoader, refetchMetadata, loading };
};

export default useArticleMetadataFetch;
