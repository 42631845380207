import {DeleteReducer} from "../redux/utils";

export interface JSONObject {
  [x: string]: any
}

export default class Model {
  readonly id?: number;
  readonly __data: JSONObject;

  constructor(jsonData: JSONObject) {
    this.__data = jsonData;
    this.id = jsonData["id"];
  }

  delete: DeleteReducer = () => {
  };
}
