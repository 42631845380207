import React from 'react';
import {Card, Stack} from "react-bootstrap";
import TextShortener from "../../../../components/TextShortener";
import {Check} from "react-feather";
import {Tab} from "../../../../hooks/custom/useOpenTabs";
import PostButton from "../../../../components/buttons/text/PostButton";
import NetworkJSONLoader from "../../../../components/network/NetworkJSONLoader";
import ServerRouter from "../../../../routers/ServerRouter";
import {setArticle} from "../../../../data/redux/actions";

type Props = {
  tab: Tab,
  articlesURLs: Set<string>,
};

export default function ExtensionAddTab({articlesURLs, tab}: Props) {
  const [error, setError] = React.useState<string | undefined>('');
  return (
    <Card className={'py-3'}>
      {error && <div className={'mx-3 text-red'}>{error}</div>}
      <Stack direction={'horizontal'}>
        <img className={'m-2'} style={{width: 32}} src={tab.favicon} alt={''}/>
        <div className={'flex-grow-1'}>
          <Card.Title className={'text-sm'}>
            <TextShortener lines={2}>
              {tab.title}
            </TextShortener>
          </Card.Title>
          <Card.Text className={'small'}>
            <TextShortener lines={1}>
              {tab.url}
            </TextShortener>
          </Card.Text>
        </div>
        {articlesURLs.has(tab.url)
          ? <Check className={'mx-3 flex-shrink-0'}/>
          : error === undefined
            ? <div className={'mx-3'}>
              <NetworkJSONLoader
                url={ServerRouter.articles()}
                onOk={setArticle}
                onError={(errorText, json) => {
                  setError(json?.url ?? errorText);
                }}
                method={'POST'}
                body={{url: tab.url}}/>
            </div>
            : <PostButton className={'flex-shrink-0 mx-3'} onClick={() => {
              setError(undefined);
            }}/>}
      </Stack>
    </Card>
  );
}
