import { useAppSelector } from '../../data/redux/hooks';
import { useCallback, useMemo } from 'react';

const useAllTopicParents = (topicId?: number | null) => {
  const topics = useAppSelector(useCallback(state => state.cache.topics, []));
  return useMemo(() => {
    if (!topicId) return [];
    const topicParents: number[] = [];
    const setAllParents = (id: number) => {
      const parent = topics[id]?.parent;
      if (parent) {
        topicParents.push(parent);
        setAllParents(parent);
      }
    };
    setAllParents(topicId);
    return topicParents;
  }, [topics, topicId]);
};

export default useAllTopicParents;
