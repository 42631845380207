import React, { useEffect, useMemo, useState } from 'react';
import styles from './style.module.css';
import { Stack } from 'react-bootstrap';
import useCheckExtensionInstalled from '../../../hooks/custom/useCheckExtensionInstalled';
import ExtensionAlert from './alerts/ExtensionAlert';
import useBrowser from '../../../hooks/custom/useBrowser';
import { ReactComponent as Bell } from '../../../assets/img/icons/notification.svg';
import { useSearchParams } from 'react-router-dom';

const Alert = ({ title, desc }: { title: string; desc?: string }) => {
  return (
    <Stack className={'p-3 d-none d-md-block'}>
      <p>
        <b>{title}</b>
      </p>
      {!!desc && (
        <p className={'small'}>
          The browser extension makes it easier to save web content while you browse.
        </p>
      )}
    </Stack>
  );
};

export default function AlertsCenter() {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(prev => !prev);

  const extensionInstalled = useCheckExtensionInstalled();
  const browser = useBrowser();
  const [searchParams, setSearchParams] = useSearchParams();
  const notification = useMemo(() => {
    const notificationParam = searchParams.get('notification');
    switch (notificationParam) {
      case 'page-add-error':
        return { title: 'Something went wrong, please try again…' };
      case 'invalid-link':
        return { title: 'Invalid URL', desc: 'Please check page URL and add again.' };
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const alerts = [];

  if (notification) {
    alerts.push(<Alert {...notification} />);
  } else if (!extensionInstalled && browser === 'chrome') {
    alerts.push(<ExtensionAlert />);
  }

  useEffect(() => {
    if (notification) {
      setShow(true);
      setSearchParams({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  return (
    <li className={'nav-item dropdown no-caret dropdown-notifications'}>
      <section className="article-button pointer" onClick={toggleShow}>
        <Bell fill="#000" width={15} height={15} />
      </section>
      {show && (
        <div className={styles.alert + ' shadow'}>
          <div className={styles.alertHeader}>
            <Bell fill="#fff" width={13} height={13} />
            Alerts Center
          </div>
          <div className="bg-white">
            {alerts.map((alert, i) => (
              <div key={`alert-${i}`} className="text-align-start">
                {alert}
                {i + 1 !== alerts.length && <div className="divider" />}
              </div>
            ))}
          </div>
        </div>
      )}
    </li>
  );
}
