import React from 'react';
import LandingNav from '../../components/nav/LandingNav';
import CasesSection from './components/CasesSection';
import FeaturesSection from './components/FeaturesSection';
import TestimonialsSection from './components/TestimonialsSection';
import TryOutSection from './components/TryOutSection';
import LandingFooter from '../../components/footer/LandingFooter';
import SummaryGifImage from '../../assets/img/extension/summary.gif';
import MetaTagsList from '../../components/MetaTagsList';

const SummaryGIF = () => {
  return (
    <div>
      <MetaTagsList type="home" />
      <LandingNav />
      <div className="bg-white z-2 pt-4">
        <div className="container bg-white">
          <div className="card bg-transparent border-0 shadow-none">
            <div className="row g-0 hstack align-items-start">
              <div className="col-md-6 px-5 pl-0 d-flex flex-column">
                <p style={{ fontSize: 20 }}>
                  getSparks summarize web articles, and text in consise, easy to digest content so
                  you can free yourself from information overload.
                </p>
                <p className="mb-5" style={{ fontSize: 20 }}>
                  Add getSparks browser extension to save web page and view page summary.
                </p>
                <a
                  href="https://chrome.google.com/webstore/detail/add-to-getsparks/dimadmjcopdjkklcmfomakopebflpbal?utm_source=getsparks.io_bookmark"
                  className="btn btn-green btn-xl m-auto"
                  rel="noreferrer"
                  target="_blank"
                >
                  getSparks - its free!
                </a>{' '}
              </div>
              <img src={SummaryGifImage} className="col-md-6" alt="loading..." />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-5">
        <FeaturesSection showSummarizeIcon />
      </div>
      <CasesSection
        order={[2, 1, 3]}
        mainCardComponent={<></>}
        buttonType="extension"
      ></CasesSection>
      <TestimonialsSection />
      <TryOutSection />
      <LandingFooter />
    </div>
  );
};

export default SummaryGIF;
