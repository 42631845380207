import React, { useCallback, useMemo } from 'react';
import styles from './styles.module.css';
import { ReactComponent as PlusIcon } from '../../assets/img/icons/plus.svg';
import { ReactComponent as CheckIcon } from '../../assets/img/icons/check.svg';
import { Colors } from '../../assets/colors';
import cn from 'classnames';

type Props = {
  item: {
    id?: number;
    name?: string;
  };
  selectedItemsIds: number[];
  onItemSelected: (id: number) => void;
  withoutMargin?: boolean;
  withoutMaxWidth?: boolean;
};

const InterestsItem = ({
  item,
  selectedItemsIds,
  onItemSelected,
  withoutMargin,
  withoutMaxWidth,
}: Props) => {
  const { id, name } = item;
  const selectItem = useCallback(() => (id ? onItemSelected(id) : null), [id, onItemSelected]);
  const selected = useMemo(
    () => (id ? selectedItemsIds.includes(id) : false),
    [id, selectedItemsIds],
  );
  return (
    <div
      className={cn(styles.onboarding__item, {
        [styles['onboarding__item--active']]: selected,
        [styles['onboarding__item--mw']]: !withoutMaxWidth,
      })}
      style={withoutMargin ? { margin: 0 } : {}}
      onClick={selectItem}
    >
      <div className={`input-label-text ${selected ? 'text-purple-1' : 'text-black'}`}>{name}</div>
      {selected ? (
        <CheckIcon fill={Colors.purple01} width={15} height={15} />
      ) : (
        <PlusIcon fill="#000" width={13} height={13} />
      )}
    </div>
  );
};

export default InterestsItem;
