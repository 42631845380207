import React from 'react';
import LandingFooter from '../../components/footer/LandingFooter';
import { ArrowLeft } from 'react-feather';
import { Link } from 'react-router-dom';
import ClientRouter from '../../routers/ClientRouter';
import notFoundErrorImage from '../../assets/img/illustrations/404-error-with-a-cute-animal.svg';

export function NotFound() {
  return (
    <div id="layoutError">
      <div id="layoutError_content">
        <main>
          <div className="container-xl px-4">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="text-center mt-4">
                  <img className="img-fluid p-4" src={notFoundErrorImage} alt="" />
                  <p className="lead">This requested URL was not found on this server.</p>
                  <Link className="text-arrow-icon" to={ClientRouter.home()}>
                    <ArrowLeft className={'ms-0 me-1'} />
                    Return to getSparks
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <LandingFooter />
    </div>
  );
}
