import React, { useCallback, useState } from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';
import ServerRouter from '../routers/ServerRouter';
import { getHeaders } from '../routers/utils';
import { reloadProfile } from '../data/redux/authUtils';

type Props = {
  steps: Step[];
  onFinish?: () => void;
  scrollOffset?: (stepIndex: number) => number;
  actionButton?: (onClick: () => void) => React.ReactNode;
  disableOverlayClose?: boolean;
  showSkipButton?: boolean;
};

export default function Tour(props: Props) {
  const { onFinish, scrollOffset, steps, actionButton, disableOverlayClose, showSkipButton } =
    props;
  const [run, setRun] = useState<boolean>(!actionButton);
  const [stepIndex, setStepIndex] = useState(0);
  const startTour = useCallback(() => {
    setRun(true);
    setStepIndex(0);
  }, []);
  const handleJoyrideCallback = useCallback(
    (data: CallBackProps) => {
      const { action, status, type } = data;

      if (type === 'tour:end') {
        fetch(ServerRouter.updateProfile(), {
          method: 'PATCH',
          headers: getHeaders(),
          credentials: 'include',
          body: JSON.stringify({ onboarding_closed: true }),
        })
          .then(() => {
            reloadProfile();
          })
          .catch(e => console.error(e));
      }

      const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

      if (action === ACTIONS.CLOSE || finishedStatuses.includes(status)) {
        onFinish?.();
        setRun(false);
      } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
        setStepIndex(stepIndex + (action === ACTIONS.PREV ? -1 : 1));
      }
    },
    [onFinish, stepIndex],
  );
  return (
    <>
      {actionButton && actionButton(startTour)}
      <Joyride
        run={run}
        callback={handleJoyrideCallback}
        spotlightClicks={true}
        scrollToFirstStep={true}
        disableScrollParentFix={true}
        disableOverlayClose={disableOverlayClose}
        scrollOffset={scrollOffset ? scrollOffset(stepIndex) : 0}
        showProgress={steps.length > 1}
        showSkipButton={showSkipButton}
        continuous={steps.length > 1}
        steps={steps}
        stepIndex={stepIndex}
        styles={{
          options: {
            primaryColor: '#0052ce',
            zIndex: 1100,
          },
          tooltip: {
            padding: '10px',
            margin: 0,
          },
          tooltipContent: {
            padding: '10px',
            margin: 0,
          },
          tooltipContainer: {
            lineHeight: 1,
          },
          tooltipFooter: {
            padding: 0,
            margin: 0,
          },
        }}
        locale={{
          skip: 'Maybe Later',
          close: 'Close',
          next: 'Continue',
          last: 'End tour',
        }}
      />
    </>
  );
}
