import TimeStampedModel from './TimeStampedModel';
import { TaskStatus } from '../../services/utils';

export type Chunk = { chunk_summary: string; id: number; page_number: number };

export default class ArticleMetadata extends TimeStampedModel {
  readonly keywords?: string;
  readonly summary?: string;
  readonly places?: string;
  readonly people?: string;
  readonly sentiment?: boolean;
  readonly redability_grade?: number;
  readonly reading_time?: number;
  readonly speaking_time?: number;
  readonly chars?: number;
  readonly words?: number;
  readonly paragraphs?: number;
  readonly page?: number;
  readonly task_result?: TaskStatus;
  readonly chunks?: Chunk[];
  readonly default_questions?: { question: string }[] | null;

  constructor(jsonData: Partial<ArticleMetadata>) {
    super(jsonData);
    Object.assign(this, jsonData);
  }
}
