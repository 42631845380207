import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../data/redux/hooks';
import { RootState } from '../../data/redux/store';
import ClientRouter from '../../routers/ClientRouter';
import ServerRouter from '../../routers/ServerRouter';
import NetworkJSONArrayLoader from '../../components/network/NetworkJSONArrayLoader';
import { fillTopics } from '../../data/redux/actions';
import { getHeaders } from '../../routers/utils';
import Awaiter from '../../components/messages/Awaiter';
import { checkIsUrlValid } from '../../services/utils';

const Save = () => {
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
  const topics = useAppSelector(useCallback(state => Object.values(state.cache.topics), []));
  const defaultTopic = useMemo(() => topics.find(topic => topic?.name === 'Default'), [topics]);

  const navigate = useNavigate();
  const { search } = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!user) {
      return navigate({
        pathname: ClientRouter.login(),
        search,
      });
    }
    const url = searchParams.get('url');
    if (url) {
      const isUrlValid = checkIsUrlValid(url);
      if (isUrlValid) {
        if (defaultTopic) {
          fetch(ServerRouter.articles(), {
            method: 'POST',
            body: JSON.stringify({
              url,
              topic: defaultTopic.id,
            }),
            headers: getHeaders(),
          })
            .then(res => {
              if (res.ok) {
                navigate({
                  pathname: ClientRouter.dashboard(),
                });
              } else {
                throw new Error();
              }
            })
            .catch(() => {
              navigate({
                pathname: ClientRouter.dashboard(),
                search: '?notification=page-add-error',
              });
            });
        }
      } else {
        navigate({ pathname: ClientRouter.dashboard(), search: '?notification=invalid-link' });
      }
    }
  }, [search, user, defaultTopic, navigate, searchParams]);
  return (
    <div className="d-flex justify-content-center mt-10">
      <Awaiter />
      <NetworkJSONArrayLoader url={ServerRouter.topics()} onOk={fillTopics} hidden />
    </div>
  );
};

export default Save;
