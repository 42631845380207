import { IField } from '../../components/crud/fields/Field';
import { Categories, getTopicsOptions } from '../FormData';
import {
  deleteIndexerFilter,
  DeleteReducer,
  indexerFilter,
  notEmpty,
  ReduxIndexer,
  StateType,
} from '../redux/utils';
import TimeStampedModel from './TimeStampedModel';
import Article from './Article';

export type TokenGroup = 'PER' | 'LOC' | 'ORG' | 'MISC' | 'AUT';

export type TopicToken = {
  by_user: boolean;
  created_at: string;
  group: TokenGroup;
  id: number;
  page_id?: number;
  topic_id?: number;
  updated_at: string;
  value: string;
};

export const tokenGroupNameByKey = {
  PER: 'Person',
  LOC: 'Location',
  ORG: 'Organization',
  MISC: 'Miscellanious',
  AUT: 'Author',
};

export default class Topic extends TimeStampedModel {
  static fields = {
    name: { name: 'name', maxLength: 32 } as IField,
    category: { name: 'category', options: Categories } as IField,
    description: { name: 'description' } as IField,
    parent: (topics: ReduxIndexer<Topic>) =>
      ({
        name: 'parent',
        options: getTopicsOptions(topics),
      } as IField),
  };

  static light_fields = {
    name: { name: 'name', maxLength: 32 } as IField,
    parent: (topics: ReduxIndexer<Topic>) =>
      ({
        name: 'parent',
        nullableName: 'None',
        options: getTopicsOptions(topics),
      } as IField),
  };

  readonly name?: string;
  readonly parent?: number;
  readonly child?: number[];
  readonly pages?: number[];
  readonly description?: string;
  readonly category?: number;
  readonly keywords?: string;
  readonly preferences?: number[];
  readonly tokens?: number[];
  readonly page_tokens?: TopicToken[];

  readonly key?: 'discover' | 'recents' | string;

  constructor(jsonData: Partial<Topic>) {
    super(jsonData);
    Object.assign(this, jsonData);
  }

  getNotes(state: StateType) {
    return Object.values(
      indexerFilter(state.topicsNotes, instance => instance?.topic === this.id),
    ).filter(notEmpty);
  }

  getArticles(state: StateType, additionalFilter?: (article: Article) => boolean) {
    if (this.id)
      return Object.values(indexerFilter(state.articles, instance => instance?.topic === this.id))
        .filter(notEmpty)
        .filter(article => (additionalFilter ? additionalFilter(article) : true))
        .sort((a, b) =>
          !b.created_at || !a.created_at
            ? -1
            : new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
        );
    return (
      this.pages
        ?.map(id => state.articles[id])
        .filter(notEmpty)
        .filter(article => (additionalFilter ? additionalFilter(article) : true))
        .sort((a, b) =>
          !b.created_at || !a.created_at
            ? -1
            : new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
        ) || []
    );
  }

  delete: DeleteReducer = state => {
    state.articles = deleteIndexerFilter(
      state,
      state.articles,
      instance => instance.topic === this.id,
    );
    state.topicsNotes = deleteIndexerFilter(
      state,
      state.topicsNotes,
      instance => instance.topic === this.id,
    );
  };
}
