import React from 'react';
import ArticleCardView from './ArticleCardView';
import ArticleCardPlaceholder from './ArticleCardPlaceholder';
import { ArticleCardAdd } from './ArticleCardAdd';
import Tour from '../../../../components/Tour';
import BlueLogo from '../../../../components/nav/BlueLogo';
import OnboardView from '../onboard/OnboardView';
import { ARTICLE_VIEWED } from './TopicArticles';
import Topic from '../../../../data/models/Topic';
import { UsePaginationReturnType } from '../../../../hooks/custom/usePagination';

type Props = {
  extensionInstalled: boolean;
  extensionWasConfigured?: boolean;
  topic: Topic;
  articleAdded: boolean;
  articlesFetched: boolean;
  pagination: UsePaginationReturnType;
  scrollToTop: (behavior?: ScrollBehavior) => void;
};

const TopicTilesArticles = ({
  extensionInstalled,
  extensionWasConfigured,
  topic,
  articleAdded,
  articlesFetched,
  pagination: {
    containerRef,
    lastRowEmptyItemsNumber,
    paginatedItems: articles,
    nextPageExists,
    onShowMoreClicked,
  },
  scrollToTop,
}: Props) => {
  const articleViewed = localStorage.getItem(ARTICLE_VIEWED);
  const shouldOpenExtensionModalFirst = extensionInstalled ? extensionWasConfigured : false;
  return (
    <>
      {!!articles.length && (
        <div className="articles-grid" ref={containerRef}>
          {articles.map(article =>
            article ? <ArticleCardView key={article.id} article={article} /> : null,
          )}
          {!!lastRowEmptyItemsNumber &&
            Array.from(Array(lastRowEmptyItemsNumber)).map((_, index) => (
              <ArticleCardPlaceholder key={`empty-${index}`} large={false} />
            ))}
        </div>
      )}
      {!articles.length && articlesFetched && (
        <div className="articles-grid articles-grid--lg">
          <ArticleCardAdd topic={topic} />
          <ArticleCardPlaceholder large />
          <ArticleCardPlaceholder large />
        </div>
      )}
      {nextPageExists && (
        <button className="article-button sparks-button-secondary" onClick={onShowMoreClicked}>
          Show More Pages
        </button>
      )}
      {articleAdded && !articleViewed && shouldOpenExtensionModalFirst && false && (
        <Tour
          key={topic.id + '_' + articles[0]?.id}
          steps={[
            {
              target: 'header + div.container-xl div div.card div.vstack.ready:first-child',
              content: 'View Saved Article to view summary or add Notes.',
              title: <BlueLogo />,
              placement: 'bottom',
              disableBeacon: true,
              disableCloseOnEsc: true,
              disableOverlayClose: true,
            },
          ]}
          scrollOffset={stepIndex => 200}
          onFinish={() => localStorage.setItem(ARTICLE_VIEWED, '1')}
          disableOverlayClose={true}
        />
      )}
      {!topic.id && topic.name === 'Recent' && <OnboardView scrollToTop={scrollToTop} />}
    </>
  );
};

export default TopicTilesArticles;
