import React, {useMemo} from 'react';
import {Button, Modal, Stack} from "react-bootstrap";
import {Step, TooltipRenderProps} from 'react-joyride';
import BlueLogo from '../../../../components/nav/BlueLogo';
import Tour from "../../../../components/Tour";
import useCheckExtensionInstalled from "../../../../hooks/custom/useCheckExtensionInstalled";
import AddToChrome from "../../../../components/links/AddToChrome";
import useBrowser from "../../../../hooks/custom/useBrowser";

const TOUR_STEPS: Step[] = [{
  target: '.dropdown-user .profile-picture-bg',
  title: <BlueLogo/>,
  disableBeacon: true,
  content: 'Welcome to getSparks Product tour!',
}, {
  target: '.sidenav-menu',
  title: <BlueLogo/>,
  content: 'Add new topic to organize your content.',
  placement: 'right',
}, {
  target: 'header + div.container-xl div :first-child',
  title: <BlueLogo/>,
  content: 'Add new article to save content.',
}, {
  target: '.topnav form',
  title: <BlueLogo/>,
  content: 'Search Content across all articles or specific topic.',
}];

function AddToChromeTooltip(props: TooltipRenderProps) {
  return <div {...props.tooltipProps}>
    <Modal centered show>
      <Modal.Body className={'p-3'}>
        <Stack className={'align-items-center'}>
          <h3>Add Extension</h3>
          <p className={"mb-3 text-center"}>
            The browser extension makes it easier to save web content while you browse.
          </p>
          <AddToChrome/>
        </Stack>
        <div className={'hstack justify-content-end mt-3'}>
          <div {...props.backProps} className={'nav-link py-0'}>Back</div>
          <Button {...props.primaryProps} style={{padding: '8px', fontSize: '16px'}}>
            Finish
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  </div>;
}

export default function ProductTour() {
  const extensionInstalled = useCheckExtensionInstalled();
  const browser = useBrowser();
  const steps = useMemo(() => {
    if (extensionInstalled || browser !== 'chrome') {
      return TOUR_STEPS;
    }
    return TOUR_STEPS.concat({
      target: 'body',
      title: <BlueLogo/>,
      content: undefined,
      tooltipComponent: AddToChromeTooltip,
      floaterProps: {hideArrow: true},
    });
  }, [browser, extensionInstalled]);
  return useMemo(() =>
    <Tour
      showSkipButton={true}
      steps={steps}
      scrollOffset={stepIndex => stepIndex === 2 || stepIndex === 4 ? 250 : 0}
      actionButton={onClick => <Button className={'d-block'} onClick={onClick}>
        Begin <b className={'text-lg d-block'}>Product Tutorial</b>
      </Button>}/>, [steps]);
}
