import React, { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import FeedbackForm from './FeedbackForm';

export default function Feedback() {
  const [opened, setOpened] = useState<Boolean>(false);
  const content = useMemo(() => {
    if (opened) return <FeedbackForm setOpened={setOpened} />;
    return (
      <Button
        style={{
          fontWeight: 'bold',
          borderRadius: '5px 5px 0 0',
          position: 'fixed',
          bottom: 0,
          right: 17,
        }}
        onClick={() => setOpened(true)}
      >
        Give Feedback
      </Button>
    );
  }, [opened]);
  return useMemo(
    () => <div className={'position-absolute bottom-0 end-0'} children={content} />,
    [content],
  );
}
