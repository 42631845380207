import React, {useMemo} from 'react';
import CloseButton from 'react-bootstrap/CloseButton';
import Card from 'react-bootstrap/Card';
import CRUD from '../../components/crud/CRUD';
import PostButton from '../../components/buttons/text/PostButton';
import ServerRouter from '../../routers/ServerRouter';

type Props = {
  setOpened: React.Dispatch<React.SetStateAction<Boolean>>,
};

export default function FeedbackForm({setOpened}: Props) {
  return useMemo(() => {
    const close = () => setOpened(false);
    const crud = new CRUD(ServerRouter.feedback(), [{name: 'description', type: 'textarea', required: true}], close);
    return <Card style={{bottom: 25, right: 25, minWidth: 350}}>
      <Card.Header className="hstack justify-content-between">
        <h4 className={'m-0'}>Have Feedback?</h4>
        <CloseButton onClick={close}/>
      </Card.Header>
      <crud.post formButton={<PostButton>Submit Feedback</PostButton>} nonLinear/>
      <div className={'mb-3'}/>
    </Card>
  }, [setOpened]);
}
