import Model from './Model';
import { IField } from '../../components/crud/fields/Field';

export type AccountPreferences = {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
};

export default class User extends Model {
  readonly username?: string;
  readonly email?: string;
  readonly first_name?: string;
  readonly last_name?: string;
  readonly get_newsletter?: boolean;
  readonly onboarding_closed?: boolean;
  readonly bookmarks_imported?: boolean;
  readonly subscription_cancelled?: boolean;
  readonly plan_name?: string;
  readonly plan_status?: string;
  readonly start_date?: string;
  readonly end_date?: string;
  readonly interval?: string;
  readonly preferences?: AccountPreferences[];

  constructor(jsonData: Partial<User>) {
    super(jsonData);
    Object.assign(this, jsonData);
  }

  static fields = {
    first_name: {
      name: 'first_name',
      maxLength: 150,
      label: 'First Name',
      placeholder: 'Your first name here',
    } as IField,
    last_name: {
      name: 'last_name',
      maxLength: 150,
      label: 'Last Name',
      placeholder: 'Your last name here',
    } as IField,
    full_name: { name: 'full_name', maxLength: 150 } as IField,
    username: { name: 'username', required: true } as IField,
    email: {
      name: 'username',
      type: 'email',
      required: true,
      placeholder: 'example@example.com',
      label: 'Email',
      errorMessageBottom: true,
    } as IField,
    real_email: {
      name: 'email',
      type: 'email',
      label: 'Email Address',
      placeholder: 'example@example.com',
      required: true,
      errorMessageBottom: true,
    } as IField,
    email_token: { name: 'token', hidden: true } as IField,
    plan_name: { name: 'plan_name', hidden: true } as IField,
    plan_status: { name: 'plan_status', hidden: true } as IField,
    start_date: { name: 'start_date', hidden: true } as IField,
    end_date: { name: 'end_date', hidden: true } as IField,
    subscription_cancelled: { name: 'subscription_cancelled', hidden: true } as IField,
    interval: { name: 'interval', hidden: true } as IField,
    uidb64: { name: 'uidb64', hidden: true } as IField,
    password: {
      name: 'password',
      required: true,
      type: 'password',
      minLength: 8,
      placeholder: 'Your password here',
      label: 'Password',
      errorMessage: 'Invalid password',
      validate(value: string) {
        return value.length > 8 && value.toLowerCase() !== value;
      },
      errorMessageBottom: true,
    } as IField,
    new_password: { name: 'new_password', required: true, type: 'password' } as IField,
    current_password: { name: 'current_password', required: true, type: 'password' } as IField,
    confirm_password: { name: 'confirm_password', required: true, type: 'password' } as IField,
    get_newsletter: {
      name: 'get_newsletter',
      type: 'checkbox',
      defaultChecked: true,
      placeholder:
        'Get the newsletter and emails from getSparks with our latest announcements, product updates, and events. Unsubscribe any time.',
    } as IField,
  };
}
