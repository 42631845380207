import React, {
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { JSONObject } from '../../../data/models/Model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDropdownButton } from '../../buttons/icon/IconDropdownButton';
import DropdownItem from 'react-bootstrap/DropdownItem';
import {
  faBold,
  faFont,
  faHeading,
  faItalic,
  faLink,
  faListOl,
  faListUl,
  faParagraph,
  faStrikethrough,
  faUnderline,
} from '@fortawesome/free-solid-svg-icons';

const formatBlock = 'formatBlock';
const queryCommandState = (command: string) => document.queryCommandState(command);
const queryCommandValue = (command: string) => document.queryCommandValue(command);

const exec = (command: string, value?: string) => document.execCommand(command, false, value);
type Action = {
  icon: ReactNode;
  title: string;
  state?: () => boolean;
  result?: () => void;
};
const blocks: { item: React.ReactNode; tag: string }[] = [
  { item: 'Normal text', tag: '<div>' },
  { item: <p>Paragraph</p>, tag: '<p>' },
  { item: <h1>Heading 1</h1>, tag: '<h1>' },
  { item: <h2>Heading 2</h2>, tag: '<h2>' },
  { item: <h3>Heading 3</h3>, tag: '<h3>' },
  { item: <h4>Heading 4</h4>, tag: '<h4>' },
  { item: <h5>Heading 5</h5>, tag: '<h5>' },
  { item: <h6>Heading 6</h6>, tag: '<h6>' },
];
const defaultActions: { [x: string]: Action } = {
  bold: {
    icon: <FontAwesomeIcon icon={faBold} />,
    title: 'Bold',
    state: () => queryCommandState('bold'),
    result: () => exec('bold'),
  },
  italic: {
    icon: <FontAwesomeIcon icon={faItalic} />,
    title: 'Italic',
    state: () => queryCommandState('italic'),
    result: () => exec('italic'),
  },
  underline: {
    icon: <FontAwesomeIcon icon={faUnderline} />,
    title: 'Underline',
    state: () => queryCommandState('underline'),
    result: () => exec('underline'),
  },
  strikethrough: {
    icon: <FontAwesomeIcon icon={faStrikethrough} />,
    title: 'Strike-through',
    state: () => queryCommandState('strikeThrough'),
    result: () => exec('strikeThrough'),
  },
  heading1: {
    icon: (
      <>
        <FontAwesomeIcon icon={faHeading} />
        <sub>1</sub>
      </>
    ),
    title: 'Heading 1',
    result: () => exec(formatBlock, '<h1>'),
  },
  heading2: {
    icon: (
      <>
        <FontAwesomeIcon icon={faHeading} />
        <sub>2</sub>
      </>
    ),
    title: 'Heading 2',
    result: () => exec(formatBlock, '<h2>'),
  },
  paragraph: {
    icon: <FontAwesomeIcon icon={faParagraph} />,
    title: 'Paragraph',
    result: () => exec(formatBlock, '<p>'),
  },
  block: {
    icon: (
      <IconDropdownButton
        className={'h-100'}
        variant={'light px-2 py-0 h-100'}
        bsPrefix={'btn'}
        title={<FontAwesomeIcon icon={faFont} />}
      >
        {blocks.map(({ item, tag }) => (
          <DropdownItem key={tag} onClick={() => exec(formatBlock, tag)} children={item} />
        ))}
      </IconDropdownButton>
    ),
    title: 'Block',
  },
  quote: {
    icon: '“ ”',
    title: 'Quote',
    result: () => exec(formatBlock, '<blockquote>'),
  },
  olist: {
    icon: <FontAwesomeIcon icon={faListOl} />,
    title: 'Ordered List',
    result: () => exec('insertOrderedList'),
  },
  ulist: {
    icon: <FontAwesomeIcon icon={faListUl} />,
    title: 'Unordered List',
    result: () => exec('insertUnorderedList'),
  },
  code: {
    icon: '</>',
    title: 'Code',
    result: () => exec(formatBlock, '<pre>'),
  },
  line: {
    icon: '―',
    title: 'Horizontal Line',
    result: () => exec('insertHorizontalRule'),
  },
  link: {
    icon: <FontAwesomeIcon icon={faLink} />,
    title: 'Link',
    result: () => {
      const url = window.prompt('Enter the link URL');
      if (url) exec('createLink', url);
    },
  },
  image: {
    icon: '📷',
    title: 'Image',
    result: () => {
      const url = window.prompt('Enter the image URL');
      if (url) exec('insertImage', url);
    },
  },
};

const defaultClasses = {
  actionbar: 'pell-actionbar',
  button: 'pell-button',
  content: 'pell-content',
  selected: 'pell-button-selected',
};

type EditorProps = {
  actions?: (string | (JSONObject & { name: string }))[];
  classes?: any;
  onChange: (html: string, index?: number) => void;
  styleWithCSS?: boolean;
  separator?: string;
  html?: string;
  contentRef?: RefObject<HTMLDivElement>;
  multiField?: boolean;
  setActionsView?: (v: JSX.Element) => any;
  fieldClassName?: string;
  hideActionView?: boolean;
} & JSONObject;

type ActionProps = {
  action: Action;
  className: string;
  selectedClassName: string;
  contentRef: RefObject<HTMLDivElement>;
  selected?: boolean;
};

function ActionView({ action, className, selectedClassName, contentRef, selected }: ActionProps) {
  return (
    <button
      className={className + (selected ? ' ' + selectedClassName : '')}
      title={action.title}
      type={'button'}
      onClick={() => {
        action.result?.() && contentRef.current?.focus();
      }}
    >
      {action.icon}
    </button>
  );
}

type actionsSelectionType = { [x: string]: boolean | undefined };
export default function WYSIWYGEditor({
  actions,
  styleWithCSS,
  classes,
  onChange,
  separator,
  html,
  contentRef,
  fieldClassName,
  setActionsView,
  hideActionView,
}: EditorProps) {
  const ref = useRef<HTMLDivElement>(null);
  const content = contentRef ?? ref;
  const [actionsSelection, setActionsSelection] = useState<actionsSelectionType>();
  const editorActions = useMemo(() => {
    return (
      actions?.map(action => {
        if (typeof action === 'string') return defaultActions[action];
        else if (defaultActions[action.name]) return { ...defaultActions[action.name], ...action };
        return action;
      }) ?? Object.values(defaultActions)
    );
  }, [actions]);
  classes = { ...defaultClasses, ...classes };
  separator = separator || 'div';
  if (styleWithCSS) {
    exec('styleWithCSS');
  }
  exec('defaultParagraphSeparator', separator);
  const updateSelection = useCallback(() => {
    const selection: actionsSelectionType = {};
    editorActions.forEach(action => {
      selection[action.title] = action.state?.() ?? false;
    });
    setActionsSelection(selection);
  }, [editorActions]);

  const actionView = useMemo(() => {
    return (
      <div className={classes.actionbar}>
        {editorActions.map((action, index) => (
          <ActionView
            action={action as Action}
            className={classes.button}
            selectedClassName={classes.selected}
            contentRef={content}
            key={index}
            selected={actionsSelection?.[action.title]}
          />
        ))}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorActions, content, actionsSelection]);

  useEffect(() => {
    if (setActionsView) {
      setActionsView(actionView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionView]);

  return (
    <>
      {!hideActionView && actionView}
      <div
        contentEditable
        ref={content}
        className={`${classes.content} ${fieldClassName}`}
        onInput={({ target }) => {
          const htmlTarget = target as HTMLElement;
          if (target && htmlTarget.nodeType === 3) {
            exec(formatBlock, `<${separator}>`);
          } else if (htmlTarget.innerHTML === '<br>') {
            htmlTarget.innerHTML = '';
          }
          onChange(htmlTarget.innerHTML);
        }}
        dangerouslySetInnerHTML={html ? { __html: html } : undefined}
        onKeyDown={({ key }) => {
          if (key === 'Enter' && queryCommandValue(formatBlock) === 'blockquote') {
            setTimeout(() => exec(formatBlock, `<${separator}>`), 0);
          }
        }}
        onClick={updateSelection}
        onKeyUp={updateSelection}
        onMouseUp={updateSelection}
        onFocus={updateSelection}
      />
    </>
  );
}
