import React, {PropsWithChildren} from 'react';
import {Link} from "react-router-dom";
import ClientRouter from "../../routers/ClientRouter";
import {ArrowLeft} from "react-feather";

type Props = PropsWithChildren<{
  name?: string,
}>;

export default function HasNotImplemented({name, children}: Props) {
  return <div className={'card-body vstack align-items-center'}>
    {children}
    <p>{name ?? "This"} hasn't been implemented yet.</p>
    <Link to={ClientRouter.home()}><ArrowLeft/> Return to Home</Link>
  </div>
}
