import React from 'react';
import {WEB_EXTENSION_URL} from "../../services/constants";
import ExternalLink, {ExternalLinkProps} from "../network/ExternalLink";

export default function AddToChrome(props: Partial<ExternalLinkProps>) {
  return (
    <ExternalLink to={WEB_EXTENSION_URL} className={'btn btn-primary'} {...props}>
      Add to Chrome
    </ExternalLink>
  );
}
