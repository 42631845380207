import React from 'react';
import {OverlayTrigger, Tooltip as TooltipView} from "react-bootstrap";
import {OverlayTriggerProps} from "react-bootstrap/OverlayTrigger";

export type TooltipProps = Omit<OverlayTriggerProps, 'overlay' | 'children'> & {
  children?: React.ReactElement;
  overlay?: React.ReactNode;
};

export default function Tooltip({children, placement, overlay, ...rest}: TooltipProps) {
  return (
    <OverlayTrigger {...rest} placement={placement ?? "top"} overlay={<TooltipView>{overlay}</TooltipView>}>
      {children ?? <></>}
    </OverlayTrigger>
  );
}
