import { useAppSelector } from '../../../data/redux/hooks';
import React, { FormEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigateNoUpdates } from '../../../hooks/RouterUtils';
import { getFormFieldsValues } from '../../../services/utils';
import ClientRouter from '../../../routers/ClientRouter';
import ModularForm from '../../../components/crud/ModularForm';
import Article from '../../../data/models/Article';

type Props = {
  selectedTopicId?: number;
};

export default function SearchComponent({ selectedTopicId }: Props) {
  const topics = useAppSelector(useCallback(state => state.cache.topics, []));
  const topic = useAppSelector(
    useCallback(
      state => (selectedTopicId ? state.cache.topics[+selectedTopicId] : null),
      [selectedTopicId],
    ),
  );
  const topicSelect = useRef<HTMLSelectElement>();
  useEffect(() => {
    if (topicSelect.current) {
      topicSelect.current.value = selectedTopicId ? selectedTopicId.toString() : 'null';
    }
  }, [selectedTopicId]);
  const navigate = useNavigateNoUpdates();
  const onSearch = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      const json = getFormFieldsValues(event);
      if (selectedTopicId) {
        json['topic_id'] = selectedTopicId.toString();
      } else {
        delete json['topic_id'];
      }
      navigate(
        ClientRouter.articlesSearch(
          Object.keys(json)
            .map(
              value => value + '=' + encodeURIComponent(json[value] as string | number | boolean),
            )
            .join('&'),
        ),
      );
    },
    [navigate, selectedTopicId],
  );
  const form = useMemo(
    () => (
      <ModularForm
        onSubmit={onSearch}
        button={<></>}
        fields={[
          {
            name: 'search',
            label: null,
            style: { minWidth: '100px', borderRadius: 4 },
            className: 'form-control bg-blue-gray-4 border-color-blue-gray-2 h-40 body-text',
            submitOnEnterPress: true,
            placeholder: !topic ? 'Search getSparks' : `Search ${topic?.name}`,
          },
          {
            ...Article.fields.topic(topics),
            name: 'topic_id',
            // className: 'd-none d-md-block',
            hidden: true,
          },
          { name: 'title', hidden: true },
          { name: 'notes', hidden: true },
        ]}
        defaultValues={{ title: true, notes: true }}
        formProps={{ className: 'flex-grow-1' }}
        inline={true}
      />
    ),
    [onSearch, topics, topic],
  );
  return useMemo(() => {
    return (
      <>
        {form}
        <div className="border-color-gray-3" />
      </>
    );
  }, [form]);
}
