import React, { useCallback, useMemo, useState } from 'react';
import { ArticleCardContainer } from './ArticleCardContainer';
import Topic from '../../../../data/models/Topic';
import { useAppSelector } from '../../../../data/redux/hooks';
import { RootState } from '../../../../data/redux/store';
import moment from 'moment';
import { PremiumModal } from '../../sidenav/FreePlan';
import ArticleAddCRUDComponent from '../../../../components/crud/ArticleAddCRUDComponent';
import { ReactComponent as PlusIcon } from '../../../../assets/img/icons/plus.svg';

type Props = {
  topic: Topic;
};

export function ArticleCardAdd({ topic }: Props) {
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));

  const [premiumModalShow, setPremiumModalShow] = useState(false);

  let articlesCount = 0;
  let articles_keys = Object.keys(
    useAppSelector(useCallback((state: RootState) => state.cache.articles, [])),
  );

  let articles = useAppSelector(useCallback((state: RootState) => state.cache.articles, []));
  for (let i = 0; i < articles_keys.length; i++) {
    if (moment(articles[articles_keys[i]]?.created_at).isSame(moment(), 'day')) {
      articlesCount = articlesCount + 1;
    }
  }
  return useMemo(() => {
    const premiumModal = (
      <PremiumModal
        showModal={premiumModalShow}
        limitType="pages"
        onCancel={() => setPremiumModalShow(false)}
        signedUser={user?.email}
      ></PremiumModal>
    );

    return (
      <>
        {user?.plan_name === 'Premium' && user?.plan_status !== 'Inactive' ? (
          <ArticleCardContainer className="article-tile--lg">
            <ArticleAddCRUDComponent
              topic={topic}
              actionButton={onClick => (
                <div className="add-new-tile">
                  <h6 className="sparks-h4 text-black mb-1">Add to Topic</h6>
                  <div className="text-gray-1 body-text-sm mb-3">
                    Start adding pages to this topic to come back to later
                  </div>
                  <div>
                    <button
                      onClick={onClick}
                      className="sparks-button button-purple lh-1 button-purple--sm button-purple-rect button-text shadow-none article-button--primary"
                    >
                      <PlusIcon width={13} height={13} fill="#fff" /> Add Page
                    </button>
                  </div>
                </div>
              )}
            />
            {premiumModal}
          </ArticleCardContainer>
        ) : (
          <>
            {articlesCount < 5 ? (
              <ArticleCardContainer className="article-tile--lg">
                <ArticleAddCRUDComponent
                  topic={topic}
                  actionButton={onClick => (
                    <div className="add-new-tile">
                      <h6 className="sparks-h4 text-black mb-1">Add to Topic</h6>
                      <div className="text-gray-1 body-text-sm mb-3">
                        Start adding pages to this topic to come back to later
                      </div>
                      <div>
                        <button
                          onClick={onClick}
                          className="sparks-button button-purple lh-1 button-purple--sm button-purple-rect button-text shadow-none article-button--primary"
                        >
                          <PlusIcon width={13} height={13} fill="#fff" /> Add Page
                        </button>
                      </div>
                    </div>
                  )}
                />
                {premiumModal}
              </ArticleCardContainer>
            ) : (
              <>
                <ArticleCardContainer className="article-tile--lg">
                  <div className="add-new-tile">
                    <h6 className="sparks-h4 text-black mb-1">Add to Topic</h6>
                    <div className="text-gray-1 body-text-sm mb-3">
                      Start adding pages to this topic to come back to later
                    </div>
                    <div>
                      <button
                        onClick={() => setPremiumModalShow(true)}
                        className="sparks-button button-purple lh-1 button-purple--sm button-purple-rect button-text shadow-none article-button--primary"
                      >
                        <PlusIcon width={13} height={13} fill="#fff" /> Add Page
                      </button>
                    </div>
                  </div>
                </ArticleCardContainer>
                {premiumModal}
              </>
            )}
          </>
        )}
      </>
    );
  }, [articlesCount, user?.email, premiumModalShow, topic, user?.plan_name, user?.plan_status]);
}
