import React, { CSSProperties, useCallback, useContext, useMemo, useState } from 'react';
import cn from 'classnames';
import Article from '../../../../data/models/Article';
import TextShortener from '../../../../components/TextShortener';
import Awaiter from '../../../../components/messages/Awaiter';
import useJSONStringToObject from '../../../../hooks/custom/useJSONStringToObject';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAudio, faFileImage, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import ArticleImageSiteIcon from '../../article/ArticleImageSiteIcon';
import { ArticleCardAnchor } from './ArticleCardAnchor';
import { useNavigate } from 'react-router-dom';
import ClientRouter from '../../../../routers/ClientRouter';
import { setArticle, setInfoTab } from '../../../../data/redux/actions';
import { ReactComponent as NotesIcon } from '../../../../assets/img/icons/notes.svg';
import { ReactComponent as DotsIcon } from '../../../../assets/img/icons/dots.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/img/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../../../assets/img/icons/edit.svg';
import { ReactComponent as HashtagIcon } from '../../../../assets/img/icons/hashtag.svg';
import { getArticleManageCRUD } from '../../../../services/cruds';
import { useAppSelector } from '../../../../data/redux/hooks';
import { RootState } from '../../../../data/redux/store';
import useMenu from '../../../../hooks/custom/useMenu';
import { Colors } from '../../../../assets/colors';
import { Command } from 'react-feather';
import DashboardContext from '../../DashboardContext';
import ArticleListCardContent from './ArticleListCardContent';
import { TagId } from '../../../../data/models/Tag';
import { disableCtrl } from '../../../../services/utils';
import { useTagClick } from '../tags/FilterByTags';
import ArticleTags from '../../article/ArticleTags';

type Props = {
  article: Article;
  ready?: boolean;
};

const presentationStyle: CSSProperties = {
  height: '192px',
  minHeight: '192px',
};

function ContentTypePresentation({ icon }: { icon: IconProp }) {
  return (
    <div className={'vstack justify-content-center text-gray-500 p-3'} style={presentationStyle}>
      <FontAwesomeIcon icon={icon} className={'flex-grow-1'} />
    </div>
  );
}

export const getContentTypeStatus = (pageContent?: string) => {
  if (!pageContent) {
    return '';
  }
  let status = 'Bookmark';
  if (pageContent === 'PDF') status = 'PDF';
  if (pageContent === 'TEXT') status = 'Text';
  if (pageContent === 'HTML') status = 'HTML';
  return status;
};

export const ArticleCardActions = ({
  article,
  minWidth,
  topOffset,
}: {
  article: Article;
  minWidth?: number;
  topOffset?: number;
}) => {
  const navigate = useNavigate();

  const topics = useAppSelector(useCallback((state: RootState) => state.cache.topics, []));
  const notes = useAppSelector(
    useCallback((state: RootState) => article?.getNotes(state.cache), [article]),
  );

  const articleCRUD = getArticleManageCRUD(setArticle, topics);
  const menu = useMenu();

  const menuRendered = useMemo(() => {
    return article.id ? (
      <menu.MenuContainer topOffset={topOffset || -85} minWidth={minWidth}>
        <articleCRUD.delete
          formRef={ref => menu.placeRef(ref, 2)}
          actionButton={onClick => (
            <menu.ItemContainer onClick={onClick}>
              <DeleteIcon width={15} height={15} fill={Colors.blueGray00} />
              <span className="body-text-sm">Delete</span>
            </menu.ItemContainer>
          )}
          defaultValues={{ ...article, id: article.id }}
        />
        <articleCRUD.patch
          formRef={ref => menu.placeRef(ref, 3)}
          actionButton={onClick => (
            <menu.ItemContainer onClick={onClick}>
              <EditIcon width={15} height={15} fill={Colors.blueGray00} />{' '}
              <span className="body-text-sm">Change Topic</span>
            </menu.ItemContainer>
          )}
          defaultValues={article}
          title="Change topic"
        />
      </menu.MenuContainer>
    ) : null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu.menuVisible, article.id]);

  return !!article.id ? (
    <div className="d-flex align-items-center">
      <div
        className="d-flex pointer gap-2 px-2 py-1 align-items-center"
        onClick={e =>
          disableCtrl(e, () => {
            setInfoTab({ visible: true, about: 'article', showing: 'notes' });
            navigate(ClientRouter.articles(article.id));
          })
        }
      >
        {!!notes.length && (
          <div className="body-text-sm text-black fw-500" style={{ lineHeight: 1 }}>
            {notes.length}
          </div>
        )}
        <NotesIcon width={14} height={14} fill="#000" />
      </div>
      <div
        className="d-flex pointer px-2 py-1"
        style={{ marginRight: -8 }}
        onClick={e => disableCtrl(e, menu.toggleMenu)}
      >
        <DotsIcon width={4} height={14} fill="#000" ref={ref => menu.placeRef(ref, 1)} />
      </div>
      {menuRendered}
    </div>
  ) : null;
};

export const ArticleCardTags = ({ tagIds }: { tagIds: TagId[] }) => {
  const tags = useAppSelector(state => state.cache.tags);
  const anyTagExists = useMemo(() => tagIds?.some(id => !!tags[id]), [tagIds, tags]);
  const onTagClick = useTagClick();

  return anyTagExists ? (
    <div className="d-flex lh-1 mt-1 gap-2">
      <span className="article-card__tag text-black fw-500">Tags:</span>
      <div className="column-gap-2 d-flex flex-wrap">
        {tagIds.map(tagId => {
          const tag = tags[tagId];
          return tag ? (
            <div
              onClick={e => onTagClick(e, tag.id)}
              key={`article-tag-${tag.id}`}
              className="pointer"
            >
              <HashtagIcon fill={Colors.blue08} width={14} height={14} />
              <span className="article-card__tag fw-500">{tag.title}</span>
            </div>
          ) : null;
        })}
      </div>
    </div>
  ) : null;
};

export default function ArticleCardContent({ article, ready }: Props) {
  const metatags = useJSONStringToObject(article?.metatags);
  const { width } = useContext(DashboardContext);
  const [isMouseOver, setMouseOver] = useState(false);
  const navigate = useNavigate();
  const articlesCardViewType = useAppSelector(state => state.cache.cardViewType);

  const isMobile = useMemo(() => (width || 0) <= 420, [width]);

  const shouldHideOverlay = article.page_content !== 'HTML';

  const navigateToArticle = useCallback(() => {
    if (!isMobile) {
      setInfoTab({
        about: 'article',
        visible: true,
        showing: 'questions',
      });
    }

    navigate(ClientRouter.articles(article.id));
  }, [isMobile, article.id, navigate]);

  const { overlayText, overlayClickHandler, overlayIcon } = (() => {
    const isMacOS = window.navigator.platform.match(/^Mac/);
    let text = !isMacOS ? 'Ctrl + Click' : ' + Click';
    const clickHandler = (
      event: React.MouseEvent<HTMLDivElement>,
      openArticleWithoutCTRL: boolean = true,
    ) => {
      event.stopPropagation();
      const isHtmlArticle = article.page_content === 'HTML';
      try {
        const isCmdKey = isMacOS && event.metaKey;
        const isCtrlKey = !isMacOS && event.ctrlKey;
        const shouldRedirectToOriginal = isCmdKey || isCtrlKey;
        const url = new URL(article?.url || '');
        url.searchParams.set('utm_source', 'getsparks.io');
        if (shouldRedirectToOriginal && isHtmlArticle) {
          window.open(url.toString(), '_blank');
          return;
        }
      } catch (e) {}

      if (!openArticleWithoutCTRL) {
        return;
      }

      navigateToArticle();
    };
    return {
      overlayText: text,
      overlayClickHandler: clickHandler,
      overlayIcon: isMacOS ? (
        <Command color="#000" className="overlay-icon" width={20} height={20} />
      ) : null,
    };
  })();

  const presentation = useMemo(() => {
    const imageStyles: CSSProperties = {
      ...presentationStyle,
      objectFit: metatags && 'image' in metatags ? 'cover' : 'contain',
    };
    const expanded = article.title || metatags?.description;
    if (article.status === 'progress' || article.status === 'queue')
      return (
        <div
          className={'vstack justify-content-center text-black'}
          style={expanded ? imageStyles : { height: '100%' }}
        >
          <Awaiter>Extracting data...</Awaiter>
        </div>
      );
    if (article.status === 'other_ct') {
      if (article.content_type?.endsWith('/pdf'))
        return <ContentTypePresentation icon={faFilePdf} />;
      if (article.content_type?.startsWith('image/'))
        return <ContentTypePresentation icon={faFileImage} />;
      if (article.content_type?.startsWith('audio/'))
        return <ContentTypePresentation icon={faFileAudio} />;
    }

    const hoverProps = {
      onMouseEnter: () => setMouseOver(true),
      onMouseLeave: () => setMouseOver(false),
    };

    let imgSrc = metatags?.image || article.screenshot;

    const overlay =
      isMouseOver && !shouldHideOverlay ? (
        <div onClick={overlayClickHandler}>
          <div className={cn('card-img-top--overlay', 'card-img-top--overlay__bg')}>
            <p className="card-img-top--overlay__text">
              {overlayIcon} {overlayText} to view original
            </p>
          </div>
        </div>
      ) : undefined;

    if (!metatags?.image && article.site_icon) {
      return (
        <div>
          <ArticleImageSiteIcon
            siteIcon={article.site_icon}
            background={article.page_bg}
            {...hoverProps}
          >
            {overlay}
          </ArticleImageSiteIcon>
        </div>
      );
    }

    return (
      <div className="position-relative" {...hoverProps}>
        <img
          className={cn('card-img-top w-100', { pointer: shouldHideOverlay })}
          onClick={() => {
            if (shouldHideOverlay) {
              navigateToArticle();
            }
          }}
          src={imgSrc}
          alt="..."
          style={imageStyles}
        />
        {overlay}
      </div>
    );
  }, [
    article,
    metatags,
    isMouseOver,
    overlayIcon,
    overlayText,
    overlayClickHandler,
    navigateToArticle,
    shouldHideOverlay,
  ]);

  return useMemo(() => {
    let domain = '';
    try {
      domain = article.url ? new URL(article.url).hostname : '';
    } catch (e) {}
    switch (articlesCardViewType) {
      case 'list':
      case 'headlines':
        let imgSrc = metatags?.image || article.screenshot;
        return (
          <ArticleListCardContent
            title={article.title}
            imgSrc={imgSrc}
            articleId={article.id}
            tags={article.tags}
            loading={article.status === 'progress' || article.status === 'queue'}
            url={article.url}
            overlay={
              !shouldHideOverlay
                ? {
                    text: overlayText,
                    clickHandler: overlayClickHandler,
                    icon: overlayIcon,
                  }
                : undefined
            }
            onImgClick={shouldHideOverlay ? navigateToArticle : undefined}
            imgClassName={shouldHideOverlay ? 'pointer' : ''}
            domain={domain}
            onTitleClick={() => navigate(ClientRouter.articles(article.id))}
            createdAt={article.created_at}
            actions={<ArticleCardActions article={article} minWidth={190} topOffset={-90} />}
            description={
              articlesCardViewType === 'headlines' || typeof metatags?.description !== 'string'
                ? ''
                : metatags?.description
            }
            additionalClassName={articlesCardViewType === 'headlines' ? 'headlines' : ''}
          />
        );

      case 'tile':
        return (
          <div className="article-tile shadow-none">
            <div className={'vstack h-100 text-start' + (ready ? ' ready' : '')}>
              <div className={'article-card-content position-relative h-100'}>
                {(article.status === 'init' || article.status === 'queue') && (
                  <div
                    className={'position-absolute bottom-0 end-0 p-1 text-sm text-black'}
                    style={{ backgroundColor: '#59C9FA', lineHeight: 1 }}
                  >
                    In {article.status}
                  </div>
                )}
                {presentation}
                <div className="article-card-content__body">
                  <div>
                    <ArticleCardAnchor isMobile={isMobile} article={article}>
                      <div className="pointer flex-grow-1" style={{ lineHeight: 1.35 }}>
                        <TextShortener
                          className={`input-label-text text-black fw-bold ${
                            !article.title ? 'text-blue' : ''
                          }`}
                          text={article.title || article.url}
                          lines={2}
                        />
                      </div>
                    </ArticleCardAnchor>
                  </div>
                  {!!article.id && !!article.tags?.length && (
                    <div className="flex-grow-1">
                      <ArticleTags
                        articleId={article.id}
                        updateFilterStateOnClick
                        tagClassName="article-card__tag"
                        titleClassName="article-card__tag text-black fw-500 pt-1"
                      />
                    </div>
                  )}

                  {article.id && (
                    <div className="hstack card-actions position-relative justify-content-between lh-1">
                      <TextShortener text={domain}>
                        <ArticleCardAnchor
                          isMobile={isMobile}
                          className="card-text text-sm text-gray-0 sparks-link fw-400"
                          article={article}
                          children={domain}
                        />
                      </TextShortener>
                      <ArticleCardActions article={article} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  }, [
    presentation,
    article,
    navigate,
    isMobile,
    ready,
    articlesCardViewType,
    metatags?.description,
    metatags?.image,
    overlayClickHandler,
    overlayIcon,
    shouldHideOverlay,
    navigateToArticle,
    overlayText,
  ]);
}
