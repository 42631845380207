import React, { useCallback, useMemo } from 'react';
import TopicDetails from '../topic/TopicDetails';
import Topic from '../../../data/models/Topic';
import TopicNotesInfo from './TopicNotesInfo';
import { useAppSelector } from '../../../data/redux/hooks';
import { RootState } from '../../../data/redux/store';
import InfoTab from './InfoTab';
import TopicSummaryInfo from './TopicSummaryInfo';
import QuestionsInfo from './QuestionsInfo';

type Props = {
  topic: Topic;
};

export default function TopicInfoTab({ topic }: Props) {
  const infoTab = useAppSelector(useCallback((state: RootState) => state.cache.infoTab, []));
  const content = useMemo(() => {
    switch (infoTab.showing) {
      case 'notes':
        return <TopicNotesInfo topic={topic} />;
      case 'summary':
        return <TopicSummaryInfo topic={topic} />;
      case 'questions':
        return <QuestionsInfo />;
      case 'details':
        return (
          <>
            <h2 className={'text-center'}>Details</h2>
            <TopicDetails topic={topic} />
          </>
        );
    }
    return null;
  }, [infoTab.showing, topic]);
  return content ? <InfoTab>{content}</InfoTab> : null;
}
