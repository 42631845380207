import { useAppSelector } from '../../data/redux/hooks';
import { useCallback } from 'react';
import { RootState } from '../../data/redux/store';
import Article from '../../data/models/Article';
import Topic from '../../data/models/Topic';

const useTopicArticlesWithFilters = (topic: Topic) => {
  const tagsFilter = useAppSelector(useCallback((state: RootState) => state.cache.tagsFilter, []));
  const filterArticleByTags = useCallback(
    (article: Article) => {
      if (!tagsFilter.length) return true;
      if (!article.tags?.length) return false;
      return tagsFilter.every(tagId => article.tags && article.tags.includes(tagId));
    },
    [tagsFilter],
  );

  return useAppSelector(
    useCallback(
      state => topic.getArticles(state.cache, filterArticleByTags),
      [topic, filterArticleByTags],
    ),
  );
};

export default useTopicArticlesWithFilters;
