import React, { FunctionComponent, SVGProps, useRef, useState } from 'react';
import { ReactComponent as PlusIcon } from '../../../assets/img/icons/plus.svg';
import { ReactComponent as CaretIcon } from '../../../assets/img/icons/caret.svg';

type Props = {
  link?: string;
  onButtonClick?: () => void;
  desc: string | JSX.Element;
  title: string;
  buttonLabel: string;
  withButtonIcon?: boolean;
  ButtonIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  hideButton?: boolean;
};

const SideNavPanel = ({
  title,
  link,
  desc,
  buttonLabel,
  withButtonIcon,
  hideButton,
  onButtonClick,
  ButtonIcon = PlusIcon,
}: Props) => {
  const [show, setShow] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const contentHeight = contentRef.current?.clientHeight || 0;

  return (
    <div
      className={`sidenav-panel text-start ${show ? 'active' : ''}`}
      style={show ? { height: contentHeight + 57 } : {}}
    >
      <div className="d-flex sidenav-panel_header">
        <h6
          onClick={() => setShow(!show)}
          className="pointer mt-1 body-text fw-400 flex-grow-1 align-items-center d-flex justify-content-between"
        >
          {title}
          <CaretIcon
            className={`caret-dropdown ${show ? 'active' : ''}`}
            fill="#000000"
            width={6}
            height={10}
          />
        </h6>
      </div>
      <div ref={contentRef} className={`sidenav-panel_body ${show ? 'active' : ''}`}>
        <div className="text-gray-0 body-text-micro mb-3">{desc}</div>
        {!hideButton && !!link && (
          <a target="_blank" rel="noreferrer" className="text-decoration-none" href={link}>
            <button className="sparks-button button-purple button-purple--sm button-purple-rect button-text shadow-none gap-2 px-4">
              {withButtonIcon && <ButtonIcon fill="#fff" />}
              {buttonLabel}
            </button>
          </a>
        )}
        {!hideButton && !!onButtonClick && (
          <button
            onClick={onButtonClick}
            className="sparks-button button-purple button-purple--sm button-purple-rect button-text shadow-none gap-2 px-4"
          >
            {withButtonIcon && <ButtonIcon fill="#fff" />}
            {buttonLabel}
          </button>
        )}
      </div>
    </div>
  );
};

export default SideNavPanel;
