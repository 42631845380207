import React, { useContext, useMemo } from 'react';
import Note from '../../../data/models/Note';
import { NoteView } from './NoteView';
import ModalCRUD from '../../../components/crud/ModalCRUD';
import { JSONObject } from '../../../data/models/Model';
import CRUD, { CRUDOnOkCallback, CRUDOnOkParam } from '../../../components/crud/CRUD';
import moment from 'moment';
import { NotesInfoContext } from '../info/NotesInfo';

export type NotesCluster = {
  notes: Note[];
  onOk: CRUDOnOkCallback;
  defaultValues: JSONObject;
  getInlineCRUD: (onOk: CRUDOnOkCallback) => CRUD;
  getModalCRUD: (onOk: CRUDOnOkCallback) => ModalCRUD;
};

type Props = {
  notesCluster: NotesCluster;
};

export function NoteList({ notesCluster }: Props) {
  const { defaultValues, notes, getModalCRUD, getInlineCRUD, onOk } = notesCluster;
  const { targetNote, setTargetNote } = useContext(NotesInfoContext);
  const [recentNotes, pastNotes] = useMemo(() => {
    return [
      notes
        .filter(
          ({ created_at }) =>
            created_at && new Date(created_at).toDateString() === new Date().toDateString(),
        )
        .sort((note1, note2) => (note1?.id && note2?.id ? note2.id - note1.id : 0)),
      notes
        .filter(
          ({ created_at }) =>
            !created_at || new Date(created_at).toDateString() !== new Date().toDateString(),
        )
        .sort((note1, note2) => (note1?.id && note2?.id ? note2.id - note1.id : 0)),
    ];
  }, [notes]);
  return useMemo(() => {
    if (targetNote === undefined) {
      return (
        <>
          {!!recentNotes.length && (
            <div>
              <div className="px-5 py-2 border-color-gray border-bottom">
                <span className="body-text text-gray-0">Today</span>
              </div>
              {recentNotes.map(note => (
                <NoteView
                  note={note}
                  light
                  key={`note-${note.id}`}
                  crud={getModalCRUD(onOk)}
                  defaultValues={defaultValues}
                  setTargetNote={setTargetNote}
                />
              ))}
              <div className="px-5 py-2 border-color-gray border-top border-bottom">
                <span className="body-text text-gray-0">{moment().format('MMM DD, YYYY')}</span>
              </div>
            </div>
          )}
          {pastNotes.map(note => (
            <NoteView
              note={note}
              key={`note-${note.id}`}
              crud={getModalCRUD(onOk)}
              defaultValues={defaultValues}
              setTargetNote={setTargetNote}
            />
          ))}
        </>
      );
    }
    const manageCRUD = getInlineCRUD((json: CRUDOnOkParam) => {
      setTargetNote(undefined);
      return onOk(json);
    });
    return (
      <div className="py-3">
        {targetNote.id ? (
          <manageCRUD.patch
            defaultValues={targetNote}
            onCancel={() => setTargetNote(undefined)}
            controlsProps={{
              className:
                'notes-button d-flex py-2 px-3 border-color-gray border-top border-bottom w-100 mt-5',
            }}
          />
        ) : (
          <manageCRUD.post
            defaultValues={defaultValues}
            onCancel={() => setTargetNote(undefined)}
            controlsProps={{
              className:
                'notes-button d-flex py-2 px-3 border-color-gray border-top border-bottom w-100 mt-5',
            }}
          />
        )}
      </div>
    );
  }, [
    defaultValues,
    recentNotes,
    pastNotes,
    getInlineCRUD,
    getModalCRUD,
    onOk,
    setTargetNote,
    targetNote,
  ]);
}
