import TimeStampedModel from './TimeStampedModel';

export default class DiscoverArticle extends TimeStampedModel {
  readonly article_author?: string;
  readonly canonical_url?: string;
  readonly date?: string;
  readonly description?: string;
  readonly id?: number;
  readonly image?: string;
  readonly is_good?: boolean;
  readonly is_tried?: boolean;
  readonly keywords?: string;
  readonly publish_time?: string;
  readonly time?: string;
  readonly title?: string;
  readonly tokens?: string;
  readonly type?: string;
  readonly url?: string;

  constructor(jsonData: Partial<DiscoverArticle>) {
    super(jsonData);
    Object.assign(this, jsonData);
  }
}
