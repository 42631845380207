import React, { LegacyRef, PropsWithChildren, ReactNode } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { Button, ButtonProps, ModalProps } from 'react-bootstrap';
import IconButton, { IconButtonProps } from '../buttons/icon/IconButton';

type Props = PropsWithChildren<{
  title?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  buttonProps?: ButtonProps;
  iconButtonProps?: IconButtonProps;
  show?: boolean;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  actionButton?: (onClick: () => void) => ReactNode;
  hideAction?: boolean;
  modalProps?: ModalProps;
  hideBody?: boolean;
  formRef?: LegacyRef<any>;
}>;

export default function ModalView(props: Props) {
  const {
    title,
    body,
    footer,
    buttonProps,
    iconButtonProps,
    show,
    setShow,
    actionButton,
    hideAction,
    hideBody,
    modalProps,
    formRef,
  } = props;
  return (
    <>
      <Modal
        centered
        show={show}
        onHide={() => setShow?.(false)}
        style={{ textAlign: 'center', zIndex: '1501' }}
        {...modalProps}
      >
        <div ref={formRef}>
          {title && (
            <Modal.Header closeButton>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
          )}
          {hideBody ? body : body && <Modal.Body>{body}</Modal.Body>}
          {footer && <Modal.Footer className={'justify-content-center p-0'}>{footer}</Modal.Footer>}
        </div>
      </Modal>
      {!hideAction &&
        (actionButton ? (
          actionButton(() => setShow?.(true))
        ) : buttonProps ? (
          <Button {...buttonProps} onClick={() => setShow?.(true)} />
        ) : (
          <IconButton {...iconButtonProps} onClick={() => setShow?.(true)} />
        ))}
    </>
  );
}
