import {useCallback, useEffect, useState} from "react";

export type Tab = {
  url: string;
  title: string;
  favicon: string;
}

export default function useOpenTabs() {
  const [openTabs, setOpenTabs] = useState<Tab[]>();
  const onMessage = useCallback((event: MessageEvent) => {
    if (event.data?.type === 'LIST_OPEN_TABS_RESPONSE') {
      const tabs = event.data.payload;
      if (Array.isArray(tabs)) {
        setOpenTabs(tabs.filter((value) => typeof value === "object"));
      }
    }
  }, []);
  useEffect(() => {
    if (!Array.isArray(openTabs)) {
      window.addEventListener("message", onMessage);
      window.postMessage({type: 'LIST_OPEN_TABS'});
      return () => window.removeEventListener("message", onMessage);
    }
  }, [onMessage, openTabs]);
  return openTabs;
}
