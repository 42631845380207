import React, {useCallback, useEffect, useRef} from 'react';
import Article from "../../../data/models/Article";
import ArticleView from "./ArticleView";
import ArticleViewPlaceholder from "./ArticleViewPlaceholder";
import {useAppSelector} from "../../../data/redux/hooks";
import {Navigate} from 'react-router-dom';
import ClientRouter from "../../../routers/ClientRouter";
import {setShowTopNav} from "../../../data/redux/actions";
import {ARTICLE_VIEWED} from "../topic/articles/TopicArticles";
import useClosedSideNav from "../../../hooks/custom/useClosedSideNav";

type Props = {
  article: Article | number,
};

export default function ArticlePage({article}: Props) {
  const showTopNav = useRef(useAppSelector(useCallback(state => state.cache.showTopNav, [])));
  const lastStableArticle = useRef<Article | null>(null);
  localStorage.setItem(ARTICLE_VIEWED, '1');
  useClosedSideNav();
  useEffect(() => {
    if (showTopNav.current) {
      setShowTopNav(false);
      return () => {
        setShowTopNav(true);
      }
    }
  }, []);
  if (typeof article === "number") {
    if (lastStableArticle.current && lastStableArticle.current.id === article) {
      return <Navigate to={ClientRouter.topics(lastStableArticle.current?.topic)}/>;
    }
    return <ArticleViewPlaceholder article={article}/>
  }
  lastStableArticle.current = article;
  return <ArticleView article={article}/>
}
