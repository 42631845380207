import React, { useMemo } from 'react';
import User from '../../data/models/User';
import CRUD from '../../components/crud/CRUD';
import ServerRouter from '../../routers/ServerRouter';
import AuthFormRepresentation from './AuthFormRepresentation';
import { login } from '../../data/redux/authUtils';
import MetaTagsList from '../../components/MetaTagsList';

export default function Login() {
  const formButton = useMemo(
    () => (
      <div className="d-flex flex-grow-1">
        <button className="button button-purple button-text text-gray-4" type="submit">
          Sign In
        </button>
      </div>
    ),
    [],
  );
  return useMemo(() => {
    const crud = new CRUD(
      ServerRouter.obtainAuthToken(),
      [User.fields.email, { ...User.fields.password, withForgotPassword: true }],
      login,
    );
    return (
      <>
        <MetaTagsList type="login" />
        <AuthFormRepresentation
          title={'Sign-In'}
          isLogin
          body={<crud.post formButton={formButton} />}
        />
      </>
    );
  }, [formButton]);
}
