import React from 'react';
import styles from './styles.module.css';
import LandingNav from '../../components/nav/LandingNav';
import { ReactComponent as CheckIcon } from '../../assets/img/icons/check-2.svg';
import About1 from '../../assets/img/about/Section-1-img.png';
import About2 from '../../assets/img/about/Section-2-img.png';
import About3 from '../../assets/img/about/Section-3-img.png';
import About4 from '../../assets/img/about/Section-4-img.png';
import About5 from '../../assets/img/about/Section-5-img.png';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import ClientRouter from '../../routers/ClientRouter';
import LandingFooter from '../../components/footer/LandingFooter';
import MetaTagsList from '../../components/MetaTagsList';

const About = () => {
  return (
    <div className={styles.about + ' about'}>
      <MetaTagsList type="home" />
      <LandingNav />
      <div>
        <div className={styles.aboutInner}>
          <div className={cn(styles.aboutSection, styles.aboutSection1)}>
            <img src={About1} alt="loading..." className={styles.aboutSection1BG} />
            <div className={styles.aboutContentRow}>
              <div className={styles.aboutTextContainer}>
                <h1 className="sparks-h2">Welcome to getSparks!</h1>
                <div className="body-text my-3">
                  We are a content consumption platform. Our objective is to make your research
                  actionable and provide tools and experience that adds efficiency to read, save,
                  analyze content from anywhere and everywhere.
                </div>
                <Link to={ClientRouter.register()}>
                  <button className={'button-variant-1 button-variant-1-lg ' + styles.aboutCTA}>
                    Sign Up
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className={cn(styles.aboutSection, styles.aboutSection2)}>
            <div className={styles.aboutContentRow + ' align-items-center h-100'}>
              <img src={About2} alt="loading..." className={styles.aboutSection2BG} />
              <div className={styles.aboutTextContainer} style={{ paddingRight: 80 }}>
                <h2 className="sparks-h2-mobile">Our Philosophy</h2>
                <div className="body-text">
                  New ideas and interests are developed from past experiences and inspirations. We
                  believe that by making it easier for people to organize, consume, and research we
                  can SPARK ideas from everyone.
                </div>
              </div>
            </div>
          </div>
          <div className={cn(styles.aboutSection, styles.aboutSection3)}>
            <div className={styles.aboutContentRow}>
              <div className={styles.aboutTextContainer}>
                <h2 className="sparks-h2-mobile">Our Inspiration</h2>
                <div className="body-text">
                  <b>Content that sparks your interest.</b>The idea for getSparks was born based on
                  my personal experience and need. I spend a lot of time on the web, reading
                  articles and doing research. There have been times where I like something and want
                  to save for future reference or want to dig more in the topic for my research.
                  Unfortunately, I did not find any solution that let me save the content in a
                  usable way. A solution that allows me to take action on my research, help me
                  discover more/similar content across the web or even find pages that I have
                  researched in an easy way.
                </div>
              </div>
              <img src={About3} alt="loading..." className={styles.aboutSection3BG} />
            </div>
          </div>
          <div className={cn(styles.aboutSection, styles.aboutSection4)}>
            <div className={styles.aboutContentRow}>
              <img src={About4} className={styles.aboutSection4BG} alt="loading..." />
              <div className={styles.aboutTextContainer}>
                <h2 className="sparks-h2-mobile">Idea</h2>
                <div className="body-text">
                  We are building getSparks as a “Content Consumption” and “Content Organization”
                  platform that provides tools and resources for users to take action on their saved
                  content. Based on our research we solve this by providing listed tools for users
                </div>
                <div className={styles.aboutTextChecksContainer}>
                  <div className={styles.aboutTextCheckRow}>
                    <CheckIcon className={styles.aboutTextCheck} height={25} width={25} />
                    <div className="body-text">Saving and Organizing</div>
                  </div>
                  <div className={styles.aboutTextCheckRow}>
                    <CheckIcon className={styles.aboutTextCheck} height={25} width={25} />
                    <div className="body-text">Searching</div>
                  </div>
                  <div className={styles.aboutTextCheckRow}>
                    <CheckIcon className={styles.aboutTextCheck} height={25} width={25} />
                    <div className="body-text">Summarizing</div>
                  </div>
                  <div className={styles.aboutTextCheckRow}>
                    <CheckIcon className={styles.aboutTextCheck} height={25} width={25} />
                    <div className="body-text">Notes</div>
                  </div>
                  <div className={styles.aboutTextCheckRow}>
                    <CheckIcon className={styles.aboutTextCheck} height={25} width={25} />
                    <div className="body-text">Asking Questions</div>
                  </div>
                  <div className={styles.aboutTextCheckRow}>
                    <CheckIcon className={styles.aboutTextCheck} height={25} width={25} />
                    <div className="body-text">Discovery</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={cn(styles.aboutSection, styles.aboutSection5)}>
            <div className={styles.aboutContentRow}>
              <div className={styles.aboutTextContainer}>
                <h2 className="sparks-h2-mobile">An Example Case</h2>
                <div className="body-text">
                  You’re staring a new hobby. Here’s what you’d do with getSparks to get all your
                  content in one place in just a few steps:
                </div>
                <div className={styles.aboutTextChecksContainer}>
                  <div className={styles.aboutTextCheckRow}>
                    <CheckIcon className={styles.aboutTextCheck} height={25} width={25} />
                    <div className="body-text">Research on Web</div>
                  </div>
                  <div className={styles.aboutTextCheckRow}>
                    <CheckIcon className={styles.aboutTextCheck} height={25} width={25} />
                    <div className="body-text">Organize and Save pages to getSparks</div>
                  </div>
                  <div className={styles.aboutTextCheckRow}>
                    <CheckIcon className={styles.aboutTextCheck} height={25} width={25} />
                    <div className="body-text">
                      Add notes, extract useful information, discover similar content, to enhance
                      your research
                    </div>
                  </div>
                  <div className={styles.aboutTextCheckRow}>
                    <CheckIcon className={styles.aboutTextCheck} height={25} width={25} />
                    <div className="body-text">Take Action</div>
                  </div>
                </div>
              </div>
              <img src={About5} alt="loading..." className={styles.aboutSection5BG} />
            </div>
          </div>
        </div>
      </div>
      <LandingFooter />
    </div>
  );
};

export default About;
