import React, { useCallback } from 'react';
import { PremiumModal } from '../sidenav/FreePlan';
import { useAppDispatch, useAppSelector } from '../../../data/redux/hooks';
import { RootState } from '../../../data/redux/store';
import ServerRouter from '../../../routers/ServerRouter';
import { getHeaders } from '../../../routers/utils';
import { setUser } from '../../../data/redux/actions';

export const FreeCard = (props: any) => {
  return (
    <div className={'col-lg-3'}>
      <div className={'card'}>
        <div className={'card-header'}>Free User</div>
        <div className="card-body">
          <p>Ready to go beyond free plan, Sign up for Premium.</p>
          <div className="justify-content-center">
            <PremiumModal
              showModal={false}
              onCancel={() => {}}
              buttonName="View Plans"
              signedUser={props.userEmail}
            ></PremiumModal>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PremiumCard = (props: any) => {
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
  const dispatch = useAppDispatch();
  const handleReActivateSubscription = async () => {
    const response = await fetch(ServerRouter.reactivateSubscription(), { headers: getHeaders() });
    const subscription_status = await response.json();
    dispatch(
      setUser({
        ...user,
        subscription_cancelled: subscription_status.subscription_cancelled,
      }),
    );
  };
  return (
    <div className={'col-lg-3'}>
      <div className={'card'}>
        <div className={'card-header'}>Premium User</div>
        <div className="card-body">
          <div className="text-start text-sm">
            <p className="text-capitalize">
              <b>Paid {props.userPeriod}ly</b>
            </p>
            {user?.plan_name === 'Premium' &&
            user?.plan_status !== 'Inactive' &&
            user?.subscription_cancelled === false ? (
              <>
                <p>Next Billing: {props.userEndDate.slice(0, 10)}</p>
                <p>Last Payment: {props.userStartDate.slice(0, 10)}</p>
              </>
            ) : (
              <>
                <p>
                  Next Billing: <b>Subscription Cancelled</b>
                </p>
                <div className="d-flex justify-content-center m-3">
                  <button onClick={handleReActivateSubscription} className="btn btn-success btn-sm">
                    Enable Subscription Again
                  </button>
                </div>
                <p>Last Payment: {props.userStartDate.slice(0, 10)}</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
