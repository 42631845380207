import React, { ReactNode } from 'react';
import { Dropdown } from 'react-bootstrap';

type Props = {
  toggle?: ReactNode;
  menu?: ReactNode;
  toggleWrapperClassName?: string;
  defaultShow?: boolean;
  removeInset?: boolean;
};

export default function NavDropdown({
  toggle,
  menu,
  toggleWrapperClassName,
  defaultShow,
  removeInset,
}: Props) {
  const CustomToggle = React.forwardRef<
    HTMLDivElement,
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  >(({ children, onClick }, ref) => (
    <div
      className={`btn btn-icon btn-transparent-dark ${toggleWrapperClassName}`}
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick?.(e);
      }}
    >
      {children}
    </div>
  ));
  const CustomMenu = React.forwardRef<
    HTMLDivElement,
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  >(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    let styleProp = { ...style };
    if (removeInset) {
      styleProp.inset = undefined;
    }
    return (
      <div
        ref={ref}
        style={{ ...styleProp, transform: 'initial', inset: undefined }}
        className={className}
        aria-labelledby={labeledBy}
      >
        {children}
      </div>
    );
  });
  return (
    <Dropdown defaultShow={defaultShow}>
      <Dropdown.Toggle as={CustomToggle}>{toggle}</Dropdown.Toggle>
      <Dropdown.Menu
        className={'shadow animated--fade-in-up'}
        style={{ inset: 'initial' }}
        as={CustomMenu}
      >
        {menu}
      </Dropdown.Menu>
    </Dropdown>
  );
}
