import React, { useCallback } from 'react';
import { Header } from '../Header';
import { getInfoTabActive, InfoTabShowing } from '../../../data/redux/utils';
import ClientRouter from '../../../routers/ClientRouter';
import Topic from '../../../data/models/Topic';
import { useAppSelector } from '../../../data/redux/hooks';
import { RootState } from '../../../data/redux/store';
import { setInfoTab } from '../../../data/redux/actions';
import { ReactComponent as ArrowForward } from '../../../assets/img/icons/arrow-forward.svg';
import { ReactComponent as SummarizeIcon } from '../../../assets/img/icons/summarize.svg';
import { ReactComponent as NotesIcon } from '../../../assets/img/icons/notes.svg';
import { ReactComponent as PrintIcon } from '../../../assets/img/icons/print.svg';
import { ReactComponent as DotsIcon } from '../../../assets/img/icons/dots.svg';
import { ReactComponent as InfoIcon } from '../../../assets/img/icons/info.svg';
import { ReactComponent as QuestionIcon } from '../../../assets/img/icons/question.svg';
import { ReactComponent as SummaryIcon } from '../../../assets/img/icons/summary.svg';
import ServerRouter from '../../../routers/ServerRouter';
import { getHeaders } from '../../../routers/utils';
import Article from '../../../data/models/Article';
import { Link } from 'react-router-dom';
import Tour from '../../../components/Tour';
import BlueLogo from '../../../components/nav/BlueLogo';
import ArticleViewTypeSwitch from './ArticleViewTypeSwitch';
import useMenu from '../../../hooks/custom/useMenu';
import { Colors } from '../../../assets/colors';
import Tooltip from '../../../components/Tooltip';

export const ARTICLE_HEADER_TOUR_COMPLETED = 'article_header_tour_completed';

type Props = {
  tab?: number;
  setTab?: (tab: number) => void;
  topic?: Topic;
  noBottomSpace?: boolean;
  article: Article;
  hidden?: boolean;
};

export default function ArticleViewHeader({ article, hidden }: Props) {
  const { toggleMenu, MenuContainer, ItemContainer, placeRef } = useMenu();
  const infoTab = useAppSelector(useCallback((state: RootState) => state.cache.infoTab, []));

  const switchTab = useCallback(
    (showing: InfoTabShowing) => {
      const visible =
        !infoTab.visible || infoTab.showing !== showing || infoTab.about !== 'article';
      setInfoTab({ visible, showing: showing, about: 'article' });
    },
    [infoTab],
  );

  const handleSummary = useCallback(() => {
    if (article?.id) {
      fetch(ServerRouter.pageActivity(), {
        method: 'POST',
        headers: getHeaders(),
        credentials: 'include',
        body: JSON.stringify({
          page: article.id,
          is_summary_viewed: true,
        }),
      }).catch(e => console.error(e));
      switchTab('summary');
    }
  }, [article?.id, switchTab]);

  const topic = useAppSelector(
    useCallback((state: RootState) => article.getTopic(state.cache), [article]),
  );

  const tourActive =
    !localStorage.getItem(ARTICLE_HEADER_TOUR_COMPLETED) &&
    !article.url?.match(':\\/\\/[^/]+\\/#\\/how-does-sparks-work\\/$');

  return hidden ? null : (
    <div className=" position-fixed top-0 left-0 right-0" style={{ zIndex: 2 }}>
      <Header className="article-header px-3" sticky noBottomSpace noMaxWidth noPadding>
        <div className="d-flex justify-content-between position-relative align-items-center">
          <div id="article-back-to-topic-btn">
            {!!topic ? (
              <div className="d-flex align-items-center overflow-hidden back-button">
                <Link
                  to={`/topics/${topic?.id}`}
                  id="article-back-to-topic-btn"
                  className="article-button article-button-icon article-button-icon--secondary"
                >
                  <ArrowForward style={{ transform: 'rotate(180deg)' }} />
                </Link>

                <span className="text-black body-text mx-1 mr-0 single-line-text">
                  {topic?.name}
                </span>
              </div>
            ) : (
              <div />
            )}
          </div>

          <div className="d-flex gap-2" id="article-actions">
            <button
              onClick={handleSummary}
              className={`sparks-button article-button article-button--primary ${
                getInfoTabActive(infoTab, 'summary') ? 'active' : ''
              } d-flex align-items-center`}
            >
              <SummarizeIcon width={16} height={16} /> <span className="mx-2 mr-0">Summary</span>
            </button>

            <button
              onClick={() => switchTab('notes')}
              className={`sparks-button article-button ${
                getInfoTabActive(infoTab, 'notes') ? 'article-button--active' : ''
              } notes-button d-flex align-items-center`}
            >
              <NotesIcon fill="black" width={16} height={16} />{' '}
              <span className="mx-2 mr-0">Notes</span>
            </button>
            <Tooltip placement="bottom" overlay="Ask a question">
              <button
                onClick={() => switchTab('questions')}
                className={`sparks-button article-button ${
                  getInfoTabActive(infoTab, 'questions') ? 'article-button--active' : ''
                } d-flex align-items-center`}
              >
                <QuestionIcon fill="black" width={16} height={16} />{' '}
              </button>
            </Tooltip>
            <Tooltip placement="bottom" overlay="See more">
              <button
                className="sparks-button article-button article-button-icon"
                onClick={toggleMenu}
                ref={ref => placeRef(ref, 0)}
              >
                <DotsIcon />
              </button>
            </Tooltip>
          </div>
          <MenuContainer>
            <ArticleViewTypeSwitch containerClassName="hide-mobile" article={article} short />
            <div className="d-flex flex-column gap-1">
              <div onClick={() => switchTab('summary')}>
                <ItemContainer>
                  <SummaryIcon fill={Colors.blueGray00} width={15} height={15} />{' '}
                  <span className="body-text-sm">View Summary</span>
                </ItemContainer>
              </div>
              <div className="blue-gray-divider-sm" />
              <Link to={ClientRouter.printArticles(article.id)} className="text-decoration-none">
                <ItemContainer>
                  <PrintIcon fill={Colors.blueGray00} width={15} height={15} />{' '}
                  <span className="body-text-sm">Print</span>
                </ItemContainer>
              </Link>
              <div onClick={() => switchTab('details')}>
                <ItemContainer>
                  <InfoIcon fill={Colors.blueGray00} width={15} height={15} />{' '}
                  <span className="body-text-sm">Details</span>
                </ItemContainer>
              </div>
            </div>
          </MenuContainer>
        </div>
      </Header>
      {tourActive && (
        <Tour
          steps={[
            {
              target: '#article-actions',
              title: <BlueLogo />,
              content: 'View Page Summary, Add Notes, Print, or see more details',
              placement: 'bottom',
              disableBeacon: true,
            },
            {
              target: '#article-back-to-topic-btn',
              title: <BlueLogo />,
              content: 'Click on the Topic Name to go back to Dashboard.',
              placement: 'bottom',
            },
          ]}
          disableOverlayClose={true}
          scrollOffset={() => 200}
          onFinish={() => localStorage.setItem(ARTICLE_HEADER_TOUR_COMPLETED, '1')}
        />
      )}
    </div>
  );
}
