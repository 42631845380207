import React from 'react';
import Topic from "../../../data/models/Topic";
import TopicView, {TopicViewProps} from "./TopicView";
import {TopicViewPlaceholder} from "./TopicViewPlaceholder";

type Props = {
  topic: Topic | number,
} & TopicViewProps;

export default function TopicPage(props: Props) {
  const {topic} = props;
  if (typeof topic === "number")
    return <TopicViewPlaceholder topic={topic}/>
  return <TopicView topic={topic} {...(props as TopicViewProps)}/>
}
