import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import Awaiter from '../messages/Awaiter';
import { processJSONObjectResponse, ProcessJSONObjectResponseProps } from '../../routers/utils';
import ErrorMessage from '../messages/ErrorMessage';
import { JSONObject } from '../../data/models/Model';

type Props = PropsWithChildren<
  {
    hidden?: boolean;
    onCancel?: () => void;
    headers?: Headers;
    nonRetriable?: boolean;
    cancelComponent?: React.ReactNode;
    onError?: (errorText: string, json?: JSONObject) => void;
    timeout?: number;
  } & Omit<ProcessJSONObjectResponseProps, 'onError'>
>;

export default function NetworkJSONLoader(props: Props) {
  const {
    children,
    hidden,
    onCancel,
    headers,
    body,
    url,
    method,
    onOk,
    nonRetriable,
    cancelComponent,
    onError,
    timeout,
  } = props;
  const [error, setError] = useState<string | undefined>();
  const load = useCallback(() => {
    setError(undefined);
    processJSONObjectResponse({
      onError: async error => {
        let message;
        let json;
        try {
          json = await error.json();
          if (!('non_field_errors' in json)) {
            if ('detail' in json) message = json['detail'];
            else if ('error' in json) message = json['error'];
          } else {
            message = json['non_field_errors'];
          }
        } catch (e: any) {
          message = e.message;
        }
        const errorText = error.statusText + (message ? ': ' + message : '');
        onError ? onError(errorText, json) : setError(errorText);
      },
      headers,
      body,
      url,
      method,
      onOk,
    });
  }, [body, headers, method, onError, onOk, url]);
  useEffect(() => {
    if (timeout) {
      setTimeout(load, timeout);
    } else {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load]);
  if (hidden) return null;
  if (error)
    return (
      <ErrorMessage
        onRetry={nonRetriable ? undefined : load}
        onCancel={onCancel}
        cancelComponent={cancelComponent}
      >
        {children}: {error}
      </ErrorMessage>
    );
  return <Awaiter>{children && <>{children}...</>}</Awaiter>;
}
