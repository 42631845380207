import Model from '../models/Model';
import { Draft } from '@reduxjs/toolkit';
import React from 'react';
import Article from '../models/Article';
import ArticleNote from '../models/ArticleNote';
import ArticleMetadata from '../models/ArticleMetadata';
import Topic from '../models/Topic';
import TopicNote from '../models/TopicNote';
import User from '../models/User';
import Question from '../models/Question';
import PageGroup from '../models/PageGroup';
import Tag from '../models/Tag';

export type ReduxIndexer<T extends Model> = { [x: string]: T | undefined };
export type DeleteReducer = React.Dispatch<Draft<StateType>>;

export function indexerFilter<T extends Model>(
  indexer: ReduxIndexer<T>,
  instanceFilter: (instance: T | undefined) => boolean,
) {
  return Object.fromEntries(Object.entries(indexer).filter(([, value]) => instanceFilter(value)));
}

export function deleteIndexerFilter<T extends Model>(
  state: Draft<StateType>,
  indexer: ReduxIndexer<T>,
  condition: (instance: T) => boolean,
) {
  return indexerFilter(indexer, instance => {
    if (instance !== undefined) {
      const keep = !condition(instance);
      if (!keep) {
        instance.delete(state);
      }
      return keep;
    }
    return false;
  });
}

export function byId<T extends Model>(indexer: ReduxIndexer<T>, id?: number) {
  return id !== undefined ? indexer[id] : undefined;
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export type MenuTab = 'open' | 'closed' | 'free' | 'toggled';
export type InfoTabShowing = 'details' | 'notes' | 'summary' | 'questions';
export type InfoTabAbout = 'article' | 'topic';
export type ArticleViewType = 'preview' | 'text' | 'web';

export type InfoTab = {
  visible: boolean;
  showing: InfoTabShowing;
  about: InfoTabAbout;
};

export const getInfoTabActive = (infoTab: InfoTab, showing: InfoTabShowing) =>
  infoTab.visible && infoTab.showing === showing;

export type CardViewType = 'tile' | 'list' | 'headlines';

export type StateType = {
  showTopNav: boolean;
  showSideNav: MenuTab;
  infoTab: InfoTab;
  user?: User;
  token?: string;
  tokenId?: string;
  isSignedUp: boolean;
  articles: ReduxIndexer<Article>;
  tags: ReduxIndexer<Tag>;
  questions: ReduxIndexer<Question>;
  pageGroups: ReduxIndexer<PageGroup>;
  articlesNotes: ReduxIndexer<ArticleNote>;
  articlesMetadata: ReduxIndexer<ArticleMetadata>;
  topics: ReduxIndexer<Topic>;
  topicsNotes: ReduxIndexer<TopicNote>;
  tagsFilter: number[];
  pageNumber: number;
  cardViewType: CardViewType;
};
