import React from 'react';
import styles from './styles.module.css';

type Props = {
  question: {
    question: string;
  };
  createQuestion: (question: string) => any;
};

const DefaultQuestionItem = ({ question, createQuestion }: Props) => {
  return (
    <div onClick={() => createQuestion(question.question)} className={styles.defaultQuestion}>
      <span>{question.question}</span>
    </div>
  );
};

export default DefaultQuestionItem;
