import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

const useNavigateToDiscover = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return useCallback(
    () => navigate({ pathname: location.pathname, hash: 'discover' }),
    [location, navigate],
  );
};

export default useNavigateToDiscover;
