import React, { ReactNode, useCallback } from 'react';
import { setInfoTab } from '../../../data/redux/actions';
import { useAppSelector } from '../../../data/redux/hooks';
import { RootState } from '../../../data/redux/store';
import { ReactComponent as ArrowForward } from '../../../assets/img/icons/arrow-forward.svg';
import { ReactComponent as Caret } from '../../../assets/img/icons/caret.svg';

type Props = {
  title?: string;
  mobileTitle?: string;
  titles?: string[];
  children?: ReactNode;
  onBackPress?: () => void;
};

const InfoTabHeader = ({ title = '', mobileTitle, titles, children, onBackPress }: Props) => {
  const infoTab = useAppSelector(useCallback((state: RootState) => state.cache.infoTab, []));

  return (
    <div className="info-tab-header position-relative bg-white d-flex justify-content-between align-items-center border-color-gray-3 border-bottom border-1 py-sm-3 px-sm-5 bg-white">
      <div className="d-flex align-items-center gap-1">
        <button
          className="article-button article-button-icon article-button-icon--secondary"
          onClick={() => {
            if (onBackPress) onBackPress();
            else setInfoTab({ ...infoTab, visible: false });
          }}
        >
          <ArrowForward
            fill="#000"
            width={16}
            height={16}
            style={{ transform: 'rotate(180deg)' }}
          />
        </button>

        <div className={mobileTitle ? 'hide-mobile' : ''}>
          {titles?.length ? (
            <div className="d-flex gap-3 align-items-center">
              {titles.map((text, i) => (
                <>
                  <div key={`title-${text}`} className="body-text-sm">
                    {text}
                  </div>
                  {i !== titles.length - 1 && <Caret fill="#000" height={15} width={7} />}
                </>
              ))}
            </div>
          ) : (
            <div className="body-text-sm">{title}</div>
          )}
        </div>
        {!!mobileTitle && <div className="only-mobile body-text-sm">{mobileTitle}</div>}
      </div>
      {children}
    </div>
  );
};

export default InfoTabHeader;
