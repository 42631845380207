import TimeStampedModel from './TimeStampedModel';

export default class PageGroup extends TimeStampedModel {
  readonly id?: number;
  readonly created_at?: string;
  readonly updated_at?: string;
  readonly name?: string;

  constructor(jsonData: Partial<PageGroup>) {
    super(jsonData);
    Object.assign(this, jsonData);
  }
}
