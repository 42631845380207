import indexImage from "../../../assets/img/backgrounds/index-image.jpg";
import RoundedBorder from "../../../components/RoundedBorder";
import React from "react";

type Props = {
  theme?: "light" | "dark",
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;

export default function LandingHeader({children, theme, ...rest}: Props) {
  const themeClass = theme === "light" ? "page-header-ui-light bg-white" : "page-header-ui-dark overlay overlay-80";
  return <header className={`page-header-ui ${themeClass} bg-img-cover`}
                 style={{backgroundImage: `url(${indexImage})`}}
                 {...rest}>
    <div className={"page-header-ui-content py-5 position-relative"}>
      <div className={"container px-5"}>
        <div className={"row gx-5 justify-content-center"}>
          <div className={"col-xl-10 col-lg-10 text-center"}>
            {children}
          </div>
        </div>
      </div>
    </div>
    <RoundedBorder className={"text-white"}/>
  </header>;
}
