import React, { FunctionComponent, SVGProps } from 'react';
import styles from './styles.module.css';
import { ReactComponent as StarsIcon } from '../../assets/img/icons/stars.svg';

type Props = {
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  title: string;
  desc: string;
  descExpanded: string;
  buttonText: string;
  buttonJSX?: JSX.Element;
  buttonColor?: string;
  smallIconBackgroundColor?: string;
  backgroundColor: string;
  expanded: boolean;
  onClick?: () => any;
  clicked?: boolean;
  buttonId?: string;
};

const DashboardTile = ({
  Icon,
  title,
  buttonText,
  onClick,
  descExpanded,
  desc,
  backgroundColor,
  smallIconBackgroundColor,
  buttonColor,
  expanded,
  buttonId,
  clicked,
}: Props) => {
  return expanded ? (
    <div className={styles.dashboardTileExpanded} style={{ backgroundColor }}>
      <StarsIcon width={57} height={57} />
      <h3 className="font-helvetica sparks-h3 text-white mt-4">{title}</h3>
      <div className="font-helvetica body-text text-white">{descExpanded}</div>
      <button
        style={buttonColor ? { backgroundColor: buttonColor } : {}}
        onClick={onClick}
        {...(buttonId ? { id: buttonId } : {})}
        className="font-helvetica button-reset-defaults article-button text-white button-text mt-4 w-75 d-flex align-items-center justify-content-center gap-2 lh-1"
      >
        <Icon width={17} height={17} fill="#fff" />
        {buttonText}
      </button>
    </div>
  ) : (
    <div
      onClick={onClick}
      className={
        styles.dashboardTile + `${clicked ? ' border border-1 border-color-blue-gray-2' : ''}`
      }
      style={!clicked ? { backgroundColor } : {}}
    >
      <div
        className={styles.dashboardTileIconWrapper}
        style={{ backgroundColor: smallIconBackgroundColor || backgroundColor }}
      >
        <Icon width={26} height={26} fill="#fff" />
      </div>
      <div>
        <h4
          className={`font-helvetica sparks-h4 text-align-start mb-0 ${
            clicked ? 'text-black' : 'text-white'
          }`}
        >
          {title}
        </h4>
        <div
          className={`font-helvetica body-text text-align-start ${
            clicked ? 'text-blue-gray-0' : 'text-white'
          }`}
        >
          {desc}
        </div>
      </div>
    </div>
  );
};

export default DashboardTile;
