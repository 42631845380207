import React from 'react';
import styles from './styles.module.css';
import LandingNav from '../../components/nav/LandingNav';
import { Link } from 'react-router-dom';
import { SummaryFooter, SummaryTitleSection } from './Summary';
import MetaTagsList from '../../components/MetaTagsList';

type Props = {};

const UninstallLP = (props: Props) => {
  return (
    <div className={styles['uninstall'] + ' bg-white'}>
      <MetaTagsList type="home" />
      <LandingNav
        hideLinks
        buttons={
          <div className="text-black body-text">
            Don’t have an account?{' '}
            <Link className="sparks-link sparks-link--blue" to="/register">
              Sign up - It’s Free
            </Link>
          </div>
        }
      />
      <div className="divider" />
      <h1 className={styles['uninstall-title'] + ' pt-10 pb-10'}>
        getSparks Chrome extension removed!
      </h1>
      <div className={styles['uninstall-add-section'] + ' pb-10'}>
        <SummaryTitleSection
          mainButtonClassName="blue-bg sparks-button-md"
          mainButtonText={
            <span style={{ fontWeight: 400 }}>
              <b>Add GetSparks</b> Its Free
            </span>
          }
          descClassName="body-text-max"
          titleClassName="sparks-h2 text-black no-gradient"
        />
      </div>
      <SummaryFooter />
    </div>
  );
};

export default UninstallLP;
