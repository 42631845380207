import React, {
  PropsWithChildren,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAppSelector } from '../../../data/redux/hooks';
import styles from './InfoTab.module.css';

type Props = PropsWithChildren<{
  scrollRef: React.MutableRefObject<HTMLDivElement | null> | null;
  className?: string;
}>;

export default function InfoTab({ children, scrollRef, className = '' }: Props) {
  const infoTab = useAppSelector(useCallback(state => state.cache.infoTab, []));
  const resizerRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<number>(0);
  const [resizing, setResizing] = useState<boolean>(false);
  //TODO: use useTransition on React v18 release
  const intervalRef = useRef<number>();
  const positionRef = useRef<number>();
  const resize = useCallback((event: MouseEvent) => {
    positionRef.current = window.innerWidth - event.clientX;
  }, []);
  const onMouseDown = useCallback(
    (event: MouseEvent) => {
      positionRef.current = window.innerWidth - event.clientX;
      intervalRef.current = window.setInterval(() => {
        positionRef.current !== undefined && setPosition(Math.max(positionRef.current, 200));
      }, 50);
      document.removeEventListener('mousemove', resize);
      document.addEventListener('mousemove', resize);
      setResizing(true);
      document.body.style.userSelect = 'none';
    },
    [resize],
  );
  const onMouseUp = useCallback(() => {
    document.removeEventListener('mousemove', resize);
    clearInterval(intervalRef.current);
    document.body.style.userSelect = '';
    setResizing(false);
  }, [resize]);
  useLayoutEffect(() => {
    const resizer = resizerRef.current;
    resizer?.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mouseup', onMouseUp);
    return () => {
      resizer?.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', resize);
      clearInterval(intervalRef.current);
      document.body.style.userSelect = '';
      setResizing(false);
    };
  }, [onMouseDown, onMouseUp, resize]);
  const visible = useMemo(() => {
    if (children) return infoTab.visible;
    return false;
  }, [children, infoTab.visible]);
  return useMemo(() => {
    const width = visible ? (position ? position + 'px' : '45%') : '0';
    const transition = resizing ? 'initial' : 'width 0.15s ease-in-out';
    return (
      <div
        ref={scrollRef}
        className={
          styles.tab +
          ` ${visible ? styles.tabVisible : ''}` +
          ' shadow-left sidenav-light d-flex border ' +
          className
        }
        style={{ width, transition }}
      >
        {/*<div ref={resizerRef} className={styles.resizer} />*/}
        <div
          style={{ transform: visible ? undefined : 'translateX(30rem)', flexGrow: 1, width: 0 }}
        >
          <div>{children}</div>
        </div>
      </div>
    );
  }, [children, position, resizing, visible, scrollRef, className]);
}
