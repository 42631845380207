import { useAppSelector } from '../../data/redux/hooks';
import { useCallback, useMemo } from 'react';

const useAllTopicChildren = (topicId?: number) => {
  const topics = useAppSelector(useCallback(state => state.cache.topics, []));
  return useMemo(() => {
    if (!topicId) return [];
    const topicChildren: number[] = [];
    const setAllChildren = (id: number) => {
      const children = topics[id]?.child;
      if (children) {
        topicChildren.push(...children);
        children.forEach(child => setAllChildren(child));
      }
    };
    setAllChildren(topicId);
    return topicChildren;
  }, [topics, topicId]);
};

export default useAllTopicChildren;
