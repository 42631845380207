import React, {CSSProperties, useMemo} from 'react';
import Tooltip from '../../Tooltip';
import {Placement} from "react-bootstrap/types";

export type IconButtonProps = {
  tooltip?: string,
  tooltipPlacement?: Placement,
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export default function IconButton(props: IconButtonProps) {
  const {tooltip, tooltipPlacement, ...rest} = props;
  const renderButton = useMemo(() => {
    let className = 'btn btn-transparent-dark';
    let style: CSSProperties = {};
    if (props.className) {
      className += ' ' + props.className;
    }
    if (!(props.style && props.style.padding)) {
      className += ' btn-icon';
    } else {
      style.borderRadius = '100%';
    }
    return <button {...rest} style={props.style ? {...props.style, ...style} : undefined} className={className}>
      {props.children}
    </button>
  }, [props.children, props.className, props.style, rest]);
  return useMemo(() => {
    if (tooltip) {
      return <Tooltip placement={tooltipPlacement} overlay={tooltip}>
        {renderButton}
      </Tooltip>
    }
    return renderButton;
  }, [renderButton, tooltip, tooltipPlacement])
}
