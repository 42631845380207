import { IField } from '../../components/crud/fields/Field';
import Topic, { TopicToken } from './Topic';
import { Categories, getTopicsOptions } from '../FormData';
import {
  byId,
  deleteIndexerFilter,
  DeleteReducer,
  indexerFilter,
  notEmpty,
  ReduxIndexer,
  StateType,
} from '../redux/utils';
import TimeStampedModel from './TimeStampedModel';
import { checkIsUrlValid } from '../../services/utils';
import { TagId } from './Tag';

export default class Article extends TimeStampedModel {
  static fields = {
    uploadType: { name: 'upload-type', label: '', type: 'upload-type', hidden: true } as IField,
    file: { name: 'pdf', label: 'Upload file', type: 'file', uploadType: 'pdf' } as IField,
    text: {
      name: 'text',
      label: 'Paste text',
      placeholder: 'Paste text here',
      type: 'textarea',
      uploadType: 'text',
      validate: (value: string) => {
        const numberOfWords = value.split(/\s+/).length;
        return numberOfWords > 50;
      },
      errorMessage: 'Text field must contain at least 50 words',
    } as IField,
    url: {
      name: 'url',
      label: 'Paste Link',
      placeholder: 'Copy & paste any article URL/link',
      uploadType: 'url',
      validate: checkIsUrlValid,
      errorMessage: 'Invalid link',
    } as IField,
    topic: (topics: ReduxIndexer<Topic>) =>
      ({ name: 'topic', options: getTopicsOptions(topics), createNewName: 'Create New' } as IField),
    category: { name: 'category', options: Categories } as IField,
  };
  readonly title?: string;
  readonly url?: string;
  readonly html?: string;
  readonly pdf?: string;
  readonly text?: string;
  readonly images?: string;
  readonly metatags?: string;
  readonly screenshot?: string;
  readonly topic?: number;
  readonly metadata?: number;
  readonly status?: string;
  readonly tags?: TagId[];
  readonly category?: number;
  readonly content_type?: string;
  readonly page_bg?: string;
  readonly page_content?: string;
  readonly site_icon?: string;
  readonly preferences?: number[];
  readonly tokens?: TopicToken[];
  readonly selected_tokens?: TopicToken[];

  constructor(jsonData: Partial<Article>) {
    super(jsonData);
    Object.assign(this, jsonData);
  }

  getTopic(state: StateType) {
    return byId(state.topics, this.topic);
  }

  getNotes(state: StateType) {
    return Object.values(
      indexerFilter(state.articlesNotes, instance => instance?.page === this.id),
    ).filter(notEmpty);
  }

  getMetadata(state: StateType) {
    const metadataArray = Object.values(
      indexerFilter(state.articlesMetadata, instance =>
        instance ? instance.id === this.metadata : false,
      ),
    );
    if (metadataArray.length > 1) {
      console.warn(`Got multiple metadata for article #${this.id}`);
    }
    return metadataArray.length ? metadataArray[0] : undefined;
  }

  delete: DeleteReducer = state => {
    state.articlesMetadata = deleteIndexerFilter(
      state,
      state.articlesMetadata,
      instance => instance.page === this.id,
    );
    state.articlesNotes = deleteIndexerFilter(
      state,
      state.articlesNotes,
      instance => instance.page === this.id,
    );
  };
}
