import React, { useCallback, useMemo, useRef, useState } from 'react';
import DashboardTile from './DashboardTile';
import { Colors } from '../../assets/colors';
import { ReactComponent as PlusIcon } from '../../assets/img/icons/plus.svg';
import { ReactComponent as DiscoverIcon } from '../../assets/img/icons/discover.svg';
import ArticleAddCRUDComponent from '../crud/ArticleAddCRUDComponent';
import Topic from '../../data/models/Topic';
import useNavigateToDiscover from '../../hooks/custom/useNavigateToDiscover';

export const ADD_PAGE_CLICKED = 'ADD_PAGE_CLICKED';
export const DISCOVER_CLICKED = 'DISCOVER_CLICKED';

type Props = {
  topic: Topic;
};

const DashboardTiles = ({ topic }: Props) => {
  const [addPageClicked, setAddPageClicked] = useState(!!localStorage.getItem(ADD_PAGE_CLICKED));
  const [discoverClicked, setDiscoverClicked] = useState(!!localStorage.getItem(ADD_PAGE_CLICKED));
  const articleCRUDRef = useRef<HTMLDivElement>(null);

  const navigateToDiscover = useNavigateToDiscover();

  const onAddPageClick = useCallback(() => {
    if (articleCRUDRef.current) {
      setAddPageClicked(true);
      localStorage.setItem(ADD_PAGE_CLICKED, 'true');
      articleCRUDRef.current.click();
    }
  }, [articleCRUDRef]);

  const onDiscoverClick = useCallback(() => {
    setDiscoverClicked(true);
    localStorage.setItem(DISCOVER_CLICKED, 'true');
    navigateToDiscover();
  }, [navigateToDiscover]);

  const expanded = !addPageClicked && !discoverClicked;

  const articleCRUDComponent = useMemo(
    () => (
      <ArticleAddCRUDComponent
        topic={topic}
        actionButton={onClick => <div ref={articleCRUDRef} onClick={onClick} />}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [topic.id],
  );

  return (
    <>
      {articleCRUDComponent}
      <div className="d-flex gap-3 mb-4">
        <DashboardTile
          Icon={PlusIcon}
          title="Add Page"
          buttonId="topic-add-page-btn"
          desc="URL, Text, Or PDFs"
          descExpanded={'Add content to generate summary, add notes, & ask questions.'}
          buttonText="Add Page"
          backgroundColor="#4B01B6"
          buttonColor={Colors.purple02}
          smallIconBackgroundColor={Colors.purple02}
          expanded={expanded}
          onClick={onAddPageClick}
          clicked={addPageClicked}
        />
        <DashboardTile
          Icon={DiscoverIcon}
          title="Discover"
          desc="Gain new knowledge, add new context."
          descExpanded="Gain new knowledge, add new context and more details to your research."
          buttonText="View"
          backgroundColor={Colors.blue01}
          buttonColor={Colors.blue03}
          smallIconBackgroundColor="#1f8fff"
          expanded={expanded}
          onClick={onDiscoverClick}
          clicked={discoverClicked}
        />
      </div>
    </>
  );
};

export default DashboardTiles;
