import React, { useEffect } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import SetupForm from './SetupForm';
import { getHeaders } from '../../../routers/utils';
import Awaiter from '../../../components/messages/Awaiter';
import ServerRouter from '../../../routers/ServerRouter';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

function StripeCard(props: any) {
  const [option, setOption] = React.useState({
    clientSecret: '',
    appearance: {
      theme: 'stripe',
      labels: 'floating',
      variables: {
        colorPrimary: '#0570de',
        colorBackground: '#ffffff',
        colorText: '#30313d',
        colorDanger: '#df1b41',
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        spacingUnit: '3px',
        borderRadius: '4px',
      },
    },
  });

  useEffect(() => {
    (async () => {
      const response = await fetch(ServerRouter.paymentIntentCreate(), { headers: getHeaders() });
      const { client_secret: clientSecret } = await response.json();
      // Render the form using the clientSecret
      let temp = option;
      temp.clientSecret = clientSecret;
      setOption({ ...temp });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return option.clientSecret ? (
    <Elements stripe={stripePromise} options={option}>
      <SetupForm closeModal={props.closeModal} yearlySub={props.yearlySub} />
    </Elements>
  ) : (
    <Awaiter>Loading...</Awaiter>
  );
}

export default StripeCard;
