import React, {PropsWithChildren, useMemo} from "react";

type Props = PropsWithChildren<{
  action?: () => void
}>

export function OnboardToDoAction({action, children}: Props) {
  return useMemo(() =>
    <div className="nav-link active pointer d-inline-block py-0 ms-auto text-nowrap"
         style={{textDecoration: "underline"}} onClick={action}>
      {children}
    </div>, [action, children]);
}
