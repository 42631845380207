import React, { useCallback, useMemo, useState } from 'react';
import { NoteList, NotesCluster } from '../notes/NoteList';
import { JSONObject } from '../../../data/models/Model';
import { useAppSelector } from '../../../data/redux/hooks';
import { RootState } from '../../../data/redux/store';
import UpgradeToPremium from '../../../components/messages/UpgradeToPremium';
import { PremiumModal } from '../sidenav/FreePlan';
import InfoTabHeader from './InfoTabHeader';
import { Plus } from 'react-feather';
import useMenu from '../../../hooks/custom/useMenu';
import { ReactComponent as SummaryIcon } from '../../../assets/img/icons/summary.svg';
import { ReactComponent as QuestionIcon } from '../../../assets/img/icons/question.svg';
import { ReactComponent as InfoIcon } from '../../../assets/img/icons/info.svg';
import { ReactComponent as NotesIcon } from '../../../assets/img/icons/notes.svg';
import { Colors } from '../../../assets/colors';
import { setInfoTab } from '../../../data/redux/actions';

type Props = {
  name: string;
  notesCluster: NotesCluster;
};

export const NotesInfoContext = React.createContext<{
  customHeaderContent?: JSX.Element;
  setCustomHeaderContent: (v: JSX.Element) => any;
  targetNote?: JSONObject;
  setTargetNote: React.Dispatch<React.SetStateAction<JSONObject | undefined>>;
}>({ setCustomHeaderContent: () => {}, setTargetNote: () => {} });

export default function NotesInfo({ notesCluster, name }: Props) {
  const [targetNote, setTargetNote] = useState<JSONObject>();
  const [premiumShow, setPremiumShow] = useState(false);
  const [premiumModalShow, setPremiumModalShow] = useState(false);
  const [customHeaderContent, setCustomHeaderContent] = useState<JSX.Element>();
  const { MenuContainer, Toggle, ItemContainer } = useMenu();

  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
  let topics_count = Object.keys(
    useAppSelector(useCallback((state: RootState) => state.cache.topicsNotes, [])),
  );
  let articles_counts = Object.keys(
    useAppSelector(useCallback((state: RootState) => state.cache.articlesNotes, [])),
  );
  let finalCount = topics_count.length + articles_counts.length;

  return useMemo(
    () => (
      <NotesInfoContext.Provider
        value={{ customHeaderContent, setCustomHeaderContent, targetNote, setTargetNote }}
      >
        <div className="bg-white notes-info">
          <InfoTabHeader
            titles={
              targetNote
                ? ['Notes', 'Add Note']
                : [`Notes ${notesCluster.notes.length ? `(${notesCluster.notes.length})` : ''}`]
            }
            mobileTitle={
              targetNote
                ? 'New Note'
                : `Notes ${notesCluster.notes.length ? `(${notesCluster.notes.length})` : ''}`
            }
            onBackPress={targetNote ? () => setTargetNote(undefined) : undefined}
          >
            {!targetNote ? (
              <button
                className="sparks-button article-button article-button--sm article-button--primary d-flex align-items-center gap-1 hide-mobile"
                onClick={() => {
                  if (
                    finalCount >= 3 &&
                    !(user?.plan_name === 'Premium' && user?.plan_status !== 'Inactive')
                  ) {
                    setPremiumShow(true);
                    return;
                  }
                  setTargetNote({});
                }}
              >
                <Plus width={16} height={16} style={{ marginBottom: 2 }} />
                <span>New Note</span>
              </button>
            ) : (
              <div className="hide-mobile">{customHeaderContent}</div>
            )}
            <div className="only-mobile">
              <Toggle />
            </div>
            <MenuContainer>
              <ItemContainer onClick={() => setTargetNote({})}>
                <NotesIcon width={18} height={18} fill="#9197A1" /> <span>Take a Note</span>
              </ItemContainer>
              <ItemContainer
                onClick={() => setInfoTab({ showing: 'summary', visible: true, about: 'article' })}
              >
                <SummaryIcon fill={Colors.blueGray00} width={15} height={15} />{' '}
                <span className="body-text-sm">View Summary</span>
              </ItemContainer>
              <ItemContainer
                onClick={() =>
                  setInfoTab({ showing: 'questions', visible: true, about: 'article' })
                }
              >
                <QuestionIcon width={18} height={18} fill="#9197A1" /> <span>Ask a Question</span>
              </ItemContainer>
              <ItemContainer
                onClick={() => setInfoTab({ showing: 'details', visible: true, about: 'article' })}
              >
                <InfoIcon fill={Colors.blueGray00} width={15} height={15} />{' '}
                <span className="body-text-sm">Details</span>
              </ItemContainer>
            </MenuContainer>
          </InfoTabHeader>
          {!!targetNote && (
            <div className="only-mobile bg-blue-gray-4 py-2 px-3 border-bottom border-1 border-color-gray-3">
              {customHeaderContent}
            </div>
          )}
          {user?.plan_name === 'Premium' && user?.plan_status !== 'Inactive' ? null : (
            <>
              {finalCount >= 3 && (
                <>
                  <UpgradeToPremium
                    show={premiumShow}
                    message={'notes'}
                    onHide={() => setPremiumShow(false)}
                    closePlan={() => {
                      setPremiumShow(false);
                      setPremiumModalShow(true);
                    }}
                  ></UpgradeToPremium>
                  <PremiumModal
                    showModal={premiumModalShow}
                    onCancel={() => setPremiumModalShow(false)}
                    signedUser={user?.email}
                  ></PremiumModal>
                </>
              )}
            </>
          )}

          <NoteList notesCluster={notesCluster} />
        </div>
      </NotesInfoContext.Provider>
    ),
    [
      customHeaderContent,
      targetNote,
      notesCluster,
      Toggle,
      MenuContainer,
      ItemContainer,
      user?.plan_name,
      user?.plan_status,
      user?.email,
      finalCount,
      premiumShow,
      premiumModalShow,
    ],
  );
}
