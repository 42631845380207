import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  name: string;
  urls: { [x: string]: string };
};

export function FooterList({ name, urls }: Props) {
  return (
    <div className="col-lg-4 col-md-4 mb-5 mb-lg-0">
      <div className="text-uppercase-expanded text-xs mb-4">{name}</div>
      <ul className="list-unstyled mb-0">
        {Object.keys(urls).map((item, index) => (
          <li className="mb-2" key={index}>
            <Link to={urls[item]}>{item}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
