import React, { LegacyRef, useCallback, useState } from 'react';
import Topic from '../../../data/models/Topic';
import ModalView from '../../../components/crud/ModalView';
import cn from 'classnames';
import ServerRouter from '../../../routers/ServerRouter';
import axios from 'axios';
import { getAxiosHeaders } from '../../../routers/utils';
import moment from 'moment';
import { useAppSelector } from '../../../data/redux/hooks';

type Props = {
  topic?: Topic;
  actionButton: (onClick: () => void) => React.ReactNode;
  formRef: LegacyRef<any>;
};

type ExportType = 'csv' | 'html' | 'txt';

const TopicExport = ({ topic, formRef, actionButton }: Props) => {
  const [show, setShow] = useState(false);
  const [exportType, setExportType] = useState<ExportType>('csv');
  const pages = useAppSelector(useCallback(state => state.cache.articles, []));
  const onSubmit = useCallback(() => {
    const pagesIds = Object.keys(pages).join();
    const urlByExportType = {
      csv: topic?.id ? ServerRouter.topicExportCSV(topic.id) : ServerRouter.homeExportCSV(pagesIds),
      html: topic?.id
        ? ServerRouter.topicExportHTML(topic.id)
        : ServerRouter.homeExportHTML(pagesIds),
      txt: topic?.id
        ? ServerRouter.topicExportText(topic.id)
        : ServerRouter.homeExportText(pagesIds),
    };
    const url = urlByExportType[exportType];
    if (url) {
      axios.get(url, getAxiosHeaders()).then(res => {
        if (res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          const fileName = `${topic?.id ? `topic-${topic.name}` : 'home'}-getSparks-${moment(
            new Date(),
          ).format('DD.MM.YY')}.${exportType}`;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      });
    }
  }, [topic, pages, exportType]);
  return (
    <ModalView
      show={show}
      setShow={setShow}
      title="Export Bookmarks"
      formRef={formRef}
      actionButton={actionButton}
      footer={
        <button onClick={onSubmit} className="button-variant-1 article-button--primary">
          Export
        </button>
      }
      body={
        <div>
          <div
            className="d-flex gap-2 align-items-center text-black pointer"
            onClick={() => setExportType('csv')}
          >
            <div
              className={cn('radio-button', 'radio-button--sm', { active: exportType === 'csv' })}
            />
            CSV
          </div>
          <div
            className="d-flex gap-2 align-items-center text-black pointer"
            onClick={() => setExportType('html')}
          >
            <div
              className={cn('radio-button', 'radio-button--sm', {
                active: exportType === 'html',
              })}
            />
            HTML
          </div>
          <div
            className="d-flex gap-2 align-items-center text-black pointer"
            onClick={() => setExportType('txt')}
          >
            <div
              className={cn('radio-button', 'radio-button--sm', {
                active: exportType === 'txt',
              })}
            />
            Text
          </div>
        </div>
      }
    ></ModalView>
  );
};

export default TopicExport;
