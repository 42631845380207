import React from 'react';
import FooterCopyright from "./FooterCopyright";

type Props = {
  light?: boolean
}

export default function SmallFooter({light}: Props) {
  const theme = light ? 'light' : 'dark';
  return <div id="layoutDefault_footer" style={{textAlign: "left"}}>
    <footer className={`footer pt-10 pb-5 mt-auto bg-${theme} footer-${theme}`}>
      <div className="container px-5">
        <FooterCopyright/>
      </div>
    </footer>
  </div>
}
