import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardContext from '../../pages/dashboard/DashboardContext';
import { OnRefChangeType } from 'react-resize-detector/build/types/types';

type Props = {
  items: any[];
};

export type UsePaginationReturnType = {
  page: number;
  onShowMoreClicked: () => void;
  pageSize: number;
  paginatedItems: any[];
  nextPageExists: boolean;
  containerRef: OnRefChangeType<any> | undefined;
  lastRowEmptyItemsNumber: number;
};

const usePagination = ({ items }: Props): UsePaginationReturnType => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(16);
  const [rowSize, setRowSize] = useState(4);
  const location = useLocation();

  const { width: dashboardContainerWidth, containerRef } = useContext(DashboardContext);

  const onShowMoreClicked = useCallback(() => {
    setPage(prev => prev + 1);
  }, []);

  const itemsToDisplayNumber = useMemo(() => {
    const firstPageLength = rowSize * 2;
    return firstPageLength + (page - 1) * pageSize;
  }, [rowSize, page, pageSize]);

  const paginatedItems = useMemo(() => {
    return items.slice(0, itemsToDisplayNumber);
  }, [itemsToDisplayNumber, items]);

  const nextPageExists = useMemo(
    () => items.length > itemsToDisplayNumber,
    [items, itemsToDisplayNumber],
  );

  const lastRowEmptyItemsNumber = useMemo(() => {
    if (nextPageExists) {
      return 0;
    }
    const itemsMissingNumber = itemsToDisplayNumber - items.length;

    if (itemsMissingNumber <= 0) {
      return 0;
    }
    return itemsMissingNumber % rowSize;
  }, [nextPageExists, items.length, itemsToDisplayNumber, rowSize]);
  useEffect(() => {
    setPage(1);
  }, [location]);

  useEffect(() => {
    if (!dashboardContainerWidth) return;
    const articleCardWidth = 272 + 24;
    const itemsInRow = Math.floor((dashboardContainerWidth + 24) / articleCardWidth);
    setRowSize(itemsInRow);
    setPageSize(itemsInRow * 4);
  }, [dashboardContainerWidth]);

  return {
    page,
    onShowMoreClicked,
    pageSize,
    paginatedItems,
    nextPageExists,
    containerRef,
    lastRowEmptyItemsNumber,
  };
};

export default usePagination;
