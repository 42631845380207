import React, { useCallback, useMemo } from 'react';
import BottomLeftMessage from '../messages/BottomLeftMessage';
import NetworkJSONLoader from './NetworkJSONLoader';
import ServerRouter from '../../routers/ServerRouter';
import { authorize, login, useUpdateHasExtensionFlag } from '../../data/redux/authUtils';
import GoogleOneTap from '../../pages/auth/GoogleOneTap';
import { useAppSelector } from '../../data/redux/hooks';
import { RootState } from '../../data/redux/store';
import { setToken, setTokenId } from '../../data/redux/actions';

export function Authorizer() {
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
  const token = useAppSelector(useCallback((state: RootState) => state.cache.token, []));
  const tokenId = useAppSelector(useCallback((state: RootState) => state.cache.tokenId, []));
  const withUpdateExtensionFlag = useUpdateHasExtensionFlag();
  return useMemo(() => {
    if (token) {
      return (
        <BottomLeftMessage>
          <NetworkJSONLoader
            url={ServerRouter.getUserData()}
            onOk={withUpdateExtensionFlag(authorize)}
            onCancel={() => setToken(undefined)}
            children={'Fetching profile data'}
          />
        </BottomLeftMessage>
      );
    }
    if (tokenId) {
      return (
        <BottomLeftMessage>
          <NetworkJSONLoader
            url={ServerRouter.googleOAuth()}
            onOk={withUpdateExtensionFlag(login)}
            body={{ tokenId: tokenId }}
            method={'POST'}
            onCancel={() => setTokenId(undefined)}
            children={'Exchanging authorization tokens'}
          />
        </BottomLeftMessage>
      );
    }
    if (!user || !user.email) {
      if (process.env.REACT_APP_GOOGLE_CLIENT_ID) {
        return <GoogleOneTap />;
      }
    }
    return null;
  }, [token, tokenId, user, withUpdateExtensionFlag]);
}
