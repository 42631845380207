export const Colors = {
  textBlack: '#222222',
  textGreen: '#34A853',
  blue02: '#003DA3',
  blue01: '#003DA3',
  blue03: '#006edb',
  blue05: '#F0F6FF',
  blue07: '#004DCC',
  blue08: '#002CF5',
  gray0: '#5C5C5C',
  gray01: '#808080',
  gray02: '#999999',
  gray03: '#CCCCCC',
  gray04: '#EDEDED',
  blueGray00: '#9197A1',
  blueGray02: '#DBDFE5',
  blueGray03: '#EAEDF0',
  blueGray04: '#F3F4F6',
  primary01: '#4301A4',
  primary03: '#8635FD',
  primary04: '#B380FE',
  primary05: '#F3EBFF',
  purple00: '#3E0198',
  purple01: '#6F26D9',
  purple02: '#8634FE',
  purple04: '#B380FE',
  red01: '#E25256',
  red02: '#FFEBEB',
};
