import React from 'react';


function PdfViewerPrint(props: any) {
    // const pdfURL = 'https://www.africau.edu/images/default/sample.pdf'

    return (
        <div className="" style={{overflow: "scroll", height: "50rem"}}>
            <object  className="bg-white" id={'printf'} name={'printf'} data={`${props.pdfFile}#toolbar=1&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`} type="application/pdf" style={{width:'90%', height:'100%'}}>
                <iframe title={'printf'} id={'printf'} name={'printf'} src={`${props.pdfFile}#toolbar=1&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`}></iframe>
            </object>
        </div>
    );
}

export default PdfViewerPrint
