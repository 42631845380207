import React from 'react';
import LogoSVG from '../LogoSVG';
import { ReactComponent as LogoShort } from '../../assets/img/icons/logo-short.svg';

export default function BlueLogo({ isShort }: { isShort?: boolean }) {
  return isShort ? (
    <LogoShort height={32} width={37} />
  ) : (
    <LogoSVG fontColor="#333333" svgProps={{ height: '32px' }} />
  );
}
