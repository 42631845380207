import React, {
  createContext,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Article from '../../../data/models/Article';
import ArticleContent from './ArticleContent';
import { useAppSelector } from '../../../data/redux/hooks';
import { RootState } from '../../../data/redux/store';
import ArticleViewHeader from './ArticleViewHeader';
import ArticleInfoTab from '../info/ArticleInfoTab';
import TopicInfoTab from '../info/TopicInfoTab';
import { ArticleViewType } from '../../../data/redux/utils';
import NetworkJSONLoader from '../../../components/network/NetworkJSONLoader';
import ServerRouter from '../../../routers/ServerRouter';
import { setArticle } from '../../../data/redux/actions';
import { getHeaders } from '../../../routers/utils';

type Props = {
  article: Article;
};

export const ArticleViewTypeContext = createContext<
  [ArticleViewType, React.Dispatch<React.SetStateAction<ArticleViewType>>]
>(['preview', () => {}]);

export const SummaryScrollContext = createContext<{
  summaryScrollRef: MutableRefObject<HTMLDivElement | null> | null;
  articleScrollRef: MutableRefObject<HTMLDivElement | null> | null;
  setShouldSyncScrolls?: React.Dispatch<React.SetStateAction<boolean>>;
}>({ summaryScrollRef: null, articleScrollRef: null });

export default function ArticleView({ article }: Props) {
  const infoTab = useAppSelector(useCallback((state: RootState) => state.cache.infoTab, []));
  const topic = useAppSelector(
    useCallback((state: RootState) => article.getTopic(state.cache), [article]),
  );
  const articleViewTypeState = useState<ArticleViewType>('preview');
  const [fetched, setFetched] = useState(false);
  const [shouldSyncScrolls, setShouldSyncScrolls] = useState(false);
  const summaryScrollRef = useRef<HTMLDivElement | null>(null);
  const articleScrollRef = useRef<HTMLDivElement | null>(null);
  const infoTabContent = useMemo(() => {
    switch (infoTab.about) {
      case 'article':
        return <ArticleInfoTab article={article} />;
      case 'topic':
        return topic && <TopicInfoTab topic={topic} />;
    }
  }, [infoTab.about, article, topic]);
  const content = useMemo(() => {
    if (fetched) {
      return (
        <div className="bg-white">
          <ArticleContent article={article} />
        </div>
      );
    }
    return (
      <NetworkJSONLoader
        url={ServerRouter.articles(article.id)}
        onOk={json => {
          setArticle(json);
          setFetched(true);
        }}
        children={'Fetching article'}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article.id, fetched]);

  useEffect(() => {
    fetch(ServerRouter.pageActivity(), {
      method: 'POST',
      headers: getHeaders(),
      credentials: 'include',
      body: JSON.stringify({
        page: article.id,
        is_viewed: true,
      }),
    }).catch(e => console.error(e));
  }, [article.id]);

  return useMemo(
    () => (
      <SummaryScrollContext.Provider
        value={{ articleScrollRef, summaryScrollRef, setShouldSyncScrolls }}
      >
        <ArticleViewTypeContext.Provider value={articleViewTypeState}>
          <ArticleViewHeader article={article} />

          <div
            id="layoutSidenav_content"
            className={'vstack header-margin'}
            style={{ top: 'initial', overflow: 'auto', flexGrow: 1, background: '#fff' }}
            ref={articleScrollRef}
            onScroll={scroll => {
              if (summaryScrollRef?.current && shouldSyncScrolls) {
                // @ts-ignore
                summaryScrollRef.current.scrollTop = scroll.target.scrollTop;
              }
            }}
          >
            {content}
          </div>
          {infoTabContent}
        </ArticleViewTypeContext.Provider>
      </SummaryScrollContext.Provider>
    ),
    [articleViewTypeState, content, infoTabContent, shouldSyncScrolls, article],
  );
}
