import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  postRef?: React.Ref<HTMLDivElement>;
}>;

export default function Post({ children, postRef }: Props) {
  return (
    <div style={{ lineHeight: '2', fontSize: '20px' }}>
      <div className={'row gx-5 justify-content-center text-start h-100'}>
        <div ref={postRef} className={'single-post m-3 test'}>
          {children}
        </div>
      </div>
    </div>
  );
}
