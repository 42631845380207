import React, { useEffect, useMemo } from 'react';
import { Route, Routes } from 'react-router';
import ClientRouter from './routers/ClientRouter';
import Home from './pages/lp/Home';
import { Dashboard } from './pages/dashboard/Dashboard';
import Blog from './pages/blog/Blog';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import ResetPassword from './pages/auth/ResetPassword';
import PrivacyPolicy from './pages/legal/PrivacyPolicy';
import TermsAndConditions from './pages/legal/TermsAndConditions';
import HowDoesGetSparksWork from './pages/legal/HowDoesGetSparksWork';
import faviconPath from './assets/img/favicon.png';
import { NotFound } from './pages/errors/NotFound';
import { ArticlePrint } from './pages/dashboard/article/ArticlePrint';
import ChangePassword from './pages/auth/ChangePassword';
import { Authorizer } from './components/network/Authorizer';
import SiteLP from './pages/lp/SiteLP';
import ExtensionLP from './pages/lp/ExtensionLP';
import { useLocation } from 'react-router-dom';
import ExtensionLP1 from './pages/lp/ExtensionLP1';
import ExtensionLP2 from './pages/lp/ExtensionLP2';
import { setCookie, getCookie } from './services/utils';
import SaveLP from './pages/lp/SaveLP';
import SummarizeLP from './pages/lp/SummarizeLP';
import NoteLP from './pages/lp/NoteLP';
import PricingLP from './pages/lp/PricingLP';
import SummaryGIF from './pages/lp/SummaryGIF';
import Refund from './pages/legal/Refund';
import NotesGIF from './pages/lp/NotesGIF';
import SaveGIF from './pages/lp/SaveGIF';
import Summary from './pages/lp/Summary';
import SummaryDemo from './pages/lp/SummaryDemo';
import 'react-toggle/style.css';
import UninstallLP from './pages/lp/UninstallLP';
import AppLP from './pages/lp/AppLP';
import Discover from './pages/discover/Discover';
import BookmarkLP from './pages/lp/BookmarkLP';
import BookmarkAppLP from './pages/lp/BookmarkAppLP';
import About from './pages/legal/About';
import Save from './pages/linking/Save';
import { useScrollToTop } from './hooks/RouterUtils';
import Redirect from './pages/Redirect';

export default function App() {
  useScrollToTop();

  useEffect(() => {
    const favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = faviconPath;
    document.head.append(favicon);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const utm_source = urlParams.get('utm_source');
    const utm_campaign = urlParams.get('utm_campaign');
    const utm_partner = urlParams.get('utm_partner');

    if (utm_source || utm_campaign || utm_partner) {
      const expiresDate = new Date();
      expiresDate.setMonth(expiresDate.getMonth() + 1);

      if (utm_source && !getCookie('utm_source'))
        setCookie('utm_source', utm_source, { expires: expiresDate });
      if (utm_campaign && !getCookie('utm_campaign'))
        setCookie('utm_campaign', utm_campaign, { expires: expiresDate });
      if (utm_partner && !getCookie('utm_partner'))
        setCookie('utm_partner', utm_partner, { expires: expiresDate });
    }

    return () => {
      favicon.remove();
    };
  }, []);

  let pathname = useLocation().pathname;
  if (pathname.lastIndexOf('/') !== pathname.length - 1) {
    pathname += '/';
  }

  const isAuthorizerEnabled = pathname !== ClientRouter.lpExtension();

  return useMemo(
    () => (
      <div className="App h-100">
        {isAuthorizerEnabled && <Authorizer />}
        <Routes>
          <Route path={ClientRouter.dashboard()} element={<Dashboard />} />
          <Route path={ClientRouter.indexHTML()} element={<Redirect to="/" />} />
          <Route path={ClientRouter.profile()} element={<Dashboard />} />
          <Route path={ClientRouter.discover()} element={<Discover />} />
          <Route path={ClientRouter.topics(':topicId')} element={<Dashboard />} />
          <Route path={ClientRouter.articles(':articleId')} element={<Dashboard />} />
          <Route path={ClientRouter.articlesSearch(':query')} element={<Dashboard />} />
          <Route path={ClientRouter.home()} element={<Home />} />
          <Route path={ClientRouter.save()} element={<Save />} />
          <Route path={ClientRouter.lpSite()} element={<SiteLP />} />
          <Route path={ClientRouter.lpApp()} element={<AppLP />} />
          <Route path={ClientRouter.lpExtension()} element={<ExtensionLP />} />
          <Route path={ClientRouter.lpExtension1()} element={<ExtensionLP1 />} />
          <Route path={ClientRouter.pricing()} element={<PricingLP />} />
          <Route path={ClientRouter.lpExtension2()} element={<ExtensionLP2 />} />
          <Route path={ClientRouter.lpSaveGIF()} element={<SaveGIF />} />
          <Route path={ClientRouter.lpBookmarkApp()} element={<BookmarkAppLP />} />
          <Route path={ClientRouter.lpBookmark()} element={<BookmarkLP />} />
          <Route path={ClientRouter.lpBookmarkBW()} element={<BookmarkLP colored={false} />} />
          <Route path={ClientRouter.lpSave()} element={<SaveLP />} />
          <Route path={ClientRouter.lpSummary()} element={<Summary />} />
          <Route path={ClientRouter.lpSummaryDemo()} element={<SummaryDemo />} />
          <Route path={ClientRouter.lpSummarize()} element={<SummarizeLP />} />
          <Route path={ClientRouter.lpUninstall()} element={<UninstallLP />} />
          <Route path={ClientRouter.lpSummaryGIF()} element={<SummaryGIF />} />
          <Route path={ClientRouter.lpNotesGIF()} element={<NotesGIF />} />
          <Route path={ClientRouter.lpNote()} element={<NoteLP />} />
          <Route path={ClientRouter.blog()} element={<Blog />} />
          <Route path={ClientRouter.login()} element={<Login />} />
          <Route path={ClientRouter.register()} element={<Register />} />
          <Route path={ClientRouter.resetPassword()} element={<ResetPassword />} />
          <Route
            path={ClientRouter.passwordReset(':uidb64', ':token')}
            element={<ChangePassword />}
          />
          <Route path={ClientRouter.privacyPolicy()} element={<PrivacyPolicy />} />
          <Route path={ClientRouter.Refund()} element={<Refund />} />
          <Route path={ClientRouter.termsAndConditions()} element={<TermsAndConditions />} />
          <Route path={ClientRouter.howDoesSparksWork()} element={<HowDoesGetSparksWork />} />
          <Route path={ClientRouter.about()} element={<About />} />
          <Route path={ClientRouter.printArticles(':articleId')} element={<ArticlePrint />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    ),
    [isAuthorizerEnabled],
  );
}
