import React, {useMemo, useState} from "react";
import NetworkJSONLoader from "../../../../components/network/NetworkJSONLoader";
import ServerRouter from "../../../../routers/ServerRouter";
import {reloadProfile} from "../../../../data/redux/authUtils";
import OnboardToDo from "./OnboardToDo";

export default function OnboardCollapsedView() {
  const [showing, setShowing] = useState<boolean>();
  const children = useMemo(() => {
    if (showing) {
      return <div className={'card card-body bg-white'} style={{position: "absolute", right: 0, top: 0}}>
        <NetworkJSONLoader url={ServerRouter.updateProfile()} children={'Loading onboard'} onOk={() => {
          setShowing(false);
          reloadProfile();
        }} onCancel={() => setShowing(false)} method={'PATCH'} body={{onboarding_closed: false}}/>
      </div>
    }
  }, [showing]);
  return useMemo(() =>
    <OnboardToDo name={'Get Started to gain best of Web Content!'}
                 actionName={'Show More'}
                 action={() => setShowing(true)}
                 children={children}/>, [children]);
}
