import React, {useCallback, useEffect, useRef} from 'react';
import {useAppSelector} from "../../data/redux/hooks";
import {setShowSideNav} from "../../data/redux/actions";

export default function useClosedSideNav() {
  const showSideNav = useRef(useAppSelector(useCallback(state => state.cache.showSideNav, [])));
  useEffect(() => {
    if (showSideNav.current !== "closed") {
      const sideNavState = showSideNav.current;
      setShowSideNav('closed');
      return () => {
        setShowSideNav(sideNavState);
      }
    }
  }, []);
  return <></>;
}
