import React, { CSSProperties, ReactNode, useEffect, useMemo } from 'react';
import Input from './Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faFileLines, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import ClientRouter from '../../../routers/ClientRouter';

type Props = {
  field: IField;
  error?: string;
  showErrors?: boolean;
  setError?: (field: IField, errorMessage: string) => void;
  next?: ReactNode;
  defaultValue?: any;
  autoFocus?: boolean;
  inline?: boolean;
  isShort?: boolean;
  setUploadType?: React.Dispatch<React.SetStateAction<string | undefined>>;
  uploadType?: string;
  setPdfFile?: React.Dispatch<React.SetStateAction<File | undefined>>;
  onEnterPress?: (() => any) | null;
};

export type IField = {
  name?: string;
  names?: string[];
  type?: string;
  hidden?: boolean;
  placeholder?: string;
  noAutoFocus?: boolean;
  placeholders?: string[];
  label?: string | null;
  labels?: string[];
  style?: CSSProperties;
  options?: [string | number, string, number | undefined][];
  maxLength?: number;
  required?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  minLength?: number;
  disabled?: boolean;
  helperText?: string;
  nullableName?: string;
  nullableValue?: any;
  createNewName?: string;
  onCreateNewSelect?: () => any;
  className?: string;
  ref?: React.MutableRefObject<any>;
  pasteFormatAutoclean?: boolean;
  uploadType?: string;
  minimumWordsNumber?: number;
  validate?: (value: string) => boolean;
  errorMessage?: string;
  withForgotPassword?: boolean;
  errorMessageBottom?: boolean;
  submitOnEnterPress?: boolean;
  value?: number | string;
  onChange?: (value: any) => void;
};

export default function Field({
  field,
  showErrors,
  error,
  setError,
  next,
  defaultValue,
  autoFocus,
  inline,
  isShort,
  uploadType,
  setUploadType,
  setPdfFile,
  onEnterPress,
}: Props) {
  if (field.placeholder === undefined && field.name && field.name.charAt) {
    field.placeholder = (field.name.charAt(0).toUpperCase() + field.name.slice(1)).replace(
      '_',
      ' ',
    );
  }
  const getLabelClassName = () => {
    if (field.type === 'checkbox') return 'form-check-label';
    if (field.type === 'wysiwyg') return 'w-100 d-block';
    return 'w-100';
  };
  const input = useMemo(
    () => (
      <Input
        field={field}
        showErrors={showErrors}
        error={error}
        setError={setError}
        defaultValue={defaultValue}
        autoFocus={autoFocus}
        setPdfFile={setPdfFile}
        onEnterPress={onEnterPress}
      />
    ),
    [autoFocus, defaultValue, error, field, setPdfFile, setError, showErrors, onEnterPress],
  );

  useEffect(() => {
    if (setUploadType) {
      setUploadType('url');
    }
  }, [setUploadType]);

  if (field.uploadType && field.uploadType !== uploadType) {
    return null;
  }

  if (field.type === 'upload-type') {
    return (
      <div className="d-flex p-2">
        <div
          onClick={() => (setUploadType ? setUploadType('url') : null)}
          className={`round-button round-button-lg d-flex align-items-center justify-content-center mx-2 mt-2 flex-grow-1`}
        >
          <FontAwesomeIcon icon={faLink} color="#0061f2" />
          <span className={`${uploadType === 'url' ? 'text-decoration-underline' : ''}`}>
            Paste link
          </span>
        </div>
        <div
          onClick={() => (setUploadType ? setUploadType('text') : null)}
          className={`round-button round-button-lg d-flex align-items-center justify-content-center mx-2 mt-2 flex-grow-1`}
        >
          <FontAwesomeIcon icon={faFileLines} color="#0061f2" />
          <span className={`${uploadType === 'text' ? 'text-decoration-underline' : ''}`}>
            Paste text
          </span>
        </div>
        <div
          onClick={() => (setUploadType ? setUploadType('pdf') : null)}
          className={`round-button round-button-lg d-flex align-items-center justify-content-center mx-2 mt-2 flex-grow-1`}
        >
          <FontAwesomeIcon icon={faFilePdf} color="#0061f2" />
          <span className={`${uploadType === 'pdf' ? 'text-decoration-underline' : ''}`}>
            Upload PDF
          </span>
        </div>
      </div>
    );
  }
  if (isShort) return input;
  const renderLabel = () => {
    return (
      <>
        <div className="d-flex">
          {field.type === 'checkbox' && <>{input}&nbsp;</>}
          <div className="d-flex flex-grow-1 justify-content-between">
            {!field.hidden && field.label !== null && (field.label || field.placeholder) && (
              <span className={field.type === 'checkbox' ? '' : 'text-gray-600 small field-label'}>
                {field.label ?? field.placeholder}
              </span>
            )}
            {field.withForgotPassword && (
              <Link className="link-text link-00" to={ClientRouter.resetPassword()}>
                Forgot password?
              </Link>
            )}
          </div>
        </div>
        {!field.errorMessageBottom && error && showErrors && (
          <span className={'small'}>&nbsp;- {error}</span>
        )}
      </>
    );
  };
  return (
    <div
      className={'field flex-grow-1' + (inline ? ' mx-2' : ' m-3')}
      style={{ textAlign: 'left' }}
      hidden={field.hidden}
    >
      <label className={getLabelClassName() + (error && showErrors ? ' field-error' : '')}>
        {field.type === 'checkbox' ? renderLabel() : <b>{renderLabel()}</b>}
        <div className={`input-group ${field.errorMessageBottom ? 'mb-1' : ''}`}>
          {field.type !== 'checkbox' && input}
          {next}
        </div>
        {field.errorMessageBottom && error && showErrors && (
          <span className={'small'}>&nbsp;- {error}</span>
        )}
        <span className={'small'}>{field.helperText}</span>
      </label>
    </div>
  );
}
