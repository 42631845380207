import React, { PropsWithChildren, useCallback, useRef } from 'react';
import cn from 'classnames';
import { ReactComponent as ArrowIcon } from '../assets/img/icons/arrow-3.svg';

type Props = {
  onScrollToEnd?: () => void;
  withArrows?: boolean;
  scrollClassName?: string;
};

const HorizontalScroll = ({
  children,
  onScrollToEnd,
  withArrows,
  scrollClassName = '',
}: PropsWithChildren<Props>) => {
  const listInnerRef = useRef<HTMLDivElement>(null);
  const onScroll = useCallback(() => {
    if (listInnerRef.current && !!onScrollToEnd) {
      const { scrollLeft, scrollWidth, clientWidth } = listInnerRef.current;
      const isScrollToEnd = scrollLeft + clientWidth === scrollWidth;
      if (isScrollToEnd) {
        onScrollToEnd();
      }
    }
  }, [listInnerRef, onScrollToEnd]);

  const onArrowClicked = useCallback(
    (isLeft?: boolean) => {
      if (listInnerRef.current) {
        listInnerRef.current.scrollTo({
          left: listInnerRef.current.scrollLeft + (isLeft ? -300 : 300),
          behavior: 'smooth',
        });
      }
    },
    [listInnerRef],
  );

  return (
    <div className="position-relative">
      {withArrows && (
        <div className="arrow" onClick={() => onArrowClicked(true)}>
          <ArrowIcon />
        </div>
      )}
      <div
        className={cn('horizontal-scroll gap-3', {
          'horizontal-scroll-bar': !withArrows,
          [scrollClassName]: !!scrollClassName,
        })}
        onScroll={onScroll}
        ref={listInnerRef}
      >
        {children}
      </div>
      {withArrows && (
        <div className="arrow arrow-right" onClick={() => onArrowClicked()}>
          <ArrowIcon />
        </div>
      )}
    </div>
  );
};

export default HorizontalScroll;
