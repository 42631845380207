import React, {useCallback, useMemo} from 'react';
import SaveButton from "../../../components/buttons/text/SaveButton";
import {useAppSelector} from "../../../data/redux/hooks";
import {RootState} from "../../../data/redux/store";
import {reloadProfile} from "../../../data/redux/authUtils";
import {getProfileCRUD} from "../../../services/cruds";
import {FreeCard, PremiumCard} from "./cards";

export default function ProfileContent() {
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
  const updateProfileView = useMemo(() => {
    if (user && user.__data) {
      const crud = getProfileCRUD(() => reloadProfile());
      return <crud.patch defaultValues={user.__data} key={new Date().getTime()}
                         formButton={<SaveButton>Update Profile</SaveButton>}/>
    }
  }, [user]);
  return useMemo(() => <div className="container-xl row">
    <div className={'col-xl-9'}>
      <div className={'card'}>
        <div className={'card-header'}>Account details</div>
        <div className={'card-body'}>
          {updateProfileView}
        </div>
      </div>
    </div>
    {user?.plan_name === "Premium" && user?.plan_status !== "Inactive" ?
        <PremiumCard userStartDate={user?.start_date} userEndDate={user?.end_date}
                     userPeriod={user?.interval}></PremiumCard>
        :
        <FreeCard userEmail={user?.email}></FreeCard>
    }

  </div>, [updateProfileView, user?.email, user?.end_date, user?.start_date, user?.interval, user?.plan_name, user?.plan_status]);
}
