import React, { FormEvent, ReactNode, useCallback, useMemo, useState } from 'react';
import { JSONObject } from '../../data/models/Model';
import { getCookie, getHeaders } from '../../routers/utils';
import ModularForm, { FormFields, ModularFormOnSubmitType } from './ModularForm';
import PostButton from '../buttons/text/PostButton';
import DeleteButton from '../buttons/text/DeleteButton';
import CancelButton from '../buttons/text/CancelButton';
import SaveButton from '../buttons/text/SaveButton';
import { getFormFieldsValues } from '../../services/utils';

export type CRUDOnOkParam = JSONObject | number;
export type CRUDOnOkCallback = (data: CRUDOnOkParam) => void;

type FormProps = {
  defaultValues?: JSONObject;
  formButton?: ReactNode;
  onCancel?: () => void;
  check?: (json: JSONObject) => JSONObject | void;
  nonLinear?: boolean;
  controlsProps?: React.HTMLAttributes<HTMLDivElement>;
  neverDisableButton?: boolean;
  defaultShowErrors?: boolean;
  onRequestFailed?: (error: JSONObject | undefined) => void;
};

export default class CRUD {
  url: string;
  fields: FormFields;
  onOk: CRUDOnOkCallback;

  constructor(url: string, fields: FormFields, onOk: CRUDOnOkCallback) {
    this.url = url;
    this.fields = fields;
    this.onOk = onOk;
  }

  processResponse =
    (
      method: string,
      onErrors: (errors?: JSONObject) => void,
      setIsSending: React.Dispatch<React.SetStateAction<boolean>>,
      check?: (json: JSONObject) => JSONObject | void,
    ) =>
    async (event: FormEvent, additionalFields?: { [key: string]: any }, uploadType?: string) => {
      const isFormData = uploadType === 'pdf';
      setIsSending(true);
      onErrors();
      const originalOnErrors = onErrors;
      onErrors = (errors?: JSONObject) => {
        setIsSending(false);
        originalOnErrors(errors);
      };
      let result;
      let requestJSON: JSONObject;
      let id = -1;
      try {
        event.preventDefault();
        requestJSON = getFormFieldsValues(event, additionalFields);
        Object.entries(requestJSON || {}).forEach(([key, value]) => {
          if (value === 'none') {
            delete requestJSON[key];
          }
        });
        let url = this.url;
        if (method === 'PATCH' || method === 'DELETE') {
          if (requestJSON.hasOwnProperty('id') && requestJSON['id']) {
            id = parseInt(requestJSON['id']);
            url = url + requestJSON['id'] + '/';
            delete requestJSON['id'];
          }
        }
        let formData: FormData = new FormData();
        if (isFormData) {
          Object.entries(requestJSON).forEach(([key, value]) => formData.append(key, value));
          if (additionalFields) {
            Object.entries(additionalFields).forEach(([key, value]) => formData.append(key, value));
          }
        }
        if (check) {
          const checkResult = check(requestJSON);
          if (checkResult) {
            onErrors(checkResult);
            return;
          }
        }
        const headers = getHeaders();
        result = await fetch(url, {
          method: method,
          headers: !isFormData
            ? headers
            : { ...headers, Authorization: `Token ${getCookie('token')}` },
          credentials: 'include',
          body: isFormData ? formData : JSON.stringify(requestJSON),
        });
      } catch (e: any) {
        result = {
          json: async () => ({
            ok: false,
            non_field_errors: e.name + '. ' + e.message,
          }),
        };
      }
      if (result.ok || (id && result.status === 404 && method === 'DELETE')) {
        if (method === 'DELETE') {
          this.onOk(id);
        } else {
          try {
            this.onOk(await result.json());
          } catch (e) {
            console.error(e);
          }
        }
      } else {
        try {
          const json = await result.json();
          if (!('non_field_errors' in json)) {
            if ('detail' in json) json['non_field_errors'] = json['detail'];
            else if ('error' in json) json['non_field_errors'] = json['error'];
          }
          onErrors(json);
        } catch (e) {
          onErrors({ non_field_errors: result.statusText });
        }
      }
    };

  renderActions = (
    button: ReactNode,
    onCancel?: () => void,
    controlsProps?: React.HTMLAttributes<HTMLDivElement>,
  ) => {
    return (
      <div
        {...controlsProps}
        className={controlsProps?.className ?? 'd-flex justify-content-evenly m-3'}
      >
        {button}
        {onCancel && <CancelButton onClick={onCancel} className={'ms-3'} />}
      </div>
    );
  };

  post = ({
    defaultValues,
    formButton,
    onCancel,
    check,
    nonLinear,
    controlsProps,
    neverDisableButton,
    defaultShowErrors = false,
    onRequestFailed,
  }: FormProps) => {
    const [sending, setSending] = useState<boolean>(false);
    const [errors, setErrors] = useState<JSONObject>();
    const [showErrors, setShowErrors] = useState(defaultShowErrors);
    const buttonDisabled = useMemo(() => {
      return (
        !neverDisableButton && showErrors && errors && Object.values(errors).some(error => !!error)
      );
    }, [errors, showErrors, neverDisableButton]);

    const onSubmit: ModularFormOnSubmitType = useCallback(
      (e, ...props) => {
        e.preventDefault();
        const values = getFormFieldsValues(e);
        if (errors && Object.values(errors).filter(err => !!err).length) {
          setShowErrors(true);
          return;
        }
        this.processResponse(
          'POST',
          error => {
            setErrors(error);
            if (error && Object.keys(values).some(key => !!error[key])) {
              setShowErrors(true);
            }
            if (onRequestFailed) {
              onRequestFailed(error);
            }
          },
          setSending,
          check,
        )(e, ...props);
      },
      [setSending, check, errors, onRequestFailed],
    );

    return (
      <ModularForm
        errors={errors}
        setErrors={setErrors}
        showErrors={showErrors}
        onSubmit={onSubmit}
        button={
          formButton ??
          this.renderActions(<PostButton disabled={buttonDisabled} />, onCancel, controlsProps)
        }
        defaultValues={defaultValues}
        sending={sending}
        nonLinear={nonLinear}
        fields={this.fields}
      />
    );
  };

  patch = (props: FormProps & { defaultValues: JSONObject }) => {
    const { defaultValues, formButton, onCancel, controlsProps, nonLinear, check } = props;
    const [sending, setSending] = useState<boolean>(false);
    const [errors, setErrors] = useState<JSONObject>();
    return (
      <ModularForm
        errors={errors}
        onSubmit={this.processResponse('PATCH', setErrors, setSending, check)}
        fields={[{ name: 'id', hidden: true }, ...this.fields]}
        button={formButton ?? this.renderActions(<SaveButton />, onCancel, controlsProps)}
        defaultValues={defaultValues}
        sending={sending}
        nonLinear={nonLinear}
      />
    );
  };

  delete = (props: FormProps & { defaultValues: { id: number; name?: string } }) => {
    const { defaultValues, formButton, onCancel, controlsProps, check } = props;
    const [sending, setSending] = useState<boolean>(false);
    const [errors, setErrors] = useState<JSONObject>();
    return (
      <ModularForm
        errors={errors}
        sending={sending}
        defaultValues={defaultValues}
        onSubmit={this.processResponse('DELETE', setErrors, setSending, check)}
        fields={[{ name: 'id', hidden: true }]}
        button={formButton ?? this.renderActions(<DeleteButton />, onCancel, controlsProps)}
      />
    );
  };
}
