import React, { FunctionComponent, HTMLProps, ReactNode, SVGProps, useMemo } from 'react';
import { ReactComponent as DotsIcon } from '../../assets/img/icons/dots.svg';
import useComponentVisible from './useComponentVisible';

export type UseMenuReturnType = {
  menuVisible: boolean;
  toggleMenu: () => void;
  MenuContainer: (
    props: {
      children: ReactNode;
      minWidth?: number;
      topOffset?: number;
    } & React.HTMLProps<HTMLDivElement>,
  ) => JSX.Element | null;
  ItemContainer: (props: { children: ReactNode } & React.HTMLProps<HTMLDivElement>) => JSX.Element;
  Toggle: (
    props: { Icon?: FunctionComponent<SVGProps<SVGSVGElement>> } & SVGProps<any>,
  ) => JSX.Element;
  placeRef: (ref: HTMLButtonElement | HTMLDivElement | SVGElement | null, index: number) => void;
};

export default function useMenu(): UseMenuReturnType {
  const { isComponentVisible, setComponentVisible, ref, insideRefs } = useComponentVisible(false);
  const toggle = () => setComponentVisible(prev => !prev);

  const MenuContainer = useMemo(
    () =>
      ({
        children,
        minWidth,
        topOffset,
        ...rest
      }: {
        children: ReactNode;
        minWidth?: number;
        topOffset?: number;
      } & HTMLProps<HTMLDivElement>) => {
        const style: React.CSSProperties = {};
        if (minWidth) style.minWidth = minWidth;
        if (topOffset) style.top = topOffset;
        return (
          <div
            ref={ref}
            className={`topic-header-menu flex-column text-black no-print ${
              isComponentVisible ? '' : 'd-none'
            }`}
            style={style}
            {...rest}
          >
            {children}
          </div>
        );
      },
    [isComponentVisible, ref],
  );

  const placeRef = (ref: HTMLButtonElement | HTMLDivElement | SVGElement | null, index: number) => {
    insideRefs.current[index] = ref;
  };

  return {
    placeRef,
    menuVisible: isComponentVisible,
    toggleMenu: toggle,
    Toggle: ({ Icon = DotsIcon, className, ...rest }) => (
      <button
        onClick={toggle}
        ref={ref => placeRef(ref, 0)}
        className={className || 'sparks-button article-button article-button-icon'}
      >
        <Icon className="pointer" height={14} width={6} fill="#323232" {...rest} />
      </button>
    ),
    MenuContainer,
    ItemContainer: ({
      children,
      className,
      ...rest
    }: { children: ReactNode } & HTMLProps<HTMLDivElement>) => (
      <div
        className={`topic-header-menu__item blue-gray-3-hover d-flex gap-2 flex-grow-1 text-black ${className}`}
        {...rest}
      >
        {children}
      </div>
    ),
  };
}
//
