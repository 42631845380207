export type Browser = "edge_html" | "edge_chromium" | "firefox" | "chrome" | "safari" | "opera" | "ie" | "unknown";

export default function useBrowser(): Browser {
  const agent = window.navigator.userAgent.toLowerCase();
  if (agent.indexOf("edge") > -1)
    return "edge_html";
  if (agent.indexOf("edg") > -1)
    return "edge_chromium";
  if (agent.indexOf("opr") > -1 && !!window.opr)
    return "opera";
  if (agent.indexOf("chrome") > -1 && !!window.chrome)
    return "chrome";
  if (agent.indexOf("trident") > -1)
    return "ie";
  if (agent.indexOf("firefox") > -1)
    return "firefox";
  if (agent.indexOf("safari") > -1)
    return "safari";
  return "unknown";
}
