import React, { LegacyRef } from 'react';
import { ReactComponent as DotsIcon } from '../assets/img/icons/dots.svg';

type Props = {
  containerRef?: React.RefObject<HTMLDivElement>;
  page?: number;
  title?: string;
  footer?: string | JSX.Element | null;
  body: string | JSX.Element | JSX.Element[];
  menu: JSX.Element;
  toggleMenu?: () => any;
  dotsClassName?: string;
  toggleRef?: LegacyRef<any>;
} & React.PropsWithChildren;

const ArticleInfoCard = ({
  containerRef,
  page,
  title,
  body,
  menu,
  toggleMenu,
  children,
  dotsClassName,
  toggleRef,
  footer,
}: Props) => {
  return (
    <div className="position-relative">
      <div
        ref={containerRef}
        className="article-info-card d-flex justify-content-between gap-3 position-relative"
      >
        <div className="flex-grow-1 mw-100">
          {!!page && (
            <>
              <span className="body-text">PAGE: {page}</span>
              <br />
            </>
          )}
          <p className="text-black fw-700 word-break">{title}</p>
          <p className="mb-0">{body}</p>
          {!!footer && <p className="mb-0 mt-4 text-gray-1">{footer}</p>}
        </div>
        {!!toggleMenu && (
          <div style={{ marginTop: -10 }}>
            <button
              onClick={toggleMenu}
              ref={toggleRef}
              className={`article-button article-button-icon article-button-icon--secondary ${dotsClassName}`}
            >
              <DotsIcon className="pointer" height={14} width={4} fill="#323232" />
            </button>
          </div>
        )}
      </div>
      {menu}
      {children}
    </div>
  );
};

export default ArticleInfoCard;
