import React, {CSSProperties} from 'react';

type Props = {
  text?: string,
  lines?: number,
} & React.HTMLAttributes<HTMLSpanElement>;

export default function TextShortener({text, lines, children, ...rest}: Props) {
  const style: CSSProperties = {
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
  if (lines !== undefined) {
    style.display = "-webkit-box";
    style.WebkitLineClamp = lines;
    style.WebkitBoxOrient = "vertical";
  } else {
    style.display = 'block';
    style.whiteSpace = 'nowrap';
  }
  return <span title={text} {...rest} style={{...style, ...rest.style}}>
    {children || text}
  </span>
}
