import React, { useMemo } from 'react';
import ServerRouter from '../../../routers/ServerRouter';
import NetworkJSONLoader from '../../../components/network/NetworkJSONLoader';
import { setArticle } from '../../../data/redux/actions';
import BackToDashboard from '../../../components/links/BackToDashboard';

type Props = {
  article: number;
};

export default function ArticleViewPlaceholder({ article }: Props) {
  return useMemo(
    () => (
      <div
        id="layoutSidenav_content"
        className={'vstack'}
        style={{ top: 'initial', overflow: 'auto' }}
      >
        <div className={'vstack h-100 justify-content-center align-items-center'}>
          <NetworkJSONLoader
            url={ServerRouter.articles(article)}
            onOk={setArticle}
            children={'Fetching article'}
            cancelComponent={<BackToDashboard />}
            nonRetriable
          />
        </div>
      </div>
    ),
    [article],
  );
}
