import React, {useCallback, useState, useEffect} from 'react';
import Button from 'react-bootstrap/esm/Button';
import {useAppDispatch, useAppSelector} from "../../../data/redux/hooks";
import {RootState} from "../../../data/redux/store";
import {FreeCard, PremiumCard} from "./cards";
import {Table} from 'react-bootstrap'
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMultiply} from "@fortawesome/free-solid-svg-icons";
import ServerRouter from "../../../routers/ServerRouter";
import {getHeaders} from "../../../routers/utils";
import {setUser} from "../../../data/redux/actions";


function ConfirmModal(props: any) {
    const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
    const dispatch = useAppDispatch();
    const handleTest = async () => {
        const response = await fetch(ServerRouter.cancelSubscription(), {headers: getHeaders()});
        const plan_name = await response.json()
        dispatch(setUser({
            ...user,
            plan_name: plan_name.plan_name,
            subscription_cancelled: plan_name.subscription_cancelled
        }))
        props.onHide()

    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter-xl"
            centered
        >

            <div className="p-4 text-black text-start">
                <div className="position-relative text-center">
                    <p><h4>Downgrade Account</h4></p>
                    <button className="position-absolute end-0 top-0 border-0 bg-transparent" onClick={props.onHide}>
                        <FontAwesomeIcon icon={faMultiply}/></button>
                </div>
                <div className="px-3">
                    <p>Are you sure you want to downgrade your account to free user?</p>
                    <p>Please note that once downgraded you will continue to enjoy the premium features till your next billing cycle.</p>
                    <p>Once your account is downgraded, you have limited access to number of Topics, Notes, and Summary.</p>
                </div>
                <div className="d-flex justify-content-center mb-2">
                    <button onClick={handleTest}
                            className="btn btn-light btn-sm px-5 shadow-md border-1 border-black">Continue
                    </button>
                </div>

            </div>
        </Modal>
    );
}

function MyVerticallyCenteredModal(props: any) {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter-xl"
            centered
        >
            <div className="p-3 text-black">

                <div className="position-relative text-center">
                    <p><h4>Manage Subscription</h4></p>
                    <button className="position-absolute end-0 top-0 border-0 bg-transparent" onClick={props.onHide}>
                        <FontAwesomeIcon icon={faMultiply}/></button>
                </div>

                <div className="container-fluid p-3 px-3">
                    <div className="row  border ">
                        <div className="col-4   border "></div>
                        <div className="col-4 border ">
                            <div className="m-3">
                                <h3>Starter</h3>
                                <div className="text-xs text-start py-4">Free, For trying the product</div>
                                <div className="d-flex justify-content-center">
                                    <button onClick={props.openConfirm}
                                            className="btn btn-success btn-sm px-4 shadow-sm">Switch to Starter
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-4  border ">
                            <div className="m-3">
                                <h3>Premium</h3>
                                <div className="text-xs">For you to manage unlimited summaries, topics and all premium
                                    features.
                                </div>
                                <div className="p-2 justify-content-center">
                                    <button className="btn btn-sm bg-gray-200 text-black shadow-sm">Current Plan
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row text-xs border ">
                        <div className="col-4 p-2 border-end border-bottom border-start"><b>Save Pages</b></div>
                        <div className="col-4 p-2 border-end border-bottom border-start">5 pages per day</div>
                        <div className="col-4 p-2 border-end border-bottom border-start">unlimited</div>
                    </div>
                    <div className="row text-xs border">
                        <div className="col-4 p-2 border-end border-bottom border-start"><b>Import Bookmarks</b></div>
                        <div className="col-4 p-2 border-end border-bottom border-start">yes</div>
                        <div className="col-4 p-2 border-end border-bottom border-start">yes</div>
                    </div>
                    <div className="row text-xs border">
                        <div className="col-4 p-2 border-end border-bottom border-start"><b>Topics</b></div>
                        <div className="col-4 p-2 border-end border-bottom border-start">3 topics</div>
                        <div className="col-4 p-2 border-end border-bottom border-start">unlimited</div>
                    </div>
                    <div className="row text-xs border">
                        <div className="col-4 p-2 border-end border-bottom border-start"><b>Summary</b></div>
                        <div className="col-4 p-2 border-end border-bottom border-start">yes</div>
                        <div className="col-4 p-2 border-end border-bottom border-start">unlimited Summaries</div>
                    </div>
                    <div className="row text-xs border">
                        <div className="col-4 p-2 border-end border-bottom border-start"><b>Summary by page sections</b>
                        </div>
                        <div className="col-4 p-2 border-end border-bottom border-start">yes</div>
                        <div className="col-4 p-2 border-end border-bottom border-start">unlimited summaries by page section</div>
                    </div>
                    <div className="row text-xs border">
                        <div className="col-4 p-2 border-end border-bottom border-start"><b>Notes</b></div>
                        <div className="col-4 p-2 border-end border-bottom border-start">3 notes</div>
                        <div className="col-4 p-2 border-end border-bottom border-start">unlimited notes</div>
                    </div>

                </div>

            </div>
        </Modal>
    );
}


const SubscriptionManager = () => {

    const [planShow, setPlanShow] = React.useState(false);
    const [confirmShow, setConfirmShow] = React.useState(false);

    const handleConfirmOpen = () => {
        setPlanShow(false);
        setConfirmShow(true);
    }

    return (
        <div className="mt-4">
            <div className={'col-lg-9'}>
                <div className={'card'}>
                    <div className={'card-header'}>Manage Subscription</div>
                    <div className="card-body">
                        <p>Manage your subscription, please note that by cancelling your subscription it will canceled
                            from next billing cycle. After cancellation you will continue to access data you have
                            saved.</p>
                        <Button variant={'primary'} onClick={() => setPlanShow(true)}>Subscription Manager</Button>
                        <MyVerticallyCenteredModal
                            show={planShow}
                            backdrop="static"
                            onHide={() => setPlanShow(false)}
                            openConfirm={handleConfirmOpen}
                        />
                        <ConfirmModal
                            show={confirmShow}
                            backdrop="static"
                            onHide={() => setConfirmShow(false)}
                        />

                    </div>
                </div>
            </div>
        </div>
    )
}

const InvoicesTable = () => {
    const [invoice, setInvoice] = useState([]);
    useEffect(() => {
        (async () => {
            const response = await fetch(ServerRouter.getInvoices(), {headers: getHeaders()});
            const {invoices} = await response.json();
            setInvoice(invoices);

        })();
    }, [])
    return (
        <div>
            <Table responsive bordered>
                <thead>
                <tr>

                    <th>Date</th>
                    <th>Invoice Number</th>
                    <th>Billing Period</th>
                    <th>Amount</th>
                    <th>Action</th>

                </tr>
                </thead>
                <tbody>
                {invoice?.map((obj: any) => {
                    return (
                        <tr>
                            <td>{obj.created.slice(0, 10)}</td>
                            <td>{obj.number}</td>
                            <td>{obj.amount_paid === 10 ? "Monthly" : "Yearly"}</td>
                            <td>{obj.amount_paid}</td>
                            {/*<td><button onClick={obj.invoice_pdf}>Action</button></td>*/}
                            <td><a href={obj.invoice_pdf}>Download</a></td>
                        </tr>
                    )
                })}

                </tbody>
            </Table>

        </div>
    )
}


export default function PaymentContent() {
    const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));

    // @ts-ignore
    return (
        <div className={'container-xl row'}>
            <div className={'col-lg-9'}>
                <div className={'card'}>
                    <div className={'card-header'}>Payments</div>
                    <div className={'card-body'}>
                        <InvoicesTable></InvoicesTable>
                    </div>
                </div>
            </div>
            {user?.plan_name === "Premium" && user?.plan_status !== "Inactive" ?

                <PremiumCard userStartDate={user?.start_date} userEndDate={user?.end_date}
                             userPeriod={user?.interval}></PremiumCard>


                :
                <FreeCard userEmail={user?.email}></FreeCard>
            }
            {user?.plan_name === "Premium" && user?.plan_status !== "Inactive" && user?.subscription_cancelled === false &&
                <SubscriptionManager></SubscriptionManager>}


        </div>);
}
