import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './InfoTab.module.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import InfoTabHeader from './InfoTabHeader';
import MainInput from '../../../components/MainInput';
import ServerRouter from '../../../routers/ServerRouter';
import { getHeaders } from '../../../routers/utils';
import NetworkJSONArrayLoader from '../../../components/network/NetworkJSONArrayLoader';
import { fillQuestions, setInfoTab, setQuestion } from '../../../data/redux/actions';
import { useAppSelector } from '../../../data/redux/hooks';
import { RootState } from '../../../data/redux/store';
import Question from '../../../data/models/Question';
import ArticleInfoCard from '../../../components/ArticleInfoCard';
import useMenu from '../../../hooks/custom/useMenu';
import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/delete.svg';
import { ReactComponent as CopyIcon } from '../../../assets/img/icons/copy.svg';
import { ReactComponent as SummaryIcon } from '../../../assets/img/icons/summary.svg';
import { ReactComponent as InfoIcon } from '../../../assets/img/icons/info.svg';
import { ReactComponent as NotesIcon } from '../../../assets/img/icons/notes.svg';
import { getQuestionCRUD } from '../../../services/cruds';
import { Colors } from '../../../assets/colors';
import { Link, useParams } from 'react-router-dom';
import ClientRouter from '../../../routers/ClientRouter';
import NetworkJSONLoader from '../../../components/network/NetworkJSONLoader';
import uuid from 'react-uuid';
import UpgradeToPremium from '../../../components/messages/UpgradeToPremium';
import { PremiumModal } from '../sidenav/FreePlan';
import { TaskStatus, taskStatusInProgress } from '../../../services/utils';
import SkeletonLoader from '../../../components/loader/SkeletonLoader';
import QuestionsAI from '../../../components/questions/QuestionsAI';

const QuestionItem = ({ question, multiple }: { question: Question; multiple?: boolean }) => {
  const { toggleMenu, MenuContainer, ItemContainer, placeRef } = useMenu();
  const questionCRUD = getQuestionCRUD(res => setQuestion(res));
  const { topicId } = useParams();
  const topic = useAppSelector(
    useCallback((state: RootState) => state.cache.topics[topicId ? +topicId : -1], [topicId]),
  );

  const articles =
    useAppSelector(
      useCallback((state: RootState) => (topic ? topic.getArticles(state.cache) : null), [topic]),
    ) || [];
  const [shouldFetch, setShouldFetch] = useState(false);
  const answersNotFetched = useMemo(
    () => {
      const taskInProgress =
        !!question.task_result && taskStatusInProgress.includes(question.task_result);
      return multiple ? taskInProgress && !!Object.values(articles).length : taskInProgress;
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [multiple, question.task_result],
  );

  const answers = useMemo(() => {
    return question.answers ? question.answers.filter(({ answer }) => !!answer) : [];
  }, [question.answers]);

  const loader = useMemo(
    () =>
      shouldFetch ? (
        <NetworkJSONLoader
          url={`${ServerRouter.questions()}${question.id}/?no-cache=${uuid()}`}
          onOk={res => {
            if (res) {
              setQuestion(res);
            }
            setShouldFetch(false);
          }}
          hidden
        />
      ) : null,
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldFetch],
  );

  useEffect(() => {
    if (answersNotFetched) {
      setTimeout(() => setShouldFetch(true), 3000);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  return (
    <>
      {loader}
      <ArticleInfoCard
        body={
          !answersNotFetched && !answers.length ? (
            <div className="body-text text-gray-0">Answer not available</div>
          ) : (
            answers.map(({ answer, page_id }, i) => {
              const article = Object.values(articles).find(item => item?.id === page_id);
              return (
                <div className={i === answers.length - 1 ? '' : 'mb-5'} key={`answer-${page_id}`}>
                  <div className="body-text text-dark-gray">{answer}</div>
                  <div className="d-flex mt-3 text-gray-0 body-text-sm">
                    {/*Confidence Score: {(score * 100).toFixed(0)}%*/}
                    {multiple && (
                      <>
                        <div className="mx-2">⋅</div>
                        {(!!article?.title || !!article?.url) && (
                          <Link
                            to={ClientRouter.articles(article.id)}
                            style={{ maxWidth: 360 }}
                            className="text-truncate sparks-link body-text-sm text-gray-0"
                          >
                            {article.title || article.url}
                          </Link>
                        )}
                      </>
                    )}
                  </div>
                </div>
              );
            })
          )
        }
        title={question.question}
        footer={
          answersNotFetched ? (
            <SkeletonLoader loaderBody={[[1], [1], [1, 0], [1, 0, 0, 0]]} withoutPadding />
          ) : null
        }
        toggleMenu={toggleMenu}
        toggleRef={ref => placeRef(ref, 0)}
        menu={
          <MenuContainer>
            {!!answers.length && (
              <CopyToClipboard text={answers.map(({ answer }) => answer).join(' ')}>
                <ItemContainer>
                  <CopyIcon width={15} height={15} fill="#9197A1" />
                  <span>Copy</span>
                </ItemContainer>
              </CopyToClipboard>
            )}
            {!!question.id && (
              <questionCRUD.deleteDropdown
                defaultValues={{ id: question.id }}
                withDefaultContainer={false}
              >
                <ItemContainer>
                  <DeleteIcon width={15} height={15} fill="#9197A1" />
                  <span>Delete</span>
                </ItemContainer>
              </questionCRUD.deleteDropdown>
            )}
          </MenuContainer>
        }
      />
    </>
  );
};

const QuestionsInfo = () => {
  const [shouldFetch, setShouldFetch] = useState(true);
  const [fetchedInitial, setFetchedInitial] = useState(false);
  const [premiumShow, setPremiumShow] = useState(false);
  const [premiumModalShow, setPremiumModalShow] = useState(false);
  const { MenuContainer, ItemContainer, Toggle } = useMenu();
  const { topicId, articleId } = useParams();

  const topic = useAppSelector(
    useCallback((state: RootState) => state.cache.topics[topicId ? +topicId : -1], [topicId]),
  );
  const article = useAppSelector(
    useCallback(
      (state: RootState) => state.cache.articles[articleId ? +articleId : -1],
      [articleId],
    ),
  );
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));

  const isTopic = useMemo(() => !!topic?.id, [topic?.id]);
  const id = useMemo(() => (isTopic ? topic?.id : article?.id), [topic?.id, article?.id, isTopic]);
  const param = useMemo(() => (isTopic ? 'topic' : 'page'), [isTopic]);
  const questions = Object.values(
    useAppSelector((state: RootState) => state.cache.questions),
  ).filter(question => !!question);

  const questionsAvailable = article?.page_content !== 'Bookmark';

  const createQuestion = useCallback(
    (question: string) => {
      if (question && id) {
        return fetch(ServerRouter.questions(), {
          method: 'POST',
          headers: getHeaders(),
          credentials: 'include',
          body: JSON.stringify({
            question,
            [param]: id,
            from_openai: true,
          }),
        })
          .then(res => {
            if (!res.ok) {
              throw new Error(res.status.toString());
            }
            return res.json();
          })
          .then(res => setQuestion({ ...(res || {}), task_result: TaskStatus.started }))
          .catch(e => {
            if (e.message === '403') {
              setPremiumShow(true);
            }
            console.log('create question err ===', e);
          });
      }
    },
    [id, param],
  );

  const loader = useMemo(
    () =>
      shouldFetch ? (
        <NetworkJSONArrayLoader
          url={`${ServerRouter.questions()}?${param}=${id}`}
          onOk={res => {
            fillQuestions(res);
            setShouldFetch(false);
            setFetchedInitial(true);
          }}
          hidden
        />
      ) : null,
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldFetch, id, fetchedInitial],
  );

  useEffect(() => {
    if (id) {
      fillQuestions([]);
      setFetchedInitial(false);
      setShouldFetch(true);
    }
  }, [id]);

  useEffect(() => {
    if (isTopic) {
      setInfoTab({ visible: false, showing: 'questions', about: 'topic' });
    }
  }, [isTopic]);

  return (
    <div className={styles.questions + ' d-flex flex-column'}>
      <InfoTabHeader title="Questions">
        {questionsAvailable && (
          <>
            <div className="only-mobile">
              <Toggle />
            </div>
            <MenuContainer>
              <ItemContainer
                onClick={() => setInfoTab({ showing: 'summary', visible: true, about: 'article' })}
              >
                <SummaryIcon fill={Colors.blueGray00} width={15} height={15} />{' '}
                <span className="body-text-sm">View Summary</span>
              </ItemContainer>
              <ItemContainer
                onClick={() => setInfoTab({ showing: 'notes', visible: true, about: 'article' })}
              >
                <NotesIcon width={18} height={18} fill="#9197A1" /> <span>Take a Note</span>
              </ItemContainer>
              <ItemContainer
                onClick={() => setInfoTab({ showing: 'details', visible: true, about: 'article' })}
              >
                <InfoIcon fill={Colors.blueGray00} width={15} height={15} />{' '}
                <span className="body-text-sm">Details</span>
              </ItemContainer>
            </MenuContainer>
          </>
        )}
      </InfoTabHeader>
      {!!article && <QuestionsAI article={article} createQuestion={createQuestion} />}
      {questionsAvailable ? (
        <>
          <div className="border-color-gray-3 border-bottom border-1">
            <MainInput
              onSubmit={createQuestion}
              inputProps={{
                placeholder: `Ask us a question related to the ${isTopic ? 'topic' : 'article'}`,
                className: 'purple-input border-blue-gray-1',
              }}
            />
          </div>
          {!!loader && !fetchedInitial && (
            <SkeletonLoader
              loaderBody={[
                [1],
                [1],
                [1],
                [1],
                [1, 0],
                [1, 1, 0, 0],
                [0],
                [1],
                [1],
                [1],
                [1],
                [1, 0],
                [1, 1, 0, 0],
              ]}
            />
          )}
          {loader}
          <div className="d-flex flex-column">
            {questions.reverse().map(question =>
              question ? (
                <div key={`question-${question.id}`}>
                  <QuestionItem question={question} multiple={isTopic} />
                  <div className="blue-gray-divider" />
                </div>
              ) : null,
            )}
          </div>
          <UpgradeToPremium
            show={premiumShow}
            message="questions"
            messageAction="ask"
            onHide={() => setPremiumShow(false)}
            closePlan={() => {
              setPremiumShow(false);
              setPremiumModalShow(true);
            }}
          ></UpgradeToPremium>
          <PremiumModal
            showModal={premiumModalShow}
            onCancel={() => setPremiumModalShow(false)}
            signedUser={user?.email}
          ></PremiumModal>
        </>
      ) : (
        <div className="bg-red-02 body-text text-red-01 text-center py-3 m-3">
          Questions not available for this page
        </div>
      )}
    </div>
  );
};

export default QuestionsInfo;
