import Model from "./Model";

export default class TimeStampedModel extends Model {
  readonly created_at?: string;

  constructor(jsonData: Partial<TimeStampedModel>) {
    super(jsonData);
    Object.assign(this, jsonData);
  }
}
