import React from 'react';
import { Link } from 'react-router-dom';
import ClientRouter from '../../routers/ClientRouter';
import DefaultLayout from './components/DefaultLayout';
import LandingNav from '../../components/nav/LandingNav';
import TryOutSection from './components/TryOutSection';
import CasesSection from './components/CasesSection';
import FeaturesSection from './components/FeaturesSection';
import LandingHeader from './components/LandingHeader';
import TestimonialsSection from './components/TestimonialsSection';
import MetaTagsList from '../../components/MetaTagsList';

export default function Home() {
  return (
    <DefaultLayout>
      <MetaTagsList type="home" />
      <LandingNav signUpLabel="Sign Up" />
      <LandingHeader>
        <div className="mb-3">
          <h1 className={'page-header-ui-title'}>
            Organize & Analyze Content that Sparks Your Interest
          </h1>
          <div className="body-text-sm">
            getSparks is a content consumption platform that makes it easier to take action on your
            research
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Link to={ClientRouter.register()} className="main-cta-btn">
            Sign Up
          </Link>
        </div>
      </LandingHeader>
      <FeaturesSection />
      <CasesSection />
      <TestimonialsSection />
      <TryOutSection signUpLabel="Sign Up" />
    </DefaultLayout>
  );
}
