import React, {useState} from 'react';
import {Button, Modal, ModalBody, Stack} from "react-bootstrap";
import BlueLogo from "../../../../components/nav/BlueLogo";
import ExtensionImportBookmarks from "./ExtensionImportBookmarks";
import ExtensionAddPage from "./ExtensionAddPage";
import {EXTENSION_WAS_CONFIGURED} from "../TopicView";
import styles from "./extension.module.css";
import {ExtensionAddOpenTabs} from "./ExtensionAddOpenTabs";

const tabs: { name: string, content: React.ReactNode }[] = [
  {name: 'Import Bookmarks', content: <ExtensionImportBookmarks/>},
  {name: 'Add Page to getSparks', content: <ExtensionAddPage/>},
  {name: 'Save now', content: <ExtensionAddOpenTabs/>},
]

type Props = {
  onClose: () => void;
}

export default function ExtensionIntroduction({onClose}: Props) {
  const [step, setStep] = useState(0);
  if (step >= tabs.length) {
    return null;
  }
  return (
    <Modal show centered dialogClassName={styles.model}>
      <ModalBody>
        <h1 className={'d-flex justify-content-between'}>
          <BlueLogo/>
          <div className={'btn btn-close'} onClick={() => {
            setStep(tabs.length);
            localStorage.setItem(EXTENSION_WAS_CONFIGURED, 'true');
            onClose();
          }}/>
        </h1>
        <Stack direction={"horizontal"}>
          <div className={'d-flex flex-column flex-shrink-0 py-3 align-self-stretch'}
               style={{backgroundColor: '#D4F0FD'}}>
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={'p-3 pointer'}
                style={{backgroundColor: index === step ? '#98E0FF' : undefined}}
                onClick={() => {
                  setStep(index);
                }}>
                <b>Step {index + 1} &gt;</b>
                <div>{tab.name}</div>
              </div>
            ))}
          </div>
          <div className={'d-flex flex-column align-self-stretch justify-content-start flex-grow-1 ps-3'}>
            <p><b>{tabs[step].name}</b></p>
            <div className={'small w-100 flex-grow-1'}>
              {tabs[step].content}
            </div>
            <p className={'mt-3 mb-0'} style={{textAlign: "right"}}>
              <Button
                onClick={() => {
                  if (step >= tabs.length - 1) {
                    localStorage.setItem(EXTENSION_WAS_CONFIGURED, 'true');
                    onClose();
                  }
                  setStep(step + 1);
                }}
                children={step + 1 < tabs.length ? 'Next' : 'Finish'}/>
            </p>
          </div>
        </Stack>
      </ModalBody>
    </Modal>
  );
}
