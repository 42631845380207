import React, {PropsWithChildren} from 'react';
import TextButton from "../buttons/text/TextButton";
import {RefreshCw} from "react-feather";

type Props = PropsWithChildren<{
  onRetry?: () => void,
  onCancel?: () => void,
  cancelComponent?: React.ReactNode,
}>;

export default function ErrorMessage({onRetry, children, onCancel, cancelComponent}: Props) {
  return <div className={"card p-2 field-error align-items-center"} style={{border: "1px solid"}}>
    <div>{children ?? "Unexpected errors"}</div>
    <div>
      {onRetry && <TextButton className={'p-1'} onClick={onRetry}><RefreshCw/>&nbsp;Retry</TextButton>}
      {cancelComponent ?? (onCancel && <TextButton className={'p-2'} onClick={onCancel}>Cancel</TextButton>)}
    </div>
  </div>
}
