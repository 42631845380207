import { JSONObject } from '../data/models/Model';
import React, { FormEvent } from 'react';

export enum TaskStatus {
  success = 'SUCCESS',
  error = 'FAILURE',
  pending = 'PENDING',
  retry = 'RETRY',
  started = 'STARTED',
}

export const taskStatusInProgress = [TaskStatus.retry, TaskStatus.started, TaskStatus.pending];

export const checkIsLimitError = (error: JSONObject | undefined) => {
  if (error?.non_field_errors?.length) {
    return error.non_field_errors.some(
      (error: string) => error?.includes('limit') && error?.includes('reached'),
    );
  }
  return false;
};

export const disableCtrl = (
  e: React.MouseEvent<HTMLDivElement>,
  callback: (e: React.MouseEvent<HTMLDivElement>) => any,
) => {
  if (e.ctrlKey || e.metaKey) {
    return;
  }
  return callback(e);
};

export const checkIsUrlValid = (urlString: string) => {
  let url;
  try {
    url = new URL(urlString);
  } catch (e) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export function keywordsToString(keywords?: string | JSONObject | []) {
  if (typeof keywords === 'string') {
    const originalKeywords = keywords;
    try {
      if (keywords.indexOf('"') < 0) keywords = keywords.replaceAll(/'/g, '"');
      keywords = JSON.parse(keywords);
    } catch (e) {
      console.warn('Bad article keywords:', e);
      return originalKeywords;
    }
  }
  const keywordMapper = (keyword: string | JSONObject) => {
    if (typeof keyword === 'string') return keyword;
    return keyword.name;
  };
  if (!keywords) return '';
  if (!Array.isArray(keywords)) return JSON.stringify(keywords);
  return keywords.map(keywordMapper).join(', ');
}

export function parseJSONSafely(str: string) {
  try {
    return JSON.parse(str.replaceAll("'", '"'));
  } catch (e) {
    return false;
  }
}

export function iteratorToDict<T>(iterator: Iterator<[string, T]>) {
  const values: { [x: string]: T } = {};
  let entry = iterator.next();
  while (!entry.done) {
    values[entry.value[0]] = entry.value[1];
    entry = iterator.next();
  }
  return values;
}

export function getFormFieldsValues(event: FormEvent, additionalFields?: { [key: string]: any }) {
  return iteratorToDict(new FormData(event.currentTarget as HTMLFormElement).entries());
}

export function isEqual(dict1: { [x: string]: any }, dict2: { [x: string]: any }) {
  if (dict1 === dict2) return true;
  if (Object.keys(dict1).length !== Object.keys(dict2).length) return false;
  for (const key in dict1) {
    if (dict1[key] !== dict2[key]) return false;
  }
  return true;
}

type CookieOptions = {
  expires?: Date | number | string;
  path?: string;
  domain?: string;
  secure?: boolean;
};

export const setCookie = (name: string, value: string, options: CookieOptions = {}) => {
  options = {
    path: '/',
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += '; ' + optionKey;
    let optionValue = options[optionKey as keyof CookieOptions];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  document.cookie = updatedCookie;
};

export const getCookie = (name: string) => {
  const match = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return match ? match[2] : undefined;
};
