import TimeStampedModel from './TimeStampedModel';
import { IField } from '../../components/crud/fields/Field';

export type TagId = number;

export default class Tag extends TimeStampedModel {
  static fields = {
    title: (tags: (Tag | undefined)[]) =>
      ({
        name: 'title',
        validate: value => !tags.some(tag => tag?.title?.toLowerCase() === value.toLowerCase()),
        errorMessage: 'Tag already exists',
      } as IField),
  };
  readonly title: string;
  readonly id: TagId;

  constructor(jsonData: Tag) {
    super(jsonData);
    this.title = jsonData.title;
    this.id = jsonData.id;
  }
}
