import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Article from '../../../data/models/Article';
import useFetchMatchUrls from '../../../hooks/custom/useFetchMatchUrls';
import DiscoverArticleCardView from '../topic/articles/DiscoverArticleCardView';
import Topic from '../../../data/models/Topic';
import InterestsSelect, {
  shortInterestSelectCommonProps,
} from '../../../components/discover/InterestsSelect';
import { useAppSelector } from '../../../data/redux/hooks';
import { RootState } from '../../../data/redux/store';
import PageGroup from '../../../data/models/PageGroup';
import { ReactComponent as SettingsIcon } from '../../../assets/img/icons/settings.svg';
import Awaiter from '../../../components/messages/Awaiter';
import ServerRouter from '../../../routers/ServerRouter';
import { getHeaders } from '../../../routers/utils';
import HorizontalScroll from '../../../components/HorizontalScroll';

type Props = {
  article: Article;
  topic: Topic;
};

const ArticleDiscoveryFeed = ({ article, topic }: Props) => {
  const [interestsSelectVisible, setInterestsSelectVisible] = useState(false);
  const [selectedTokens, setSelectedTokens] = useState<number[]>(
    (article.selected_tokens || []).map(({ id }) => id),
  );
  const [selectedPreferences, setSelectedPreferences] = useState<number[]>(
    article.preferences || [],
  );

  const [page, setPage] = useState(1);

  const {
    fetchDiscoveryArticles,
    discoverArticles,
    isLoading,
    clearDiscoveryArticles,
    onDiscoverArticleAdded,
    canFetchMore,
  } = useFetchMatchUrls({
    urls: [article.url || ''],
    tokens: selectedTokens,
    preferences: selectedPreferences,
    page,
    topic,
  });

  const pageGroups = useAppSelector(
    useCallback(
      (state: RootState) =>
        Object.values(state.cache.pageGroups)
          .filter(pageGroup => !!pageGroup)
          .sort((a, b) => (a?.name || '').localeCompare(b?.name || '')),
      [],
    ),
  ) as PageGroup[] | undefined;

  const defaultTokens = useMemo(
    () => (article.selected_tokens ? article.selected_tokens.map(({ id }) => id) : []),
    [article.selected_tokens],
  );

  const onInterestsSubmit = (selectedPageGroupsIds: number[], selectedTokensIds: number[]) => {
    const promises: Promise<any>[] = [];
    const commonProps = {
      method: 'PATCH',
      headers: getHeaders(),
      credentials: 'include',
    } as RequestInit;
    promises.push(
      fetch(ServerRouter.articleTokensUpdate(article.id), {
        ...commonProps,
        body: JSON.stringify({
          tokens: selectedTokensIds,
        }),
      }),
    );
    promises.push(
      fetch(ServerRouter.articles(article.id), {
        ...commonProps,
        body: JSON.stringify({
          preferences: selectedPageGroupsIds,
        }),
      }),
    );
    Promise.all(promises).finally(() => {
      setSelectedPreferences(selectedPageGroupsIds);
      setSelectedTokens(selectedTokensIds);
      setInterestsSelectVisible(false);
    });
  };

  useEffect(() => {
    setSelectedPreferences(article.preferences || []);
    setSelectedTokens((article.selected_tokens || []).map(({ id }) => id));
  }, [article.selected_tokens, article.preferences]);

  useEffect(() => {
    clearDiscoveryArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article.id]);

  useEffect(() => {
    fetchDiscoveryArticles(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPreferences, selectedTokens]);

  useEffect(() => {
    if (page > 1) {
      fetchDiscoveryArticles(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div className="py-3">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="sparks-h3 mb-0">Discover more like this</div>
        <button
          className="sparks-button article-button notes-button d-flex align-items-center height-40"
          onClick={() => setInterestsSelectVisible(true)}
        >
          <SettingsIcon height={13} width={13} fill="#000" />
        </button>
      </div>
      <HorizontalScroll
        onScrollToEnd={() => {
          if (canFetchMore) {
            setPage(page + 1);
          }
        }}
      >
        {discoverArticles.map(article => (
          <div key={`discover-article-${article.id}`} style={{ maxWidth: 272 }}>
            <DiscoverArticleCardView
              discoverArticle={article}
              topic={topic}
              onlyTilesView
              onDiscoverArticleAdded={onDiscoverArticleAdded}
            />
          </div>
        ))}
      </HorizontalScroll>
      {isLoading && <Awaiter />}
      <InterestsSelect
        {...shortInterestSelectCommonProps}
        visible={interestsSelectVisible}
        tokens={article.tokens}
        onClose={() => setInterestsSelectVisible(false)}
        onSubmit={onInterestsSubmit}
        pageGroups={pageGroups}
        defaultTokens={defaultTokens}
        defaultPageGroups={article.preferences}
      />
    </div>
  );
};

export default ArticleDiscoveryFeed;
