import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AuthContainer } from './AuthContainer';
import User from '../../data/models/User';
import CRUD from '../../components/crud/CRUD';
import ServerRouter from '../../routers/ServerRouter';
import ClientRouter from '../../routers/ClientRouter';
import { ArrowLeft } from 'react-feather';
import { JSONObject } from '../../data/models/Model';

export default function ChangePassword() {
  const [sent, setSent] = useState<boolean>(false);
  const { uidb64, token } = useParams();
  const fields = [
    User.fields.email_token,
    User.fields.uidb64,
    User.fields.password,
    User.fields.confirm_password,
  ];
  const crud = new CRUD(ServerRouter.passwordReset(), fields, () => setSent(true));
  const check = (json: JSONObject) => {
    if (json.password !== json.confirm_password) {
      return { password: 'Must be the same', confirm_password: 'Must be the same' };
    }
  };
  return (
    <AuthContainer
      title={'Change Password'}
      header={<div className="sparks-h3 mb-3 text-align-start">Change Password</div>}
      body={
        sent ? (
          <>
            <p>Successful password change</p>
            <Link to={ClientRouter.login()}>
              <ArrowLeft /> Sign in!
            </Link>
          </>
        ) : (
          <crud.post
            defaultValues={{ uidb64, token }}
            check={check}
            formButton={
              <div className="d-flex flex-column  justify-content-evenly mb-0">
                <button
                  className="button button-purple button-text text-gray-4 flex-grow-1"
                  type="submit"
                >
                  Change password
                </button>
              </div>
            }
          />
        )
      }
    />
  );
}
