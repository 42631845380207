import React from 'react';
import LandingNav from '../../components/nav/LandingNav';
import CasesSection from './components/CasesSection';
import TestimonialsSection from './components/TestimonialsSection';
import TryOutSection from './components/TryOutSection';
import LandingFooter from '../../components/footer/LandingFooter';
import FeaturesSection from './components/FeaturesSection';
import MetaTagsList from '../../components/MetaTagsList';

const SaveLP = () => {
  return (
    <div>
      <MetaTagsList type="home" />
      <LandingNav />
      <CasesSection order={[1, 2, 3]} buttonType="extension">
        <div className="mb-5">
          <FeaturesSection />
        </div>
      </CasesSection>
      <TestimonialsSection />
      <TryOutSection />
      <LandingFooter />
    </div>
  );
};

export default SaveLP;
