import React, { ChangeEvent, useState } from 'react';
import Topic from '../../../data/models/Topic';
import ModalView from '../../../components/crud/ModalView';
import { ReactComponent as ArrowUp } from '../../../assets/img/icons/file-arrow-up-solid 1.svg';
import axios from 'axios';
import ServerRouter from '../../../routers/ServerRouter';
import { getAxiosHeaders } from '../../../routers/utils';
import { fillTopics } from '../../../data/redux/actions';
import classNames from 'classnames';
import useConditionalLoader from '../../../hooks/custom/useConditionalLoader';

type Props = {
  topic: Topic;
  actionButton: (onClick: () => any) => React.ReactNode;
  formRef: React.LegacyRef<any>;
};

const TopicImport = ({ topic, actionButton, formRef }: Props) => {
  const [show, setShow] = useState(false);
  const [dragActive, setDragActive] = React.useState(false);
  const [fileSelected, setFileSelected] = React.useState<File | null>(null);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleDrag: React.DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleFile = (file: File) => setFileSelected(file);

  const handleDrop: React.DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current?.click();
  };

  const { loader: topicsLoader, setShouldFetch: setShouldFetchTopics } = useConditionalLoader({
    onOk: fillTopics,
    url: ServerRouter.topics(),
    defaultShouldFetch: false,
  });

  const fileType = fileSelected?.type;
  const fileTypeCorrect =
    fileType === 'text/html' || fileType === 'text/csv' || fileType === 'text/plain';

  const onSubmit = async () => {
    if (fileSelected && fileTypeCorrect) {
      try {
        const formData = new FormData();
        formData.append('file_content', fileSelected);
        const config = getAxiosHeaders();
        config.headers['Content-Type'] = 'multipart/form-data';
        const type = fileType as 'text/html' | 'text/csv' | 'text/plain';
        const urlByType = {
          'text/html': ServerRouter.importHTML(),
          'text/csv': ServerRouter.importCSV(),
          'text/plain': ServerRouter.importText(),
        };
        const url = urlByType[type];
        const res = await axios.post(url, formData, config);
        if (res?.data) {
          setShouldFetchTopics(true);
          setShow(false);
        }
      } catch (e) {
        setShow(false);
      }
    }
  };

  return (
    <>
      {topicsLoader}
      <ModalView
        show={show}
        setShow={setShow}
        title="Import Bookmarks"
        formRef={formRef}
        footer={
          <div className="d-flex flex-column align-items-center">
            {!!fileType && !fileTypeCorrect && (
              <div className="field-error mb-1">
                Uploaded file is incorrect, please try another one
              </div>
            )}
            <button
              onClick={onSubmit}
              disabled={!fileTypeCorrect}
              className={classNames('button-variant-1 article-button--primary', {
                'article-button--primary-disabled': !fileTypeCorrect,
              })}
            >
              Import
            </button>
          </div>
        }
        body={
          <div className="d-flex flex-column gap-2">
            <h2 className="m-0 body-text-sm fw-700 text-align-start">
              Upload bookmarks file (html, csv, or text)
            </h2>
            <p className="m-0 body-text-sm text-align-start">
              This file can be generated by your browser or third-party service in the “Export”
              section.
            </p>
            <div onDragEnter={handleDrag}>
              <input id="topic-import-upload" onChange={handleChange} type="file" hidden />
              <label
                htmlFor="topic-import-upload"
                style={{
                  background: dragActive ? '#fff' : '#F3F6FB',
                  border: '1px solid #6B2ACB',
                  borderRadius: 8,
                }}
                className="pointer w-100 py-4 px-4"
                onClick={onButtonClick}
              >
                <ArrowUp width={30} height={37.5} fill="#000" />
                <p className="m-0 body-text-sm text-center mt-3 text-black fw-500">
                  {fileSelected
                    ? fileSelected.name
                    : 'Drag and drop file here or click in the box to browse'}
                </p>
              </label>
              {dragActive && (
                <div
                  className="drag-file-element"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                />
              )}
            </div>
          </div>
        }
        actionButton={actionButton}
      />
    </>
  );
};

export default TopicImport;
