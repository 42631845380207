import React, { FormEvent, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { JSONObject } from '../../data/models/Model';
import Field, { IField } from './fields/Field';
import { Button } from 'react-bootstrap';
import Awaiter from '../messages/Awaiter';

export type FormFields = ReadonlyArray<IField | IField[]>;

export type ModularFormOnSubmitType = (
  event: FormEvent,
  additionalFields?: { [key: string]: any },
  uploadType?: string,
) => void;

type Props = {
  onSubmit: ModularFormOnSubmitType;
  fields: FormFields;
  showErrors?: boolean;
  errors?: JSONObject;
  setErrors?: React.Dispatch<React.SetStateAction<JSONObject | undefined>>;
  button?: ReactNode;
  defaultValues?: JSONObject;
  sending?: boolean;
  nonLinear?: boolean;
  formProps?: React.FormHTMLAttributes<HTMLFormElement>;
  inline?: boolean;
};

export default function ModularForm(props: Props) {
  let {
    onSubmit,
    fields,
    errors,
    setErrors,
    button,
    defaultValues,
    sending,
    nonLinear,
    formProps,
    inline,
    showErrors,
  } = props;

  const [uploadType, setUploadType] = useState<string>();
  const [pdfFile, setPdfFile] = useState<File>();
  const formRef = useRef<HTMLFormElement>(null);

  const fireSubmitFormEvent = useCallback(() => {
    if (formRef.current?.dispatchEvent) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }, []);

  const getVisibleFieldsCount = () => {
    let counter = 0;
    fields.forEach(batch => {
      if (Array.isArray(batch)) batch.forEach(field => !field.hidden && ++counter);
      else !batch.hidden && ++counter;
    });
    return counter;
  };

  const getFirstVisibleField = (fields: FormFields) => {
    for (const batch of fields)
      if (Array.isArray(batch)) {
        for (const field of batch) if (!field.hidden) return field;
      } else if (!batch.hidden) return batch;
  };

  const firstVisibleField = getFirstVisibleField(fields);

  const lastVisibleField = useMemo(() => {
    if (fields.length) {
      const field = fields[fields.length - 1];
      if (Array.isArray(field)) {
        return getFirstVisibleField(field.slice().reverse());
      } else {
        return getFirstVisibleField(
          fields
            .map(value => (Array.isArray(value) ? value.slice().reverse() : value))
            .slice()
            .reverse(),
        );
      }
    }
    return undefined;
  }, [fields]);

  const isButtonNext =
    !nonLinear && getVisibleFieldsCount() === 1 && firstVisibleField?.type !== 'wysiwyg';
  button = useMemo(() => {
    if (sending) return <Awaiter className={isButtonNext ? 'p-0 m-0 hstack mx-2' : undefined} />;
    return (
      button ?? (
        <Button variant={'primary'} type={'submit'}>
          Send
        </Button>
      )
    );
  }, [button, isButtonNext, sending]);

  const setError = useCallback(
    (field: IField, errorMessage: string) => {
      if (setErrors) {
        setErrors(prev => ({ ...prev, [field.name as string]: errorMessage }));
      }
    },
    [setErrors],
  );

  const renderField = useCallback(
    (field: IField, index: number, inlineField?: boolean) => {
      const defaultValue = field.name && defaultValues?.[field.name];
      const error = field.name && errors?.[field.name];
      const next =
        (isButtonNext && firstVisibleField === field) || (inline && lastVisibleField === field)
          ? button
          : undefined;
      const autoFocus = field === firstVisibleField && !field.noAutoFocus;
      if (field.name === 'name' && isButtonNext) {
        return (
          <>
            <Field
              field={field}
              key={index}
              error={error}
              showErrors={showErrors}
              setError={setError}
              defaultValue={defaultValue}
              autoFocus={autoFocus}
              inline={inlineField}
              isShort={inline}
              uploadType={uploadType}
              setUploadType={setUploadType}
              setPdfFile={setPdfFile}
              onEnterPress={field.submitOnEnterPress ? fireSubmitFormEvent : null}
            />
            <div>{button}</div>
          </>
        );
      }

      return (
        <Field
          field={field}
          key={index}
          error={error}
          showErrors={showErrors}
          setError={setError}
          next={next}
          defaultValue={defaultValue}
          autoFocus={autoFocus}
          inline={inlineField}
          isShort={inline}
          uploadType={uploadType}
          setUploadType={setUploadType}
          setPdfFile={setPdfFile}
          onEnterPress={field.submitOnEnterPress ? fireSubmitFormEvent : null}
        />
      );
    },
    [
      button,
      defaultValues,
      errors,
      firstVisibleField,
      inline,
      isButtonNext,
      lastVisibleField,
      uploadType,
      setError,
      showErrors,
      fireSubmitFormEvent,
    ],
  );

  const renderFields = useCallback(
    (batch: IField | IField[], index: number) => {
      if (Array.isArray(batch)) {
        const fields = batch.map((field, index) => {
          return renderField(field, index, true);
        });
        return (
          <div className={'d-flex flex-wrap column-gap-2'} key={index}>
            {fields}
          </div>
        );
      }
      return renderField(batch, index);
    },
    [renderField],
  );

  const content = useMemo(() => {
    if (inline)
      return (
        <div className={'input-group flex-nowrap'}>
          {fields.map(renderFields)}
          {button}
        </div>
      );
    return fields.map(renderFields);
  }, [button, fields, inline, renderFields]);

  return useMemo(
    () => (
      <form {...formProps} ref={formRef} onSubmit={e => onSubmit(e, { pdf: pdfFile }, uploadType)}>
        <div className={'field-error'}>{errors?.['non_field_errors']}</div>
        {content}
        {!isButtonNext && !inline && button}
      </form>
    ),
    [button, content, errors, formProps, inline, isButtonNext, onSubmit, pdfFile, uploadType],
  );
}
