import React, {useCallback, useMemo, useState} from 'react';
import User from "../../../data/models/User";
import Button from 'react-bootstrap/esm/Button';
import CRUD from "../../../components/crud/CRUD";
import ServerRouter from "../../../routers/ServerRouter";
import {Link} from "react-router-dom";
import ClientRouter from "../../../routers/ClientRouter";
import {ArrowLeft} from "react-feather";
import {IField} from "../../../components/crud/fields/Field";
import PostButton from "../../../components/buttons/text/PostButton";
import {useAppSelector} from "../../../data/redux/hooks";
import {RootState} from "../../../data/redux/store";
import {FreeCard, PremiumCard} from "./cards";
import {getHeaders} from "../../../routers/utils";
import {setUser} from '../../../data/redux/actions';
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faMultiply} from "@fortawesome/free-solid-svg-icons";


const handleDeleteAccount = async () => {
    const response = await fetch(ServerRouter.deleteAccount(), {
        method: 'DELETE',
        headers: getHeaders()
    });
    if (response) {
        setUser(0);
    }
}

function ConfirmModal(props: any) {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter-xl"
            centered
        >
            <div className="p-4 text-black text-start">
                <div className="position-relative text-center">
                    <p><h4>Delete Account Permanently</h4></p>
                    <button className="position-absolute end-0 top-0 border-0 bg-transparent" onClick={props.onHide}>
                        <FontAwesomeIcon icon={faMultiply}/></button>
                </div>
                <div className="px-3">
                <p>Are you sure that you want to proceed with deleting the account?</p>
                <p>Please note that once deleted, the action can not be reversed. For the privacy of our users we delete user profile from our system,
                    making it impossible for us to restore or access by anyone.</p>
                </div>
                <div className="d-flex justify-content-center mb-2">
                    <button onClick={handleDeleteAccount} className="btn btn-danger btn-sm px-5 shadow-md">Delete</button>
                </div>

            </div>
        </Modal>
    );
}

function CancelSubscriptionModal(props: any) {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter-xl"
            centered
        >
            <div className="p-4 text-black text-start">
                <div className="position-relative text-center">
                    <p><h4>Account cannot be deleted</h4></p>
                    <button className="position-absolute end-0 top-0 border-0 bg-transparent" onClick={props.onHide}>
                        <FontAwesomeIcon icon={faMultiply}/></button>
                </div>
                <div className="px-3">
                <p>You have active subscription for your account, please cancel subscription before deleting the account.</p>
                <p>You can cancel subscription by visiting Account <FontAwesomeIcon icon={faArrowRight}/> Profile <FontAwesomeIcon icon={faArrowRight}/> Payment and clicking on subscription manager.</p>
                </div>
                <div className="d-flex justify-content-center mb-2">
                    <button onClick={props.onHide} className="btn btn-light btn-sm px-5 shadow-md border-1 border-black">Close</button>
                </div>

            </div>
        </Modal>
    );
}

export default function ProfileSecurity() {
    const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
    const [sent, setSent] = useState<boolean>(false);
    const resetPasswordButton = useMemo(() => <PostButton>Reset password</PostButton>, []);
    const [confirmShow, setConfirmShow] = React.useState(false);
    const [cancelSubsShow, setCancelSubsShow] = React.useState(false);




    const crud = useMemo(() => {
        const fields = [{...User.fields.real_email, hidden: true}, {
            ...User.fields.real_email,
            disabled: true
        } as IField];
        return new CRUD(ServerRouter.passwordResetRequest(), fields, () => setSent(true));
    }, []);
    const resetPasswordView = useMemo(() => {
        if (sent)
            return <>
                <p>Check your email for the link to proceed</p>
                <Link to={ClientRouter.dashboard()}><ArrowLeft/> Return to Dashboard</Link>
            </>
        return user && user.__data && <crud.post defaultValues={user.__data} formButton={resetPasswordButton}/>
    }, [crud, resetPasswordButton, sent, user]);
    return useMemo(() => <div className={'container-xl row'}>
        <div className={'col-lg-9'}>
            <div className={'card'}>
                <div className={'card-header'}>Reset password</div>
                <div className={'card-body'}>
                    {resetPasswordView}
                </div>
            </div>
        </div>
        {user?.plan_name === "Premium" && user?.plan_status !== "Inactive" ?
            <PremiumCard userStartDate={user?.start_date} userEndDate={user?.end_date}
                         userPeriod={user?.interval}></PremiumCard>
            :
            <FreeCard userEmail={user?.email}></FreeCard>
        }

        <div className={'col-lg-9'}>
            <div className={'card'}>
                <div className={'card-header'}>Delete Account</div>
                <div className="card-body">
                    <p>Deleting your account is a permanent action and cannot be undone. If you are sure you want to
                        delete your
                        account, select the button below.</p>
                    <Button variant={'danger'} onClick={() => {

                        if (user?.plan_name === "Premium" && user?.subscription_cancelled === false) {
                            setCancelSubsShow(true)
                        } else {
                            setConfirmShow(true)
                        }

                    }}>I understand, delete my
                        account</Button>
                    <ConfirmModal
                        show={confirmShow}
                        backdrop="static"
                        onHide={() => setConfirmShow(false)}
                    />
                    <CancelSubscriptionModal
                        show={cancelSubsShow}
                        backdrop="static"
                        onHide={() => setCancelSubsShow(false)}
                    />
                </div>
            </div>
        </div>
    </div>, [resetPasswordView, confirmShow, cancelSubsShow, user?.email, user?.subscription_cancelled,user?.end_date, user?.start_date, user?.interval, user?.plan_name, user?.plan_status]);
}
