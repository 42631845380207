import React from 'react';
import styles from './styles.module.css';
import BookmarkImg1 from '../../assets/img/lp/bookmark1.png';
import BookmarkImg2 from '../../assets/img/lp/bookmark2.png';
import BookmarkImg4 from '../../assets/img/lp/bookmark4.png';
import BookmarkImg5 from '../../assets/img/lp/bookmark5.png';
import BookmarkImg6 from '../../assets/img/lp/bookmark6.png';
import BookmarkImg7 from '../../assets/img/lp/bookmark7.png';
import BookmarkImg3 from '../../assets/img/lp/bookmark3.png';
import BookmarkImg1BW from '../../assets/img/lp/bookmark1bw.png';
import BookmarkImg2BW from '../../assets/img/lp/bookmark2bw.png';
import BookmarkImg4BW from '../../assets/img/lp/bookmark4bw.png';
import BookmarkImg5BW from '../../assets/img/lp/bookmark5bw.png';
import BookmarkImg6BW from '../../assets/img/lp/bookmark6bw.png';
import BookmarkImg7BW from '../../assets/img/lp/bookmark7bw.png';
import BookmarkImg3BW from '../../assets/img/lp/bookmark3bw.png';
import LandingNav from '../../components/nav/LandingNav';
import { Link } from 'react-router-dom';
import ClientRouter from '../../routers/ClientRouter';
import { SummaryFooter } from './Summary';
import MetaTagsList from '../../components/MetaTagsList';

type Props = {
  colored?: boolean;
};

const BookmarkLP = ({ colored = true }: Props) => {
  return (
    <div className="bg-white">
      <MetaTagsList type="home" />
      <LandingNav
        buttons={[
          <div className="text-black d-flex align-items-center gap-3">
            Don’t have an account?{' '}
            <Link className="sparks-link--blue text-decoration-none" to="/register">
              <button className="sparks-button sparks-button-sm article-button--primary">
                Sign up
              </button>
            </Link>
          </div>,
        ]}
      />
      <div className={styles.bookmark}>
        <div className={styles.bookmarkItem}>
          <img
            src={colored ? BookmarkImg1 : BookmarkImg1BW}
            style={{ maxWidth: 572, minWidth: 300, width: '100%' }}
            alt="loading..."
          />
          <div className={styles.bookmarkItemBody}>
            <h2 className="sparks-h2 mb-3">Try Bookmark Manger for Free</h2>
            <div className="body-text-max text-black mb-4">
              Do not just bookmark, save web links and upload local PDF files to create your
              personalized library of organized content
            </div>
            <div>
              <Link to={ClientRouter.register()}>
                <button className={styles.bookmarkItemButton + ' button-reset-defaults'}>
                  Sign Up
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.bookmarkItem}>
          <img
            src={colored ? BookmarkImg2 : BookmarkImg2BW}
            style={{ maxWidth: 676, minWidth: 300, width: '100%' }}
            alt="loading..."
          />
          <div className={styles.bookmarkItemBody}>
            <h2 className="sparks-h2 mb-3">Organize</h2>
            <div className="body-text-max text-black">
              GetSparks can help you untangle your bookmark mess. Create a topic and save bookmark
              based on context.
            </div>
          </div>
        </div>
        <div className={styles.bookmarkItem}>
          <img
            src={colored ? BookmarkImg3 : BookmarkImg3BW}
            style={{ maxWidth: 677, minWidth: 300, width: '100%' }}
            alt="loading..."
          />
          <div className={styles.bookmarkItemBody}>
            <h2 className="sparks-h2 mb-3">Search</h2>
            <div className="body-text-max text-black">
              Full content including page title and URL is searchable. Find your saved content with
              quick search.
            </div>
          </div>
        </div>
        <div className={styles.bookmarkItem}>
          <img
            src={colored ? BookmarkImg7 : BookmarkImg7BW}
            style={{ maxWidth: 601, minWidth: 300, width: '100%' }}
            alt="loading..."
          />
          <div className={styles.bookmarkItemBody}>
            <h2 className="sparks-h2 mb-3">Notes</h2>
            <div className="body-text-max text-black">
              Found a specific details or analysis you want to remember? GetSparks makes it easy to
              add notes next to saved pages. Review amd analyze notes anytime.
            </div>
          </div>
        </div>
        <div className={styles.bookmarkItem}>
          <img
            src={colored ? BookmarkImg5 : BookmarkImg5BW}
            style={{ maxWidth: 612, minWidth: 300, width: '100%' }}
            alt="loading..."
          />
          <div className={styles.bookmarkItemBody}>
            <h2 className="sparks-h2 mb-3">Summarize</h2>
            <div className="body-text-max text-black">
              Read summary of the articles and other important artifacts before reading full text
              version. Having a context summary not only saves you time but also helps you retain
              more details.
            </div>
          </div>
        </div>
        <div className={styles.bookmarkItem}>
          <img
            src={colored ? BookmarkImg6 : BookmarkImg6BW}
            style={{ maxWidth: 612, minWidth: 300, width: '100%' }}
            alt="loading..."
          />
          <div className={styles.bookmarkItemBody}>
            <h2 className="sparks-h2 mb-3">Ask Questions</h2>
            <div className="body-text-max text-black">
              Utilize AI to retrieve information by asking simple questions about the article.
              Generate top questions and get analyzed answers that helps you enhance learning about
              the topic without going through in lot of details
            </div>
          </div>
        </div>
        <div className={styles.bookmarkItem}>
          <img
            src={colored ? BookmarkImg4 : BookmarkImg4BW}
            style={{ maxWidth: 496, minWidth: 300, width: '100%' }}
            alt="loading..."
          />
          <div className={styles.bookmarkItemBody}>
            <h2 className="sparks-h2 mb-3">Discover Content</h2>
            <div className="body-text-max text-black">
              Discover more topics and similar content based on your interests. Modify filters to
              update what content you wan to see in your discover feed.
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bookmarkSubFooter}>
        <div className={styles.bookmarkSubFooterInner}>
          <h3 className="mb-3">Save bookmarks for pages you need to research later</h3>
          <div className="body-text-max mb-5">
            Add articles, videos, and links from across the web in getSparks to personalize your
            collection. Use our app to enjoy them on any device in a distraction free environment.
          </div>
          <div>
            <Link to={ClientRouter.register()}>
              <button className={styles.bookmarkItemButton + ' button-reset-defaults'}>
                Sign Up
              </button>
            </Link>
          </div>
        </div>
      </div>
      <SummaryFooter short />
    </div>
  );
};

export default BookmarkLP;
