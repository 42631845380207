import {useMemo} from "react";
import {JSONObject} from "../../data/models/Model";

export default function useJSONStringToObject(jsonString?: string) {
  return useMemo(() => {
    try {
      if (jsonString)
        return JSON.parse(jsonString) as (JSONObject | undefined);
    } catch (e) {
      console.warn(e);
    }
  }, [jsonString]);
}
