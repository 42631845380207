import React, { PropsWithChildren, useMemo } from 'react';

type Props = PropsWithChildren<{
  sticky?: boolean;
  noBottomSpace?: boolean;
  className?: string;
  style?: React.CSSProperties;
  noPadding?: boolean;
  noMaxWidth?: boolean;
}>;

const defaultStyle: React.CSSProperties = {
  backgroundColor: '#fff',
};

export function Header({
  sticky,
  children,
  noBottomSpace,
  className,
  style,
  noPadding,
  noMaxWidth,
}: Props) {
  className = className ?? '';
  className += ' page-header page-header-compact page-header-light border-bottom ';
  if (!noBottomSpace) {
    className += 'mb-4 ';
  }
  style = useMemo(() => {
    if (sticky) {
      return {
        ...defaultStyle,
        ...style,
        position: 'sticky',
        top: 0,
        zIndex: 1,
      };
    }
    return { ...defaultStyle, ...style };
  }, [sticky, style]);
  return (
    <header className={className} style={style}>
      <div className={`${noMaxWidth ? '' : 'container-xl'} ${noPadding ? '' : 'px-4'}`}>
        <div className="page-header-content">
          <div className="row align-items-center justify-content-between py-2 m-0">{children}</div>
        </div>
      </div>
    </header>
  );
}
