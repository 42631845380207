import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { faClipboard, faRectangleList } from '@fortawesome/free-regular-svg-icons';
import RoundedBorder from '../../../components/RoundedBorder';
import React from 'react';
import ArrowIcon from '../../../assets/img/icons/arrow.png';

type Props = {
  showSummarizeIcon?: boolean;
};

export default function FeaturesSection({ showSummarizeIcon }: Props) {
  return (
    <section className={'bg-white py-10'} id="get-started">
      <div className={'container px-5'}>
        <h1 className="page-header-ui-title text-black text-center mb-4">
          Make getSparks work for you
        </h1>
        <div className={'row gx-5 text-center'}>
          <div className="col-lg-4 mb-5 mb-lg-0">
            <div
              className={
                'icon-stack icon-stack-xxl bg-gradient-primary-to-secondary text-white mb-4'
              }
            >
              <FontAwesomeIcon icon={faFloppyDisk} style={{ width: '4rem', height: '4rem' }} />
            </div>
            <h2>Save</h2>
            <p className={'mb-0'}>
              Save content from everywhere and add to your collection to access anywhere
            </p>
          </div>
          <div className={'col-lg-4 mb-5 mb-lg-0 position-relative'}>
            {showSummarizeIcon && <img src={ArrowIcon} className="lp-arrow" alt="loading" />}
            <div
              className={
                'icon-stack icon-stack-xxl bg-gradient-primary-to-secondary text-white mb-4'
              }
            >
              <FontAwesomeIcon icon={faRectangleList} style={{ width: '4rem', height: '3rem' }} />
            </div>
            <h2>Summarize</h2>
            <p className={'mb-0'}>
              Extract summary of web articles and save time to focus on key points before diving
              deep
            </p>
          </div>
          <div className={'col-lg-4'}>
            <div
              className={
                'icon-stack icon-stack-xxl bg-gradient-primary-to-secondary text-white mb-4'
              }
            >
              <FontAwesomeIcon icon={faClipboard} style={{ width: '3rem', height: '4rem' }} />
            </div>
            <h2>Add Note</h2>
            <p className={'mb-0'}>Add notes to any web page for future reference</p>
          </div>
        </div>
      </div>
      <RoundedBorder className={'text-light'} />
    </section>
  );
}
