import React, { useCallback, useMemo } from 'react';
import Tag from '../../../data/models/Tag';
import cn from 'classnames';
import TextShortener from '../../../components/TextShortener';
import useMenu from '../../../hooks/custom/useMenu';
import { Colors } from '../../../assets/colors';
import { ReactComponent as DotsIcon } from '../../../assets/img/icons/dots.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../../assets/img/icons/edit.svg';
import { useTagClick } from '../topic/tags/FilterByTags';
import { useAppSelector } from '../../../data/redux/hooks';
import { getTagCRUD } from '../../../services/cruds';
import { setTag } from '../../../data/redux/actions';

type Props = {
  tag: Tag;
};

const NavTagsItem = ({ tag }: Props) => {
  const { ItemContainer, MenuContainer, toggleMenu, placeRef, menuVisible } = useMenu();
  const selectedTags = useAppSelector(state => state.cache.tagsFilter);
  const tags = useAppSelector(useCallback(state => Object.values(state.cache.tags), []));
  const onTagClick = useTagClick();
  const crud = getTagCRUD(setTag, tags);

  const menuRendered = useMemo(() => {
    return (
      <MenuContainer topOffset={30}>
        <crud.delete
          defaultValues={{
            id: tag.id,
          }}
          formRef={ref => placeRef(ref, 1)}
          nonLinear
          actionButton={onClick => (
            <ItemContainer onClick={onClick}>
              <DeleteIcon width={15} height={15} fill={Colors.blueGray00} />
              <span>Delete</span>
            </ItemContainer>
          )}
        ></crud.delete>
        <crud.patch
          defaultValues={{ id: tag.id, title: tag.title }}
          nonLinear
          formRef={ref => placeRef(ref, 2)}
          actionButton={onClick => (
            <ItemContainer onClick={onClick}>
              <EditIcon width={15} height={15} fill={Colors.blueGray00} />
              <span>Rename</span>
            </ItemContainer>
          )}
        ></crud.patch>
      </MenuContainer>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuVisible, tag]);

  return (
    <div className={'flex-1 parent-hover position-relative sidenav-item_container w-100'}>
      <div
        className={cn('sidenav-item overflow-hidden text-capitalize position-relative', {
          active: selectedTags.includes(tag.id),
        })}
        onClick={e => onTagClick(e, tag.id)}
      >
        <TextShortener text={tag.title} className="sidenav-item_title" />
      </div>
      <div>
        <button
          ref={ref => placeRef(ref, 0)}
          className="sidenav-item_icon position-absolute"
          onClick={toggleMenu}
        >
          <DotsIcon fill="black" />
        </button>
        {menuRendered}
      </div>
    </div>
  );
};

export default NavTagsItem;
