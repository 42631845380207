import React, { useCallback, useMemo, useState } from 'react';
import ClientRouter from '../../routers/ClientRouter';
import NetworkJSONLoader from '../../components/network/NetworkJSONLoader';
import ServerRouter from '../../routers/ServerRouter';
import { AuthContainer, AuthContainerProps } from './AuthContainer';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../data/redux/hooks';
import { RootState } from '../../data/redux/store';
import { login } from '../../data/redux/authUtils';
import GoogleButton from '../../components/buttons/text/GoogleButton';

export default function AuthFormRepresentation({
  title,
  body,
  footer,
  colSize,
  isLogin,
  header,
  hideGoogleLogin,
}: AuthContainerProps) {
  const user = useAppSelector(useCallback((state: RootState) => state.cache.user, []));
  const [tokenId, setTokenId] = useState<string>();
  const [oAuthError, setOAuthError] = useState<string>();
  const { search } = useLocation();
  const requestBody = useMemo(() => {
    return { tokenId: tokenId };
  }, [tokenId]);
  const social = useMemo(() => {
    if (tokenId) {
      return (
        <NetworkJSONLoader
          url={ServerRouter.googleOAuth()}
          onOk={login}
          body={requestBody}
          method={'POST'}
          onCancel={() => setTokenId(undefined)}
          children={'Exchanging authorization tokens'}
        />
      );
    }
    if (process.env.REACT_APP_GOOGLE_CLIENT_ID && !hideGoogleLogin) {
      return (
        <div>
          {oAuthError && <div className={'field-error'}>{oAuthError}</div>}
          <GoogleButton setOAuthError={setOAuthError} setTokenId={setTokenId} />
          <div className="or-separator mt-2">
            <div>OR</div>
          </div>
        </div>
      );
    } else {
      console.warn('Google client id is unknown');
    }
  }, [oAuthError, requestBody, tokenId, hideGoogleLogin]);
  return useMemo(() => {
    if (user) {
      if (search) {
        return <Navigate to={{ pathname: ClientRouter.save(), search }} />;
      }
      return <Navigate to={ClientRouter.dashboard()} replace={true} />;
    }
    return (
      <AuthContainer
        title={title}
        body={body}
        footer={footer}
        isLogin={isLogin}
        colSize={colSize}
        header={
          <>
            <div className="mb-4">
              <div className="sparks-h3" style={{ textAlign: 'left' }}>
                {title}
              </div>
              {header}
            </div>
            {social}
          </>
        }
      />
    );
  }, [body, colSize, footer, social, title, user, header, isLogin, search]);
}
