import React, { useEffect } from 'react';
import 'react-google-one-tap-login';
import { setTokenId } from '../../data/redux/actions';

export default function GoogleOneTap() {
  useEffect(() => {
    try {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: async (response: any) => {
          if ('credential' in response) {
            setTokenId(response['credential']);
          }
        },
      });
      window.google.accounts.id.prompt();
    } catch (e) {
      console.warn('google auth error ===', e);
    }
  }, []);
  return <div />;
}
