import React, {useCallback, useMemo} from 'react';
import {useAppSelector} from "../../../data/redux/hooks";
import {RootState} from "../../../data/redux/store";
import NotesInfo from "./NotesInfo";
import Topic from "../../../data/models/Topic";
import {setTopicNote} from "../../../data/redux/actions";
import {getTopicNotesCRUD, getTopicNotesManageCRUD} from "../../../services/cruds";

type Props = {
  topic: Topic,
};

export default function TopicNotesInfo({topic}: Props) {
  const notes = useAppSelector(useCallback((state: RootState) => topic.getNotes(state.cache), [topic]));
  const notesCluster = useMemo(() => ({
    notes: notes,
    getModalCRUD: getTopicNotesCRUD,
    getInlineCRUD: getTopicNotesManageCRUD,
    onOk: setTopicNote,
    defaultValues: {topic: topic.id}
  }), [notes, topic.id]);
  return useMemo(() => <NotesInfo name={'Topic Notes'} notesCluster={notesCluster}/>, [notesCluster]);
}
