import React from 'react';
import addArticle from '../../../../assets/img/extension/add-article.gif';

export default function ExtensionAddPage() {
  return (
    <div>
      <p>Add page to getSparks following steps below.</p>
      <ol>
        <li>Open page in the browser.</li>
        <li>Click on the extension icon.</li>
      </ol>
      <div className={'w-100 text-center'}>
        <img style={{maxWidth: '75%'}} src={addArticle} alt={'Adding an article'}/>
      </div>
    </div>
  );
}
