import React, { useEffect } from 'react';
import type { Location, NavigateFunction } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';

type RouterUtilsContextType = {
  navigateRef: React.MutableRefObject<NavigateFunction> | null;
  locationRef: React.MutableRefObject<Location> | null;
};
const RouterUtilsContext = React.createContext<RouterUtilsContextType>({
  navigateRef: null,
  locationRef: null,
});

const RouterUtils: React.FC<{ children: React.ReactChild }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigateRef = React.useRef(navigate);
  const locationRef = React.useRef(location);
  navigateRef.current = navigate;
  locationRef.current = location;
  const contextValue = React.useMemo(() => {
    return { navigateRef, locationRef };
  }, [locationRef, navigateRef]);
  return <RouterUtilsContext.Provider value={contextValue}>{children}</RouterUtilsContext.Provider>;
};

export const useNavigateNoUpdates = () => {
  const { navigateRef } = React.useContext(RouterUtilsContext);
  if (navigateRef === null) {
    throw new Error(
      'RouterUtils context should be added to the React tree right below BrowserRouter for useNavigateNoUpdates hook ' +
        'to work. If you need router in tests or stories, please use WrappedMemoryRouter utility.',
    );
  }
  return navigateRef.current;
};

export const useLocationNoUpdates = () => {
  const { locationRef } = React.useContext(RouterUtilsContext);
  if (locationRef === null) {
    throw new Error(
      'RouterUtils context should be added to the React tree right below BrowserRouter for useLocationNoUpdates hook ' +
        'to work. If you need router in tests or stories, please use WrappedMemoryRouter utility.',
    );
  }
  return locationRef.current;
};

export const useQueryParamsNoUpdates = () => {
  const { search } = useLocationNoUpdates();

  return React.useMemo(() => {
    const urlSearchParams = new URLSearchParams(search);
    return Object.fromEntries(urlSearchParams.entries());
  }, [search]);
};

export const useQueryParamNoUpdates = (name: any) => {
  const params = useQueryParamsNoUpdates();

  if (!name) {
    throw new Error(`useQueryParam name arg cannot be empty — name: ${name}`);
  }

  return params[name];
};

export function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scroll({ left: 0, top: 0, behavior: 'auto' });
  }, [pathname]);

  return null;
}

export default RouterUtils;
