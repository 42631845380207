import React, { PropsWithChildren, ReactNode } from 'react';

type Props = PropsWithChildren<{
  error?: ReactNode;
  className?: string;
  small?: boolean;
}>;

export default function Awaiter({ children, error, className, small }: Props) {
  return (
    <div className={className ?? (children ? 'text-center p-3' : undefined)}>
      <div className={`spinner-border ${small ? 'spinner-border-sm' : ''}`} />
      {children && <div>{children}</div>}
      {error && <div className={'field-error'}>{error}</div>}
    </div>
  );
}
