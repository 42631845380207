import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { getHeaders } from '../../../routers/utils';
import ServerRouter from '../../../routers/ServerRouter';

const SetupForm = (props: any) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);

    const intent = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: 'http://localhost:3000/dashboard/',
      },
      redirect: 'if_required',
    });
    if (intent.error) {
      setErrorMessage(intent.error.message || '');
      setIsLoading(false);
    } else {
      const response = await fetch(ServerRouter.updateSubscription(), {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({
          yearly: props.yearlySub,
          paymentMethod: intent.setupIntent.payment_method,
        }),
      });
      if (response) {
        props.closeModal();
      }
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="d-flex flex-column">
      <PaymentElement />
      <button
        className="button-variant-1 article-button--primary mt-3 align-self-center"
        disabled={isLoading}
      >
        {isLoading ? <span className="spinner-border spinner-border-sm"></span> : 'Submit'}
      </button>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

export default SetupForm;
