import React from 'react';
import LandingNav from '../../components/nav/LandingNav';
import heroImage from '../../assets/img/how-does-getsparks-work/heroImage_article.png';
import CWS from '../../assets/img/how-does-getsparks-work/CWS.png';
import extensionSummary from '../../assets/img/how-does-getsparks-work/extensionSummary.png';
import AppAddNew from '../../assets/img/how-does-getsparks-work/AppAddNew.png';
import AppAddNew1 from '../../assets/img/how-does-getsparks-work/AppAddNew1.png';
import ViewSummaryApp from '../../assets/img/how-does-getsparks-work/ViewSummaryApp.png';
import ViewPageApp from '../../assets/img/how-does-getsparks-work/ViewPageApp.png';
import Organize from '../../assets/img/how-does-getsparks-work/Organize.png';
import Search from '../../assets/img/how-does-getsparks-work/Search.png';
import AddNoteImg from '../../assets/img/how-does-getsparks-work/AddNoteApp.png';
import extensionNote from '../../assets/img/how-does-getsparks-work/extensionNote.png';
import extensionSave from '../../assets/img/how-does-getsparks-work/extensionSave.png';
import { HashLink } from 'react-router-hash-link';
import ClientRouter from '../../routers/ClientRouter';
import RoundedBorder from '../../components/RoundedBorder';
import LandingFooter from '../../components/footer/LandingFooter';
import ExternalLink from '../../components/network/ExternalLink';
import { WEB_EXTENSION_URL } from '../../services/constants';
import { Link } from 'react-router-dom';
import MetaTagsList from '../../components/MetaTagsList';

type Paragraph = {
  name: string;
  content?: React.ReactNode;
  subParagraphs?: Paragraph[];
};

const content: Paragraph[] = [
  {
    name: 'View Summary',
    content: (
      <>
        <p>
          Please follow simple steps below to generate and view summary of almost any web page you
          browse. Please note that some pages have restrictions and are in specific format that does
          not allow getSparks to parse the content. Few examples of such pages include, content
          behind login, and PDFs.
        </p>
      </>
    ),
    subParagraphs: [
      {
        name: 'Method 1: Using Browser Extension',
        content: (
          <>
            <p>
              Step 1: Add{' '}
              <ExternalLink to={WEB_EXTENSION_URL}>getSparks web Extension</ExternalLink>.
            </p>
            <p className={'text-center'}>
              <img src={CWS} alt="save page" style={{ maxWidth: '75%' }} />
            </p>
            <p>Step 2: Open web page in Chrome browser and click on view summary icon</p>
            <p className={'text-center'}>
              <img src={extensionSummary} alt="save page" style={{ maxWidth: '75%' }} />
            </p>
          </>
        ),
      },
      {
        name: 'Method 2: Using Web Application',
        content: (
          <>
            <p>
              Step 1: Go to{' '}
              <ExternalLink to={'https://getsparks.io/dashboard/'}>
                https://getsparks.io
              </ExternalLink>{' '}
              dashboard and click “Add New”
            </p>
            <p className={'text-center'}>
              <img src={AppAddNew} alt="copy-url" style={{ maxWidth: '75%' }} />
            </p>
            <p>Step 2: Paste the web link in URL field and click add.</p>
            <p className={'text-center'}>
              <img src={AppAddNew1} alt="paste-url" style={{ maxWidth: '75%' }} />
            </p>
            <p>Step 3: Click on the saved article to view content.</p>
            <p className={'text-center'}>
              <img src={ViewPageApp} alt="paste-url" style={{ maxWidth: '75%' }} />
            </p>
            <p>Step 4: Click on Summary icon to view Summary.</p>
            <p className={'text-center'}>
              <img src={ViewSummaryApp} alt="paste-url" style={{ maxWidth: '75%' }} />
            </p>
          </>
        ),
      },
    ],
  },
  {
    name: 'Add Notes',
    content: (
      <>
        <p>
          Add your personalized notes to any live web page and reference back when you visit the
          page again. This can be really helpful if you want to do research and share notes across
          multiple pages.
        </p>
      </>
    ),
    subParagraphs: [
      {
        name: 'Method 1: Using Browser Extension',
        content: (
          <>
            <p>
              Step 1: Add{' '}
              <ExternalLink to={WEB_EXTENSION_URL}>getSparks web Extension</ExternalLink>.
            </p>
            <p className={'text-center'}>
              <img src={CWS} alt="save page" style={{ maxWidth: '75%' }} />
            </p>
            <p>Step 2: Open web page in Chrome browser and click on view notes icon</p>
            <p className={'text-center'}>
              <img src={extensionNote} alt="save page" style={{ maxWidth: '75%' }} />
            </p>
          </>
        ),
      },
      {
        name: 'Method 2: Using Web Application',
        content: (
          <>
            <p>
              Step 1: Go to{' '}
              <ExternalLink to={'https://getsparks.io/dashboard/'}>
                https://getsparks.io
              </ExternalLink>
              dashboard and click “Add New”
            </p>
            <p className={'text-center'}>
              <img src={AppAddNew} alt="save page" style={{ maxWidth: '75%' }} />
            </p>
            <p>Step 2: Paste the web URL in field and click add.</p>
            <p className={'text-center'}>
              <img src={AppAddNew1} alt="save page" style={{ maxWidth: '75%' }} />
            </p>
            <p>Step 3: Click on the saved article to view content.</p>
            <p className={'text-center'}>
              <img src={ViewPageApp} alt="save page" style={{ maxWidth: '75%' }} />
            </p>
            <p>
              Step 4: Click on Notes icon to add page note or Shared Notes icon to add Topic Note.
            </p>
            <p className={'text-center'}>
              <img src={AddNoteImg} alt="save page" style={{ maxWidth: '75%' }} />
            </p>
          </>
        ),
      },
    ],
  },
  {
    name: 'Save to Read Later',
    content: (
      <>
        <p>
          Save almost any web page. Please note that some pages have restrictions and are in
          specific format that does not allow getSparks to save the content. In case we are not able
          to parse the content to save a page we add the page as a bookmark. Few examples of such
          pages include, content behind login, and PDFs, etc. To generate and view summary please
          follow simple steps.
        </p>
      </>
    ),
    subParagraphs: [
      {
        name: 'Method 1: Using Browser Extension',
        content: (
          <>
            <p>
              Step 1: Add{' '}
              <ExternalLink to={WEB_EXTENSION_URL}>getSparks web Extension</ExternalLink>.
            </p>
            <p className={'text-center'}>
              <img src={CWS} alt="save page" style={{ maxWidth: '75%' }} />
            </p>
            <p>Step 2: Open web page in Chrome browser and click on Add page icon</p>
            <p className={'text-center'}>
              <img src={extensionSave} alt="save page" style={{ maxWidth: '75%' }} />
            </p>
          </>
        ),
      },
      {
        name: 'Method 2: Using Web Application',
        content: (
          <>
            <p>
              Step 1: Go to{' '}
              <ExternalLink to={'https://getsparks.io/dashboard/'}>
                https://getsparks.io
              </ExternalLink>
              dashboard and click “Add New”
            </p>
            <p className={'text-center'}>
              <img src={AppAddNew} alt="save page" style={{ maxWidth: '75%' }} />
            </p>
            <p>Step 2: Paste the web URL in field and click add.</p>
            <p className={'text-center'}>
              <img src={AppAddNew1} alt="save page" style={{ maxWidth: '75%' }} />
            </p>
            <p>Step 3: Click on the saved article to view content.</p>
            <p className={'text-center'}>
              <img src={ViewPageApp} alt="save page" style={{ maxWidth: '75%' }} />
            </p>
          </>
        ),
      },
    ],
  },
  {
    name: 'Organize Content',
    content: (
      <>
        <p>
          We believe that less is more when it comes to organizing. Create topics in your account
          and organize your web content by topic.{' '}
        </p>
        <p className={'text-center'}>
          <img src={Organize} alt="save page" style={{ maxWidth: '75%' }} />
        </p>
      </>
    ),
  },
  {
    name: 'Search Content',
    content: (
      <>
        <p>
          With advanced search capabilities you can search for web content across web urls, article
          content, titles, notes, highlights and topics.
        </p>
        <p>
          Type your search keyword in the search box at the top of the app to see results across
          your saved content.{' '}
        </p>
        <p className={'text-center'}>
          <img src={Search} alt="save page" style={{ maxWidth: '75%' }} />
        </p>
        <p>
          With getSparks you can access your saved content anytime on your device by simply logging
          in to your account.
        </p>
      </>
    ),
  },
  {
    name: 'Analysis',
    subParagraphs: [
      {
        name: 'Machine Learning to Help You Analyze',
        content: (
          <>
            <p>
              GetSparks uses advanced algorithms to make it easier for users to consume web content
              and presents data in different views to make decision making faster and easier for
              users.
            </p>
            <p>Some of the key analytical features include:</p>
            <ul>
              <li>Text Summary</li>
              <li>Keyword Analysis</li>
              <li>Sentiment Analysis</li>
              <li>Readability score</li>
              <li>Person/People Analysis</li>
              <li>Category assignment and personalized view based on the content vertical</li>
              <li>Timeline journey</li>
            </ul>
            <p>
              Visit <Link to={ClientRouter.home()}>getSparks</Link> to Learn more and provide
              feedback as we add more features.
            </p>
          </>
        ),
      },
    ],
  },
];

export default function HowDoesGetSparksWork() {
  return (
    <div id="layoutDefault">
      <MetaTagsList type="home" />
      <div id="layoutDefault_content">
        <main>
          <LandingNav />
          <section className="bg-white">
            <div className="container px-5">
              <div className="row gx-5 justify-content-center">
                <div className="col-lg-10 mt-5">
                  <div className={'text-center'}>
                    <h1 className="mb-1">How Does getSparks Work?</h1>
                    <p>
                      <img
                        src={`${window.location.origin}${heroImage}`}
                        alt="How Does Sparks Work?"
                        className="img-fluid"
                        style={{ maxWidth: '400px' }}
                      />
                    </p>
                  </div>
                  <p>
                    getSparks is a platform that enables users to be more productive. It offers
                    tools for users to
                    <b> save web pages to read later</b> (better than bookmark) ,
                    <b> to summarize web pages</b>, and
                    <b> to add personalized notes to live web pages</b>. getSparks helps you
                    retrieve, analyze, and take actions on web content.
                  </p>
                  <p>See below to learn more about how to utilize getSparks to be productive.</p>
                  {content.map((p, index) => {
                    const id = p.name.replaceAll(' ', '');
                    return (
                      <div key={index}>
                        <div>
                          <HashLink to={ClientRouter.howDoesSparksWork() + '#' + id}>
                            {p.name}
                          </HashLink>
                        </div>
                        {p.subParagraphs &&
                          p.subParagraphs.map((sp, index) => (
                            <div key={index} className={'ms-4'}>
                              <HashLink
                                to={
                                  ClientRouter.howDoesSparksWork() +
                                  '#' +
                                  id +
                                  '_' +
                                  sp.name.replaceAll(' ', '')
                                }
                              >
                                {sp.name}
                              </HashLink>
                            </div>
                          ))}
                      </div>
                    );
                  })}
                  <hr className="my-5" />
                  {content.map((p, index) => {
                    const id = p.name.replaceAll(' ', '');
                    return (
                      <div key={index}>
                        <h2 id={id} className="mb-3">
                          {index + 1}. {p.name}
                        </h2>
                        {p.content}
                        {p.subParagraphs &&
                          p.subParagraphs.map((sp, index) => (
                            <div key={index}>
                              <h3 id={id + '_' + sp.name.replaceAll(' ', '')}>{sp.name}</h3>
                              {sp.content}
                            </div>
                          ))}
                        <hr className="my-5" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <RoundedBorder className={'text-dark'} />
          </section>
        </main>
      </div>
      <LandingFooter />
    </div>
  );
}
