import { useCallback, useEffect, useMemo, useRef } from 'react';
import { processJSONObjectResponse } from '../../routers/utils';
import { JSONObject } from '../../data/models/Model';
import { useLocation } from 'react-router-dom';
import ServerRouter from '../../routers/ServerRouter';

type Props = {
  url?: string;
  apply: (json: JSONObject[] | null) => void;
  interval?: number;
};

const DEFAULT_INTERVAL = 5000;

export default function useContentRefresh({ apply, interval }: Props) {
  const location = useLocation();
  const isNotArticlePage = useMemo(
    () => !location.pathname.includes('/articles/'),
    [location.pathname],
  );
  const refreshTimeout = useRef<number>();

  const refreshContent = useCallback(() => {
    const url = ServerRouter.articles();
    processJSONObjectResponse({
      url,
      onOk: json => {
        json && apply(json?.results || []);
        if (isNotArticlePage) {
          refreshTimeout.current = window.setTimeout(refreshContent, interval ?? DEFAULT_INTERVAL);
        }
      },
      onError: error => {
        console.warn('Failed to refresh articles', error);
        if (isNotArticlePage) {
          refreshTimeout.current = window.setTimeout(refreshContent, interval ?? DEFAULT_INTERVAL);
        }
      },
    });
  }, [apply, interval, isNotArticlePage]);
  useEffect(() => {
    if (isNotArticlePage) {
      window.clearTimeout(refreshTimeout.current);
      refreshTimeout.current = window.setTimeout(refreshContent, interval ?? DEFAULT_INTERVAL);
    }
    return () => {
      if (refreshTimeout.current) {
        window.clearTimeout(refreshTimeout.current);
      }
    };
  }, [interval, refreshContent, isNotArticlePage]);
}
